import React from 'react'

function Aside() {
  return (
      
  //   <div
  //   className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
  //   style={{ backgroundImage: "url(assets/media/misc/auth-bg.png)" }}
  // >
     <div
    className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
    style={{ backgroundImage: "url(assets/media/images/StMinaAppLogin.jpg)" }}>
    {/* <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
      <h3>St Mina</h3>
      <img
        className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
        src="assets/media/images/StMinaAppLogin.jpg"
        alt=""
      />
      <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
        Fast, Efficient and Productive
      </h1>
      Fast, Efficient and Productive Logistics
      St. Mina Transport Web App is a private logistics management system designed to improve the efficiency and productivity of the company's operations, providing a fast and user-friendly interface for managing transportation and delivery processes, including tracking deliveries, scheduling pickups, and monitoring fleet performance.
     
      <div className="d-none d-lg-block text-white fs-base text-center">
        In this kind of post,
        <a href="#" className="opacity-75-hover text-warning fw-bold me-1">
          the blogger
        </a>
        introduces a person they’ve interviewed
        <br />
        and provides some background information about
        <a href="#" className="opacity-75-hover text-warning fw-bold me-1">
          the interviewee
        </a>
        and their
        <br />
        work following this is a transcript of the interview.
      </div>
    </div> */}
  </div>
  )
}

export default Aside