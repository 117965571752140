
import React, {useState, useEffect} from 'react';
import {NavLink, Outlet, useOutletContext} from 'react-router-dom';
import { ViewDatePicker } from '../../components/common';
import Selector from "../../components/common/Selector"
import {useDispatch, useSelector} from "react-redux"
import { Table } from "../../components/common";
import { getPartners } from '../../actions';

export const partnerstableHeads = [
  "Partner Name",
  "ABN",
  "Contractor Name",
  "Contractor ABN",
  "Status",
  "Edit",
  "Delete",
];


const PartnerList = () => {
    const setOpen=useOutletContext()//function that we pass as a props in Outlet in index.js file
    const [active, setActive] = useState(0)
    const { partners, loader } =  useSelector(({partner})=>partner)
    const dispatch = useDispatch();

    useEffect(() => {
     dispatch(getPartners())
    }, [])
    
  return (
    
    <div className="col-xl-8 mb-5 mb-xl-10" style={{width:"100%"}} >     
    <div className="card card-flush h-md-100">
      <div className="card-body">
      <div className="tab-content">
    <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
      <div className="table-responsive">
        <div className="card-title flex-column">
        <h3 className="fw-bold mb-1">Partners</h3>
        </div>
        </div>

        <hr />
        <div className="table-responsive" style={{padding: "0 -20px"}}>
              <Table
            lable="partners"
            tableRows={partners?.rows}
            tableHeads={partnerstableHeads}
            setOpen={setOpen?.length?setOpen[0]:""}
          />
          </div>
      </div>
    </div>
  </div>       
      </div>      
      </div>

)
}

export default PartnerList;