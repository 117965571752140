import React, {useState, useEffect, useRef} from 'react'
import {Selector,ViewDatePicker} from "../../components/common"
import {useNavigate, Link} from 'react-router-dom';
import {  NotificationManager } from 'react-notifications';
import {updateDriver, getSandElist, uploadDriverDocument, updateSorterDriver}  from '../../actions';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form,useFormik} from 'formik';
import Loader from "../../components/common/loader"
import * as yup from 'yup';

import {
  validator,
  name,
  num,
  email,
  phoneRegExp,
  upperLower,
  digit,
  specialChar,
  charLength,
  password
} from "../../helpers/validation";

const type=[
"employee",
"contractor"
]

const driverTypeOptions = [
  "driver",
  'sorter',
  'both'
]

function Edit({}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {sorterDriver:editDriver={}} = useSelector((driver)=>driver.driver)
  const {sAndEmpList=[] } = useSelector(({driver})=>driver)
  const {loader} = useSelector(({driver})=>driver)
  const {areas,options} = useSelector(({area})=>area);
  const [getSandElistVal, setSandElistVal ] = useState([]);
  const[Document,setDocument]=useState("")
  const driverDocument = useRef(null);

  let rateType = []
  let rateType1=[]

  const handleData =()=>{
    sAndEmpList?.length && sAndEmpList?.map((data,index)=> {return data.rateType === 1 ? rateType.push(data) : rateType1.push(data)})
    }
  handleData()

  useEffect(()=>{
    dispatch(getSandElist(editDriver.id))
  },[])

  const initialValues ={
    fname:editDriver?.firstName,
    lname:editDriver?.lastName,
    mobile_number: editDriver?.mobileNumber,
    home_number: editDriver?.homeNumber,
    abn: editDriver?.abn,
    tfn: editDriver?.tfn,
    delivery_area: editDriver?.areas?.map((it)=>{
      return it.id
    }),
    employment_from: editDriver?.from,
    employment_type: editDriver?.employmentType,
    salary: editDriver?.wages,
    car_plate_number: editDriver?.carPlateRegistrationNumber,
    registration_expiry_date: editDriver?.registrationExpiryDate,
    insurance_expiry_date: editDriver?.insuranceExpiryDate,
    insurance_number: editDriver?.insurancePolicyNumber,
    scanner_number: editDriver?.scannerNumber,
    scanner_user_name: editDriver?.scannerUserName,
    scanner_password: editDriver?.scannerPassword,
    driverDocuments:editDriver?.driversDocuments?.length>0?editDriver?.driversDocuments.map((ele)=>ele.documentURL):[],
    // driver_number: editDriver?.driver_number,
    email: editDriver.email,
    password: editDriver.password,
    driver_type:editDriver?.driverType
    
    }
  const validation = yup.object({
    driver_type:yup.string()
      .required("driver type is required"),
    fname:yup.string()
      .required("First Name is required")
            .matches(name, "invalid name")
      .min(2, "first name must not be less than 2 characters")
      .max(30, "first name must not be greater than 30 characters"),
    lname: yup
      .string()
      // .required("Last Name is required")
      .nullable()
      .matches(name, "invalid name")
      .min(2, "last name must not be less than 2 characters")
      .max(30, "last name must not be greater than 30 characters"),
    mobile_number: yup.string().nullable(),
    // .required("Mobile Number is required"),
    home_number: yup.number().nullable(),
    // .required("Home Number is required"),
    abn: yup
      .string()
      .nullable()
      .typeError("must be a number")
      // .required("abn is required")
      .matches(num, "Please provide 11 digit abn number")
      .min(11, "abn number must not be less than 11 digits")
      .max(11, "abn number must not be greater than 11 digits"),
    tfn: yup
      .string()
      .nullable()
      .typeError("must be a number")
      // .required("tfn number is required")
      .matches(num, "Please provide 9 digits tfn number")
      .min(9, "tfn number must not be less than 9 digits")
      .max(9, "tfn number must not be greater than 9 digits"),
    delivery_area: yup.array(),
    // .min(1, "Must select atleast one the given options"),
    employment_type: yup.string().nullable(),
    // .required("employment type is required"),
    employment_from: yup.date().nullable(),
    // .max(new Date(), ` date must not be greater than ${new Date().toLocaleDateString()}`),
    salary: yup
      .number()
      .nullable()
      .when("employment_type", {
        is: "employee",
        then: yup
          .number()
          .nullable()
          .typeError("must be a number")
          .required("Must enter salary"),
      }),
    car_plate_number: yup.string().nullable(),
    // .required("car plate number is required"),
    registration_expiry_date: yup.date().nullable(),
    // .required("registration expiry date is required"),
    insurance_expiry_date: yup.date().nullable(),
    // .required("Insurance expiry date is required"),
    insurance_number: yup.string().nullable(),
    // .required("insurance number is required"),
    scanner_number: yup
      .number()
      .positive()
      .integer()
      .typeError("must be a number")
      .nullable(),
    scanner_user_name: yup.string().nullable(),
    // .required("scanner user name is required"),
    scanner_password: yup.string().nullable(),
    // .required("scanner password is required"),
    password: yup
      .string()
      .required("Please enter a password")
      .matches(upperLower, "password must include uppercase & lowercase characters")
      .matches(digit, "password must include one digit")
      .matches(specialChar, "password must include specialChar")
      .matches(charLength, "password must be 8 characters long"),
    email:yup.string()
      .required("Email is required")
      .matches(email, "please enter valid email"),
  })

  const handleSubmit= (values, {resetForm})=>{
    if(values){
      const driver = {
        firstName: values?.fname,
        lastName: values?.lname,
        tfn: values.tfn,
        homeNumber: values.home_number,
        abn: values.abn,
        mobileNumber: values.mobile_number,
        registrationExpiryDate: values.registration_expiry_date,
        insuranceExpiryDate: values.insurance_expiry_date,
        carPlateRegistrationNumber: values.car_plate_number,
        employmentType: values.employment_type,
        from: values.employment_from,
        insurancePolicyNumber: values.insurance_number,
        scannerNumber: values.scanner_number,
        scannerPassword: values.scanner_password,
        scannerUserName: values.scanner_user_name,
        wages: values.salary,
        areas: values.delivery_area,
        driverDocuments:values.driverDocuments,
        password: values.password,
        email: values.email,
        driverType:values.driver_type
      }
      dispatch(updateSorterDriver(driver,navigate))
    }
    else NotificationManager.error('please fill data')
  }
  const handleDocuments=async (e)=>{
    e.preventDefault();
    const data = new FormData();
    data.append("file",Document);
    const response = await uploadDriverDocument(data)
    formik.setFieldValue("driverDocuments", [...formik.values.driverDocuments,response])
    setDocument("")
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const errorElement = document.getElementsByName(Object.keys(formik.errors)[0])[0];
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      errorElement.focus({ preventScroll: true });
    }
  }, [formik]);

  const handleDeleteDocument=(ind)=>{
    const updateData = formik.values.driverDocuments?.length&&formik.values.driverDocuments.filter((ele,ind1)=>ind1!=ind);
    formik.setFieldValue("driverDocuments",updateData)
  }


  const handleChangeDocument=async(e,ind)=>{
    e.preventDefault();
    const data = new FormData();
    data.append("file",e.target.files[0])
    const response = await uploadDriverDocument(data)
    const updateData =formik.values.driverDocuments.map((ele,ind1)=>ind1==ind?response:ele);
    formik.setFieldValue("driverDocuments",updateData)
  }

  const handleDriverDocumentClick=()=>{
    driverDocument.current.click();
  }
  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        {/*begin::Content container*/}
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
          <div className="card mb-5 mb-xl-10">
            {/* add new card element start */}
            <div className="card card-flush h-md-100">
              <div className="card-body">
            </div>
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
                style={{marginTop:"-45px"}}
              >
                {/*begin::Card title*/}
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Edit Driver</h3>
                </div>
                {/*end::Card title*/}
              </div>
            </div>
            {/*begin::Card header*/}
            {/*begin::Content*/}
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              {/*begin::Form*/}
              {/* <formik
            initialValues = {initialValues}
              validationSchema = {validation}
              onSubmit= {handleSubmit}
          >
              {({
                  values, handleChange, handleBlur, errors, touched, formik.setFieldValue, setFieldTouched
              })=> ( */}
              <form id="kt_account_profile_details_form" className="form" onSubmit={formik.handleSubmit}>
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Full Name
                    </label>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            name="fname"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="First name"
                            defaultValue="Max"
                            onChange={formik.handleChange}
                            value={formik.values.fname}
                            onBlur={formik.handleBlur}
                          />
                          <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                              {formik.touched.fname && formik.errors.fname ? <div>{formik.errors.fname}</div> : null}
                            </div></div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            name="lname"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Last name"
                            defaultValue="Smith"
                            onChange={formik.handleChange}
                            value ={formik.values.lname}
                            onBlur={formik.handleBlur}
                          />
                          <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                              {formik.touched.lname && formik.errors.lname ? <div>{formik.errors.lname}</div> : null}
                            </div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      <span className="required">Mobile Number</span>
                      <i
                        className="fas fa-exclamation-circle ms-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Phone number must be active"
                      />
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="mobile_number"
                        onKeyDown={(e) =>["+", "-"].includes(e.key) && e.preventDefault()}
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Mobile Number"
                        onChange={formik.handleChange}
                        value = {formik.values.mobile_number}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.mobile_number && formik.errors.mobile_number ? <div>{formik.errors.mobile_number}</div> : null}
                        </div></div>
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      <span className="required">Home Number</span>

                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="number"
                        name="home_number"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Home Number"
                        onChange={formik.handleChange}
                        value ={formik.values.home_number}
                        onBlur={formik.handleBlur}
                      
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.home_number && formik.errors.home_number ? <div>{formik.errors.home_number}</div>: null}
                        </div></div>
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      ABN
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="number"
                        name="abn"
                        onKeyDown={(e) =>["+", "-"].includes(e.key) && e.preventDefault()}
                        className="form-control form-control-lg form-control-solid"
                        placeholder="abn"
                        onChange={formik.handleChange}
                        value= {formik.values.abn}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.abn && formik.errors.abn ? <div>{formik.errors.abn}</div> : null}
                        </div></div>
                    </div>
                    {/*end::Col*/}
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      TFN
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="number"
                        name="tfn"
                        onKeyDown={(e) =>["+", "-"].includes(e.key) && e.preventDefault()}
                        className="form-control form-control-lg form-control-solid"
                        placeholder="TFN"
                        onChange={formik.handleChange}
                        value = {formik.values.tfn}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.tfn && formik.errors.tfn ? <div>{formik.errors.tfn}</div> : null}
                        </div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Driver Type
                    </label>
                    <div className="col-lg-8 fv-row">
                      <Selector
                        name={"driver_type"}
                        data={driverTypeOptions.map((it)=>{
                          return {
                            value: it,
                            title: it
                              }
                        })}
                        style={{width:"270px"}}
                        onChange={(value)=>
                          formik.setFieldValue("driver_type", value)

                        }
                        value = {formik.values.driver_type}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">
                          {formik.touched.driver_type && formik.errors.driver_type ? <div>{formik.errors.driver_type}</div> : null}</div>
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Employment Type
                    </label>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row" style={{display: 'flex',alignItems: "baseline" }}>
                          <label className="col-lg-2 col-form-label required fw-semibold fs-6">
                            From
                          </label>
                          <div style={{display: 'flex',flexDirection: 'column',width: '100%',alignItems: 'center',justifyContent: 'center'}}>
                            <ViewDatePicker
                              className="form-control form-control-lg form-control-solid"
                              type="text"
                              id="employment_from"
                              name="employment_from"
                              onChange={(value)=>formik.setFieldValue("employment_from", value)}
                              value={formik.values.employment_from}
                              onBlur={formik.handleBlur}
                              style={{paddingLeft: 10}}
                            />
                            <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                                {formik.touched.employment_from && formik.errors.employment_from ? <div>{formik.errors.employment_from}</div> : null}
</div></div>
                              </div>
                            </div>
                          <div className="col-lg-6 fv-row" style={{display: 'flex', }}>
                          <label className="col-lg-2 col-form-label required fw-semibold fs-6">
                            Type
                          </label>
                          <div style={{display: 'flex',flexDirection: 'column',width: '100%',alignItems: 'center',justifyContent: 'center'}}>
                            <Selector
                              data={type.map((it)=>{
                                return {
                                  value: it,
                                  title: it
                            }
                              })}
                              style={{width:"270px"}}
                              onChange={(value)=>formik.setFieldValue("employment_type", value)}
                              value = {formik.values.employment_type}
                              onBlur={formik.handleBlur}
                            />
                            <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                                {formik.touched.employment_type && formik.errors.employment_type ? <div>{formik.errors.employment_type}</div> : null}</div></div>
                          </div>
                        </div>
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {formik.values?.employment_type!=="contractor" ?
                    <>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Salary
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="number"
                            name="salary"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Salary"
                            onChange={formik.handleChange}
                            value= {formik.values.salary}
                            onBlur={formik.handleBlur}
                          />
                          <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                              {formik.touched.salary && formik.errors.salary ? <div>{formik.errors.salary}</div> : null}
                            </div></div>
                        </div>
                      </div>
                    </>
                  :null}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Car Plate Registration No
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="car_plate_number"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Car Plate Number"
                        onChange={formik.handleChange}
                        value={formik.values.car_plate_number}
                        onBlur={formik.handleBlur}
                      
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.car_plate_number && formik.errors.car_plate_number ? <div>{formik.errors.car_plate_number}</div> : null}
                        </div></div>
                    </div>
                    {/*end::Col*/}
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Registration Expiry Date
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <ViewDatePicker
                        className="form-control form-control-lg form-control-solid"
                        type="date"
                        id="birthday"
                        name="registration_expiry_date"
                        onChange={(value)=>formik.setFieldValue("registration_expiry_date", value)}
                        value={formik.values.registration_expiry_date}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.registration_expiry_date && formik.errors.registration_expiry_date ? <div>{formik.errors.registration_expiry_date}</div> : null}
                        </div></div>

                    </div>
                    {/*end::Col*/}
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Insurance Expiry Date
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <ViewDatePicker
                        className="form-control form-control-lg form-control-solid"
                        type="date"
                        id="birthday"
                        name="insurance_expiry_date"
                        onChange={(value)=>formik.setFieldValue("insurance_expiry_date", value)}
                        value={formik.values.insurance_expiry_date}
                        onBlur={formik.handleBlur}
                      
                       />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.insurance_expiry_date && formik.errors.insurance_expiry_date ? <div>{formik.errors.insurance_expiry_date}</div> : null}
                        </div></div>
                    </div>
                    {/*end::Col*/}
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Insurance No
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        onKeyDown={(e) =>["+", "-"].includes(e.key) && e.preventDefault()}
                        name="insurance_number"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Insurance No"
                        onChange={formik.handleChange}
                        value={formik.values.insurance_number}
                        onBlur={formik.handleBlur}

                        //   defaultValue="keenthemes.com"
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.insurance_number && formik.errors.insurance_number ? <div>{formik.errors.insurance_number}</div> : null}
                        </div></div>

                    </div>
                    {/*end::Col*/}
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Scanner Number
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="number"
                        onKeyDown={(e) =>["+", "-"].includes(e.key) && e.preventDefault()}
                        name="scanner_number"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Scanner Number"
                        onChange={formik.handleChange}
                        value={formik.values.scanner_number}
                        onBlur={formik.handleBlur}

                        //   defaultValue="keenthemes.com"
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.scanner_number && formik.errors.scanner_number ? <div>{formik.errors.scanner_number}</div> : null}
                        </div></div>
                    </div>
                    {/*end::Col*/}
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Scanner User Name
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="scanner_user_name"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Scanner User Name"
                        onChange={formik.handleChange}
                        value={formik.values.scanner_user_name}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.scanner_user_name && formik.errors.scanner_user_name ? <div>{formik.errors.scanner_user_name}</div> : null}
                        </div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Scanner Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="scanner_password"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Scanner Password"
                        onChange={formik.handleChange}
                        value={formik.values.scanner_password}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.scanner_password && formik.errors.scanner_password ? <div>{formik.errors.scanner_password}</div> : null}
                        </div></div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Email
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}
                        </div></div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="password"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.password && formik.errors.password ? <div>{formik.errors.password}</div> : null}
                        </div></div>
                    </div>
                  </div>


                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Delivery area
                    </label>
                    <div className="col-lg-8 fv-row">
                      <Selector2
                        name="delivery_area"
                        multi={true}
                        data={options.map((it)=>{
                          return {
                            value: it.id,
                            title: it.areaName,
                          }}
                          )}
                        value = {formik.values.delivery_area}
                        onChange = {(value)=>formik.setFieldValue("delivery_area", value)}
                        onBlur = {formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                          {formik.touched.delivery_area && formik.errors.delivery_area ? <div>{formik.errors.delivery_area}</div> : null}</div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Driver Documents
                    </label>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-9 fv-row">

                          <input
                            name="driverDocuments"
                            type='text'
                            value={Document && Document.name?Document?.name: "Select File"}
                            className="form-control form-control-lg form-control-solid"
                            placeholder="select driver document"
                            onClick = {handleDriverDocumentClick}
                          />

                          <input
                            style={{display:"none"}}
                            name="driverDocuments"
                            type='file'
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Scanner User Name"
                            onChange = {(e)=>setDocument(e.target.files[0])}
                            onBlur = {formik.handleBlur}
                            ref={driverDocument}
                          />
                          <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                              {formik.touched.driverDocuments && formik.errors.driverDocuments ? <div>{formik.errors.driverDocuments}</div> : null}</div></div>
                        </div>
                        <div className="col-lg-3 col-md-lg fv-row">
                          <button onClick={handleDocuments} className='btn btn-primary' style={{width:"100%"}} disabled={Document!==""?false:true}>
                            Add Document
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formik.values.driverDocuments?.length?<div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Selected Files
                      </label>
                      <div className="col-lg-8 d-flex flex-wrap justify-content-start">
                        {formik.values.driverDocuments?formik.values.driverDocuments.map((ele,ind)=><div className="col-lg-3" style={{margin:'auto',margin:"20px 10px"}}>
    {/*begin::Image input*/}
                              <div
                                className="image-input image-input-outline"
                              >
                                                                  {/*begin::Preview existing avatar*/}
                                  <div
                                    className="image-input-wrapper w-125px h-125px"
                                    style={{ backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_IMAGE}${ele})`}}
                                  />
                                  {/*end::Preview existing avatar*/}
                                  {/*begin::Label*/}
                                  <label
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    title="Change avatar"
                                  >
                                    <i className="bi bi-pencil-fill fs-7" />
                                    {/*begin::Inputs*/}
                                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={(e)=>handleChangeDocument(e,ind)}/>
                                    <input type="hidden" name="avatar_remove" />
                                    {/*end::Inputs*/}
                                  </label>
                                  <span
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="remove"
                                    data-bs-toggle="tooltip"
                                    title="Remove avatar"
                                    onClick={()=>handleDeleteDocument(ind)}
                                  >
                                    <i className="bi bi-x fs-2" />
                                  </span>
                                  {/*end::Remove*/}
                                </div>
                                {/*end::Image input*/}
                                {/*end::Col*/}
                              </div>
                            ):"No Result Found!"}
                      </div>
                    </div>:null}
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2"
                    onClick={()=>navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loader}
                    id="kt_account_profile_details_submit"
                    // onClick={handleSubmit}
                  >
                    {loader ? <Loader size={17}/>:"Save Changes"}
                  </button>
                </div>

                <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
                  <div className="table-responsive">
                    <h3>Employee Status Changes</h3>
                    <table className="table table-hover table-row-dashed align-middle gs-0 gy-4 my-0">
                      <thead>
                        <tr className="fw-bold border-bottom-0 bg-light" style={{fontSize: "15px"}}>
                          <th className ="min-w-200px rounded-start" style={{paddingLeft: "7px"}}>Entered date</th>
                          <th className ="min-w-200px">Effective Date</th>
                          <th className ="min-w-200px">New Status</th>
                          <th className ="min-w-200px rounded-end">Old Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        { rateType?.length && rateType?.map((data,index)=>
                            <tr key={index} className ="table-div">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <span
                                      href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                      style={{padding: "5px"}}
                                      // className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                    >
                                      {`${new Date(data?.createdAt).toLocaleDateString()}
                      ${new Date(data?.createdAt).toLocaleTimeString()}`}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                  style={{padding: "5px"}}>
                                  {new Date(data?.effectiveDate).toLocaleDateString()}</span>
                              </td>
                              <td>
                                <span href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                  style={{padding: "5px"}}>
                                  {data?.newStatus}</span>
                              </td>
                              <td>
                                <span href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                  style={{padding: "5px"}}>
                                  {data?.oldStatus}</span>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr></hr>
                <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
                  <div className="table-responsive">
                    <h3>Salary Changes</h3>
                    <table className="table table-hover table-row-dashed align-middle gs-0 gy-4 my-0">
                      <thead>
                        <tr className="fw-bold border-bottom-0 bg-light" style={{fontSize: "15px"}}>
                          <th className ="min-w-200px rounded-start" style={{paddingLeft: "7px"}}>Entered date</th>
                          <th className ="min-w-200px ">Effective Date</th>
                          <th className ="min-w-200px ">Current Rate</th>
                          <th className ="min-w-200px rounded-end">Previous Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        { rateType1?.length && rateType1?.map((data,index)=>
                            <tr key={index} className ="table-div">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    <span
                                      href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                      style={{padding: "5px"}}
                                    >
                                      {`${new Date(data?.createdAt).toLocaleDateString()}
                          ${new Date(data?.createdAt).toLocaleTimeString()}`}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                  style={{padding: "5px"}}>
                                  {new Date(data?.effectiveDate).toLocaleDateString()}</span>
                              </td>
                              <td>
                                <span href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                  style={{padding: "5px"}}>
                                  {!data?.effectiveRate ? data?.oldRate:data?.effectiveRate}</span>
                              </td>
                              <td>
                                <span href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                  style={{padding: "5px"}}>
                                  {data?.oldRate}</span>
                              </td>
                            </tr>
                          
                   )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
              {/* )}
              </formik> */}
              {/*end::Form*/}

            </div>
            {/*end::Content*/}
          </div>
        </div>
        {/*end::Content container*/}
      </div>
    </>
  
  )
}


const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px"
}

function Selector2({name="",data =[],style = {},onChange = ()=>{},value=[]}) {
  const [type,setType] = useState(value);

  const handleChange = (e)=>{
    const selectedItems = e.target.selectedOptions
    const flavors = [];
    for (let i=0; i<selectedItems?.length; i++) {
      flavors.push(selectedItems[i].value);
    }
    setType(flavors);
    onChange(flavors);
  }
  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars"  style={{...styles,...style}} onChange={handleChange} multiple={true}>
      <option hidden selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
          <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value.includes(op.value)} key={index} value={op.value} >{op.title}</option>
        )}
    </select>
  )
}

export default Edit

