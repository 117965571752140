import React, {useState, useEffect} from 'react'
import ViewDatePicker from "../../components/common/datePicker"
import Selector from "../../components/common/Selector";
import {useNavigate} from "react-router-dom";
import {useFormik} from 'formik';
import {useDispatch} from "react-redux"
import {  NotificationManager } from 'react-notifications';
import {createAdmin} from "../../actions";
import * as yup from 'yup';
import {name, email,
    upperLower,
    digit,
    specialChar,
    charLength} from "../../helpers/validation"
import { userType } from '../../constants';

function AddUser() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const initialValues={
        first_name:"",
        last_name:"",
        phone_number:"",
        email:"",
        dob:"",
        password:"",
        // document:"",
        role:""
    }

    const validation = yup.object({
        first_name:yup.string()
        .required("First Name is required")
        .matches(name, "invalid name")
        .min(2, "first name must not be less than 2 characters")
        .max(30, "first name must not be greater than 30 characters"),
        last_name:yup.string()
        .required("Last Name is required")
        .matches(name, "invalid name")
        .min(2, "last name must not be less than 2 characters")
        .max(30, "last name must not be greater than 30 characters"),
        phone_number:yup.string()
        .required("Phone Number is required"),
        email:yup.string()
        .required("Email is required")
        .matches(email, "please enter valid email"),
        dob:yup.string()
        .required("Date of Birth is required"),
        password:yup.string()
        .required("Please enter a password")
        .matches(upperLower, "password must include uppercase & lowercase characters")
        .matches(digit, "password must include one digit")
        .matches(specialChar, "password must include specialChar")
        .matches(charLength, "password must be 8 characters long"),
        // document:yup.string()
        // .required("Please Upload a document"),
        role:yup.string()
        .required("please select at least one user type")
    })
    
    const handleSubmit= (values, {resetForm})=>{
        if(values){
         dispatch(createAdmin(values, navigate))       
        }
        else NotificationManager.error('please fill data')
      }
    
      const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validation,
        onSubmit: handleSubmit
      });
    
      useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
          const errorElement = document.getElementsByName(Object.keys(formik.errors)[0])[0];
            errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            errorElement.focus({ preventScroll: true });
        }
      }, [formik]);
    
  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        {/*begin::Content container*/}
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
          <div className="card mb-5 mb-xl-10">
            {/* add new card element start */}
          <div className="card card-flush h-md-100">
          <div className="card-body">
            </div>                   
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
              style={{marginTop:"-45px"}}
            >
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Add User</h3>
              </div>
              {/*end::Card title*/}
            </div>
            </div>
            {/*begin::Card header*/}
            {/*begin::Content*/}
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              <form id="kt_account_profile_details_form" className="form" onSubmit={formik.handleSubmit}>
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Full Name
                    </label> 
                      <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            name="first_name"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="First name"    
                            onChange={formik.handleChange}
                            value={formik.values.first_name}     
                            onBlur={formik.handleBlur}                 
                            />
                            <div class="fv-plugins-message-container invalid-feedback"><div data-field="first_name" data-validator="notEmpty">
                          {formik.touched.first_name && formik.errors.first_name ? <div>{formik.errors.first_name}</div> : null}
                          </div></div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            name="last_name"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Last name"  
                            onChange={formik.handleChange}
                            value={formik.values.last_name}
                            onBlur={formik.handleBlur}                       
                          />
                             <div className="fv-plugins-message-container invalid-feedback"><div data-field="last_name" data-validator="notEmpty">
                          {formik.touched.last_name && formik.errors.last_name ? <div>{formik.errors.last_name}</div> : null}
                          </div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      <span className="required">Phone Number</span>
                      <i
                        className="fas fa-exclamation-circle ms-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Phone number must be active"
                      />
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Phone Number"
                        onChange={formik.handleChange}
                        values={formik.values.phone_number}
                        onBlur={formik.handleBlur}
                      />
                        <div className="fv-plugins-message-container invalid-feedback"><div data-field="phone_number" data-validator="notEmpty">
                        {formik.touched.phone_number && formik.errors.phone_number ? <div>{formik.errors.phone_number}</div> : null}
                        </div></div>
                    </div>
                    {/*end::Col*/}
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      <span className="required">Email Address</span>
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="email"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Email Address"
                        autoComplete = "off"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                      />
                     <div className="fv-plugins-message-container invalid-feedback"><div data-field="email" data-validator="notEmpty">
                        {formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}
                        </div></div>
                    </div>
                    {/*end::Col*/}
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Date of birth
                    </label>
                    <div className="col-lg-8 fv-row" id='datepickerdob'>
                    <ViewDatePicker 
                        className="form-control form-control-lg form-control-solid" 
                        type="date"
                        id="birthday" 
                        name="dob"
                        onChange={(value)=>formik.setFieldValue("dob", value.toISOString())}
                        value={formik.values.dob}
                        onBlur={formik.handleBlur}
                        /> 
                     <div className="fv-plugins-message-container invalid-feedback"><div data-field="dob" data-validator="notEmpty">
                        {formik.touched.dob && formik.errors.dob ? <div>{formik.errors.dob}</div> : null}
                        </div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      <span className="required">Password</span>
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Password"
                        autoComplete = "off"
                        onChange={formik.handleChange}
                        value = {formik.values.password}
                        onBlur={formik.handleBlur}
                      />
                     <div className="fv-plugins-message-container invalid-feedback"><div data-field="password" data-validator="notEmpty">
                        {formik.touched.password && formik.errors.password ? <div>{formik.errors.password}</div> : null}
                        </div></div>
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/* <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      <span className="required">Upload Document</span>
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="file"
                        name="document"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="upload document"
                        onChange={formik.handleChange}
                        value={formik.values.document}
                        onBlur={formik.handleBlur}
                      />
                     <div class="fv-plugins-message-container invalid-feedback"><div data-field="document" data-validator="notEmpty">
                        {formik.touched.document && formik.errors.document ? <div>{formik.errors.document}</div> : null}
                        </div></div>
                    </div>
                  </div> */}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      <span className="required">User Type</span>
                    </label>

                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                    <div style={{display: 'flex',flexDirection: 'column',width: '100%',alignItems: 'center',justifyContent: 'center'}}>
                    <Selector
                        name="role"
                        data={userType.map((it)=>{
                        return {
                        value: it.value,
                        title: it.title
                        }
                        })} 
                        onChange={(value)=>
                            formik.setFieldValue("role", value)                           
                          }
                        value={formik.values.role}
                        onBlur={formik.handleBlur}
                    />
                     <div className="fv-plugins-message-container invalid-feedback"><div data-field="role" data-validator="notEmpty">
                        {formik.touched.role && formik.errors.role ? <div>{formik.errors.role}</div> : null}
                        </div></div>
                        </div>
                    </div>
                    {/*end::Col*/}
                  </div>    
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2"
                    onClick={()=>navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="kt_account_profile_detail_submit"
                  >
                    Add User
                  </button>
                  <button
                  type="reset"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  style={{marginLeft:10}}>
                  <i className="bi bi-arrow-repeat" style ={{fontSize:25}}></i>                              
              </button>
                </div>   
                  </div>
             
              </form>
              {/* )}
              </formik> */}
              {/*end::Form*/}
              
            </div>
            {/*end::Content*/}
          </div>
        </div>
        {/*end::Content container*/}
      </div>
     </>

  )
}

export default AddUser

