import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
const counts = [0, 1, 3, 5, 7, 9, 11, 12, 14, 17];

const styles = { borderRight: "1px solid", fontSize: "13px" };

function formatInvoiceId(id) {
  const idString = id?.toString();
  const chunks = [];

  for (let i = idString.length - 1; i >= 0; i -= 3) {
    const chunk = idString.slice(Math.max(0, i - 2), i + 1);
    chunks.unshift(chunk.padStart(3, "0"));
  }
  if (chunks.length === 1) {
    return "000-" + chunks.join("-");
  } else {
    return chunks.join("-");
  }
}

const TableIncome2 = ({ invoices, partner }) => {
  const driverTypeOrder = {
    both: "both",
    driver: "driver",
    sorter: "sorter",
  };
  const driverData = invoices?.filter(
    (ele) => ele?.driver?.driverType === driverTypeOrder.driver
  );
  const sorterData = invoices?.filter(
    (ele) => ele?.driver?.driverType === driverTypeOrder.sorter
  );
  const bothData = invoices?.filter(
    (ele) => ele?.driver?.driverType === driverTypeOrder.both
  );
  
  return (
    <div className="table-responsive" style={{ padding: "0 -20px" }}>
      <div
        className="fw-bold border-bottom-0 bg-light"
        style={{ padding: "10px", width: "100%", fontSize: "20px" }}
      >
        {partner?.name || "N/A"}
      </div>
      <table
        className="table table-hover table-row-dashed table-row-gray-300 align-middle listab"
        id="lastab"
        style={{ overflow: "scroll", padding: "10px" }}
        width="100%"
        cellSpacing={1}
        cellPadding={0}
        border={1}
      >
        <thead>
          <tr
            className="fw-bold border-bottom-0"
            style={{ backgroundColor: "black", color: "#fff" }}
          >
            <td
              rowSpan={2}
              style={{
                ...styles,
                borderBottom: "1px solid gray",
                paddingLeft: "7px",
              }}
            >
              No.
            </td>
            <td
              rowSpan={2}
              style={{ ...styles, borderBottom: "1px solid gray" }}
            >
              {/* <div style={{textAlign: "center", marginTop:"-88px"}}> */}
              Description
              {/* </div> */}
            </td>
            <td
              rowSpan={2}
              style={{ ...styles, borderBottom: "1px solid gray" }}
            >
              Price ex GST
            </td>
            <td
              rowSpan={2}
              style={{ ...styles, borderBottom: "1px solid gray" }}
              border={1}
            >
              Price in GST
            </td>
          </tr>
        </thead>
        <tbody>
          {driverData?.length?<tr>
            <td colSpan={8} width={8} style={{ padding: "7px", fontWeight: "bold", opacity: 0}}>
              Drivers
            </td>
          </tr>: null}
          {driverData?.length?<tr>
            <td colSpan={8} width={8} style={{ padding: "7px", fontWeight: "bold"}}>
              Drivers
            </td>
          </tr>: null}
          {driverData?.length
            ? driverData.map((row, i) => (
                <tr key={i} style={{ fontSize: "13px" }}>
                  <td style={{ padding: "7px" }} width={6}>
                    {i + 1}
                  </td>

                  <td style={{ padding: "7px" }} width={6}>
                    {formatInvoiceId(row?.id) +
                      " | " +
                      row?.createdAt?.substring(0, 10) +
                      " | " +
                      row?.driver?.firstName +
                      " " +
                      row?.driver?.lastName +
                      " | " +
                      row?.area?.areaName}
                  </td>
                  <td style={{ fontSize: "13px" }} width={6}>
                    {(row?.amount).toFixed(2)}
                  </td>
                  <td style={{ fontSize: "13px" }} width={6}>
                    ${((row?.amount / 100) * 10 + row?.amount).toFixed(2)}
                  </td>
                </tr>
              ))
            : null}
          {sorterData?.length?<tr>
            <td colSpan={8} width={8} style={{ padding: "7px", fontWeight: "bold", opacity: 0}}>
              Sorters
            </td>
          </tr>: null}
         {sorterData?.length?<tr>
            <td colSpan={8} width={8} style={{ padding: "7px", fontWeight: "bold" }}>
              Sorters
            </td>
          </tr>: null}
          {sorterData?.length
            ? sorterData.map((row, i) => (
                <tr key={i} style={{ fontSize: "13px" }}>
                  <td style={{ padding: "7px" }} width={6}>
                    {i + 1}
                  </td>

                  <td style={{ padding: "7px" }} width={6}>
                    {formatInvoiceId(row?.id) +
                      " | " +
                      row?.createdAt?.substring(0, 10) +
                      " | " +
                      row?.driver?.firstName +
                      " " +
                      row?.driver?.lastName +
                      " | " +
                      row?.area?.areaName}
                  </td>
                  <td style={{ fontSize: "13px" }} width={6}>
                    {(row?.amount).toFixed(2)}
                  </td>
                  <td style={{ fontSize: "13px" }} width={6}>
                    ${((row?.amount / 100) * 10 + row?.amount).toFixed(2)}
                  </td>
                </tr>
              ))
            : null}
          {bothData?.length?<tr>
            <td colSpan={8} width={8} style={{ padding: "7px", fontWeight: "bold", opacity: 0}}>
              Sorters
            </td>
          </tr>: null}
          {bothData?.length?<tr>
            <td colSpan={8} width={8} style={{ padding: "7px", fontWeight: "bold" }}>
              Both
            </td>
          </tr>: null}
          {bothData?.length
            ? bothData.map((row, i) => (
                <tr key={i} style={{ fontSize: "13px" }}>
                  <td style={{ padding: "7px" }} width={6}>
                    {i + 1}
                  </td>

                  <td style={{ padding: "7px" }} width={6}>
                    {formatInvoiceId(row?.id) +
                      " | " +
                      row?.createdAt?.substring(0, 10) +
                      " | " +
                      row?.driver?.firstName +
                      " " +
                      row?.driver?.lastName +
                      " | " +
                      row?.area?.areaName}
                  </td>
                  <td style={{ fontSize: "13px" }} width={6}>
                    {(row?.amount).toFixed(2)}
                  </td>
                  <td style={{ fontSize: "13px" }} width={6}>
                    ${((row?.amount / 100) * 10 + row?.amount).toFixed(2)}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};
export default TableIncome2;
