
import React, {useState, useEffect} from 'react';
import {NavLink, Outlet, useOutletContext} from 'react-router-dom';
import { ViewDatePicker } from '../../components/common';
import Selector from "../../components/common/Selector"
import {useDispatch, useSelector} from "react-redux"
import { getInvoices } from '../../actions';
import { Table } from "../../components/common";
import { invoicestableHeads } from '../../constants';


const checks = [
  {
    id: "color1",
    name: "Driver",
    color: "#336667",
  },
  {
    id: "color2",
    name: "Sorter",
    color: "#FF5349",
  }
];


const InvoicesList = () => {
    const setOpen=useOutletContext()//function that we pass as a props in Outlet in index.js file
    const [active, setActive] = useState(0)
    const {options=[]} =  useSelector(({area})=>area)
    const { invoices } =  useSelector(({invoices})=>invoices)
    const all=  useSelector((all)=>all)
    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(getInvoices({}))
    
      // return () => {
      //   second
      // }
    }, [])
    
  return (
    
    <div className="col-xl-8 mb-5 mb-xl-10" style={{width:"100%"}} >     
    <div className="card card-flush h-md-100">
      <div className="card-body">
      <div className="tab-content">
    <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
      <div className="table-responsive">
        <div className="card-title flex-column">
        <h3 className="fw-bold mb-1">Driver/Sorter Invoices</h3>
        </div>
        {/* <div style={{display: 'flex',justifyContent: "flex-start" , marginTop:"10px"}}>
        <div className="d-flex align-items-center position-relative my-1">
								<span className="svg-icon svg-icon-1 position-absolute ms-6">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
										<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
										</svg>
									</span>
									<input type="text" data-kt-filemanager-table-filter="search" className="form-control form-control-solid w-350px ps-15" placeholder="Search Invoice #,Driver Name" />
									</div>       
          <div style={{display: 'flex',justifyContent: "flex-end",alignItems: 'center', position:"relative", left:110, bottom:10, gap:20}}>
          <div className="row mb-6" style={{width:"220px"}}> 
            <label className="col-form-label fw-semibold fs-6">
             Start Date
            </label>
            <div className="col-lg-12 fv-row" style={{width:"220px"}}>
              <ViewDatePicker
              name="date"
              className="form-control form-control-lg form-control-solid"
              placeholder="Date"
              />
            </div>
          </div>
          <div className="row mb-6" style={{width:"220px"}}>
            <label className="col-form-label fw-semibold fs-6" >
             End Date
            </label>
            <div className="col-lg-12 fv-row" style={{width:"220px"}}>
              <ViewDatePicker
              name="date"
              className="form-control form-control-lg form-control-solid"
              placeholder="Date"
              />
            </div>
          </div>
          <div className="row mb-6">
            <label className="col-form-label fw-semibold fs-6">
              Area
            </label>
            <div className="col-lg-12 fv-row" style={{width:"180px"}}>
            <Selector 
            style={{width: "100% !important", height:"48px"}} 
            data={options.map((it)=>{
              return {
                value: it.id,
                title: it.areaName
              }
            })} 
            />
            </div>
          </div>
          </div>
        </div> */}
        </div>

        <hr />
        <div className="table-responsive" style={{padding: "0 -20px"}}>
              <Table
            lable="invoices"
            tableRows={invoices?.rows}
            tableHeads={invoicestableHeads}
            setOpen={setOpen?.length?setOpen[0]:""}
          />
          </div>
          <hr />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {checks.map((checkName, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: `${checkName.color}`,
                          margin: "0 5px 0 10px",
                        }}
                      ></div>
                      <div>{checkName.name}</div>
                    </div>
                  );
                })}
              </div>
      </div>
    </div>
  </div>       
      </div>      
      </div>

)
}

export default InvoicesList;