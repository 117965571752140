import React, { useRef } from "react";
import { useSelector } from "react-redux";
import generatePDF from 'react-to-pdf';
import { NotificationManager } from "react-notifications";
import { NavLink } from "react-router-dom";
import DriverPackageInvoiceTable from "./driverPackageInvoiceTable";

const styles = {
  padding: "1em",
  backgroundColor: "#fff",
};
// function formatInvoiceId(id) {
//   const idString = id?.toString();
//   const chunks = [];

//   for (let i = idString.length - 1; i >= 0; i -= 3) {
//     const chunk = idString.slice(Math.max(0, i - 2), i + 1);
//     chunks.unshift(chunk.padStart(3, "0"));
//   }
//   if (chunks.length === 1) {
//     return "000-" + chunks.join("-");
//   } else {
//     return chunks.join("-");
//   }
// }

const DownloadDriverPackageInvoice = () => {
const { driverPackageInvoice, loader } = useSelector(({ driverPackageInvoices }) => driverPackageInvoices);
  const targetRef = useRef();
  
  const generatePDFWithOptions = () => {

    generatePDF(targetRef, { filename: `${new Date().toISOString()}.pdf`})
      .then((blob) => {
        NotificationManager.success("Pdf downloaded success");
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong while generate pdf"));
      });

  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <ul className="nav nav-pills nav-pills-custom mb-3">
          <li className="nav-item mb-3 me-3 me-lg-6">
            <button
              style={{ float: "left", marginLeft: 10, marginTop: "-30px" }}
              type="submit"
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={generatePDFWithOptions}
            >
              {" "}
              Download Pdf
            </button>
          </li>
        </ul>
      </div>
      <div id="report" ref={targetRef}>
        <div id="kt_app_content" className="app-content flex-column-fluid py-20 px-10">
            <div style={{  width: "100%"}}>
                <div className="mx-auto w-100">
                <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
                   <div>
                     <h4 className=" text-gray-800 fs-2qx pe-5 pb-7">Tax Invoice#</h4>
                    <div className="fw-bold fs-2 " style={{paddingLeft:"5px"}}>
                        {driverPackageInvoice.driver
                        ? `${driverPackageInvoice.driver?.firstName} ${driverPackageInvoice.driver?.lastName}`
                        : null}
                        <br />
                        <span className="text-muted fs-5">
                        {`ABN ${driverPackageInvoice.driver?.abn || "N/A"}`}
                        </span>
                    </div>
                   </div>
                    <div className="text-sm-end">
                    <NavLink className="d-block mw-150px ms-sm-auto">
                        <img
                        alt="Logo"
                        src="/assets/media/images/img-logo.png"
                        className="w-100"
                        />
                    </NavLink>
                    <div className="text-sm-end fw-semibold fs-4 text-muted mt-7">
                        <div>Address will be placed here</div>
                        <div> St. Mina Transport Pty Ltd</div>
                        <div>ABN 81 136 635 848</div>
                    </div>
                    </div>
                </div>

                <div className="pb-12">
                    <div className="d-flex flex-column gap-7 gap-md-10">
                    <div className="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
                        <div className="flex-root d-flex flex-column">
                        <span className="text-muted">Date created</span>
                        <span className="fs-5">
                            {new Date().toDateString()}
                        </span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between flex-column">
                        <hr />
                        <div
                        className="table-responsive border-bottom mb-9"
                        id="report-table"
                        >
                        <DriverPackageInvoiceTable />
                        </div>
                    </div>
                    </div>
                </div>
                <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <div className="table-responsive">
                    <div
                        className="tab"
                        style={{
                        border: "1px solid",
                        width: "300px",
                        borderRadius: "1em",
                        }}
                    >
                        <table
                        style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            borderRadius: "1em",
                            overflow: "hidden",
                        }}
                        >
                        <tr>
                            <th
                            colspan="2"
                            style={{
                                backgroundColor: "black",
                                padding: "1em",
                                color: "#fff",
                                fontSize: "15px",
                            }}
                            >
                            Invoice Summary
                            </th>
                        </tr>
                        <tr>
                            <td
                            style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}
                            >
                            Total Hours Worked
                            </td>
                            <td
                            style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}
                            >
                            {driverPackageInvoice.hours_worked}
                            </td>
                        </tr>
                        <tr>
                            <td style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}>
                                Total
                            </td>
                            <td style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}>{(driverPackageInvoice.total_amount.toFixed(2))?.toLocaleString("en-US", {style: "currency",currency: "USD",})}
                            </td>                       
                        </tr>
                        <tr>
                            <td style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}>
                                Total Amount (inc. GST)
                            </td>
                            <td style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}>
                                {(driverPackageInvoice.total_amount+ (driverPackageInvoice.total_amount * 0.1)).toFixed(2).toLocaleString("en-US", {style: "currency",currency: "USD"})}
                            </td>                       
                        </tr>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadDriverPackageInvoice;
