import {ACTION_TYPES} from "../constants"
const INITIAL_STATE = {
    expenses: [],
    loader: false,
    expense: {},
    category:[],
    allExpenses:[],
    page: 1,
    pageSize: 20,
    field: "createdAt",
    order: "ASC",
    filtered_expenses:[],
    salary_expenses:[],
    selected_salary_expenses:{},
    open: true
}

const expenses = (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case ACTION_TYPES.EXPENSES_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.EXPENSES_SUCCESS:
            return {...state,expenses: action.payload,loader: false};
        case ACTION_TYPES.EXPENSES_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.EXPENSE_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.EXPENSE_SUCCESS:
            return {...state,expense: action.payload,loader: false};
        case ACTION_TYPES.EXPENSE_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.EDIT_EXPENSE_REQUEST:
            return {...state, loader: true};
        case ACTION_TYPES.EDIT_EXPENSE_SUCCESS:
            return {...state, loader:false};
        case ACTION_TYPES.EDIT_EXPENSE_FAILED:
            return {...state, loader:false}
            
        case ACTION_TYPES.EXPENSE_CATEGORY_REQUEST:
            return {...state,loader:true};
        case ACTION_TYPES.EXPENSE_CATEGORY_SUCCESS:
            return {...state, category:action.payload,loader: false}
        case ACTION_TYPES.EXPENSE_CATEGORY_FAILED:
            return {...state, loader:false}
        
        case ACTION_TYPES.GET_EXPENSES_REQUEST:
            return {...state, loader: true}
        case ACTION_TYPES.GET_EXPENSES_SUCCESS:
            return {...state, allExpenses:action.payload,loader: false}
        case ACTION_TYPES.GET_EXPENSES_FAILED:
            return {...state, loader:false}
        case ACTION_TYPES.SEARCH_EXPENSE_REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.SEARCH_EXPENSE_SUCCESS:
            return {...state, filtered_expenses:action.payload,loader: false}
        case ACTION_TYPES.SEARCH_EXPENSE_FAILED:
        return {...state, loader:false}
    
        case ACTION_TYPES.SET_EXPENSES_PAGE:
            return {...state, page:action.payload}
        case ACTION_TYPES.RESET_EXPENSES_PAGE:
            return {...state, page: 1};
        
        case ACTION_TYPES.SALARY_EXPENSE_REQUEST:
            return {...state, loader: true}
        case ACTION_TYPES.SALARY_EXPENSE_SUCCESS:
            return {...state, salary_expenses:action.payload,loader: false}
        case ACTION_TYPES.SALARY_EXPENSE_FAILED:
            return {...state, loader:false}
        
        case ACTION_TYPES.SET_SALARY_EXPENSE_REQUEST:
            return {...state, loader: true}
        case ACTION_TYPES.SET_SALARY_EXPENSE_SUCCESS:
            return {...state, selected_salary_expenses:action.payload,loader: false}
        case ACTION_TYPES.SET_SALARY_EXPENSE_FAILED:
            return {...state, loader:false}
        case ACTION_TYPES.SET_OPEN_SALARY_EXPENSE:
            return {...state,open_salary_expense:action.payload.open ,loader:false}
        default:
            return state
    }
}

export default expenses