
export const fixedValue =(num)=>{
  const str = String(num);
  let fixed = "";
  if(str.includes(".")){
      const start = str.split(".")[0].length;
      fixed = str.split(".")[1].length>=2 ? start+3:start+2;
      let val = Number(str.slice(0,fixed));
      return val;
  }
  return Number(str)
}

export const display = (num)=>{
    const str = String(num);
    let fixed = "";
    if(str.includes(".")){
      const start = str.split(".")[0].length;
      fixed = str.split(".")[1].length>=2 ? start+3:start+2;
      let val = Number(str.slice(0,fixed))
      return val.toFixed(2)
    }
    else{
      return Number(str).toFixed(2);
    }
  }
  export const replaceLeadingZeros = (num)=>{
    //All leading zero will be replaced by non-zero digits
    //eg. 01 --> 1
    if(num?.length>=2 && num[0]==='0' && num[1]!='.'){
        num = num.replace(/^0+/, '');
        return num;
    }
    return num;
}