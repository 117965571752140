import { ACTION_TYPES } from "../constants";
const INITIAL_STATE = {
  driverPackageInvoice: {},
  driverPackageInvoices:[],
  alldriverPackageInvoices: [],
  loader: false,
  page: 1,
  pageSize: 20,
  field: "createdAt",
  order: "ASC",
};


const driverPackageInvoices =  (state = INITIAL_STATE,action)=>{
    switch(action.type){

        //--------------------------------------------------------driver package invoices
        case ACTION_TYPES.DRIVER_PACKAGE_INVOICE_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.DRIVER_PACKAGE_INVOICE_SUCCESS:
            return {...state,driverPackageInvoice: action.payload,loader: false};
        case ACTION_TYPES.DRIVER_PACKAGE_INVOICE_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.GET_DRIVER_PACKAGE_INVOICES_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.GET_DRIVER_PACKAGE_INVOICES_SUCCESS:
            return {...state,driverPackageInvoices: action.payload,loader: false};
        case ACTION_TYPES.GET_DRIVER_PACKAGE_INVOICES_FAILED:
            return {...state,loader: false};
        
        case ACTION_TYPES.SET_DRIVER_PACKAGE_INVOICE_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.SET_DRIVER_PACKAGE_INVOICE_SUCCESS:
            return {...state,driverPackageInvoice: action.payload,loader: false};
        case ACTION_TYPES.SET_DRIVER_PACKAGE_INVOICE_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.EDIT_DRIVER_PACKAGE_INVOICE_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.EDIT_DRIVER_PACKAGE_INVOICE_SUCCESS:
            return {...state,driverPackageInvoice: action.payload,loader: false};
        case ACTION_TYPES.EDIT_DRIVER_PACKAGE_INVOICE_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.SET_DRIVER_PACKAGE_INVOICE_PAGE:
            return {...state,page: action.payload};
        case ACTION_TYPES.RESET_DRIVER_PACKAGE_INVOICE_PAGE:
            return {...state, page: 1};

        default:
            return state
    }
}

export default driverPackageInvoices