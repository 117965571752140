import React from 'react'
import {Table} from "../../components/common"
import {useSelector} from 'react-redux';
import { useOutletContext } from 'react-router-dom';

const tableHeads = [
  "Category Name",
  "Category Description",
  "Edit",
  "Delete"
]
  function CategoryList() {
  const setOpen=useOutletContext()//function that we pass as a props in Outlet in index.js file
  const {categories,loader} = useSelector((state)=>state.category)
  const {all} = useSelector((state)=>state)
  return (
    <>
      {
        loader ?<h3> Loading...</h3>:
        <>
        <div className="tab-content">
        <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
          <div className="table-responsive">
          <div className="d-flex flex-wrap justify-content-start">
				<div class="d-flex flex-wrap">
       <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" style={{marginTop:5}}>
				<div className="d-flex align-items-center">
					<div className="fs-6 text-gray-500 fw-bold" >List of Categories</div>
					</div>
					<div className="fs-2 fw-bold text-success" style={{textAlign:"center", paddingTop:5}}>{categories.count}</div>
						</div>
            </div>
          </div>
           <Table lable="category" tableRows={categories?.rows} tableHeads={tableHeads} setOpen={setOpen?.length?setOpen[0]:""}/>
           {/* <div className ="right-area" style={{float:"right", paddingTop:8}}>
            <PaginationControlled lable='area'/>
            </div> */}
          </div>
        </div>
      </div>
        </>
      }
    </>
  )
}

export default CategoryList