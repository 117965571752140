import React from 'react'
import {useNavigate} from "react-router-dom"
import SweetAlert from 'react-bootstrap-sweetalert';
import "./common.css"
import { useSelector } from 'react-redux';

const styles = {
    width: '100vw',
    height: '100vh',
    zIndex:1000,
    backgroundColor: 'rgb(170, 167, 167,0.2)',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
}

function Alert({setOpenAlert,alertMsg="Success",data=null}) {
    const navigate = useNavigate();

    const {show=false,message="",type=""} = useSelector(({app})=>app)

  return (
    <>
    {
        show ?
        <SweetAlert
        success={type==="success" || type===""}
        error={type=="error"}
        title={alertMsg}
        onConfirm={this.hideAlert}
        >
        {message}
        </SweetAlert>
        :null
    }
    </>
  )
}

export default Alert