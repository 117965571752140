import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Outlet, useLocation } from "react-router-dom";
import { Selector } from "../../components/common";
import {
  fetchAllDriver,
  getExpenses,
  getExpens,
  filteredExpense,
  getSalaryExpenses,
  getAllCategoryByfilter,
  fetchAllSorterDriver,
} from "../../actions";
import { Formik, Form } from "formik";
import ViewDatePicker from "../../components/common/datePicker";
import { useDispatch, useSelector } from "react-redux";
import {status } from "../../constants/expenses";
import PaginationControlled from "../../components/common/paginate";
import useOutsideClick from "../../components/common/ousideClick";
import InputSelect from "../../components/common/InputSelect";

const categories = ["new", "approved", "paid"];

const driverName = ["name1", "name2"];

const initialValues = {
  category: "",
  status: "",
  driver: "",
  startDate: "",
  endDate: "",
};
function Expenses() {
  const ref = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(initialValues);
  const {categories} = useSelector((state)=>state.category)
  const categoryData = categories?.rows
  const { allExpenses = [], page = 1 } = useSelector(
    ({ expenses }) => expenses
  );
  // const { drivers } = useSelector(({ driver }) => driver);
  // const { rows: data } = drivers;
  const { sorterDrivers } = useSelector(({ driver }) => driver);
  const { rows: data } = sorterDrivers;

  const onkeyChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const filteredValues = {
      status: state.status,
      category: state.category,
      driver_id: state.driver,
      startDate: state.startDate
        ? new Date(
            new Date(state.startDate ? state.startDate : new Date()).setHours(
              0,
              0,
              0,
              0
            )
          ).toISOString()
        : "",
      endDate: state.endDate
        ? new Date(
            new Date(state.endDate ? state.endDate : new Date()).setHours(
              23,
              59,
              59,
              999
            )
          ).toISOString()
        : "",
    };
    dispatch(filteredExpense(filteredValues));
  };

  useOutsideClick(ref, () => {
    if (open) setOpen(false);
  });

  const handleShowAll = () => {
    if (active === 3) {
      dispatch(getSalaryExpenses());
      dispatch({ type: "SET_OPEN_SALARY_EXPENSE", payload: { open: false } });
    }
    dispatch(getExpenses());
    setOpen(false);
  };
  useEffect(() => {
    dispatch(getExpenses());
    // dispatch(fetchAllDriver());
    dispatch(fetchAllSorterDriver())
    dispatch(getAllCategoryByfilter())
  }, []);
  //For search

  return (
    <div className="col-xl-8 mb-5 mb-xl-10" style={{ width: "100%" }}>
      <div className="card card-flush h-md-100">
        <div className="card-body">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ul className="nav nav-pills nav-pills-custom mb-3">
              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="list"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(1) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    data-bs-toggle="pill"
                    onClick={()=>setOpen(false)}
                  >
                    <div className="nav-icon mb-3">
                      <i className="fonticon-layers fs-2"></i>
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      Driver Expenses
                    </span>
                    <span
                      className={
                        active === 1
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>
              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="salary"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(3) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    data-bs-toggle="pill"
                    onClick={()=>{
                      if (active === 3) {
                        dispatch({
                          type: "SET_OPEN_SALARY_EXPENSE",
                          payload: { open: !open },
                        });
                      }
                      return setOpen((p) => !p);
                    }}
                  >
                    <div className="nav-icon mb-3">
                      <i className="fonticon-content-marketing fs-2"></i>
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      Salary
                    </span>
                    <span
                      className={
                        active === 3
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>
              {/* <li className="nav-item mb-3 me-3 me-lg-6">
          <NavLink to="driverExpense" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style={({isActive})=>isActive ?setActive(4):{}}>
            <div
              className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
              data-bs-toggle="pill"
            >
              <div className="nav-icon mb-3">
              <i className="fonticon-layers fs-2"></i>
              </div>
                <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Driver Expenses</span>
              <span className={ active===4 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
            </div>
            </NavLink>
          </li> */}
              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="add"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(2) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i class="bi bi-plus-lg fs-2" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      New Expense
                    </span>
                    <span
                      className={
                        active === 2
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>
              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="addSalaryExpense"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(6) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i class="bi bi-plus-lg fs-2" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      Salary Expense
                    </span>
                    <span
                      className={
                        active === 6
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>

              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="category"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(7) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i className="bi bi-bag fs-2" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      Category
                    </span>
                    <span
                      className={
                        active === 7
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>
            </ul>
            {(active === 1 || active === 3) &&
              location.pathname !== "/expenses/edit" && (
                <ul className="nav nav-pills nav-pills-custom mb-3">
                  <li className="nav-item mb-3 me-3 me-lg-6">
                    <button
                      style={{ float: "left", marginLeft: 10 }}
                      type="submit"
                      name="description"
                      className="btn btn-primary"
                      id="kt_account_profile_details_submit"
                      onClick={handleShowAll}
                    >
                      {" "}
                      Show All
                    </button>
                  </li>
                  <li className="nav-item mb-3 me-3 me-lg-6">
                    <button
                      type="submit"
                      style={{ float: "right", marginleft: 20 }}
                      name="description"
                      className="btn btn-primary"
                      id="kt_account_profile_details_submit"
                      onClick={() => {
                        if (active === 3) {
                          dispatch({
                            type: "SET_OPEN_SALARY_EXPENSE",
                            payload: { open: !open },
                          });
                        }
                        return setOpen((p) => !p);
                      }}
                    >
                      <i class="bi bi-search"></i> Search
                    </button>
                  </li>
                </ul>
              )}
          </div>
          {open && active === 1 && (
            <div style={{ backgroundColor: "#fff", margin: "10px 0" }}>
              <form id="kt_account_profile_details_form" className="form">
                <div className="card-body border-top p-9">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Category
                    </label>
                    <div className="col-lg-8 fv-row">
                      <Selector1
                        data={categoryData?.map((it) => {
                          return { value: it?.id, title: it?.category };
                        })}
                        onChange={(value) => onkeyChange("category", value)}
                        value={state.category}
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Status
                    </label>
                    <div className="col-lg-8 fv-row">
                      <Selector1
                        data={status.map((it) => {
                          return { value: it, title: it };
                        })}
                        onChange={(value) => onkeyChange("status", value)}
                        value={state.status}
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Driver
                    </label>
                    <div className="col-lg-8 fv-row" style={{position:'relative'}}>
                      {/* <Selector1
                        data={data.map((it) => {
                          return {
                            value: it.id,
                            title: `${it.firstName} ${it.lastName}`,
                          };
                        })}
                        onChange={(value) => onkeyChange("driver", value)}
                        value={state.driver}
                      /> */}
                      <InputSelect
                      data={
                        data.length
                          ? data.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it?.driverType
                              };
                            })
                          : ["Loadin..."]
                      }
                      onChange={(value)=>onkeyChange("driver",value)}
                      value ={state.driver}
                    />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Start Date
                    </label>
                    <div className="col-lg-8 fv-row">
                      <ViewDatePicker
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        id="date"
                        name="startDate"
                        onChange={(value) => onkeyChange("startDate", value)}
                        value={state.startDate}
                        style={{ paddingLeft: 10 }}
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      End Date
                    </label>
                    <div className="col-lg-8 fv-row">
                      <ViewDatePicker
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        id="date"
                        name="endDate"
                        onChange={(value) => onkeyChange("endDate", value)}
                        value={state.endDate}
                        style={{ paddingLeft: 10 }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer d-flex "
                  style={{ border: "none", marginLeft: 0, marginTop: -30 }}
                >
                  <button
                    type="submit"
                    name="description"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    onClick={handleSubmit}
                  >
                    Find
                  </button>
                </div>
              </form>
            </div>
          )}
          <Outlet context={[setOpen]}/>
        </div>
      </div>
      {(location.pathname === "/expenses/list" ||
        location.pathname === "/expenses") && (
        <div class="d-flex flex-stack flex-wrap pt-10">
          <div class="fs-6 fw-semibold text-gray-700">
            {allExpenses?.count &&
              `Showing ${1 + 10 * (page - 1)} to ${
                10 * page > allExpenses?.count ? allExpenses?.count : 10 * page
              } of ${allExpenses?.count} entries`}
          </div>
          <PaginationControlled p={page} lable="expenses" />
        </div>
      )}
    </div>
  );
}

export default Expenses;

const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px",
};

function Selector1({ data = [], style = {}, onChange = () => {}, value = "" }) {
  const [type, setType] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setType(value);
    onChange(value);
  };

  //option attributes --> hidden,disabled
  return (
    <select
      name="cars"
      id="cars"
      data-control="select2"
      className="form-select form-select-solid form-select-lg"
      style={{ ...styles, ...style }}
      onChange={handleChange}
    >
      <option selected={value === ""} value="">
        {" "}
        choose one{" "}
      </option>
      {data?.length &&
        data.map((op, index) => (
          <option
            className="col-lg-8 fv-row fw-semibold fs-6"
            selected={value === op.value}
            key={index}
            value={op.value}
          >
            {op.title}
          </option>
        ))}
    </select>
  );
}
