export const validations = {
    email: (val) =>
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        val
        ),
    password: (val) => /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/.test(val),
    cpassword: (confPass, pass) => pass === confPass,
    fullname: (val) => !/[^A-Za-z_ '-]/.test(val),
    phone: (val) => /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(val),
    role: (val) => val,
    check: (val) => val,
};

export const validator = (key, value) => {
    if (key && !value) return false;

    if (key.substring(key.length - 4) === "name") key = "name";

    switch (key) {
        case "email":
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
        );
        case "name":
        return /^[a-zA-Z_ '-]+$/.test(value);
        case "mobile_number":
        return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(value);
        // case "booking_amount":
        //   return /^([1-9][0-9]{0,4}|100000)$/.test(value);
        case "Amount":
        return /^[1-9]+$/.test(value);
        default:
        return !!value;
    }
};
  
export const name = /^[a-zA-Z_ '-]+$/
export const email =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
export const upperLower = /^(?=.*[a-z])(?=.*[A-Z])/
export const digit =/^(?=.*?[0-9])/
export const specialChar = /^(?=.*?[#?!@$%^&*-])/
export const charLength = /^.{8,}/

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const num = /^[0-9]+$/
