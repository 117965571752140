import React,{useState,useEffect, useRef} from 'react'
import {NavLink,Outlet,useLocation} from "react-router-dom"
import {useDispatch,useSelector} from "react-redux"
import Selector from '../../components/common/Selector';
import {fetchAllPackages} from "../../actions"
import PaginationControlled from '../../components/common/paginate';
import useOutsideClick from '../../components/common/ousideClick';

const packageTypes = [
  "A", "B", "C", "D", "E", "F", "G", "H" 
];

const packageCategories = [1, 2];

function Packages() {
  const ref = useRef()
  const location = useLocation()
  const [active,setActive] = useState(1)
  const [open, setOpen] = useState(false)
  const {options} = useSelector(({area})=>area);
  const {packages = [],page=1} = useSelector(({packages})=>packages);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    type: "",
    category: "",
    delivery_area: 0,
    description: "",
    showHidden:false
  })
  const handleChange=(e)=>{
    const {name, value} = e.target
    setState({...state, [name]: value})
  }

  useOutsideClick(ref, () => {
    if (open) setOpen(false);
  });
  const onChangeCategory =(key, value)=>{
    setState({...state, [key]: value})
  }

  const handleSubmit = (e)=>{
    e.preventDefault()
    const filter = {
      type: state.type,
      description: state.description,
      area_id: state.delivery_area,
      packageCategory: state.category,
      showHidden: state.showHidden
    }
    dispatch(fetchAllPackages(filter));
  }

  const handleShowAll =()=>{
    dispatch(fetchAllPackages())
    setOpen(false)
  }

  useEffect(()=>{
    dispatch(fetchAllPackages())
  },[]);

  return (
    <div className="col-xl-8 mb-5 mb-xl-10" style={{width:"100%"}} ref={ref}>
    <div className="card card-flush h-md-100">
      <div className="card-body">
      <div style={{display:"flex",justifyContent: 'space-between',alignItems: 'center'}}>

        {/*begin::Nav*/}
        <ul className="nav nav-pills nav-pills-custom mb-3">
          <li className="nav-item mb-3 me-3 me-lg-6">
          <NavLink to="list" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style={({isActive})=>isActive ?setActive(1):{}}>
            <div
              className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
              data-bs-toggle="pill"
              onClick={()=>setOpen(false)}
            >
              <div className="nav-icon mb-3">
              <i className="fonticon-insurance fs-2"></i>
              </div>
                <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Packages</span>
              <span className={ active===1 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
            </div>
            </NavLink>
          </li>
          <li className="nav-item mb-3 me-3 me-lg-6">
          <NavLink to="add" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style={({isActive})=>isActive ?setActive(2):{}}>
            <div
              className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
              data-bs-toggle="pill"
            >
              <div className="nav-icon mb-3">
              <i className="bi bi-plus-lg fs-2" />
              </div>
                <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Add new</span>
              <span className={ active===2 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
            </div>
            </NavLink>
          </li>
        </ul>
        {
          (active === 1 && location.pathname!== '/packages/edit') && <ul className = "nav nav-pills nav-pills-custom mb-3" >
            <li className ="nav-item mb-3 me-3 me-lg-6">
          <button
              style={{float:"left", marginLeft:10}}
              type="submit"
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={handleShowAll}
              > Show All              
          </button>
          </li>
          <li className ="nav-item mb-3 me-3 me-lg-6">
          <button
                 type="submit"
                 style={{float:"right", marginleft:20}}
                 name="description"
                 className="btn btn-primary"
                 id="kt_account_profile_details_submit"
                 onClick={()=>setOpen((p)=>!p)}
              ><i className="bi bi-search"></i> Search                 
              </button>
              </li>
            </ul>
            }
          </div>
        {
           open && active === 1 && <div style={{backgroundColor: "#fff",margin: "10px 0"}}>
            <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
              style={{padding: "0px !important"}}
            >
              <div className="card mb-5 mb-xl-10">               
                <div
                  id="kt_account_settings_profile_details"
                  className="collapse show"
                >          
                <form id="kt_account_profile_details_form" className="form">
                <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  Package Type
                  </label>                  
                  <div className="col-lg-8 fv-row">
                  <Selector data={packageTypes.map((it)=>{
                    return {
                      value: it,
                      title: it
                    }
                  })} onChange={(value)=>{
                        onChangeCategory("type",value)
                    }}/>                 
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  Package Category
                  </label>                  
                  <div className="col-lg-8 fv-row">
                  <Selector data={packageCategories.map((it)=>{
                    return {
                      value: it,
                      title: it
                    }
                  })} onChange={(value)=>{
                        onChangeCategory("category",value)
                    }}/>                 
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Delivery Area
                  </label>
                  <div className="col-lg-8 fv-row">
                  <Selector data={options.map((it)=>{
                    return {value: it.id,title: it.areaName}
                    })} 
                    value={state.delivery_area}
                    onChange={(value)=>{
                          onChangeCategory("delivery_area",value)
                    }}/>
                  </div>
                </div>              
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Description
                  </label>
                  <div className="col-lg-8 fv-row">
                    <textarea
                      type="text"
                      name="description"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Description"
                      onChange = {handleChange}
                      value = {state.description}
                    />                   
                  </div>
                </div> 
                <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Show hidden packages
                    </label>
                    <div className="col-lg-8 fv-row">
                    <label className="form-check form-check-custom form-check-solid me-5">
													<input className="form-check-input" 
                            type="checkbox" 
                            name="showHidden" 
                            onChange ={(e)=>handleChange({target:{name:e.target.name, value:e.target.checked}})}
                            checked={state.showHidden} />
												</label>                
                     </div>
                    {/*end::Col*/}
                  </div>
              </div>
              {/* </div> */}
              <div className="card-footer d-flex" style={{border:"none" , marginLeft:0, marginTop:-30}}>
                  <button
                    type="submit"
                    name="description"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    onClick={handleSubmit}
                  >
                    Find
                  </button>
                </div>
            </form>            
          </div>               
        </div>
      </div>
    </div>
            </div>
      }
        <Outlet context={[setOpen]}/>
      </div>
    </div>
     {(location.pathname === "/packages/list" || location.pathname === "/packages") && <div class="d-flex flex-stack flex-wrap pt-10">
					<div className="fs-6 fw-semibold text-gray-700">
          {packages?.count!==0 && `Showing ${1+20*(page-1)} to ${20*page>packages?.count?packages?.count:20*page} of ${packages?.count} entries`}
            </div>
        <PaginationControlled p = {page} lable="packages"/>
        </div>}
  </div>
  )
}

export default Packages