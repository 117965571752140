import React, {useState,useEffect,useMemo, useRef} from 'react'
import {NavLink, Outlet,useLocation,useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux"
import Selector from '../../components/common/Selector'
import {fetchAllDriver,getAreas,fetchFilteredDriver, fetchAllSorterDriver, fetchFilteredSorterDriver} from "../../actions"
import PaginationControlled from '../../components/common/paginate';
import useOutsideClick from '../../components/common/ousideClick';

const type=[
  "employee",
  "contractor"
]
const driverTypeOptions = [
  'all',
  "driver",
  'sorter',
  'both'
]
const initialValues = {
  firstName:"",
  lastName:"",
  mobileNumber: "",
  abn: "",
  area_id: "",
  employmentType: "",
  carPlateRegistrationNumber:"",
  showHiddenDrivers:false,
  driverType:""
}
function Drivers({width="100%"})  {
  const ref = useRef()
  const navigate = useNavigate()
  const location = useLocation()
    const [active, setActive] = useState(0)
    const [open, setOpen] = useState(false);
    const [all,setAll] = useState(false);
    const {options=[]} = useSelector(({area})=>area)
    const {sorterDrivers,page=1} = useSelector(({driver})=>driver)
    
    useOutsideClick(ref, () => {
      if (open) setOpen(false);
    });

    const dispatch = useDispatch();
    const {pathname} = useLocation();

    const paths = useMemo(()=>{
      const temp = pathname.split("/");
      return temp;
    },[pathname])

    const [state, setState] = useState(initialValues)
    const handleChange =(e)=>{
      const {name, value} = e.target;
      setState({...state, [name]:value})
    }

    const onKeyChange =(key, value)=>{
      setState({...state, [key]:value})

    }
    const handleSubmit =(e)=>{
      e.preventDefault()
      const filterObject={};
      for(let j in state){
        if(state[j]) filterObject[j]=state[j]
      }
      // console.log("67",filterObject)
      dispatch(fetchFilteredSorterDriver(filterObject));
    }
  
    useEffect(()=>{
      dispatch(fetchFilteredSorterDriver({driverType:'driver'}));//get all drivers with driverType (driver)
      // dispatch(fetchAllDriver())
    },[open])

    const handleShowAll = ()=>{
      setOpen(false)
      const showDrivers={}
      for(let j in state){
        if(state[j]) showDrivers[j]=state[j]
      }
      setAll(true)
      dispatch(fetchFilteredSorterDriver(showDrivers));
      navigate("/drivers");
    }

    return (
      <div className="col-xl-8 mb-5 mb-xl-10" style={{width:width?width: ""}}  ref={ref} >
        <div className="card card-flush h-md-100">
        <div className="card-body">
        <div style={{display:"flex",justifyContent: 'space-between',alignItems: 'center'}}>

        <ul className="nav nav-pills nav-pills-custom mb-3">
        <li className="nav-item mb-3 me-3 me-lg-6">
          {/*begin::Icon*/}
          <NavLink
          to = "driversList"
          style={({isActive})=>isActive ? setActive(1):{}}
          >
          <div
        className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
        data-bs-toggle="pill"
        onClick={()=>setOpen(false)}
        >
         <div className="nav-icon mb-3">
          <i className="fonticon-truck fs-1 p-0" />
        </div>
        <span  className="nav-text text-gray-800 fw-bold fs-6 lh-1">
          Driver
          </span>
        <span className= {active === 1 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
          </div>
        </NavLink>
        </li>
        {
          (location.pathname === '/drivers/customRate' || location.pathname === '/drivers/customRateForWeekend') && 
        <li className="nav-item mb-3 me-3 me-lg-6">
          {/*begin::Icon*/}
          <NavLink
          to = "customRateForWeekend"
          style={({isActive})=>isActive ? setActive(3):{}}
          >
          <div
        className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
        data-bs-toggle="pill"
        >
         <div className="nav-icon mb-3">
          <i className="fonticon-truck fs-1 p-0" />
        </div>
        <span  className="nav-text text-gray-800 fw-bold fs-6 lh-1">
          Weekend Rates
          </span>
        <span className= {active === 3 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
          </div>
        </NavLink>
        </li>
       }

        <li>
      <NavLink
       to = "addDriver"
       style={({isActive})=>isActive ? setActive(2):{}}
      >
      <div
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
          >
            <div className="nav-icon mb-3">
            {/* <i className="fonticon-like-1 fs-2" /> */}
            <i class="bi bi-plus-lg" />

            </div>
            <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
              Add new
            </span>
            <span className= {active === 2 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />

          </div>
      </NavLink>

          </li>
          </ul>
          {
            (active === 1 && pathname!=='/drivers/edit') && <ul className = "nav nav-pills nav-pills-custom mb-3" >
              <li className ="nav-item mb-3 me-3 me-lg-6">
            <button
              style={{float:"left", marginLeft:10}}
              type="submit"
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={handleShowAll}
              > Show All              
            </button>
            </li>            
            <li className ="nav-item mb-3 me-3 me-lg-6">
           { location.pathname!=='/drivers/customRate' && <button              
              type="submit"
              style={{float:"right", marginleft:20}}
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={() => setOpen(!open)}
            > <i class="bi bi-search"></i> Search                 
            </button>
            }
            </li>
          </ul>
        }
      </div>
      {
        open && active === 1 && 
        (
        <div  style={{backgroundColor: "#fff",margin: "10px 0"}}>
          <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
        <div className="card mb-5 mb-xl-10">
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <form id="kt_account_profile_details_form" className="form">
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Full Name
                  </label> 
                    <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 fv-row">
                        <input
                          type="text"
                          name="firstName"
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          placeholder="First name"
                          defaultValue="Max"
                          onChange={handleChange}
                          value={state.firstName}
                              />
                      </div>
                      <div className="col-lg-6 fv-row">
                        <input
                          type="text"
                          name="lastName"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Last name"
                          defaultValue="Smith"
                          onChange={handleChange}
                          value ={state.lastName}
                        />                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    <span>Mobile Number</span>
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="number"
                      name="mobileNumber"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Mobile Number"
                      onChange={handleChange}
                      value = {state.mobileNumber}
                    />                   
                  </div>
              </div>              
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    ABN
                  </label>                  
                  <div className="col-lg-8 fv-row">
                    <input
                      type="number"
                      name="abn"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="abn"
                      onChange={handleChange}
                      value= {state.abn}
                    />                   
                  </div>
                </div> 
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  C-P-Registration
                  </label>                  
                  <div className="col-lg-8 fv-row">
                    <input
                      type="number"
                      name="carPlateRegistrationNumber"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="C-P-Registration"
                      onChange={handleChange}
                      value= {state.carPlateRegistrationNumber}
                    />                   
                  </div>
                </div> 
                              
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  Employment Type
                  </label>                    
                  <div className="col-lg-8 fv-row" >
                  <Selector 
                    data={type.map((it)=>{
                      return {
                        value: it,
                        title: it
                      }
                    })} 
                    onChange={(value)=>onKeyChange("employmentType", value)}
                    value = {state.employmentType}
                  />                      
                </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Delivery area
                  </label>
                  <div className="col-lg-8 fv-row">
                    <Selector
                      data={options.map((it)=>{
                        return {
                          value: it.id,
                          title: it.areaName
                        }
                      })} 
                        value = {state.area_id}
                        onChange={(value)=>onKeyChange("area_id", value)}
                      />                    
                  </div>
                  {/*end::Col*/}
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Driver Type
                  </label>
                  <div className="col-lg-8 fv-row">
                    <Selector 
                    data={driverTypeOptions.map((it)=>{
                      return {
                        value: it==='all'?"":it,
                        title: it
                      }
                    })} 
                    onChange={(value)=>onKeyChange("driverType", value)}
                    value = {state.driverType}
                  />                                  
                  </div>
                  {/*end::Col*/}
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Show hidden drivers
                  </label>
                  <div className="col-lg-8 fv-row">
                  <label className="form-check form-check-custom form-check-solid me-5">
                        <input className="form-check-input" 
                          type="checkbox" 
                          name="showHiddenDrivers" 
                          onChange ={(e)=>handleChange({target:{name:e.target.name, value:e.target.checked}})}
                          checked={state.showHiddenDrivers} />
                      </label>                
                  </div>
                  {/*end::Col*/}
                </div>
              </div>
              <div className="card-footer d-flex " style={{border:"none" , marginLeft:0, marginTop:-30}}>
                <button
                  type="submit"
                  name="description"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  onClick={handleSubmit}
                >
                  Find
                </button>
              </div>
            
            </form>
          </div>
        </div>
        </div>
  </div>
        </div>
      
      )}         
      <Outlet context={[setOpen]}/>

      </div>

      </div>
      {(location.pathname === "/drivers/driversList" || location.pathname === "/drivers") && <div class="d-flex flex-stack flex-wrap pt-10">
      <div class="fs-6 fw-semibold text-gray-700">
      {sorterDrivers?.count && `Showing ${1+20*(page-1)} to ${20*page>sorterDrivers?.count?sorterDrivers?.count:20*page} of ${sorterDrivers?.count} entries`}
      </div>
        <PaginationControlled  lable="driver" driverType={all?{driverType:""}:{driverType:'driver'}} />
    </div>}
      </div>

  )
}

export default Drivers


const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px"
}

function Selector2({data =[],style = {},onChange = ()=>{},values=[]}) {
  const [type,setType] = useState(values);

  const handleChange = (e)=>{
    const selectedItems = e.target.value
    setType(selectedItems);
    onChange(selectedItems);
  }

  return (
    <select name="cars" id="cars"  style={{...styles,...style}} onChange={handleChange}>
      <option hidden selected={values === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={values.includes(op.id)} key={index} value={op.id} >{op.areaName}</option>
      )}
    </select>
  )
}
