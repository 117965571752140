import React,{useEffect} from 'react'
import {Sidebar,Header,Footer} from '../components/common'
import {Outlet} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux"
import {fetchAllAreas, fetchAllCategoriesss, fetchAllData, getMonthlyData} from "../actions"

function DashboardLayout() {
  const dispatch = useDispatch();
  const {users} = useSelector((state)=>state.user)
 
  useEffect(()=>{
    dispatch(fetchAllAreas());
    dispatch(fetchAllCategoriesss())
    dispatch(getMonthlyData());
  },[])

  return (
    <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
      <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
        <Header />
        <div
        className="app-wrapper flex-column flex-row-fluid"
        id="kt_app_wrapper"
        >
          <Sidebar />
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main" style={{marginTop:"30px", marginBottom:"30px"}}>
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                  <Outlet />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout