import {ACTION_TYPES} from "../constants"
const INITIAL_STATE = {
    packages: [],
    totalPackages: [],
    loader: false,
    package: {},
    packageCategory: [],
    packageType: [],
    page: 1,
    pageSize: 20,
    field: "createdAt",
    order: "ASC",
    hidePackages:{}
}

const packages = (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case ACTION_TYPES.PACKAGES_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.PACKAGES_SUCCESS:
            return {...state,packages: action.payload,loader: false};
        case ACTION_TYPES.PACKAGES_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.PACKAGES_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.TOTAL_PACKAGES_SUCCESS:
            return {...state,totalPackages: action.payload,loader: false};
        case ACTION_TYPES.PACKAGES_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.EDIT_PACKAGE_SUCCESS:
            return {...state,package: action.payload,loader: false};
        case ACTION_TYPES.PACKAGE_CATEGORY_SUCCESS:
            return {...state,packageCategory: action.payload,loader: false};
        case ACTION_TYPES.PACKAGE_TYPE_SUCCESS:
            return {...state,packageType: action.payload,loader: false};
        case ACTION_TYPES.SET_PACKAGE_PAGE:
            return {...state,page: action.payload};

        case ACTION_TYPES.RESET_PACKAGES_PAGE:
            return {...state,page: 1};
        case "SET_HIDE_PACKAGES":
            return {...state, hidePackages:action.payload}

        default:
            return state
    }
}

export default packages