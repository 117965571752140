import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch , useSelector} from 'react-redux'
import {Selector} from "../../components/common"
import {validator} from "../../helpers/validation"
import {addExpenses, fetchAllDriver, getArea, getAreas, fetchAllAreas, updateExpenseSalary, fetchAllSorterDriver} from "../../actions"
import {categoryData, months, status} from "../../constants/expenses";
import {driverName} from '../../constants/driver'
import { NotificationManager } from 'react-notifications';
import {Formik,Form,useFormik} from "formik";
import ViewDatePicker from "../../components/common/datePicker";
import ViewDateTimePicker from "../../components/common/dateTimePicker";
import {fixedValue} from '../../components/common/round'
import * as Yup from 'yup';
import { FormEvent } from "react";
import Loader from "../../components/common/loader";
import dayjs from 'dayjs';
import InputSelect from '../../components/common/InputSelect'
const initialState = {
  month: "",
  start_date: "",
  finish_date: "",
  area: "",
  driver: "",
  amount: "",
  status: "",
}

function EditSalaryExpense() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [amt, setAmt] = useState(0)
  const {selected_salary_expenses, loader} = useSelector(({expenses})=>expenses)
  // console.log('sele',selected_salary_expenses);
  initialState['month'] = +selected_salary_expenses.month
  initialState['amount'] = +selected_salary_expenses.amount;
  initialState['driver'] = +selected_salary_expenses.driver_id;
  initialState['status'] = selected_salary_expenses.status;
  initialState['area'] = selected_salary_expenses.area_id;

  // initialState['start_date'] = selected_salary_expenses.start_date;
  // initialState['finish_date'] = selected_salary_expenses.finish_date;
  // dispatch(getAreas())
  const {areas,options} = useSelector(({area})=>area);
  // const {drivers} = useSelector(({driver})=>driver)
  // const {rows : data} = drivers
  const { sorterDrivers } = useSelector(({ driver }) => driver);
  const { rows: data } = sorterDrivers;
  
   useEffect(() =>{
    // dispatch((fetchAllDriver()))
    dispatch(fetchAllSorterDriver())
    dispatch(fetchAllAreas())
   },[])
   
  
  const FormValidation = Yup.object({
    month: Yup.number(),
    start_date: Yup.date(),
    finish_date: Yup.date(),
    area: Yup.string(),
    driver: Yup.string(),
    amount: Yup.number().typeError("must be a number"),
    status: Yup.string(),
  });

//   const handleOnChange = (event: FormEvent) => {
//     const {name} = event.target
//     if(name === "amount"){
//       let num = event.target.value
//       if(num.includes(".") && num.split(".")[1].length>2) return;
//       setAmt(num)
//     }
// };
  const handleSubmit = (values,resetForm) => {
    const salary_expenses ={}
    if(values.month)salary_expenses['month'] =  values.month;
    if(values.amount)salary_expenses['amount'] =  +values.amount;
    if(values.area)salary_expenses['area_id'] = +values.area;
    if(values.start_date)salary_expenses['start_date'] =  values.start_date;
    if(values.finish_date)salary_expenses['finish_date'] =  values.finish_date;
    if(values.status)salary_expenses['status'] = values.status;
    if(values.driver)salary_expenses['driver_id'] = +values.driver;
    // console.log('>>update',selected_salary_expenses);
    dispatch(updateExpenseSalary({id: selected_salary_expenses.id, data: salary_expenses},navigate));

    // resetForm({values: ""})

    }

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{padding: "0px !important"}}
      >
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{padding: 0}}
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Update Salary Expense</h3>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
        <Formik
            initialValues={initialState}
            validationSchema={FormValidation}
            // onSubmit={handleSubmit}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched,handleChange,resetForm, handleBlur,setFieldValue })=>(
                <Form id="kt_account_profile_details_form" className="form">
                  <div className="card-body border-top p-9">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Month
                    </label>
                    <div className="col-lg-8 fv-row">
                    <Selector2 
                      name="month"
                      style={{height:"50px"}}
                      data={months.map((it, index)=>{return {it:index+1, title:it} })} 
                      value ={selected_salary_expenses.month}
                      onBlur ={handleBlur}
                      onChange={(value)=>{
                          setFieldValue("month",months.indexOf(value)+1)
                      }}
                    />
                    <div class="fv-plugins-message-container invalid-feedback">
                    <div data-field="fname" data-validator="notEmpty">{touched.status && errors.status ? errors.status: ""}</div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Start Date/Time
                    </label>
                    <div className="col-lg-8 fv-row">
                    <ViewDateTimePicker 
                            className="form-control form-control-lg form-control-solid"
                            type="string"
                            required
                            id="start_date" 
                            name="start_date"
                            onChange={(value)=>setFieldValue("start_date", value)}
                            // value={values.start_date}
                            // value={selected_salary_expenses.start_date}
                            // onBlur={handleBlur}
                            value={values.start_date || selected_salary_expenses.start_date}
                            style={{paddingLeft: 10}}
                            />
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">{touched.date && errors.date? errors.date:""}
                        </div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Finish Date/Time
                    </label>
                    <div className="col-lg-8 fv-row">
                    <ViewDateTimePicker 
                            className="form-control form-control-lg form-control-solid"
                            type="string"
                            id="finish_date" 
                            required
                            name="finish_date"
                            onChange={(value)=>setFieldValue("finish_date", value)}
                            // value={selected_salary_expenses.finish_date}
                            // onBlur={handleBlur}
                            value={values.finish_date || selected_salary_expenses.finish_date}
                            style={{paddingLeft: 10}}
                            />
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">{touched.date && errors.date? errors.date:""}
                        </div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Amount
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="number"
                        name="amount"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Enter Amount"
                        onChange = {handleChange}
                        value = {values.amount}
                        onBlur={handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">{touched.amount && errors.amount ?errors.amount: ""}</div>
                    </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Status
                    </label>
                    <div className="col-lg-8 fv-row">
                    <Selector2 
                      name="status"
                      style={{height:"50px"}}
                      data={status.map((it)=>{
                      return {it:it, title:it}
                    })}
                    value ={selected_salary_expenses.status}
                    onBlur ={handleBlur}

                    onChange={(value)=>{
                        setFieldValue("status",value)
                    }}/>
                    <div class="fv-plugins-message-container invalid-feedback">
                    <div data-field="fname" data-validator="notEmpty">{touched.status && errors.status ? errors.status: ""}</div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Area
                    </label>
                  <div className="col-lg-8 fv-row">                       
                       <Selector3
                       name="area"
                       multi={false}
                          data={options.map((it)=>{
                            return {
                              value: it.id,
                              title: it.areaName
                          }}
                          )}
                          value = {selected_salary_expenses.area}
                          onChange = {(value)=>setFieldValue("area", value)}
                          onBlur = {handleBlur}
                        />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    {touched.area && errors.area ? <div>{errors.area}</div> : null}</div></div>
                     </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Driver
                    </label>
                    <div className="col-lg-8 fv-row position-relative">
                    {/* <Selector4 data={data.map((it)=>{
                      return {value:it.id, title:it.firstName+" "+it.lastName}
                    })} 
                      onChange={(value)=>setFieldValue("driver",value)}
                      value={selected_salary_expenses.driver_id}
                      style={{height:"50px"}}
                      name="driver"
                      onBlur={handleBlur}
                    /> */}
                     <InputSelect
                        name="driver"
                        data={
                        data.length
                          ? data.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it?.driverType
                              };
                            })
                          : ["Loadin..."]
                        }
                        onChange={(value) => setFieldValue("driver", value)}
                        value ={selected_salary_expenses.driver_id}
                      />
                    <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">{touched.driver && errors.driver ?errors.driver: ""}</div></div>
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2"
                    onClick={()=>navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                  type="submit"
                    disabled={loader}
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    // onClick={()=>handleSubmit(values,resetForm)}
                  >
                    {loader ? <Loader size={17}/>: "Update Salary Expenses"}
                  </button>
                  <button
                  type="reset"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  style={{marginLeft:10}}
                  onClick={()=>resetForm({
                    date: "",
                    category: "",
                    amount: "",
                    status: "",
                    driver: "",
                    description: ""
                  })
                }
                >
                  <i class="bi bi-arrow-repeat" style ={{fontSize:25}}></i>              
                 
              </button>
                </div>
              </Form>
            )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditSalaryExpense

const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px"
}

function Selector2({name ="", data =[],style = {},onChange = ()=>{},value=""}) {
  const [type,setType] = useState("");
  const handleChange = (e)=>{
    const {value} = e.target
    setType(value)
    onChange(value);
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars" data-control="select2" className="form-select form-select-solid" style={{...styles,...style}} onChange={handleChange}>
      <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        {
          return <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value == op.it} key={index} value={op.value} >{op.title}</option>
        }
      )}
    
  </select>
  )
}

function Selector3({name ="", data =[],style = {},onChange = ()=>{},value=""}) {
  const [type,setType] = useState("");
  const handleChange = (e)=>{
    const {value} = e.target
    setType(value)
    onChange(value);
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars" data-control="select2" className="form-select form-select-solid" style={{...styles,...style}} onChange={handleChange}>
      <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        {
          return <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value.id == op.value} key={index} value={op.value} >{op.title}</option>
        }
      )}
    
  </select>
  )
}

function Selector4({name ="", data =[],style = {},onChange = ()=>{},value=""}) {
  const [type,setType] = useState("");
  const handleChange = (e)=>{
    const {value} = e.target
    setType(value)
    onChange(value);
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars" data-control="select2" className="form-select form-select-solid" style={{...styles,...style}} onChange={handleChange}>
      <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        {
          return <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value === op.value} key={index} value={op.value} >{op.title}</option>
        }
      )}
    
  </select>
  )
}