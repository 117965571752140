import { ACTION_TYPES } from "../constants";
const INITIAL_STATE = {
  user: {},
  users:[],
  isLoggedIn: false,
  loader: false,
  xloader: false,
};


export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_TYPES.AUTH_REQUEST:
      return { ...state, loader: true };
      case ACTION_TYPES.AUTH_COMPLETE:
      localStorage.setItem("profile",JSON.stringify(action.payload));
      return {
        ...state,
        loader: false,
        isLoggedIn: true,
        user: action.payload,
      };
    case ACTION_TYPES.AUTH_FAILED:
      return { ...state, loader: false, isLoggedIn: false, user: {} };
    case ACTION_TYPES.SIGNUP:
      return { ...state, loader: false };
      case ACTION_TYPES.SIGNUP_SUCCESS:
        return {...state, users:action.payload}
        case ACTION_TYPES.SIGNUP_FAILED:
          return {...state, loader: false}
    case ACTION_TYPES.RESET:
      return INITIAL_STATE;
    case ACTION_TYPES.LOG_OUT:
      localStorage.removeItem("profile");
      return {...state, isLoggedIn:false, user:{}};
    case ACTION_TYPES.DEVICE_TOKEN:
      return { ...state, fcm: action.payload };
    case ACTION_TYPES.ACCOUNT_UPDATE_REQUEST:
      return { ...state, xloader: true };
    case ACTION_TYPES.ACCOUNT_UPDATE_COMPLETE:
      return { ...state, xloader: false };
    case ACTION_TYPES.ACCOUNT_UPDATE_FAILED:
      return { ...state, xloader: false };
    default:
      return state;
  }
}
