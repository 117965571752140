import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useOutletContext } from "react-router-dom";
import { ViewDatePicker } from "../../components/common";
import Selector from "../../components/common/Selector";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersInvoices } from "../../actions";
import { Table } from "../../components/common";
import PaginationControlled from '../../components/common/paginate';

const invoicestableHeads = [
  "Invoice",
  "Invoice Date",
  "Partner Name",
  "Invoice Value",
  "Status",
  "Edit",
  "Delete",
  "View",
];

const PartnerInvoicesList = () => {
  const setOpen = useOutletContext(); //function that we pass as a props in Outlet in index.js file
  // const [active, setActive] = useState(0)
  // const {options=[]} =  useSelector(({area})=>area)
  const { partnersInvoices } = useSelector(({ partner }) => partner);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPartnersInvoices({}));

    // return () => {
    //   second
    // }
  }, []);

  return (
    <div className="col-xl-8 mb-5 mb-xl-10" style={{ width: "100%" }}>
      <div className="card card-flush h-md-100">
        <div className="card-body">
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              id="kt_stats_widget_6_tab_1"
            >
              <div className="table-responsive">
                <div className="card-title flex-column">
                  <h3 className="fw-bold mb-1">Partner Invoices</h3>
                </div>
              </div>

              <hr />
              <div className="table-responsive" style={{ padding: "0 -20px" }}>
                <Table
                  lable="partners_invoices"
                  tableRows={partnersInvoices?.rows}
                  tableHeads={invoicestableHeads}
                  setOpen={setOpen?.length ? setOpen[0] : ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <PaginationControlled  lable="partners_invoices" />
    </div>
  );
};

export default PartnerInvoicesList;
