import React,{useEffect} from 'react'
import {tablePackHeads} from "../../constants"
import {useSelector} from "react-redux"
import {Table} from "../../components/common"
import PaginationControlled from '../../components/common/paginate'
import { useOutletContext } from 'react-router-dom'

function List() {
    const setOpen = useOutletContext()
    const {packages = [],page=1} = useSelector(({packages})=>packages);

  return (
    <div className="tab-content">
    <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
      <div className="table-responsive">
      <div className="card-title flex-column">
        {/* Expense Information */}
      <h3 className="fw-bold mb-1">Package Information</h3>
      <div className="d-flex flex-wrap justify-content-start">
				<div className="d-flex flex-wrap">
       <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" style={{marginTop:5}}>
				<div className="d-flex align-items-center">
					<div className="fs-6 text-gray-500 fw-bold" >List of Packages</div>
					</div>
					<div className="fs-2 fw-bold text-success" style={{textAlign:"center", paddingTop:5}}>{packages.count}</div>
						</div>
            </div>
          </div>
      </div>
        <hr />
        <Table lable="packages" tableRows={packages.rows} tableHeads={tablePackHeads} setOpen={setOpen?.length?setOpen[0]:""}/>
      </div>
    </div>
  </div>
  )
}

export default List