export const reportList = [
    {
        name: "Guillermo Felipe OLARRA",
        a: 48,
        b: 113,
        c: 4,
        d: 100,
        e: 0,
        f: 3,
        c1Total: 123,
        g: 2,
        h: 1,
    },
    {
        name: "Rony RAMOS",
        a: 48,
        b: 113,
        c: 4,
        d: 100,
        e: 0,
        f: 3,
        c1Total: 123,
        g: 2,
        h: 1,
    },
]

export const tableReportHeads = [
    "Driver",
    "Package A",
    "Package B",
    "Package D",
    "Package E",
    "Package F",
    "C1-Total",
    "Package G",
    "Package H",
    "Hours Worked"
]

export const tableReportHeadsOverView = [
    "Driver",
    "A",
    "B",
    "D",
    "E",
    "F",
    "G",
    "H",
    "Total"
]