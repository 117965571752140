import React,{useState} from 'react'
import {Link,useNavigate} from "react-router-dom"
import {loggedIn} from "../../services/services";
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../../actions'
function AccountMenu({setAuth}) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [openSub,setOpenSub] = useState(false);

    const {user} = useSelector(({user})=>user)

    const handleLogOut = ()=>{
        dispatch(logout())
        navigate("/login");
        // localStorage.removeItem("userLogin");
    }
  return (
    //  {/*h-250px*/}
        <div className="menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"  style={{border:"1px solid #ffffff"}}>
            {/*begin::Menu item*/}
            <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-50px me-5">
                        <img alt="Logo" src="/assets/media/avatars/blank.png" />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Username*/}
                    <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">
                               {user.email}
                        </div>
                    </div>
                    {/*end::Username*/}
                </div>
            </div>
            {/*end::Menu item*/}
            {/*begin::Menu separator*/}
            <div className="separator my-2" />
            <div className="menu-link px-5" onClick={handleLogOut}>
                    Sign Out
            </div>
    </div>
  )
}

export default AccountMenu