import { ACTION_TYPES } from "../constants";
import {
  postPartner,
  fetchPartners,
  fetchPartner,
  updatePartner,
  postPartnerInvoice,
  fetchPartnersInvoices,
  fetchPartnerInvoice,
  updatePartnerInvoice,
  deletePartnerInvoice,
  deletePartner,
} from "../api";
import { NotificationManager } from "react-notifications";

export const createPartner = (res, navigate) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.PARTNER_REQUEST });
    const { data } = await postPartner(res);
    navigate("/partnerInvoices/partnerList");
    NotificationManager.success("Successfully added new partner");
    dispatch(getPartners());
  } catch (error) {
    dispatch({ type: ACTION_TYPES.PARTNER_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Something went wrong")
    );
  }
};

export const getAllPartners = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GET_ALL_PARTNER_REQUEST });
    const { data } = await fetchPartners();
    dispatch({
      type: ACTION_TYPES.GET_ALL_PARTNER_SUCCESS,
      payload: data?.data?.rows,
    });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.GET_ALL_PARTNER_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Something went wrong")
    );
  }
};

export const getPartners = (res = {}) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_PARTNERS_REQUEST });
      const {
        page = 1,
        pageSize = 20,
        order = "ASC",
        field = "createdAt",
      } = getState().partner;
      const filter = {
        page,
        pageSize: 20,
        field,
        order,
        ...res,
      };
      const { data } = await fetchPartners(filter);
      dispatch({
        type: ACTION_TYPES.GET_PARTNERS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.GET_PARTNERS_FAILED });
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Something went wrong")
      );
    }
};

export const getPartner = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.PARTNER_REQUEST });
    const { partners } = getState().partner;
    const { data } = await fetchPartner(id);
    dispatch({
      type: ACTION_TYPES.PARTNER_SUCCESS,
      payload: { ...partners, count: 1, rows: [data.data] },
    });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.PARTNER_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Something went wrong")
    );
  }
};

export const setPartner = (res) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.SET_PARTNER_REQUEST });
    dispatch({ type: ACTION_TYPES.SET_PARTNER_SUCCESS, payload: res });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.SET_PARTNER_FAILED });
  }
};

export const editPartner = (res, navigate) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.EDIT_PARTNER_REQUEST });
    const { partner } = getState().partner;
    const response = await updatePartner(partner.id, res);
    dispatch({ type: ACTION_TYPES.EDIT_PARTNER_SUCCESS });
    navigate("/partnerInvoices/partnerList");
    NotificationManager.success("Successfully Updated partner");
    dispatch(getPartners());
  } catch (error) {
    dispatch({ type: ACTION_TYPES.EDIT_PARTNER_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Partner updation failed")
    );
  }
};

export const removePartner = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_REQUEST });
    const response = await deletePartner(id);
    dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_SUCCESS });

    NotificationManager.success("Successfully deleted partner");
    dispatch(getPartners());
  } catch (error) {
    dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Partner updation failed")
    );
  }
};

export const createPartnerInvoice = (res, navigate) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_REQUEST });
      const { data } = await postPartnerInvoice(res);
      navigate("/partnerInvoices/partnerInvoicesList");
      NotificationManager.success("Successfully generated new partner invoice");
      dispatch(getPartnersInvoices());
    } catch (error) {
      dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_FAILED });
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Something went wrong")
      );
    }
  };

// export const getAllPartners = ()=>async(dispatch,getState)=>{
//     try {
//         dispatch({type: ACTION_TYPES.GET_ALL_PARTNER_REQUEST});
//         const {data} = await fetchPartners();
//         dispatch({type: ACTION_TYPES.GET_ALL_PARTNER_SUCCESS,payload: data?.data?.rows});
//     } catch (error) {
//         dispatch({type: ACTION_TYPES.GET_ALL_PARTNER_FAILED})
//         NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
//     }
// }

export const getPartnersInvoices = (res = {}) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_PARTNERS_INVOICES_REQUEST });
      const {
        page = 1,
        pageSize = 20,
        order = "ASC",
        field = "createdAt",
      } = getState().partner;
      const filter = {
        page,
        pageSize: 20,
        field,
        order,
        ...res,
      };
      const { data } = await fetchPartnersInvoices(filter);
      dispatch({
        type: ACTION_TYPES.GET_PARTNERS_INVOICES_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.GET_PARTNERS_INVOICES_FAILED });
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Something went wrong")
      );
    }
  };

export const getPartnerInvoice = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_REQUEST });
    const { partners } = getState().partner;
    const { data } = await fetchPartnerInvoice(id);
    dispatch({
      type: ACTION_TYPES.PARTNER_INVOICE_SUCCESS,
      payload: { ...partners, count: 1, rows: [data.data] },
    });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Something went wrong")
    );
  }
};

export const setPartnerInvoice = (res) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.SET_PARTNER_INVOICE_REQUEST });
    dispatch({ type: ACTION_TYPES.SET_PARTNER_INVOICE_SUCCESS, payload: res });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.SET_PARTNER_INVOICE_FAILED });
  }
};

export const editPartnerInvoice = (res, navigate) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.EDIT_PARTNER_INVOICE_REQUEST });
      const { partnerInvoice } = getState().partner;
      const response = await updatePartnerInvoice(partnerInvoice.id, res);
      dispatch({ type: ACTION_TYPES.EDIT_PARTNER_INVOICE_SUCCESS });
      navigate("/partnerInvoices/partnerInvoicesList");
      NotificationManager.success("Successfully Updated partner invoice");
      dispatch(getPartnersInvoices());
    } catch (error) {
      dispatch({ type: ACTION_TYPES.EDIT_PARTNER_INVOICE_FAILED });
      console.log("error", error);
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Partner updation failed")
      );
    }
  };

  export const removePartnerInvoice = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_REQUEST });
      const response = await deletePartnerInvoice(id);
      dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_SUCCESS });

      NotificationManager.success("Successfully deleted partner invoice");
      dispatch(getPartnersInvoices());
    } catch (error) {
      dispatch({ type: ACTION_TYPES.PARTNER_INVOICE_FAILED });
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Partner updation failed")
      );
    }
  };
