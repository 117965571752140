import React, {useState, useEffect, useRef} from 'react'
import {Selector,ViewDatePicker} from "../../components/common"
import {useNavigate, Link} from 'react-router-dom';
import {  NotificationManager } from 'react-notifications';
import {updateDriver, getSandElist, uploadDriverDocument, updateSorterDriver, updateDriverInOutData}  from '../../actions';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form,useFormik} from 'formik';
import Loader from "../../components/common/loader"
import * as yup from 'yup';

import InputSelect from '../../components/common/InputSelect';


const initialValues = {
    area_name:"",
    driver: "",
    startDate: "",
    endDate: "",
  };

function EditTrackingData({}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {options} = useSelector(({area})=>area);
  const {sorterDrivers,loader,trackingData } = useSelector(({ driver }) => driver);
  const {rows:data} = sorterDrivers

//   useEffect(()=>{
//     dispatch(getSandElist(editDriver.id))
//   },[])

  const initialValues ={
        in_area_id:trackingData?.in_area_id,
        out_area_id:trackingData?.out_area_id,
        log_in_time:trackingData?.log_in_time,
        log_out_time:trackingData?.log_out_time,
        driver_id: trackingData?.driver_id
    }
  const validation = yup.object({
      in_area_id:yup.string()
      .required("In area is required"),
      out_area_id:yup.string()
      .required("Out area is required"),
      driver_id:yup.string()
      .required("Driver is required"),
      log_in_time:yup.string()
      .required("Login time is required"),
      log_out_time:yup.string()
      .required("Logout time is required"),
  })

  const handleSubmit= (values)=>{
    if(values){
          if(new Date(values?.log_in_time)>new Date()){
            NotificationManager.error("Login time should be less than or equal to current date")
            return
          }
          if(new Date(values?.log_out_time)>new Date()){
            NotificationManager.error("Logout time should be less than or equal to current date")
            return
          }
          if(new Date(values?.log_in_time)>new Date(values?.log_out_time)){
            NotificationManager.error("Login time should be less than Logout time")
            return
          }

      const driver = {
        in_area_id: values?.in_area_id,
        out_area_id: values?.out_area_id,
        log_in_time: values.log_in_time ? new Date(new Date(values.log_in_time ? values.log_in_time : new Date())).toISOString()
        : "",
        log_out_time: values.log_out_time
        ? new Date(new Date( values.log_out_time ?  values.log_out_time : new Date())).toISOString()
        : "",
        driver_id: values.driver_id
      }
      dispatch(updateDriverInOutData(driver,navigate))
    }
    else NotificationManager.error('please fill data')
  }
 

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: handleSubmit
  });

//   useEffect(() => {   
//     if (!formik.isSubmitting) return;
//     if (Object.keys(formik.errors).length > 0) {
//       const errorElement = document.getElementsByName(Object.keys(formik.errors)[0])[0];
//       errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
//       errorElement.focus({ preventScroll: true });
//     }
//   }, [formik]);

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        {/*begin::Content container*/}
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
          <div className="card mb-5 mb-xl-10">
            {/* add new card element start */}
          <div className="card card-flush h-md-100">
          <div className="card-body">
            </div>                   
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
              style={{marginTop:"-45px"}}
            >
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Edit Driver</h3>
              </div>
              {/*end::Card title*/}
            </div>
            </div>
            {/*begin::Card header*/}
            {/*begin::Content*/}
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              {/*begin::Form*/}
              {/* <formik
            initialValues = {initialValues}
              validationSchema = {validation}
              onSubmit= {handleSubmit}
          >
              {({
                  values, handleChange, handleBlur, errors, touched, formik.setFieldValue, setFieldTouched
              })=> ( */}
              <form id="kt_account_profile_details_form" className="form" onSubmit={formik.handleSubmit}>
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Driver
                    </label>
                    <div className="col-lg-8 fv-row" style={{position:'relative'}}>
                     <InputSelect
                      data={
                        data.length
                          ? data.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it.driverType
                              };
                            })
                          : ["Loadin..."]
                      }
                      onChange={(value)=>formik.setFieldValue("driver_id", value)}
                      value ={formik.values.driver_id}
                      onBlur={formik.handleBlur}
                    />
                    <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                      {formik.touched.driver_id && formik.errors.driver_id ? <div>{formik.errors.driver_id}</div> : null}
                      </div></div>
                  </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Login Time
                    </label>
                    <div className="col-lg-8 fv-row">
                      <ViewDatePicker
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        id="date"
                        name="startDate"
                        onChange={(value) => formik.setFieldValue("log_in_time", value)}
                        value={formik.values.log_in_time}
                        onBlur={formik.handleBlur}
                        style={{ paddingLeft: 10 }}
                      />
                       <div class="fv-plugins-message-container invalid-feedback">
                            <div data-field="fname" data-validator="notEmpty">
                            {formik.touched.log_in_time && formik.errors?.log_in_time ? <div>{formik.errors.log_in_time}</div> : null}</div>
                        </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Logout Time
                    </label>
                    <div className="col-lg-8 fv-row">
                      <ViewDatePicker
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        id="date"
                        name="endDate"
                        onChange={(value) => formik.setFieldValue("log_out_time", value)}
                        value={formik.values.log_out_time}
                        onBlur={formik.handleBlur}
                        style={{ paddingLeft: 10 }}
                      />
                       <div class="fv-plugins-message-container invalid-feedback">
                            <div data-field="fname" data-validator="notEmpty">
                            {formik.touched.log_out_time && formik.errors.log_out_time ? <div>{formik.errors.log_out_time}</div> : null}</div>
                        </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Login Area
                    </label>
                    <div className="col-lg-8 fv-row">
                    <Selector 
                      data={options.map((it)=>{
                        return {
                          value: it.id,
                          title: it.areaName,
                        }
                      })} 
                      onChange={(value)=>formik.setFieldValue("in_area_id",value)}
                      onBlur={formik.handleBlur}
                      value={formik.values.in_area_id}
                      />
                       <div class="fv-plugins-message-container invalid-feedback">
                            <div data-field="fname" data-validator="notEmpty">
                            {formik.touched.in_area_id && formik.errors.in_area_id ? <div>{formik.errors.in_area_id }</div> : null}</div>
                        </div>
                    </div>
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      {" "}
                    </label>
                  </div>  
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Logout Area
                    </label>
                    <div className="col-lg-8 fv-row">
                    <Selector 
                      data={options.map((it)=>{
                        return {
                          value: it.id,
                          title: it.areaName,
                        }
                      })} 
                      onChange={(value)=>formik.setFieldValue("out_area_id",value)}
                      onBlur={formik.handleBlur}
                      value={formik.values.out_area_id}
                      />
                       <div class="fv-plugins-message-container invalid-feedback">
                            <div data-field="fname" data-validator="notEmpty">
                            {formik.touched.out_area_id && formik.errors.out_area_id ? <div>{formik.errors.out_area_id }</div> : null}</div>
                        </div>
                    </div>
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      {" "}
                    </label>
                  </div>  
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2"
                    onClick={()=>navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loader}
                    id="kt_account_profile_details_submit"
                    // onClick={handleSubmit}
                  >
                   {loader ? <Loader size={17}/>:"Save Changes"}
                  </button>
                </div>
              </form>
              {/* )}
              </formik> */}
              {/*end::Form*/}
              
            </div>
            {/*end::Content*/}
          </div>
        </div>
        {/*end::Content container*/}
      </div>
     </>

  )
}


const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px"
}

function Selector2({name="",data =[],style = {},onChange = ()=>{},value=[]}) {
  const [type,setType] = useState(value);

  const handleChange = (e)=>{
    const selectedItems = e.target.selectedOptions
    const flavors = [];
    for (let i=0; i<selectedItems?.length; i++) {
        flavors.push(selectedItems[i].value);
    }
    setType(flavors);
    onChange(flavors);
  }
  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars"  style={{...styles,...style}} onChange={handleChange} multiple={true}>
      <option hidden selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value.includes(op.value)} key={index} value={op.value} >{op.title}</option>
      )}
    </select>
  )
}

export default EditTrackingData
