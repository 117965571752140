import {ACTION_TYPES} from "../constants"
import {fetchReports,updateReposts,fetchDriverPackageIncome,fetchDriverProfit,fetchProfiteLoss, fetchCharts, fetchDailyEntrySummary, fetchReportComparison} from "../api"
import {  NotificationManager } from 'react-notifications';

export const getReports = (req = {}) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.REPORTS_REQUEST});
        const filter = {};
        if(req.date) filter.date = req.date; 
        else filter.date = new Date().toISOString();
        filter.areaId = req.areaId;       
        const {data} = await fetchReports(filter);
        dispatch({type: ACTION_TYPES.REPORTS_SUCCESS,payload: data?.data})
    } catch (error) {
        dispatch({type: ACTION_TYPES.REPORTS_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getDailyEntrySummary=(req = {}) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.REPORTS_REQUEST});
        const {data} = await fetchDailyEntrySummary(req);
        dispatch({type: ACTION_TYPES.SUMMARY_REPORTS_SUCCESS,payload: data?.data})
    } catch (error) {
        dispatch({type: ACTION_TYPES.REPORTS_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getDailyEntrySummaryByDriver = async(req, selectedDriver, setState, setLoading) => {
    try {
        setLoading(true)
        const {data} = await fetchReports(req);

        const newData = data?.data?.filter(
            (ele) => ele?.[0].driver_id === selectedDriver?.driver_id
          );
        
        setState(newData)
        setLoading(false)
    } catch (error) {
        setLoading(false)
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getReportComparison=(req = {}) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.REPORTS_REQUEST});
        const {data} = await fetchReportComparison(req);
        dispatch({type: ACTION_TYPES.COMPAIR_REPORT_SUCCESS,payload: data?.data})
    } catch (error) {
        dispatch({type: ACTION_TYPES.REPORTS_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const updateReport = (res,state)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.REPORTS_REQUEST});

        const {data} = await updateReposts(res);

        dispatch(getReports(state))
        NotificationManager.success("Successfull Operation");
    } catch (error) {
        dispatch({type: ACTION_TYPES.REPORTS_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getDriverPackageIncome = (res={})=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.REPORTS_REQUEST});
        const {data} = await fetchDriverPackageIncome(res);
        dispatch({type: ACTION_TYPES.DRIVER_PACKAGE_INCOME_SUCCESS,payload: data?.data});
        // NotificationManager.success("Successfull Operation");
        
    } catch (error) {
        
        dispatch({type: ACTION_TYPES.REPORTS_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getDriverProfit = (res)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.REPORTS_REQUEST});
        const {data} = await fetchDriverProfit(res)
        dispatch({type: ACTION_TYPES.DRIVER_PROFIT_SUCCESS,payload: data?.data})
        // NotificationManager.success("Successfull Operation");
    } catch (error) {
        
        dispatch({type: ACTION_TYPES.REPORTS_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getProfitLoss = (res)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.REPORTS_REQUEST});
        const {data} = await fetchProfiteLoss(res)
        dispatch({type: ACTION_TYPES.DRIVER_PROFIT_LOSS_SUCCESS,payload: data?.data})
        // NotificationManager.success("Successfull Operation");
    } catch (error) {
        dispatch({type: ACTION_TYPES.REPORTS_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getCharts =
  (req) =>
  async (dispatch, getState) => {
    
    try {
        dispatch({type: ACTION_TYPES.CHART_REQUEST});   
        const {data} = await fetchCharts(req);
        dispatch({type: ACTION_TYPES.CHART_SUCCESS,payload: data?.data})
    } catch (error) {
        dispatch({type: ACTION_TYPES.CHART_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}