import React, {useState,useEffect} from 'react'
import {
  getCustomDriverRate,
  getAllCustomRateForWeekend,
  upsertCustomDriverRateForWeekends
} from "../../actions"
import {useDispatch, useSelector} from "react-redux"
import { useNavigate, useLocation } from 'react-router-dom';

function CustomRateForWeekend() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
const location = useLocation()
  const {
    driverId,
    customRate: customRates=[],
    loader,allCustomRate = [],
    customRateForWeekend
  } = useSelector(({customRate})=>customRate)

  const [state,setState] = useState([]);
  useEffect(()=>{
    //TODO :set weekends custom rate
    setState(customRateForWeekend);
  },[JSON.stringify(customRateForWeekend)])

  useEffect(()=>{
    dispatch(getCustomDriverRate(driverId))
    // dispatch(getAllCustomDrivers())
    dispatch(getAllCustomRateForWeekend(driverId))
  },[])

  const onChange =(k,v, row)=>{   
    const value  = v.toISOString().substring(0,10)
    setState(state.map((it)=>{
       if(it.package.type === row.package.type && it.package.area.id === row.package.area.id) return {...it,[k]: +value};
      return it;
    }))
  }
  const handleChange = (e,row)=>{
    const {name,value} = e.target;
    setState(state.map((it)=>{
      if(it.package.type === row.package.type && it.package.area.id === row.package.area.id) return {...it,[name]: +value};
      return it;
    }))
  }
  const handleClick = (e)=>{
    e.preventDefault();
    // const ans = {customRatesArray:removedFields};
    dispatch(upsertCustomDriverRateForWeekends(state,navigate));
  }

  return (
    <>
      {loader?<h2>Loading...</h2>:
      <>
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-xxl"
          >
            <div className="card mb-5 mb-xl-10">
              <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
                <div className="tab-content" style={{padding: "20px"}}>
                <div className="table-responsive">
                  <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                    <thead>
                      <tr className="fw-bold text-gray-500 border-bottom-0" style={{fontSize: "15px"}}>
                        <th  >Package</th>
                        <th>Saturday Rate</th>
                        <th>Sunday Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state?.length && state.map((row,index)=>
                        <tr key={index}>            
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Package {row.package?.type+` [in ${row.package?.area?.areaName}]`}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                        <div className="col-lg-6 fv-row">
                          <input
                            type="number"
                            name="saturday_customRate"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="saturday_customRate"
                            defaultValue="0"
                            onChange={(e)=>handleChange(e,row)}
                            value={row.saturday_customRate}
                          />
                        </div>
                        </td>
                        
                        <td>
                        <div className="col-lg-6 fv-row">
                          <input
                            type="number"
                            name="sunday_customRate"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="sunday_customRate"
                            defaultValue="0"
                            onChange={(e)=>handleChange(e,row)}
                            value={row.sunday_customRate}
                          />
                        </div>
                        </td>

                      </tr>
                      )}
                    </tbody>
                  </table>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form id="kt_account_profile_details_form" className="form">
                      <div className="card-body border-top p-9">

                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                          onClick={()=>navigate("/drivers/customRateForWeekend")}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="kt_account_profile_details_submit"
                          onClick={handleClick}
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  )
}

export default CustomRateForWeekend