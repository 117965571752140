import React, {useState,useEffect} from 'react'
import {getCustomDriverRate,upsertCustomDriverRate,getAllCustomDrivers} from "../../actions"
import {useDispatch, useSelector} from "react-redux"
import { useNavigate } from 'react-router-dom';
import { ViewDatePicker } from '../../components/common';

const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

function CustomRate({}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {driverId,customRate: customRates=[],loader,allCustomRate = []} = useSelector(({customRate})=>customRate)
  const {sorterDriver} = useSelector((driver)=>driver.driver)
  const [state,setState] = useState([]);

  useEffect(()=>{
    setState(customRates);
  },[JSON.stringify(customRates)])

  useEffect(()=>{
    dispatch(getCustomDriverRate(driverId))
    dispatch(getAllCustomDrivers())
  },[])

  const onChange =(k,v, row)=>{   
    const value  = v.toISOString().substring(0,10)
    setState(state.map((it)=>{
       if(it.packageType === row.packageType && it.area_id === row.area_id) return {...it,[k]: value};
      return it;
    }))
  }
  const handleChange = (e,row)=>{
    const {name,value} = e.target;
    setState(state.map((it)=>{
      if(it.packageType === row.packageType && it.area_id === row.area_id) return {...it,[name]: value};
      return it;
    }))
  }
  const handleClick = (e)=>{
    e.preventDefault();
    const removedFields = state.map((it)=>{
      delete it.areaName;
      delete it.packageType;
      return {...it,effectiveDate: it.effectiveDate ? new Date(it.effectiveDate).toISOString():new Date().toISOString()};
    })
    const ans = {customRatesArray:removedFields};
    dispatch(upsertCustomDriverRate(ans,navigate));
  }

  return (
    <>
      {loader?<h2>Loading...</h2>:
      <>
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-xxl"
          >
            <div className="card mb-5 mb-xl-10">
              <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
                <div className="tab-content" style={{padding: "20px"}}>
                  <h2 style={{textAlign:'center',marginBottom:'5px'}}>{`${sorterDriver?.firstName?sorterDriver?.firstName:""} ${sorterDriver?.lastName?sorterDriver?.lastName:""}`}</h2>
                  <hr/>
                <div className="table-responsive">
                  <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                    <thead>
                      <tr className="fw-bold text-gray-500 border-bottom-0" style={{fontSize: "15px"}}>
                        <th>Package</th>
                        <th>Package Description</th>
                        <th>Effective From</th>
                        <th>Rate (inc. GST)</th>
                        <th>Saturday Rate (inc. GST)</th>
                        <th>Sunday Rate (inc. GST)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state?.length && state.map((row,index)=>
                        <tr key={index}>            
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Package {row.packageType+` [in ${row.areaName}]`}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Description {`[in ${row?.packageDescription}]`}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <ViewDatePicker
                            class="form-control form-control-lg form-control-solid"
                            name="effectiveDate"
                            defaultValue = {new Date().toISOString()}
                            onChange ={(e)=>onChange("effectiveDate", e, row)}
                            value ={row.effectiveDate.substring(0,10)}

                          />
                          {/* <input 
                            class="form-control form-control-lg form-control-solid"
                            type="date"
                            id="birthday"
                            name="effectiveDate"
                            defaultValue={new Date().toISOString()}
                            onChange={(e)=>handleChange(e,row)}
                            value={row.effectiveDate.substring(0, 10)}
                            /> */}
                        </td>
                        <td>
                        <div className="col-lg-6 fv-row">
                          <input
                            type="number"
                            name="customRate"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="customRate"
                            defaultValue="0"
                            onChange={(e)=>handleChange(e,row)}
                            value={row.customRate}
                          />
                        </div>
                        </td>
                          <td>
                        <div className="col-lg-6 fv-row">
                          <input
                            type="number"
                            name="saturday_customRate"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="saturday_customRate"
                            defaultValue="0"
                            onChange={(e)=>handleChange(e,row)}
                            value={row?.saturday_customRate?row.saturday_customRate:0}
                          />
                        </div>
                        </td>
                        <td>
                        <div className="col-lg-6 fv-row">
                          <input
                            type="number"
                            name="sunday_customRate"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="sunday_customRate"
                            defaultValue="0"
                            onChange={(e)=>handleChange(e,row)}
                            value={row?.sunday_customRate?row.sunday_customRate:0}
                          />
                        </div>
                        </td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form id="kt_account_profile_details_form" className="form">
                      <div className="card-body border-top p-9">

                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                          onClick={()=>navigate("/drivers/driversList")}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="kt_account_profile_details_submit"
                          onClick={handleClick}
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
                  <div className="table-responsive">
                    <h3>Rate Changes</h3>
                    <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                      {/*begin::Table head*/}
                      <thead>
                        <tr className="fw-bold text-gray-500 border-bottom-0" style={{fontSize: "15px"}}>
                          <th  >Entered Date</th>
                          <th  >Effective Date</th>
                          <th  >Package</th>
                          <th  >Current Rate</th>
                          <th  >Previous Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allCustomRate?.length ? allCustomRate.map((rate,index)=>
                          <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <span
                                  href="#"
                                  // className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  class="fs-6 fw-bold"
                                >
                                  {`${new Date(rate.createdAt).getDate()} ${month[new Date(rate.createdAt).getMonth()]}, ${new Date(rate.createdAt).getFullYear()}
                                  ${new Date(rate.createdAt).getHours()}:${new Date(rate.createdAt).getMinutes()}:${new Date(rate.createdAt).getSeconds()}`}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span
                              className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                            >
                            {
                            `${new Date(rate.effectiveDate).getDate()} ${month[new Date(rate.effectiveDate).getMonth()]}, ${new Date(rate.effectiveDate).getFullYear()}`
                            }
                            </span>
                          </td>
                          <td>
                            <span
                              className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                            >
                            {rate.package?.type + ` [in ${rate?.package?.area?.areaName}]`}
                            </span>
                          </td>
                          <td>
                            <span
                            className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                            >
                            {rate.customRate}
                            </span>
                          </td>
                          <td>
                            <span
                          className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                          >
                          {rate.previousRate}
                          </span>
                          </td>
                        </tr>
                        ):null}
                      </tbody>
                      {/*end::Table body*/}
                    </table>
                  </div>
                  {/*end::Table*/}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  )
}

export default CustomRate