export const packageList = [
    {
        id: "1",
        type: "A",
        category: "Category1",
        description: "ORDINARY PARCEL",
        delivery_area: "Liverpool",
        ausPost_rate: "1.601",
        ausDriver_rate: "1.2",
    },
    {
        id: "2",
        type: "D",
        category: "Category2",
        description: "ORDINARY PARCEL",
        delivery_area: "Liverpool",
        ausPost_rate: "1.601",
        ausDriver_rate: "1.2",
    },
    {
        id: "3",
        type: "D",
        category: "Category1",
        description: "ORDINARY PARCEL",
        delivery_area: "Liverpool",
        ausPost_rate: "1.601",
        ausDriver_rate: "1.2",
    }
]

export const tablePackHeads = [
    "Id",
    "Type",
    "Category",
    "Description",
    "Delivery Area",
    "AusPost Rate",
    "AusDriver Rate",
    "Edit",
    "Hide"
]