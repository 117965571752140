import { ACTION_TYPES, customRate as customRateList } from "../constants";
import {
    fetchCustomDriverRate,
    postCustomDriverRate,
    fetchAllCustomDriverRate,
    fetchAllCustomDriverRateForWeekend,
    postCustomDriverRateForWeekend
} 
from "../api"
import {  NotificationManager } from 'react-notifications';

export const getCustomDriverRate = (id)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_REQUEST});

        const {data} = await fetchCustomDriverRate(id);
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_SUCCESS,payload: data?.data})
    } catch (error) {
        console.log(error);
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const upsertCustomDriverRate = (res=[],navigate)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_REQUEST});
        const {driverId} = getState().customRate;

        const {data} = await postCustomDriverRate(res);
        // dispatch({type: ACTION_TYPES.CUSTOM_RATE_SUCCESS,payload: data?.data});
        dispatch(getCustomDriverRate(driverId))
        NotificationManager.success("Successfull operation");
        navigate(-1);
    } catch (error) {
        console.log(error);
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getAllCustomDrivers = ()=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_REQUEST});
        const {driverId} = getState().customRate;
        const {data} = await fetchAllCustomDriverRate(driverId);
        // console.log("data",data.data)
        dispatch({type: ACTION_TYPES.SET_ALL_CUSTOM_RATE,payload: data?.data})
    } catch (error) {
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getAllCustomRateForWeekend = ()=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_FOR_WEEKEND_REQUEST});
        const {driverId} = getState().customRate;
        const {data} = await fetchAllCustomDriverRateForWeekend(driverId);
        // console.log("data",data.data)
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_FOR_WEEKEND_SUCCESS,payload: data?.data})
    } catch (error) {
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const upsertCustomDriverRateForWeekends = (res=[],navigate)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_REQUEST});
        const {driverId} = getState().customRate;

        const {data} = await postCustomDriverRateForWeekend(res);
        // dispatch({type: ACTION_TYPES.CUSTOM_RATE_SUCCESS,payload: data?.data});
        dispatch(getAllCustomRateForWeekend(driverId))
        NotificationManager.success("Successfull operation");
        navigate('/drivers/customRateForWeekend');
    } catch (error) {
        console.log(error);
        dispatch({type: ACTION_TYPES.CUSTOM_RATE_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}