import {ACTION_TYPES} from "../constants"
const INITIAL_STATE = {
    drivers: [],
    loader: false,
    driver:{},
    sorterDriver:{},
    show:{},
    allDrivers:[],
    sorterDrivers:[],
    hiddenDrivers:[],
    page: 1,
    pageSize: 20,
    field: "createdAt",
    order: "ASC",
    hiddenDriver:{},
    sAndEmpList: [],
    loggedInDrivers: [],
    driversInOutData:[],
    trackingData:{} //object for perticular driver_in_out_data
}

const driver = (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case ACTION_TYPES.DRIVER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.DRIVER_SUCCESS:
            return {...state,drivers: action.payload,loader: false};
        case ACTION_TYPES.DRIVER_FAILED:
            return {...state,loader: false};
        case ACTION_TYPES.EDIT_DRIVER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.EDIT_DRIVER_SUCCESS:
            return {...state,driver: action.payload,loader: false};
        case ACTION_TYPES.EDIT_DRIVER_FAILED:
            return {...state,loader: false};
        case ACTION_TYPES.GET_ALL_DRIVERS:
            return {...state,allDrivers: action.payload, loader:false}
        case "GET_ALL_HIDDEN_DRIVERS":
            return {...state,hiddenDrivers: action.payload, loader:false}
            case "SHOW_HIDE":
                return {...state, show:action.payload}

        case ACTION_TYPES.SET_DRIVER_FILTER:
            const {page=1,pageSize=20,order="ASC",field="createdAt"} = action.payload;
            return {...state,page: page, pageSize: pageSize, field: field, order: order,
            };
        case ACTION_TYPES.SET_DRIVER_PAGE:
            return {...state,page: action.payload};
            case ACTION_TYPES.RESET_DRIVER_PAGE:
            return {...state, page: 1};
        case "SET_HIDE_DRIVER":
            return {...state, hiddenDriver:action.payload}
        case ACTION_TYPES.S_AND_EMP_LIST_REQUEST:
                return {...state,loader: true};
        case ACTION_TYPES.S_AND_EMP_LIST_SUCCESS:
            return {...state,sAndEmpList: action.payload,loader: false};
        case ACTION_TYPES.S_AND_EMP_LIST_FAILED:
            return {...state,loader: false};
        case ACTION_TYPES.LOGGED_IN_DRIVER_SUCCESS:
            return {...state,loggedInDrivers: action.payload,loader: false};
        case ACTION_TYPES.LOGGED_IN_DRIVER_FAILED:
            return {...state,loader: false};
        case ACTION_TYPES.LOGGED_IN_DRIVER_REQUEST:
            return {...state,loader: true};

//-------------------------------------------------------------------------------- Sorter Driver
        case ACTION_TYPES.SORTER_DRIVER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.SORTER_DRIVER_SUCCESS:
            return {...state,sorterDrivers: action.payload,loader: false};
        case ACTION_TYPES.SORTER_DRIVER_FAILED:
            return {...state,loader: false};
        
        case ACTION_TYPES.SET_SORTER_DRIVER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.SET_SORTER_DRIVER_SUCCESS:
            return {...state,sorterDriver: action.payload,loader: false};
        case ACTION_TYPES.SET_SORTER_DRIVER_FAILED:
            return {...state,loader: false};


//* ----------------------------------------------------------------- Driver in-out pause data
        case ACTION_TYPES.SORTER_DRIVER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.DRIVER_IN_OUT_DATA_SUCCESS:
            return {...state,driversInOutData: action.payload,loader: false};
        case ACTION_TYPES.SORTER_DRIVER_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.SET_SORTER_DRIVER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.SET_DRIVER_IN_OUT_DATA:
            return {...state,trackingData: action.payload,loader: false};
        case ACTION_TYPES.SET_SORTER_DRIVER_FAILED:
            return {...state,loader: false};


            default:
            return state
    }
}

export default driver