import React from 'react'

function Footer() {
  return (
    <div id="kt_app_footer" className="app-footer">
    {/*begin::Footer container*/}
    <div className="app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
        {/*begin::Copyright*/}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">Copyright 2023 © All Rights Reserved.</span>
        </div>

        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">St. Mina Transport | RapidStack Technologies | Version 2.0</span>
        </div>
    </div>
    </div>
  )
}

export default Footer