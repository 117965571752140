import React, {useState} from 'react'
import {Drawer, Box} from "@mui/material";
import NewSidebar from './newSidebar';

function Header() {
    const [isDrawerOpen, setIsDrawerOpen] =  useState(false)
   
  return (
   <>
    {/*begin::Header container*/}
  
            {/*begin::Menu wrapper*/}
            
            {/*end::Menu wrapper*/}
            {/*begin::Logo wrapper*/}
            <div className="d-flex align-items-center">
                {/*begin::Logo wrapper*/}
                <div
                    className="btn btn-icon btn-color-gray-600 btn-active-color-primary ms-n3 me-2 d-flex d-lg-none"
                    id="kt_app_sidebar_toggle"
                    onClick={()=>setIsDrawerOpen(true)}
                >
                    {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
                    <span className="svg-icon svg-icon-2">
                        <svg
                         style={{width: "2rem !important",
                            height: "2rem !important",
                            marginLeft: "58px",
                            marginTop: "20px"
                        }}
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                fill="currentColor"
                            />
                            <path
                                opacity="0.3"
                                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                fill="currentColor"
                            />
                        </svg>
                    </span>
                    {/*end::Svg Icon*/}
                </div>
                <Drawer
                    anchor="left"
                    open={isDrawerOpen}
                    onClose={()=>setIsDrawerOpen(false)}
                >
                    <Box p={2} width="300px">
                    <NewSidebar />
                    </Box>
                </Drawer>
                {/*end::Logo wrapper*/}
                {/*begin::Logo image*/}
                {/* <a
                    href="../../demo23/dist/index.html"
                    className="d-flex d-lg-none"
                > */}
                    {/* <img
                        alt="Logo"
                        src="assets/media/logos/demo23.svg"
                        className="h-20px theme-light-show"
                    /> */}
                    {/* <img
                        alt="Logo"
                        src="assets/media/logos/demo23-dark.svg"
                        className="h-20px theme-dark-show"
                    />
                </a> */}
                {/*end::Logo image*/}
            </div>
            {/*end::Logo wrapper*/}
            {/*begin::Navbar*/}
            {/* <div className="app-navbar flex-shrink-0"> */}
                {/*begin::Chat*/}
                {/* <div className="app-navbar-item ms-1 ms-lg-3"> */}
                    {/*begin::Menu wrapper*/}
                    {/* <div
                        className="btn btn-icon btn-circle btn-color-gray-500 btn-active-color-primary btn-custom shadow-xs bg-body"
                        id="kt_drawer_chat_toggle"
                        onClick={()=>setOpenChat((p)=>!p)}
                    >
                        <i className="fonticon-messenger fs-2" />
                    </div> */}
                    {/*end::Menu wrapper*/}
                {/* </div> */}
                {/*end::Chat*/}
                {/*begin::Header menu mobile toggle*/}
                {/* <div
                    className="app-navbar-item d-lg-none ms-2 me-n3"
                    title="Show header menu"
                // >
                    
                        {/*begin::Svg Icon | path: icons/duotune/text/txt001.svg*/}
                        {/* <div
                        className="btn btn-icon btn-color-gray-600 btn-active-color-primary"
                        id="kt_app_header_menu_toggle"
                    >  */}
                        {/* <span className="svg-icon svg-icon-2">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                                    fill="currentColor"
                                />
                                <path
                                    opacity="0.3"
                                    d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </span> */}
                        {/*end::Svg Icon*/}
                     {/* </div> */}
                {/* </div> */} 
                {/*end::Header menu mobile toggle*/}
            {/* </div> */}
            {/*end::Navbar*/}   
                 
    </>
    )
}

export default Header