import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Selector, ViewDatePicker } from "../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSorterDriver, getAllPartners, getInvoices, getPartners, getPartnersInvoices } from "../../actions";
import InputSelect from "../../components/common/InputSelect";
import { NotificationManager } from "react-notifications";

const partnerStatusOptions = ["all", "active", "inactive"];
const partnerInvoiceStatusType = ["new", "paid", "unpaid", "partial", "void"];

const PartnerInvoices = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const location = useLocation();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const initialValues = {
    partner: null,
    status: "",
    start_date: "",
    end_date: "",
  };

  const initialValues1 = {
    partner: null,
    status: "",
    start_date: "",
    end_date: "",
  };

  const [state, setState] = useState(initialValues);
  const [state1, setState1] = useState(initialValues1)
  const { partners, loader, allPartners } = useSelector(
    ({ partner }) => partner
  );

  const onkeyChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const onkeyChange1 = (key, value) => {
    setState1({ ...state1, [key]: value });
  };

  const handleSubmit = (e) => {
    let payload = {};
    if (state.start_date) payload["start_date"] = state.start_date;
    if (state.end_date) payload["end_date"] = state.end_date;
    if (state.partner) payload["partner_id"] = +state.partner;
    payload["status"] = state.status;
    dispatch(getPartners(payload));
  };

  const handleSubmit1 = (e) => {
    if(!state1.start_date && state1.end_date){
      NotificationManager.error("Start date required");
      return
    }
    if(!state1.end_date && state1.start_date){
      NotificationManager.error("End date required");
      return
    }
    
    let payload = {};
    if (state1.start_date) payload["start_date"] = state1.start_date;
    if (state1.end_date) payload["end_date"] = state1.end_date;
    if (state1.partner) payload["partner_id"] = +state1.partner;
    payload["status"] = state1.status;
    dispatch(getPartnersInvoices(payload));
  };

  const handleShowAll = () => {
    setOpen(false);
    setState(initialValues);
    dispatch(getPartners({status: ''}));
  };

  const handleShowAll1 = () => {
    setOpen(false);
    setState(initialValues1);
    dispatch(getPartners());
  };

  useEffect(() => {
    if (active === 4) {
      dispatch(getAllPartners());
    }
    else if(active === 1){
      dispatch(getAllPartners())
    }
    return () => setOpen(false);
  }, [active]);

  return (
    <div className="col-xl-8 mb-5 mb-xl-10" style={{ width: "100%" }}>
      <div className="card card-flush h-md-100">
        <div className="card-body">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ul className="nav nav-pills nav-pills-custom mb-3">
              {/*begin::Item*/}
              <li className="nav-item mb-3 me-3 me-lg-6">
                {/*begin::Link*/}
                {/* <a
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
            href="#kt_stats_widget_6_tab_1"
          > */}
                <NavLink
                  to="partnerInvoicesList"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(1) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    onClick={() => setOpen(false)}
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i className="bi bi-receipt fs-2" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      Partner Invoices
                    </span>
                    <span
                      className={
                        active === 1
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>

                {/* </a> */}
              </li>
              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="create"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(2) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i className="bi bi-plus-lg" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      Create
                    </span>
                    <span
                      className={
                        active === 2
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>
              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="createPartner"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(3) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i className="bi bi-person-workspace fs-2" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      Add Partner
                    </span>
                    <span
                      className={
                        active === 3
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>
              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="partnerList"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(4) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i className="bi bi-people-fill fs-2" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      View Partners
                    </span>
                    <span
                      className={
                        active === 4
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>
            </ul>
            {active === 1 && pathname === "/partnerInvoices/partnerInvoicesList" && (
              <ul className="nav nav-pills nav-pills-custom mb-3">
                <li className="nav-item mb-3 me-3 me-lg-6">
                  <button
                    style={{ float: "left", marginLeft: 10 }}
                    type="submit"
                    name="description"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    onClick={handleShowAll1}
                  >
                    {" "}
                    Show All
                  </button>
                </li>
                <li className="nav-item mb-3 me-3 me-lg-6">
                  {location.pathname !== "/drivers/customRate" && (
                    <button
                      type="submit"
                      style={{ float: "right", marginleft: 20 }}
                      name="description"
                      className="btn btn-primary"
                      id="kt_account_profile_details_submit"
                      onClick={() => setOpen(!open)}
                    >
                      {" "}
                      <i class="bi bi-search"></i> Search
                    </button>
                  )}
                </li>
              </ul>
            )}
            {active === 4 && pathname === "/partnerInvoices/partnerList" && (
              <ul className="nav nav-pills nav-pills-custom mb-3">
                <li className="nav-item mb-3 me-3 me-lg-6">
                  <button
                    style={{ float: "left", marginLeft: 10 }}
                    type="submit"
                    name="description"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    onClick={handleShowAll}
                  >
                    {" "}
                    Show All
                  </button>
                </li>
                <li className="nav-item mb-3 me-3 me-lg-6">
                  {location.pathname !== "/drivers/customRate" && (
                    <button
                      type="submit"
                      style={{ float: "right", marginleft: 20 }}
                      name="description"
                      className="btn btn-primary"
                      id="kt_account_profile_details_submit"
                      onClick={() => setOpen(!open)}
                    >
                      {" "}
                      <i class="bi bi-search"></i> Search
                    </button>
                  )}
                </li>
              </ul>
            )}
          </div>
          {open && active === 4 && (
            <div style={{ backgroundColor: "#fff", margin: "10px 0" }}>
              <div
                id="kt_app_content"
                className="app-content flex-column-fluid"
              >
                <div
                  id="kt_app_content_container"
                  className="app-container container-xxl"
                >
                  <div className="card mb-5 mb-xl-10">
                    <div
                      id="kt_account_settings_profile_details"
                      className="collapse show"
                    >
                      <form
                        id="kt_account_profile_details_form"
                        className="form"
                      >
                        <div className="card-body border-top p-9">
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Start Date
                            </label>
                            <div className="col-lg-8">
                              <ViewDatePicker
                                name="date"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Date"
                                onChange={(value) =>
                                  onkeyChange("start_date", value)
                                }
                                value={state.start_date}
                                style={{ paddingLeft: 10 }}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              End Date
                            </label>
                            <div className="col-lg-8">
                              <ViewDatePicker
                                name="date"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Date"
                                onChange={(value) =>
                                  onkeyChange("end_date", value)
                                }
                                value={state.end_date}
                                style={{ paddingLeft: 10 }}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Partner
                            </label>
                            <div
                              className="col-lg-8 fv-row"
                              style={{ position: "relative" }}
                            >
                              <InputSelect
                                data={
                                  allPartners?.length
                                    ? allPartners.map((it) => {
                                        return {
                                          value: it.id,
                                          title: `${it.name}`,
                                          status: it.status,
                                        };
                                      })
                                    : null
                                }
                                onChange={(value) =>
                                  onkeyChange("partner", value)
                                }
                                value={state.partner}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Status
                            </label>
                            <div className="col-lg-8 fv-row">
                              <Selector
                                data={partnerStatusOptions.map((it) => {
                                  return {
                                    value: it === "all" ? "" : it,
                                    title: it,
                                  };
                                })}
                                onChange={(value) =>
                                  onkeyChange("status", value)
                                }
                                value={state.status}
                              />
                            </div>
                            {/*end::Col*/}
                          </div>
                        </div>
                        <div
                          className="card-footer d-flex "
                          style={{
                            border: "none",
                            marginLeft: 0,
                            marginTop: -30,
                          }}
                        >
                          <button
                            type="button"
                            name="description"
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                            onClick={handleSubmit}
                          >
                            Find
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {open && active === 1 && (
            <div style={{ backgroundColor: "#fff", margin: "10px 0" }}>
              <div
                id="kt_app_content"
                className="app-content flex-column-fluid"
              >
                <div
                  id="kt_app_content_container"
                  className="app-container container-xxl"
                >
                  <div className="card mb-5 mb-xl-10">
                    <div
                      id="kt_account_settings_profile_details"
                      className="collapse show"
                    >
                      <form
                        id="kt_account_profile_details_form"
                        className="form"
                      >
                        <div className="card-body border-top p-9">
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Start Date
                            </label>
                            <div className="col-lg-8">
                              <ViewDatePicker
                                name="date"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Date"
                                onChange={(value) =>
                                  onkeyChange1("start_date", value)
                                }
                                value={state1.start_date}
                                style={{ paddingLeft: 10 }}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              End Date
                            </label>
                            <div className="col-lg-8">
                              <ViewDatePicker
                                name="date"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Date"
                                onChange={(value) =>
                                  onkeyChange1("end_date", value)
                                }
                                value={state1.end_date}
                                style={{ paddingLeft: 10 }}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Partner
                            </label>
                            <div
                              className="col-lg-8 fv-row"
                              style={{ position: "relative" }}
                            >
                              <InputSelect
                                data={
                                  allPartners?.length
                                    ? allPartners.map((it) => {
                                        return {
                                          value: it.id,
                                          title: `${it.name}`,
                                          status: it.status,
                                        };
                                      })
                                    : null
                                }
                                onChange={(value) =>
                                  onkeyChange1("partner", value)
                                }
                                value={state1.partner}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Status
                            </label>
                            <div className="col-lg-8 fv-row">
                              <Selector
                                data={partnerInvoiceStatusType.map((it) => {
                                  return {
                                    value: it,
                                    title: it,
                                  };
                                })}
                                onChange={(value) =>
                                  onkeyChange1("status", value)
                                }
                                value={state1.status}
                              />
                            </div>
                            {/*end::Col*/}
                          </div>
                        </div>
                        <div
                          className="card-footer d-flex "
                          style={{
                            border: "none",
                            marginLeft: 0,
                            marginTop: -30,
                          }}
                        >
                          <button
                            type="button"
                            name="description"
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                            onClick={handleSubmit1}
                          >
                            Find
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Outlet context={[setOpen]} />
        </div>
      </div>
    </div>
  );
};

export default PartnerInvoices;

const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px",
};

