import React from 'react';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function Checkboxes({checked,onChange}) {
  return (
    <div>
      <Checkbox checked={checked} {...label} style={{color: '#3e97ff'}}  onChange={onChange} />
    </div>
  );
}