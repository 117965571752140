import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDriverPackageInvoices } from "../../../actions/driverPackageInvoices";
import { Table } from "../../../components/common";
import PaginationControlled from "../../../components/common/paginate";
// import { Table } from "../../components/common";
// import { getDriverPackageInvoices } from "../../../actions/driverPackageInvoices";

const invoicestableHeads = [
  "Invoice",
  "Invoice Date",
  "Driver/Sorter Name",
  "Invoice Value",
  "Hours Worked",
  "Status",
  "Edit",
  "Delete",
  "View",
];

const DriverPackageInvoices = () => {
  const setOpen = useOutletContext(); //function that we pass as a props in Outlet in index.js file

  const { driverPackageInvoices } = useSelector(({ driverPackageInvoices }) => driverPackageInvoices);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getDriverPackageInvoices());
  }, []);

  return (
    <div className="col-xl-8 mb-5 mb-xl-10" style={{ width: "100%" }}>
      <div className="card card-flush h-md-100">
        <div className="card-body">
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              id="kt_stats_widget_6_tab_1"
            >
              <div className="table-responsive">
                <div className="card-title flex-column">
                  <h3 className="fw-bold mb-1">Driver/Sorter Invoices</h3>
                </div>
              </div>

              <hr />
              <div className="table-responsive" style={{ padding: "0 -20px" }}>
                <Table
                  lable="driver_package_invoices"
                  tableRows={driverPackageInvoices?.rows}
                  tableHeads={invoicestableHeads}
                  setOpen={setOpen?.length ? setOpen[0] : ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <PaginationControlled  lable="driver_package_invoices" />
    </div>
  );
};

export default DriverPackageInvoices;

