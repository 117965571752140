import { combineReducers } from "redux";
import user from "./user";
import area from "./areas"
import driver from "./driver";
import app from "./app";
import expenses from "./expenses";
import packages from "./packages";
import reports from "./reports";
import customRate from './customRate';
import common from './common';
import admin from "./admin"
import invoices from "./invoices";
import category from "./category";
import partner from "./partner"
import driverPackageInvoices from "./driverPackageInvoice"

export default combineReducers({
  user,
  area,
  driver,
  app,
  expenses,
  packages,
  reports,
  customRate,
  common,
  admin,
  invoices,
  category,
  partner,
  driverPackageInvoices
});
