import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Selector } from "../../components/common";
import { fetchDrivers, getProfitLoss } from "../../actions";
import { ViewDatePicker } from "../../components/common";
import InputSelect from '../../components/common/InputSelect';

const title = [
  "Total Income",
  "Package Payment",
  "Salary",
  "Gross Profit",
  "Total Expenses",
  "Net Profit",
];

function ProfiteLoss() {
  const dispatch = useDispatch();
  const { options } = useSelector(({ area }) => area);
  const {allDrivers = [] } = useSelector(({ driver }) => driver);
  const { profitLoss = {} } = useSelector(({ reports }) => reports);

  var date = new Date();
  const lastDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59
  );
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 2);

  const [state, setState] = useState({
    startDate: firstDate.toISOString(),
    endDate: lastDate.toISOString(),
    area_id: options[0]?.id, //after selecting area id will become string type
    driver_id: allDrivers[0]?.id,
  });

  const onChange = (k, v) => {
    setState({ ...state, [k]: v });
  };

  const onChangeWithKey = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //Please change field name according to API params before fetching data
    dispatch(getProfitLoss(state));
  };

  useEffect(() => {
    dispatch(fetchDrivers());
  }, []);
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ padding: "0px !important" }}
      >
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{ padding: 0 }}
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Profit And Loss</h3>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <form id="kt_account_profile_details_form" className="form">
              {/* <div className="card-body border-top p-9"> */}
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Start Date
                  </label>
                  <div className="col-lg-8 fv-row">
                    <ViewDatePicker
                      name="startDate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        onChange("startDate", value.toISOString())
                      }
                      value={state.startDate.substring(0, 10)}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    End Date
                  </label>
                  <div className="col-lg-8 fv-row">
                    <ViewDatePicker
                      name="endDate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        onChange("endDate", value.toISOString())
                      }
                      value={state.endDate.substring(0, 10)}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Area
                  </label>
                  <div className="col-lg-8 fv-row">
                    <Selector
                      data={options.map((it) => {
                        return {
                          value: it.id,
                          title: it.areaName,
                        };
                      })}
                      onChange={(value) => onChangeWithKey("area", value)}
                      value={state.area_id}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Driver
                  </label>
                  <div className="col-lg-8 fv-row" style={{position: 'relative'}}>
                    <InputSelect
                        name="driver"
                        data={
                          allDrivers.length
                          ? allDrivers.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it?.driverType
                              };
                            })
                          : ["Loadin..."]
                        }
                        onChange={(value) => onChangeWithKey("driver", value)}
                        value ={state.driver_id}
                     />
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="card-footer d-flex justify-content-start py-6 px-9">
                <button
                  type="submit"
                  name="description"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  onClick={handleSubmit}
                >
                  Find
                </button>
              </div>
            </form>
            <hr />
            <div className="mb-15">
              <h1
                className="fw-bold fs-6 text-gray-800 text-center border-0  rounded min-h-50px"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#DBB768",
                }}
              >
                {`Report: ${state.startDate.substring(
                  0,
                  10
                )} to ${state.endDate.substring(
                  0,
                  10
                )} For All Drivers For All Areas`}
              </h1>
            </div>
            <div className="mb-14">
              <div className="table-responsive">
                <table
                  className="table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                  border={1}
                >
                  <tbody>
                    {title?.length === Object.keys(profitLoss).length
                      ? Object.keys(profitLoss).map((d, i) => (
                          <tr className="fw-semibold fs-6 text-gray-800 text-center">
                            <td className="text-start ps-6 fs-4">{title[i]}</td>
                            <td
                              className="text-end"
                              style={{ paddingRight: "25px" }}
                            >
                              <span className="text-gray-800 fw-bold d-block fs-6">{`$${profitLoss[d]}`}</span>
                            </td>
                          </tr>
                        ))
                      : profitLoss?.length && "Something went wrong"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfiteLoss;
