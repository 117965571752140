
import React, {useState} from 'react';
import {NavLink, Outlet} from 'react-router-dom';
import { ViewDatePicker } from '../../components/common';
import Selector from "../../components/common/Selector"
import {useSelector} from "react-redux";
const tableHeading = [
    "Expense ID",
    "Expense Date",
    "Category Name",
    "Driver Name",
    "Expense Value $",
    "Description",
    "Edit",
    "Delete"
]

const status =[
    "New",
    "Paid",
    "Approved",
]

const DriverExpense = () => {
    const [active, setActive] = useState(0)
    const {options=[]} =  useSelector(({area})=>area)

  return (    
    <div className="col-xl-8 mb-5 mb-xl-10" style={{width:"100%"}} >     
    <div className="card card-flush h-md-100">
      <div className="card-body">
      <div className="tab-content">
    <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
      <div className="table-responsive">
        <div className="card-title flex-column">
        <h3 className="fw-bold mb-1">Driver Expenses</h3>
        </div>
        {/* <hr /> */}
        <div style={{display: 'flex',justifyContent: "flex-start" , marginTop:"10px"}}>
        <div className="d-flex align-items-center position-relative my-1">
			<span className="svg-icon svg-icon-1 position-absolute ms-6">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
				<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
			</svg>
			</span>
			<input type="text" data-kt-filemanager-table-filter="search" className="form-control form-control-solid w-350px ps-15" placeholder="Category, Driver Name" />
		</div>       
          <div style={{display: 'flex',justifyContent: "flex-end",alignItems: 'center', position:"relative", left:110, bottom:10, gap:20}}>
          <div className="row mb-6" style={{width:"220px"}}> 
            <label className="col-form-label fw-semibold fs-6">
             Start Date
            </label>
            <div className="col-lg-12 fv-row" style={{width:"220px"}}>
              <ViewDatePicker
              name="date"
              className="form-control form-control-lg form-control-solid"
              placeholder="Date"
              />
            </div>
          </div>
          <div className="row mb-6" style={{width:"220px"}}>
            <label className="col-form-label fw-semibold fs-6" >
             End Date
            </label>
            <div className="col-lg-12 fv-row" style={{width:"220px"}}>
              <ViewDatePicker
              name="date"
              className="form-control form-control-lg form-control-solid"
              placeholder="Date"
              />
            </div>
          </div>
          <div className="row mb-6">
            <label className="col-form-label fw-semibold fs-6">
              Status
            </label>
            <div className="col-lg-12 fv-row" style={{width:"180px"}}>
            <Selector 
            style={{width: "100% !important", height:"48px"}} 
            data={status.map((it)=>{
              return {
                value: it,
                title: it
              }
            })} 
            />
            </div>
          </div>
          </div>
        </div>
        </div>

        <hr />
        <div className="table-responsive" style={{padding: "0 -20px"}}>
            <table 
              className="table table-hover table-row-dashed align-middle gs-0 gy-4 my-0"
              id="lastab"
              style={{ overflow: "scroll",padding: "10px"}}
              width="100%"             
             >
            <thead>
                <tr className="fs-7 fw-bold text-black-500 border-bottom-0 bg-light" style={{fontSize: "15px", color:"#838383"}}>
                {
                    tableHeading?.length && tableHeading.map((head, index)=>
                    <th className={index === 0 ? "rounded-start" : index === tableHeading?.length-1 ? "rounded-end" : ""} style={{paddingLeft:"5px"}}>{head}</th>
                    )
                }
                </tr>
              </thead>
              <tbody className='border-right-1'>           
                  <tr>
                    <td style={{padding: "5px"}}>
                    df
                  </td>
                  <td style={{padding: "5px"}}>
                    df
                  </td>
                  </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </div>     
      </div>
      
      </div>

)
}

export default DriverExpense;