import {ACTION_TYPES} from "../constants"

const initialState = {
    adminUsers:[],
    allAdmins:[],
    admin:{},
    loader:false
}

 const admin = (state= initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.ADD_ADMIN_REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.ADD_ADMIN_SUCCESS:
            return {...state,loader:false}
        case ACTION_TYPES.ADD_ADMIN_FAILED:
            return {...state, loader:false}
        
        case ACTION_TYPES.FETCH_ADMIN_REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.FETCH_ADMIN_SUCCESS:
            return {...state,  loader:false}
        case ACTION_TYPES.FETCH_ADMIN_FAILED:
            return {...state, loader:true}

        case ACTION_TYPES.SET_ADMIN_REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.SET_ADMIN_SUCCESS:
            return {...state, admin:action.payload, loader:false}
        case ACTION_TYPES.SET_ADMIN_FAILED:
            return {...state, loader:false}

        case ACTION_TYPES.UPDATE_ADMIN_REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.UPDATE_ADMIN_SUCCESS:
            return {...state,loader:false}
        case ACTION_TYPES.UPDATE_ADMIN_FAILED:
            return {...state, loader:false}

                
        case ACTION_TYPES.EDIT_ADMIN_REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.EDIT_ADMIN_SUCCESS:
            return {...state, loader:false}
        case ACTION_TYPES.EDIT_ADMIN_FAILED:
            return {...state, loader:false}

        // case ACTION_TYPES.DELETE_ADMIN_SUCCESS:
        //     return {...state, allAdmins:[], loader:true}
        case ACTION_TYPES.FETCH_ALL_ADMIN__REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.FETCH_ALL_ADMIN__SUCCESS:
            return {...state, loader:false}
        case ACTION_TYPES.FETCH_ALL_ADMIN__FAILED:
            return {...state, loader:false}
        case ACTION_TYPES.GET_ALL_ADMINS:
            return {...state, allAdmins:action.payload, loader:false}
        default:
            return state
    }

}

export default admin