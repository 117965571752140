import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "./ousideClick";
const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px",
};

function InputSelect({
  data = null,
  style = {},
  onChange = () => {},
  multi = false,
  value = "",
  disabled=false
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("choose one");
  const [options, setOptions] = useState(null);
  const Id = useRef(0);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (showOptions) setShowOptions(false);
  });
  const handleChange = (value, title) => {
    setSelectedOption(title);
    setShowOptions(false);
    onChange(value, title);
    setOptions(data);
  };

  useEffect(() => {
    if (value) {
      let findData = data?.find((ele) => ele.value === value);
      if (findData) {
        const { title, status } = findData;
        setSelectedOption(
          `${title} ${status === "hidden" ? "(hidden)" : `(${status})`}`
        );
      }
    }
    setOptions(data);
  }, []);

  const handleSearch = (e) => {
    if (Id.current) {
      clearTimeout(Id.current);
    }
    Id.current = setTimeout(() => {
      let newData = data?.filter((ele) =>
        ele.title.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setOptions(newData);
    }, 500);
  };
  //option attributes --> hidden,disabled
  return (
    <div
      style={{ ...styles, ...style, opacity: disabled && 0.5 }}
      class="form-select form-select-solid"
      onClick={() => setShowOptions(true)}
    >
      {selectedOption}
      {showOptions && !disabled ? (
        <div
          style={{
            position: "absolute",
            width: "auto",
            left: "10px",
            top: "105%",
            height: "auto",
            background: "rgb(105,105,105)",
            padding: "15px 15px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            zIndex: 10,
            border: "1px solid black",
          }}
          ref={ref}
        >
          <input
            type="text"
            placeholder="search"
            style={{
              padding: "4px 7px",
              borderRadius: "5px",
              marginTop: "15px",
            }}
            onChange={handleSearch}
          />
          <div
            style={{
              width: "100%",
              marginTop: "5px",
              maxHeight: "250px",
              overflow: "auto",
            }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleChange("", "choose one");
              }}
              key={value}
              style={{
                background: selectedOption === "choose one" ? "#3E8EDE" : null,
                padding: "5px 8px",
                color: selectedOption === "choose one" ? "white" : "white",
                borderRadius: "5px",
              }}
            >
              {"choose one"}
            </div>
            {options?.length ? (
              options?.map(({ value, title, status }) => (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChange(
                      value,
                      `${title} ${
                        status === "hidden" ? "(hidden)" : `(${status})`
                      }`
                    );
                  }}
                  key={value}
                  style={{
                    background:
                      selectedOption ===
                      `${title} ${
                        status === "hidden" ? "(hidden)" : `(${status})`
                      }`
                        ? "#3E8EDE"
                        : null,
                    padding: "5px 8px",
                    color:
                      selectedOption ===
                      `${title} ${
                        status === "hidden" ? "(hidden)" : `(${status})`
                      }`
                        ? "white"
                        : "white",
                    borderRadius: "5px",
                  }}
                >
                  {`${title} ${
                    status === "hidden" ? "(hidden)" : `(${status})`
                  }`}
                </div>
              ))
            ) : (
              <div
                style={{ padding: "5px 8px", color: "white" }}
                onClick={() => setShowOptions(false)}
              >
                no result found
              </div>
            )}
            <button
              type="button"
              aria-label="Close"
              style={{
                position: "absolute",
                top: "5px",
                right: "8px",
                background: "transparent",
                border: "none",
                padding: "0",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowOptions(false);
                setOptions(data);
              }}
            >
              <i
                class="bi bi-x-lg"
                style={{ color: "white", fontSize: "16px" }}
              ></i>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default InputSelect;
