import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
const counts = [0, 1, 3, 5, 7, 9, 11, 12, 14, 17];

const styles = { borderRight: "1px solid", fontSize: "13px" };

const TableIncome = ({ invoices, partner }) => {
  //   const driverTypeOrder = {
  //     "both": 0,
  //     "driver": 1,
  //     "sorter": 2
  // };

  // const InvoicesData = invoices?.sort((a, b) => {
  //   return driverTypeOrder[a?.driver?.driverType] - driverTypeOrder[b?.driver?.driverType];
  // });

  return (
    <div
      className="table-responsive"
      style={{ padding: "0 -20px"}}
    >
      <div
        className="fw-bold border-bottom-0 bg-light"
        style={{ padding: "10px", width: "100%", fontSize: "20px" }}
      >
        {partner?.name || "No Partner Selected"}
      </div>
      <table
        className="table table-hover table-row-dashed table-row-gray-300 align-middle listab"
        id="lastab"
        style={{ overflow: "scroll", padding: "10px" }}
        width="100%"
        cellSpacing={1}
        cellPadding={0}
        border={1}
      >
        <thead>
          <tr
            className="fw-bold border-bottom-0"
            style={{ backgroundColor: "black", color: "#fff" }}
          >
            <td
              rowSpan={2}
              style={{
                ...styles,
                borderBottom: "1px solid gray",
                paddingLeft: "7px",
              }}
            >
              No.
            </td>
            <td
              rowSpan={2}
              style={{ ...styles, borderBottom: "1px solid gray" }}
            >
              {/* <div style={{textAlign: "center", marginTop:"-88px"}}> */}
              Description
              {/* </div> */}
            </td>
            <td
              rowSpan={2}
              style={{ ...styles, borderBottom: "1px solid gray" }}
            >
              Price ex GST
            </td>
            <td
              rowSpan={2}
              style={{ ...styles, borderBottom: "1px solid gray" }}
              border={1}
            >
              Price in GST
            </td>
          </tr>
        </thead>
        <tbody>
          {invoices?.length
            ? invoices.map((row, i) => (
                <tr key={i} style={{ fontSize: "13px" }}>
                  <td style={{ padding: "7px" }} width={6}>
                    {i + 1}
                  </td>

                  <td style={{ padding: "7px" }} width={6}>
                    {row?.driver?.firstName + " " + row?.driver?.lastName}
                  </td>
                  <td style={{ fontSize: "13px" }} width={6}>
                    {(row?.amount).toFixed(2)}
                  </td>
                  <td style={{ fontSize: "13px" }} width={6}>
                    ${((row?.amount / 100) * 10 + row?.amount).toFixed(2)}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};
export default TableIncome;
