export const user = [
    "Name",
    "Email",
    "Phone Number",
    "Date of Birth",
    "Document",
    "Edit",
    "Delete",
    "user Type"
]

export const userType=[
    {
      title : 'Admin',
      value: 2
    },
    {
      title : 'Staff',
      value: 3
    }
]
