import {ACTION_TYPES} from "../constants"
import {  NotificationManager } from 'react-notifications';
import {
   createUser,
   fetchAllUsers,
   updateUser,
   deleteUser
} from "../api"

export const createAdmin =(res, navigate)=>async(dispatch, getState)=>{
    try{
        // dispatch({type: ACTION_TYPES.ADD_ADMIN_REQUEST})
        // const {adminUsers} = getState().admin
        // dispatch({type: ACTION_TYPES.ADD_ADMIN_SUCCESS, payload:[...adminUsers, res]})
        // NotificationManager.success("Successfully added new AdminUser");
        // dispatch(getAdmin())
        // navigate("/users")

        dispatch({type: ACTION_TYPES.ADD_ADMIN_REQUEST})
        const data = await createUser(res);
        dispatch({type: ACTION_TYPES.ADD_ADMIN_SUCCESS, payload:data})
        NotificationManager.success("Successfully added new AdminUser");
        dispatch(fetchAllAdmins())
        navigate("/users")

    }
    catch(err){
        console.log(err, err.message);
        dispatch({type: ACTION_TYPES.ADD_ADMIN_FAILED})
        NotificationManager.error("Something went wrong");

    }

}

export const getAdmin =()=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.FETCH_ADMIN_REQUEST})
        const {adminUsers} = getState().admin
        dispatch({type: ACTION_TYPES.FETCH_ADMIN_SUCCESS, payload:adminUsers})       
    }
    catch(err){
        console.log(err, err.message);
        dispatch({type:ACTION_TYPES.FETCH_ADMIN_FAILED})
    }

}

export const setAdmin = (res)=> (dispatch,getState) =>{
    try {
        dispatch({type: ACTION_TYPES.SET_ADMIN_REQUEST});
        dispatch({type: ACTION_TYPES.SET_ADMIN_SUCCESS,payload:res})
    } catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.SET_ADMIN_FAILURE})
    }
}

export const updateAdmin = (res, navigate)=> async (dispatch,getState) =>{
    try {
        dispatch({type: ACTION_TYPES.UPDATE_ADMIN_REQUEST});
        let data = await updateUser(res.id, res);
        dispatch({type: ACTION_TYPES.UPDATE_ADMIN_SUCCESS,payload:data})
        NotificationManager.success("Successfully updated");
        navigate("/users")

    } catch (error) {
        dispatch({type: ACTION_TYPES.UPDATE_ADMIN_FAILURE})
        NotificationManager.error("Something went wrong");
    }
}

export const editAdmin =(id, data)=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.EDIT_ADMIN_REQUEST})
        // console.log(data)
        dispatch({type:ACTION_TYPES.EDIT_ADMIN_SUCCESS, payload:""})
    }
    catch(err){
        console.log(err, err.message)
        dispatch({type:ACTION_TYPES.EDIT_ADMIN_FAILED})

    }
}

export const deleteAdmin =(id)=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.DELETE_ADMIN_REQUEST})
        let data = await deleteUser(id);
        dispatch({type:ACTION_TYPES.DELETE_ADMIN_SUCCESS, payload: data})
        NotificationManager.success("Successfully updated");
        dispatch(fetchAllAdmins())
    }
    catch(err)
    {
        console.log(err)
        dispatch({type:ACTION_TYPES.DELETE_ADMIN_FAILED})
    }
}

export const fetchAllAdmins =()=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.FETCH_ALL_ADMIN_REQUEST})
        let data = await fetchAllUsers();
        dispatch({type: ACTION_TYPES.FETCH_ALL_ADMIN_SUCCESS,payload:data?.data })
        dispatch({type: ACTION_TYPES.GET_ALL_ADMINS, payload:data?.data?.data?.rows})
    }
    catch(err)
    {
        console.log(err)
        dispatch({type:ACTION_TYPES.FETCH_ALL_ADMIN_FAILED})
    }
}

