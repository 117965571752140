import React,{useState} from 'react';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

export default function ViewDatePicker({name="",onChange = ()=>{}, value}) {
  // const [val, setValue] = useState(value);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
      <MobileDateTimePicker
          className="form-control form-control-lg form-control-solid" 
          // views={["day"]}        
          value={value}
          onChange={(newValue) => {
            // setValue(value)
            onChange(newValue)
          }}
          InputProps={{
            disableUnderline: true
          }}
          // inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField name ={name} onKeyDown={(e) => {
            e.preventDefault();
         }}

          {...params} variant="standard" style={{padding:10}} />}
        />

      </Stack>
    </LocalizationProvider>
  );
}
