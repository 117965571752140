import { ACTION_TYPES } from "../constants";
import {
  postDriverPackageInvoice,
  fetchDriverPackageInvoices,
  fetchDriverPackageInvoice,
  updateDriverPackageInvoice,
  deleteDriverPackageInvoice,
} from "../api";
import { NotificationManager } from "react-notifications";


export const createDriverPackageInvoice = (res, navigate) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.DRIVER_PACKAGE_INVOICE_REQUEST });
      const { data } = await postDriverPackageInvoice(res);
      navigate("/reports/driverPackageInvoice/list");
      NotificationManager.success("Successfully generated new driver package invoice");
      dispatch(getDriverPackageInvoices());
    } catch (error) {
      dispatch({ type: ACTION_TYPES.DRIVER_PACKAGE_INVOICE_FAILED });
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Something went wrong")
      );
    }
  };

export const getDriverPackageInvoices = (res = {}) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_DRIVER_PACKAGE_INVOICES_REQUEST });
      const {
        page = 1,
        pageSize = 20,
        order = "ASC",
        field = "createdAt",
      } = getState().driverPackageInvoices;
      const filter = {
        page,
        pageSize: 20,
        field,
        order,
        ...res,
      };
      const { data } = await fetchDriverPackageInvoices(filter);
      dispatch({
        type: ACTION_TYPES.GET_DRIVER_PACKAGE_INVOICES_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.GET_DRIVER_PACKAGE_INVOICES_FAILED });
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Something went wrong")
      );
    }
  };

export const getDriverPackageInvoice = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.DRIVER_PACKAGE_INVOICE_REQUEST });
    const { driverPackageInvoices } = getState().driverPackageInvoices;
    const { data } = await fetchDriverPackageInvoice(id);
    dispatch({
      type: ACTION_TYPES.DRIVER_PACKAGE_INVOICE_SUCCESS,
      payload: { ...driverPackageInvoices, count: 1, rows: [data.data] },
    });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.DRIVER_PACKAGE_INVOICE_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Something went wrong")
    );
  }
};

export const setDriverPackageInvoice = (res) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.SET_DRIVER_PACKAGE_INVOICE_REQUEST });
    dispatch({ type: ACTION_TYPES.SET_DRIVER_PACKAGE_INVOICE_SUCCESS, payload: res });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.SET_DRIVER_PACKAGE_INVOICE_FAILED });
  }
};

export const editDriverPackageInvoice = (res, navigate) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.EDIT_DRIVER_PACKAGE_INVOICE_REQUEST });
      const { driverPackageInvoice } = getState().driverPackageInvoices;
      const response = await updateDriverPackageInvoice(driverPackageInvoice.id, res);
      dispatch({ type: ACTION_TYPES.EDIT_DRIVER_PACKAGE_INVOICE_SUCCESS });
      navigate("/reports/driverPackageInvoice/list");
      NotificationManager.success("Successfully Updated Driver Package invoice");
      dispatch(getDriverPackageInvoices());
    } catch (error) {
      dispatch({ type: ACTION_TYPES.EDIT_DRIVER_PACKAGE_INVOICE_FAILED });
      console.log("error", error);
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "DriverPackage updation failed")
      );
    }
  };

export const removeDriverPackageInvoice = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.DRIVER_PACKAGE_INVOICE_REQUEST });
    const response = await deleteDriverPackageInvoice(id);
    dispatch({ type: ACTION_TYPES.DRIVER_PACKAGE_INVOICE_SUCCESS });

    NotificationManager.success("Successfully deleted Driver Package invoice");
    dispatch(getDriverPackageInvoices());
  } catch (error) {
    dispatch({ type: ACTION_TYPES.DRIVER_PACKAGE_INVOICE_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Driver Package updation failed")
    );
  }
};
