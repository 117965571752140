import {ACTION_TYPES} from "../constants"
import {getAllCategories,fetchCategory, updateCategory,fetchAllCategoriess, postCategory, deleteCategory} from '../api';
import {  NotificationManager } from 'react-notifications';

export const addCategory = (res, navigate)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.ADD_CATEGORY_REQUEST})
        const {data} = await postCategory(res);       
        navigate("/expenses/category/categoryList")
        NotificationManager.success('Successfully added new Category');
        dispatch(getAllCategoryByfilter())
        } 
    catch (error) {
        dispatch({type: ACTION_TYPES.ADD_CATEGORY_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const fetchAllCategoriesss = ()=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.CATEGORY_REQUEST});
        const {data} = await fetchAllCategoriess();
        dispatch({type: ACTION_TYPES.SET_CATEGORY_OPTIONS,payload: data?.data?.rows});
    } catch (error) {
        dispatch({type: ACTION_TYPES.CATEGORY_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getAllCategoryByfilter = ()=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.CATEGORY_REQUEST});
        const {page=1, pageSize=20,order="ASC",field="createdAt"} = getState().category;
        const filter = {
            page,
            pageSize:20,
            field,
            order
        }
        const {data}  = await getAllCategories(filter);        
        dispatch({type: ACTION_TYPES.CATEGORY_SUCCESS,payload: data.data});
    } catch (error) {
        dispatch({type: ACTION_TYPES.CATEGORY_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}


export const getCategory = (id)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.CATEGORY_REQUEST});
        const {categories} = getState().category;
        const {data}  = await fetchCategory(id);
        dispatch({type: ACTION_TYPES.CATEGORY_SUCCESS,payload: data.data });
    } catch (error) {
        dispatch({type: ACTION_TYPES.CATEGORY_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}
export const setCategory =(res) =>async(dispatch, getState) => {
    try{
        dispatch({type: ACTION_TYPES.EDIT_CATEGORY_REQUEST})
        dispatch({type: ACTION_TYPES.EDIT_CATEGORY_SUCCESS, payload:res})
    }
    catch (error) {
        dispatch({type: ACTION_TYPES.EDIT_CATEGORY_FAILED})
        
    }
}

export const updateCategoryDetail =(res,navigate) =>async(dispatch, getState) => {
    try{
        dispatch({type: ACTION_TYPES.UPDATE_CATEGORY_REQUEST})
        const {category} = getState().category;
        const response = await updateCategory(category.id,res)
        dispatch({type: ACTION_TYPES.UPDATE_CATEGORY_SUCCESS})
        navigate("/expenses/category")
        NotificationManager.success('Successfully update category');
        dispatch(getAllCategoryByfilter())
    }
    catch (error) {
        dispatch({type: ACTION_TYPES.UPDATE_CATEGORY_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Category updation failed"));

    }
}


export const deleteCategoryFunc = (id)=> async(dispatch, getState) =>{
    try{
        dispatch({type:ACTION_TYPES.CATEGORY_DELETE_REQUEST});
        const {data} = await deleteCategory(id)
        dispatch({type: ACTION_TYPES.CATEGORY_DELETE_SUCCESS})
        NotificationManager.success("Successfully deleted expenses");     
        dispatch(getAllCategoryByfilter())
    }
    catch(error){
        dispatch({type: ACTION_TYPES.CATEGORY_DELETE_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

