import {ACTION_TYPES} from '../constants';
const initialState ={
    customRate:[],
    loader: false,
    driverId: null,
    allCustomRate: [],
    customRateForWeekend: []
}

const customRate = (state= initialState, action) =>{

    switch(action.type){
        case ACTION_TYPES.CUSTOM_RATE_REQUEST:
            return {...state,loader: true}
        case ACTION_TYPES.CUSTOM_RATE_SUCCESS:
            return {...state, customRate: action.payload,loader: false}
        case ACTION_TYPES.CUSTOM_RATE_FAILED:
            return {...state,loader: false}

        case ACTION_TYPES.SET_DRIVER_ID:
            return {...state,driverId: action.payload};

        case ACTION_TYPES.SET_ALL_CUSTOM_RATE:
            return {...state,allCustomRate: action.payload,loader: false};
        
        case ACTION_TYPES.CUSTOM_RATE_FOR_WEEKEND_REQUEST:
            return {...state,loader: true}
        case ACTION_TYPES.CUSTOM_RATE_FOR_WEEKEND_SUCCESS:
            return {...state, customRateForWeekend: action.payload,loader: false}
        case ACTION_TYPES.CUSTOM_RATE_FOR_WEEKEND_FAILED:
            return {...state,loader: false}
        default: 
        return state;
    }
}
export default customRate;