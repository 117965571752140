import React, {useState} from 'react'
import {getArea} from '../../services/services';
import {useDispatch, useSelector} from "react-redux"
import { useNavigate } from 'react-router-dom';
import {validator} from "../../helpers/validation"
import {createAlert,closeAlert} from "../../actions"
import {  NotificationManager } from 'react-notifications';
import {updateAreaDetail} from '../../actions';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {name} from '../../helpers/validation';
import Loader from "../../components/common/loader"

function Edit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {area:editArea, loader} = useSelector((state)=>state.area)
  const initialValues ={
    area_name:editArea.areaName ? editArea.areaName :"", 
    area_description:editArea.description ? editArea.description :"",
    isArchived: editArea.isArchived ? editArea.isArchived : false
  }
  // const [data, setData] = useState([])

  const validation =yup.object({
    area_name:yup.string()
    .required("area name is required"),
    area_description:yup.string()
    .required("area description is required")
  })

  const handleSubmit =(values, {resetForm})=>{
  if(values){
    const data = {
      areaName : values.area_name,
      description:values.area_description
    }
    dispatch(updateAreaDetail(data, navigate))
    // alert("area details added successfully")
    resetForm({values:""})
    dispatch(createAlert("successfully added","success",4000))
  }    
  }

  const handleSubmitArchiveArea =(values)=>{
    if(values){
      const data = {
        areaName: values.areaName,
        description: values.description,
        isArchived: values.isArchived
      }
      dispatch(updateAreaDetail(data, navigate))
      dispatch(createAlert("successfully added","success",4000))
    }    
  }
  
  return (
    <>
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
      >
        <div className="card mb-5 mb-xl-10">
        <div className="card-body">
          {/*begin::Nav*/}
          </div>
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{marginTop:"-45px"}}
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Add/Edit Delivery Area</h3>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <Formik
            initialValues = {initialValues}
              validationSchema = {validation}
              onSubmit= {handleSubmit}
          >
          {({
              values, handleChange, resetForm, handleBlur, errors, touched
          })=> (
            <Form id="kt_account_profile_details_form" className="form" >
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    Area Name
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      name="area_name"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Area Name"
                      value = {values.area_name}
                      onChange={handleChange}
                      onBlur ={handleBlur}
                    />

                    <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    {touched.area_name && errors.area_name ? <div>{errors.area_name}</div> : null}<br />

                      </div></div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  Description
                  </label>
                  <div className="col-lg-8 fv-row">
                    <textarea 
                      type="text"
                      name="area_description"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Description"
                      value = {values.area_description}
                      onChange={handleChange}
                      onBlur ={handleBlur}
                    />
                    <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    {touched.area_description && errors.area_description ? <div >{errors.area_description}</div> : null}<br />

                      </div></div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="reset"
                  className="btn btn-light btn-active-light-primary me-2"
                  onClick={()=>navigate(-1)}
                >
                  Back
                </button>
                <button
                  type="button"
                  disabled={loader}
                  className="btn btn-danger me-2"
                  id="kt_account_profile_details_submit"
                  onClick={()=>handleSubmitArchiveArea({...values, isArchived:!values.isArchived})}
                >
                 {loader ? <Loader size={17} />: values.isArchived?"UnArchive Area":"Archive Area"}
                </button>
                <button
                  type="submit"
                  disabled={loader}
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  // onClick={handleSubmit}
                >
                  {loader ? <Loader size ={17}/>:"Save Changes"}
                </button>
              </div>
            </Form>
          )}
          </Formik>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}



export default Edit