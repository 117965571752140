import React, { useMemo } from "react";
import { useSelector } from "react-redux";
const counts = [0,1,3,5,7,9,11,12,14,17]

const styles = {borderRight:"1px solid", fontSize: "13px"}

const TableIncome2 = ()=>{
    let {driverPackageIncome=[],lastState} = useSelector(({reports})=>reports);
    let {options} = useSelector(({area})=>area)
    const areaName = useMemo(()=>{
        if(lastState){
            return options.filter((it)=>it.id==lastState.areaId)
        } else {
            return options[0]
        }
    },[driverPackageIncome])
  
    return (
        <div className="table-responsive" style={{padding: "0 -20px"}}>

            <div className= "fw-bold border-bottom-0 bg-light"
            style={
                { padding: "10px",
                width: "100%",
                fontSize: "15px"
                }
            }>
                    {areaName[0]?.areaName}
                </div>  
            <table
                className="table table-hover table-row-dashed table-row-gray-300 align-middle listab"
                id="lastab"
                style={{ padding: "5px", fontSize: "1px"}}
                width="100%"
                cellSpacing={1}
                cellPadding={0}
                border={1}
            >
                <thead>
                <tr className="fw-bold border-bottom-0">
                    <td rowSpan={2} width="1%" className="min-w-10px" >
                        <div style={{textAlign: "right",fontSize: "10px"}}>
                        Date
                        </div>
                    </td>
                    <td colSpan={2} width="5%" className="min-w-10px" style={{...styles,fontSize: "10px"}}>
                    Package A
                    </td>
                    <td colSpan={2} width="5%" className="min-w-10px" style={{...styles,fontSize: "10px"}}>
                    Package B
                    </td>
                    <td colSpan={2} width="5%" className="min-w-10px" style={{...styles,fontSize: "10px"}}>
                    Package D
                    </td>
                    <td colSpan={2} width="5%" className="min-w-10px" style={{...styles,fontSize: "10px"}}>
                    Package E
                    </td>
                    <td colSpan={2} width="5%" className="min-w-10px" style={{...styles,fontSize: "10px"}}>
                    Package F
                    </td>
                    <td rowSpan={2} style={{...styles,fontSize: "10px" }} >Total Count</td>
                    <td colSpan={2} width="5%" className="min-w-10px" style={{...styles,fontSize: "10px"}}>
                    Package G
                    </td>
                    <td colSpan={2} width="5%" className="min-w-10px" style={{...styles,fontSize: "10px"}}>
                    Package H
                    </td>
                    <td rowSpan={2} style={{fontSize: "10px"}}>Total</td>
                   <td rowSpan={2} style={{fontSize: "10px", paddingRight:"5px"}}>Hours Worked</td>     
                </tr>
                <tr>
                    {[0,0,0,0,0,0,0].map((it,i)=>(
                        <>
                        <td style={{fontSize: "10px"}}>Count</td>
                        <td style={{fontSize: "10px"}}>Driver Pay</td>
                        </>
                    ))}
                </tr>
                </thead>
                <tbody>
                {driverPackageIncome?.length ? driverPackageIncome.map((row,i)=> 
                i!==driverPackageIncome?.length-1 ?
                <tr key={i} style={{fontSize: "8px"}}>
                <td style={{padding: "2px"}} width={1}>
                    {`${new Date(row[0]?.date).getDate()}/${new Date(row[0]?.date).getMonth()+1}/${new Date(row[0]?.date).getFullYear()}`}
                    </td>
                    <td style={{fontSize:"8px"}} width={6}>{row[0]?.count || 0}</td>
                    <td style={{fontSize:"8px"}} width={6}>${row[0]?.driverPay || 0}</td>
                    <td style={{fontSize:"8px"}} width={6}>{row[1]?.count || 0}</td>
                    <td style={{fontSize:"8px"}} width={6}>${row[1]?.driverPay || 0}</td>
                    <td style={{fontSize:"8px"}} width={6}>{row[2]?.count || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>${row[2]?.driverPay || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>{row[3]?.count || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>${row[3]?.driverPay || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>{row[4]?.count || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>${row[4]?.driverPay || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>{row[4]?.horizontalAFCountTotal || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>{row[5]?.count || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>${row[5]?.driverPay || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>{row[6]?.count || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>${row[6]?.driverPay || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>${row[6]?.horizontalGrandTotal || 0 }</td>
                    <td style={{fontSize:"8px"}} width={6}>{row[0]?.totalWorkingHours || 0 }</td>
                </tr>
                :null
                ):null}
                </tbody>
                <tfoot>
                    <tr>
                    {driverPackageIncome?.length ? driverPackageIncome[driverPackageIncome?.length-1].map((col,i)=>
                    <td key={i} colSpan={1} width="6%"  style={{fontSize:"8px"}}>
                        {/* {JSON.stringify(counts)} */}
                        {i === 0 ? 
                        `Total`:  
                        counts.includes(i)?col:`$${col}`}
                    </td>
                    ):null}
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}
export default TableIncome2;