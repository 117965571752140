import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Selector } from '../../components/common'
import {addPackage} from "../../actions"
import {Formik,Form} from "formik";
import ViewDatePicker from "../../components/common/datePicker";
import {name} from "../../helpers/validation"
import * as Yup from 'yup';
import Loader from "../../components/common/loader"

const initialState = {
  type: "",
  category: "",
  delivery_area: "",
  ausPost_rate: "",
  ausPost_from: "",
  ausDriver_rate: "",
  ausDriver_from: "",
  description: "",
}

const packageTypes = [
  "A", "B", "C", "D", "E", "F", "G", "H" 
];

const packageCategories = [1, 2];

function Edit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {loader} = useSelector(({packages})=>packages)
  const {options=[]} = useSelector(({area})=>area)

  const FormValidation = Yup.object({
    type: Yup.string()
    .required("Required"),
    ausPost_from: Yup.date()
    .required("Required"),
    ausDriver_from: Yup.date()
    .required("Required"),
    description: Yup.string()
      .required('Required'),
    category: Yup.number()
    .required('Required'),
    ausDriver_rate: Yup.number()
    .required("Required"),
    ausPost_rate: Yup.number()
    .required(),
    delivery_area: Yup.number()
    .required("Required"),
  });

  const handleSubmit = (values,{resetForm}) => {
    const packagee = {
      ausPostRate: values.ausPost_rate,
      ausPostRateFrom: values.ausPost_from,
      driverRateFrom: values.ausDriver_from,
      driverRate: values.ausDriver_rate,
      description: values.description,
      packageCategory: values.category,
      type: values.type,
      area_id: Number(values.delivery_area)
    }
    dispatch(addPackage(packagee,navigate));
    // resetForm({values: ""})
    }


  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{padding: "0px !important"}}
      >
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{padding: 0}}
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Add Packages</h3>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <Formik 
              initialValues={initialState}
              validationSchema={FormValidation}
              onSubmit={handleSubmit}
            >
              {({handleChange,errors,touched,resetForm,handleBlur,values,setFieldValue})=> (
                <Form id="kt_account_profile_details_form" className="form">
                <div className="card-body border-top p-9">
                
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  Package Type Name
                  </label>                 
                  <div className="col-lg-8 fv-row">
                  <input
                      type="text"
                      name="type"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Package Type Name"
                      onChange = {handleChange}
                      value = {values.type}
                      onBlur={handleBlur}
                    />
                  {/* <Selector data={packageTypes.map((it)=>{
                    return {value: it,title: it}
                  })}
                  value={values.type}
                  onChange={(value)=>{
                        setFieldValue("type",value)
                    }}/> */}
                  <div class="fv-plugins-message-container invalid-feedback">
                    <div data-field="fname" data-validator="notEmpty">{touched.type && errors.type ? errors.type: ""}
                  </div></div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  Package Category
                  </label>
                  <div className="col-lg-8 fv-row">
                  <Selector 
                    name="category"
                    style={{height:"50px"}}
                    data={packageCategories.map((it)=>{
                    return {value: it,title: it}
                  })} 
                  value={values.category}
                  onChange={(value)=>{
                        setFieldValue("category",value)
                    }}/>
                  <div class="fv-plugins-message-container invalid-feedback">
                    <div data-field="fname" data-validator="notEmpty">{touched.category && errors.category ? errors.category: ""}
                    </div>
                  </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    Delivery Area
                  </label>
                  <div className="col-lg-8 fv-row">
                  <Selector 
                    name="delivery_area"
                    style={{height:"50px"}}
                    data={options.map((it)=>{
                    return {value: it.id,title: it.areaName}
                    })} 
                    value={values.delivery_area}
                    onChange={(value)=>{
                          setFieldValue("delivery_area",value)
                    }}/>
                    
                  <div class="fv-plugins-message-container invalid-feedback">
                    <div data-field="fname" data-validator="notEmpty">{touched.delivery_area && errors.delivery_area ? errors.delivery_area : ""}
                  </div></div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    AusPost Rate
                  </label>
                  <div className="col-lg-8">
                      <div className="row mb-12">
                        <div className="col-lg-6 fv-row" style={{display: 'flex',alignItems: "baseline" }}>
                        <label className="col-lg-2 col-form-label required fw-semibold fs-6">
                          From
                        </label> 
                        <div style={{display: 'flex',flexDirection: 'column',width: '100%',alignItems: 'center',justifyContent: 'center'}}>
                  <ViewDatePicker
                   className="form-control form-control-lg form-control-solid"
                   type="text"
                   name="ausPost_from"
                   placeholder="Enter Description"
                   onChange = {(value)=>setFieldValue("ausPost_from", value.toISOString())}
                   value = {values.ausPost_from}
                   onBlur={handleBlur}
                   style={{paddingLeft: 10}}
                  />
                    <div class="fv-plugins-message-container invalid-feedback">
                      <div data-field="fname" data-validator="notEmpty">{touched.ausPost_from && errors.ausPost_from ? errors.ausPost_from : ""}
                    </div></div></div>
                  </div>
                  <div className="col-lg-6 fv-row">
                  <input
                      type="number"
                      name="ausPost_rate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="AusPost Rate"
                      onChange = {handleChange}
                      value = {values.ausPost_rate}
                      onBlur={handleBlur}
                    />
                    <div className="fv-plugins-message-container invalid-feedback">
                      <div data-field="fname" data-validator="notEmpty">{touched.ausPost_rate && errors.ausPost_rate ? errors.ausPost_rate : ""}
                    </div></div>
                    </div> </div>
                    </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    Driver Date
                  </label>
                  <div className="col-lg-8">
                      <div className="row mb-12">
                        <div className="col-lg-6 fv-row" style={{display: 'flex',alignItems: "baseline" }}>
                        <label className="col-lg-2 col-form-label required fw-semibold fs-6">
                          From
                        </label> 
                        <div style={{display: 'flex',flexDirection: 'column',width: '100%',alignItems: 'center',justifyContent: 'center'}}>
                  <ViewDatePicker
                   className="form-control form-control-lg form-control-solid"
                   type="text"
                   name="ausDriver_from"
                   placeholder="Enter Description"
                   onChange = {(value)=>setFieldValue("ausDriver_from", value.toISOString())}
                   value = {values.ausDriver_from}
                   onBlur={handleBlur}
                   style={{paddingLeft: 10}}
                   />
                  
                    <div class="fv-plugins-message-container invalid-feedback">
                      <div data-field="fname" data-validator="notEmpty">{touched.ausDriver_from && errors.ausDriver_from ? errors.ausDriver_from : ""}
                    </div></div></div>
                  </div>
                  <div className="col-lg-6 fv-row">
                    {/* <label htmlFor="ausDriver_rate">Rate</label> */}
                  <input
                      type="number"
                      name="ausDriver_rate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Driver Rate"
                      onChange = {handleChange}
                      value = {values.ausDriver_rate}
                      onBlur={handleBlur}
                    />
                    <div className="fv-plugins-message-container invalid-feedback">
                      <div data-field="fname" data-validator="notEmpty">{touched.ausDriver_rate && errors.ausDriver_rate ? errors.ausDriver_rate : ""}
                    </div></div></div></div>

                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    Description
                  </label>
                  <div className="col-lg-8 fv-row">
                    <textarea
                      type="text"
                      name="description"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Description"
                      onChange = {handleChange}
                      value = {values.description}
                      onBlur={handleBlur}
                    />
                    <div class="fv-plugins-message-container invalid-feedback">
                      <div data-field="fname" data-validator="notEmpty">{touched.description && errors.description ? errors.description :""}
                    </div></div>
                  </div>
                </div> 
              </div>
              {/* </div> */}
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="reset"
                  className="btn btn-light btn-active-light-primary me-2"
                  onClick={()=>navigate(-1)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  name="description"
                  disabled={loader}
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                >
                  {loader ? <Loader size={17}/>:"Add Package"}
                </button>
                <button
                  type="reset"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  style={{marginLeft:10}}
                  onClick={resetForm}
                >
                  <i className="bi bi-arrow-repeat" style ={{fontSize:25}}></i>              
                 
              </button>
              </div>
            </Form>
              )}
            </Formik>
          </div>
          <hr />
          <div style={{paddingLeft: 20}}
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
            >
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Aus.Post Rate Changes</h3>
              </div>
              {/*end::Card title*/}
            </div>
            <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
              <thead>
                <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                  <th className="p-5 w-200px w-xxl-450px" >Entered date</th>
                  <th className="p-5 min-w-150px" >Effective Date</th>
                  <th className="p-5 min-w-150px" >Current Rate</th>
                  <th className="p-5 min-w-190px" >Rrevious Rate</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
              {/*end::Table body*/}
            </table>
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
                style={{paddingLeft: 20}}
                  >
                    {/*begin::Card title*/}
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Driver Rate Changes</h3>
                    </div>
                    {/*end::Card title*/}
                  </div>
            <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
              <thead>
                <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                  <th className="p-5 w-200px w-xxl-450px" >Entered date</th>
                  <th className="p-5 min-w-150px" >Effective Date</th>
                  <th className="p-5 min-w-150px" >New Status</th>
                  <th className="p-5 min-w-190px" >Old Status</th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody>
              </tbody>
              {/*end::Table body*/}
            </table>
        </div>
      </div>
    </div>
  )
}

export default Edit

const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px"
}

function Selector3({data =[],style = {},onChange = ()=>{},value=""}) {
  const [type,setType] = useState(value);

  const handleChange = (e)=>{
    const val = e.target.value
    setType(val); 
    onChange(val);
  }

  //option attributes --> hidden,disabled
  return (
    <select name="cars" id="cars"  style={{...styles,...style}} onChange={handleChange}>
      <option hidden selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value===op.value} key={index} value={op.value} >{op.title}</option>
      )}
    </select>
  )
}
