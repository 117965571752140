
import React, {useState} from 'react';
import {NavLink, Outlet, useLocation, useNavigate} from 'react-router-dom';
import { Selector, ViewDatePicker } from '../../components/common';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoices } from '../../actions';
import InputSelect from '../../components/common/InputSelect';
import { driverAndSorterInvoiceStatus } from '../../constants';

const Invoices = () => {
  const dispatch = useDispatch()
    const [active, setActive] = useState(0)
  const location = useLocation()
  const {pathname} = useLocation();
  const [open, setOpen] = useState(false);
  const {options=[]} =  useSelector(({area})=>area);
  const initialValues ={
    driver: 0,
    area: [],
    start_date:"",
    end_date:"",
    status: ""
  }
  const [state, setState] = useState(initialValues)
  const onkeyChange =(key,value)=>{
    setState({...state, [key]:value})
  }
  const navigate = useNavigate();
  const {drivers,sorterDrivers} = useSelector(({driver})=>driver)
  // const {rows:data} = drivers
  const {rows:data} = sorterDrivers
  const handleSubmit =(e)=>{
    let payload = {}
    if(state.start_date) payload['start_date'] = state.start_date
    if(state.end_date) payload['end_date'] = state.end_date
    if(state.driver) payload['driver_id'] = +state.driver
    if(state.start_date) payload['start_date'] = state.start_date
    if(state.area) payload['areas'] = state.area?.join(",")
    if(state.status) payload['status'] = state.status
    dispatch(getInvoices(payload))
    // navigate('/')
  }

  const handleShowAll = () => {
    setOpen(false)
    setState(initialValues)
    dispatch(getInvoices()) 
  }
  return (
    
    <div className="col-xl-8 mb-5 mb-xl-10" style={{width:"100%"}} >      
    <div className="card card-flush h-md-100">
      <div className="card-body">   
      <div style={{display:"flex", justifyContent:"space-between",  alignItems: 'center'}}>
     
       <ul className="nav nav-pills nav-pills-custom mb-3">
        {/*begin::Item*/}
        <li className="nav-item mb-3 me-3 me-lg-6">
          {/*begin::Link*/}
          {/* <a
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
            href="#kt_stats_widget_6_tab_1"
          > */}
            <NavLink to ="invoicesList" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style ={({isActive})=>isActive ? setActive(1):{}}>
           <div
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            onClick={()=>setOpen(false)}
            data-bs-toggle="pill"
          >
            <div className="nav-icon mb-3" >
            <i className="bi bi-receipt fs-2"/>
            </div>
            <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Invoices</span>              
            <span className= {active === 1 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" : ""} />
            </div>
            </NavLink>

          {/* </a> */}
        </li>
        <li className="nav-item mb-3 me-3 me-lg-6">
        <NavLink to="create" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style ={({isActive})=> isActive ? setActive(2):{}}>
          <div
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
          >
            <div className="nav-icon mb-3">
            <i className="bi bi-plus-lg" />
            </div>
              <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Create</span>
            <span className= {active === 2 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" : ""} />
          </div> 
          </NavLink>
        
        </li>
        </ul>
        {
            (active === 1 && pathname==='/invoices/invoicesList') && <ul className = "nav nav-pills nav-pills-custom mb-3" >
              <li className ="nav-item mb-3 me-3 me-lg-6">
            <button
              style={{float:"left", marginLeft:10}}
              type="submit"
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={handleShowAll}
              > Show All         
            </button>
            </li>            
            <li className ="nav-item mb-3 me-3 me-lg-6">
           { location.pathname!=='/drivers/customRate' && <button              
              type="submit"
              style={{float:"right", marginleft:20}}
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={() => setOpen(!open)}
            > <i class="bi bi-search"></i> Search                 
            </button>
            }
            </li>
          </ul>
        }
        </div>
                 {
        open && active === 1 && 
        (
        <div  style={{backgroundColor: "#fff",margin: "10px 0"}}>
          <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
        <div className="card mb-5 mb-xl-10">
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <form id="kt_account_profile_details_form" className="form">
              <div className="card-body border-top p-9">
              <div className="row mb-6"> 
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
             Start Date
            </label>
            <div className="col-lg-8">
              <ViewDatePicker
              name="date"
              className="form-control form-control-lg form-control-solid"
              placeholder="Date"
              onChange={(value)=>onkeyChange("start_date", value)}
              value={state.start_date}
              style={{paddingLeft: 10}}
              />
            </div>
          </div>
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6" >
             End Date
            </label>
            <div className="col-lg-8">
              <ViewDatePicker
              name="date"
              className="form-control form-control-lg form-control-solid"
              placeholder="Date"
              onChange={(value)=>onkeyChange("end_date", value)}
              value={state.end_date}
              style={{paddingLeft: 10}}
              />
            </div>
          </div>
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
              Area
            </label>
            <div className="col-lg-8 fv-row">                       
                       <Selector2
                       name="delivery_area"
                       multi={true}
                          data={options.map((it)=>{
                            return {
                              value: it.id,
                              title: it.areaName
                          }}
                          )}
                          onChange={(value)=>onkeyChange("area", value)}
                          // value = {formik.values.delivery_area}
                          // onChange = {(value)=>formik.setFieldValue("delivery_area", value)}
                          // onBlur = {formik.handleBlur}
                        />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    {/* {formik.touched.delivery_area && formik.errors.delivery_area ? <div>{formik.errors.delivery_area}</div> : null} */}
                    </div></div>
                     </div>
          </div>
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
              Driver
            </label>
            <div className="col-lg-8 fv-row" style={{position:'relative'}}>
            {/* <Selector1 
            data={data.map((it)=>{return {value:it.id, title:`${it.firstName} ${it.lastName}`}})}
            onChange={(value)=>onkeyChange("driver",value)}
            value ={state.driver}
            /> */}
            <InputSelect
                      data={
                        data.length
                          ? data.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it.driverType
                              };
                            })
                          : ["Loadin..."]
                      }
                      onChange={(value)=>onkeyChange("driver",value)}
                      value ={state.driver}
                    />
            </div>
          </div>
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
              Status
            </label>
            <div className="col-lg-8 fv-row">                       
                       <Selector
                       name="status"
                          data={driverAndSorterInvoiceStatus.map((it)=>{
                            return {
                              value: it,
                              title: it
                          }}
                          )}
                          onChange={(value)=>onkeyChange("status", value)}
                        />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    {/* {formik.touched.delivery_area && formik.errors.delivery_area ? <div>{formik.errors.delivery_area}</div> : null} */}
                    </div></div>
                     </div>
          </div>
          </div>
          <div className="card-footer d-flex " style={{border:"none" , marginLeft:0, marginTop:-30}}>
            <button
              type="button"
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={handleSubmit}
            >
              Find
            </button>
          </div>
            
            </form>
          </div>
        </div>
        </div>
  </div>
        </div>
      
      )}  
      <Outlet context={[setOpen]}/>
      </div>
      
      </div>
    </div>
  )
}

export default Invoices;

const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px"
}

function Selector2({name="",data =[],style = {},onChange = ()=>{},value=""}) {
  const [type,setType] = useState(value);
  const handleChange = (e)=>{
    const selectedItems = e.target.selectedOptions
    const flavors = [];
    for (let i=0; i<selectedItems.length; i++) {
        flavors.push(selectedItems[i].value);
    }
    setType(flavors);
    onChange(flavors);
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars"  style={{...styles,...style}} onChange={handleChange} multiple={true}>
      <option hidden selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value===op.value} key={index} value={op.value} >{op.title}</option>
      )}
    </select>
  )
}


function Selector1({data =[],style = {},onChange = ()=>{},value=""}) {
  const [type,setType] = useState("");

  const handleChange = (e)=>{
    const {value} = e.target
    setType(value)
    onChange(value);
  }

  //option attributes --> hidden,disabled
  return (
    <select name="cars" id="cars" data-control="select2" className="form-select form-select-solid form-select-lg" style={{...styles,...style}} onChange={handleChange}>
      <option selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value===op.value} key={index} value={op.value} >{op.title}</option>
      )}
    
  </select>
  )
}