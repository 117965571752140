import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckBox } from "../../components/common";
import { fetchDrivers, getDriverProfit } from "../../actions";
import { ViewDatePicker } from "../../components/common";
import Selector from "../../components/common/Selector";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";

const tableTwo = [
  "Date",
  "Driver",
  "Total Number of Packages",
  "Total Income From Australia Post",
  "Total Payment To Employee",
  "Gross Profit",
  "Expenses",
  "Net Profit",
  "Area",
];

const hiddenTable = ["A", "B", "D", "E", "F", "G", "H"];

const styles = { fontSize: "13px", "text-align": "center" };

//remove dataRows also
const dataRows = [
  {
    date: "2022-12-21T00:00:00.000Z",
    driverName: 17,
    totalNumberOfPackages: 19,
    totalIncomeFromAusPost: 20,
    totalPaymentToEmployee: 23,
    grossProfit: "234.34",
    expenses: "129.98",
    netProfit: "129.98",
    A: 2.23,
    B: 2.23,
    C: 2.23,
    D: 2.23,
    E: 2.23,
    F: 2.23,
    G: 2.23,
    H: 2.23,
    I: 2.23,
    ORDINARYPARCEL: 2.23,
  },
  {
    date: "2022-12-21T00:00:00.000Z",
    driverName: 17,
    totalNumberOfPackages: 19,
    totalIncomeFromAusPost: 20,
    totalPaymentToEmployee: 23,
    grossProfit: "234.34",
    expenses: "129.98",
    netProfit: "129.98",
    A: 2.23,
    B: 2.23,
    C: 2.23,
    D: 2.23,
    E: 2.23,
    F: 2.23,
    G: 2.23,
    H: 2.23,
    I: 2.23,
    ORDINARYPARCEL: 2.23,
  },
];

function DriverProfit() {
  const dispatch = useDispatch();
  const { driverProfit = [] } = useSelector(({ reports }) => reports);
  let { allDrivers = [] } = useSelector(({ driver }) => driver);
  const { options = [] } = useSelector(({ area }) => area);
  const [state, setState] = useState({
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    driver_id: [],
    area: options[0].id, // need to filter data on area also
  });

  const [dailyCount, setDailyCount] = useState(false);
  const [individualPackage, setIndividualPackage] = useState(false);
  const [showHidden, setShowHidden] = useState(false);

  const onChange = (k, v) => {
    setState({ ...state, [k]: v });
  };

  const onChangeWithKey = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleSubmit = (e) => {
    // `driverProfit/${data.driver_id}/${data.startDate}/${data.endDate}/${data.showIndividualPackages}/${data.showDailyCount}`);
    e.preventDefault();
    setShowHidden(false);
    if (dailyCount && individualPackage) setShowHidden(true);
    const filters = {
      ...state,
      showDailyCount: dailyCount,
      showIndividualPackages: individualPackage,
    };

    //Please change field name according to API parmas before fetching data
    dispatch(getDriverProfit(filters));
    // dispatch({type: ACTION_TYPES.DRIVER_PROFIT_SUCCESS,payload: dataRows})
  };

  useEffect(() => {
    dispatch(fetchDrivers());
  }, []);

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ padding: "0px !important" }}
      >
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{ padding: 0 }}
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Driver Profitability Breakdown</h3>
            </div>
            <div className="d-flex justify-content-start py-7 px-6">
              {driverProfit && (
                <CSVLink
                  data={driverProfit}
                  filename={"DriverProfit.csv"}
                  target="_blank"
                >
                  <button
                    type="submit"
                    name="description"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                  >
                    Export
                  </button>
                </CSVLink>
              )}
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <form id="kt_account_profile_details_form" className="form">
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Start Date
                  </label>
                  <div className="col-lg-8 fv-row">
                    <ViewDatePicker
                      name="startDate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        onChange("startDate", value.toISOString())
                      }
                      value={state.startDate.substring(0, 10)}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    End Date
                  </label>
                  <div className="col-lg-8 fv-row">
                    <ViewDatePicker
                      name="endDate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        onChange("endDate", value.toISOString())
                      }
                      value={state.endDate.substring(0, 10)}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Select Driver
                  </label>
                  <div className="col-lg-8 fv-row" style={{position:'relative'}}>
                    {/* <MuiSelector data={allDrivers.map((it)=>{
                  return {
                    value: it.id,
                    title: `${it.firstName} ${it.lastName}`
                  }
                })}
                  onChange={(ids)=>setState({...state,driver_id: ids})}
                  defaultValue={allDrivers[0]}
                /> */}
                    <Selector2
                      name="driver_id"
                      multi={true}
                      data={allDrivers.map((it) => {
                        return {
                          value: it.id,
                          title: `${it.firstName} ${it.lastName}`,
                        };
                      })}
                      value={state.driver_id}
                      onChange={(value) =>
                        setState({ ...state, driver_id: value })
                      }
                    />
                     {/* <MultiInputSelect
                        name="driver_id"
                        data={
                          allDrivers.length
                          ? allDrivers.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it?.driverType
                              };
                            })
                          : ["Loadin..."]
                        }
                        onChange={(value) => onChangeWithKey("driver", value)}
                        value ={state.driver_id}
                     /> */}
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Area
                  </label>
                  <div className="col-lg-8 fv-row">
                    <Selector
                      name="area"
                      // multi={true}
                      data={options.map((it) => {
                        return {
                          value: it.id,
                          title: it.areaName,
                        };
                      })}
                      value={state.area}
                      onChange={(value) => setState({ ...state, area: value })}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    show individual packages
                  </label>
                  <div className="col-lg-8 fv-row">
                    <CheckBox
                      checked={dailyCount}
                      onChange={() => setDailyCount((prev) => !prev)}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    show daily counts
                  </label>
                  <div className="col-lg-8 fv-row">
                    <CheckBox
                      checked={individualPackage}
                      onChange={() => setIndividualPackage((prev) => !prev)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-start py-6 px-9">
                <button
                  type="submit"
                  name="description"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  onClick={handleSubmit}
                >
                  Find
                </button>
              </div>
            </form>
            <hr />
            <div className="table-responsive" style={{ padding: "0 -20px" }}>
              <table
                className="table table-hover table-row-dashed align-middle gs-0 gy-4 my-0"
                id="lastab"
                style={{ overflow: "scroll", padding: "10px" }}
                width="100%"
                cellSpacing={1}
                cellPadding={0}
              >
                <thead>
                  <tr className="fs-7 fw-bold text-black-500 border-bottom-0 bg-light">
                    {tableTwo?.length &&
                      tableTwo.map((ele, index) => (
                        <th
                          key={index}
                          style={{
                            ...styles,
                            paddingLeft: [0].includes(index) ? "6px" : 0,
                            paddingRight:
                              index === tableTwo?.length - 1 && "5px",
                          }}
                          className={
                            [].includes(index)
                              ? "min-w-200px"
                              : index === 0
                              ? "rounded-start"
                              : index === tableTwo?.length - 1 && !showHidden
                              ? "rounded-end"
                              : "min-w-100px"
                          }
                        >
                          {ele}
                        </th>
                      ))}
                    {showHidden
                      ? hiddenTable.map((elem, ind) => (
                          <th
                            key={ind}
                            style={{ ...styles }}
                            className={
                              ind === hiddenTable?.length - 1
                                ? "rounded-end pe-5"
                                : "min-w-100px"
                            }
                          >
                            {elem}
                          </th>
                        ))
                      : null}
                  </tr>
                </thead>
                <tbody className="border-right-1">
                  {driverProfit.map((row) => (
                    <tr>
                      <td style={{ padding: "5px" }} width={6}>
                        {`${new Date(row?.date).getDate()}/${
                          new Date(row?.date).getMonth() + 1
                        }/${new Date(row?.date).getFullYear()}`}
                      </td>
                      <td style={styles} width={6}>
                        {row?.driverName}
                      </td>
                      <td style={styles} width={6}>
                        {row?.totalNumberOfPackages}
                      </td>
                      <td style={styles} width={6}>
                        $
                        {Number.parseFloat(row?.totalIncomeFromAusPost).toFixed(
                          2
                        )}
                      </td>
                      <td style={styles} width={6}>
                        $
                        {Number.parseFloat(row?.totalPaymentToEmployee).toFixed(
                          2
                        )}
                      </td>
                      <td style={styles} width={6}>
                        $
                        {Number.parseFloat(
                          row?.totalIncomeFromAusPost -
                            row?.totalPaymentToEmployee
                        ).toFixed(2)}
                      </td>
                      <td style={styles} width={6}>
                        {row?.expenses}
                      </td>
                      <td style={styles} width={6}>
                        $
                        {Number.parseFloat(
                          row?.totalIncomeFromAusPost -
                            row?.totalPaymentToEmployee -
                            row?.expenses
                        ).toFixed(2)}
                      </td>
                      <td style={styles} width={6}>
                        {row?.areaName}
                      </td>
                      {showHidden ? (
                        <>
                          <td style={styles} width={6}>
                            {row?.A}
                          </td>
                          <td style={styles} width={6}>
                            {row?.B}
                          </td>
                          <td style={styles} width={6}>
                            {row?.D}
                          </td>
                          <td style={styles} width={6}>
                            {row?.E}
                          </td>
                          <td style={styles} width={6}>
                            {row?.F}
                          </td>
                          <td style={styles} width={6}>
                            {row?.G}
                          </td>
                          <td style={styles} width={6}>
                            {row?.H}
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles1 = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px",
};

function Selector2({
  name = "",
  data = [],
  style = {},
  onChange = () => {},
  value = [],
}) {
  const [type, setType] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    const selectedItems = e.target.selectedOptions;
    const flavors = [];
    for (let i = 0; i < selectedItems?.length; i++) {
      flavors.push(selectedItems[i].value);
    }
    setType(flavors);
    onChange(flavors);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        style={{
          ...styles1,
          marginBottom: "10px",
        }}
        onChange={handleSearch}
      />
      <select
        name={name}
        id="cars"
        style={{ ...styles1, ...style }}
        onChange={handleChange}
        multiple={true}
      >
        <option hidden selected={value === ""} value="">
          {" "}
          Choose one{" "}
        </option>
        {data
          .filter((option) =>
            option.title.toLowerCase().includes(searchTerm)
          )
          .map((op, index) => (
            <option
              className="col-lg-8 fv-row fw-semibold fs-6"
              selected={value.includes(op.value)}
              key={index}
              value={op.value}
            >
              {op.title}
            </option>
          ))}
      </select>
    </div>
  );
}

export default DriverProfit;
