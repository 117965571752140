import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import jsPdf from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import TableIncome2 from "./partnerPackageInvoiceTable2";
import generatePDF from 'react-to-pdf';
import { NotificationManager } from "react-notifications";

const styles = {
  padding: "1em",
  backgroundColor: "#fff",
};
function formatInvoiceId(id) {
  const idString = id?.toString();
  const chunks = [];

  for (let i = idString.length - 1; i >= 0; i -= 3) {
    const chunk = idString.slice(Math.max(0, i - 2), i + 1);
    chunks.unshift(chunk.padStart(3, "0"));
  }
  if (chunks.length === 1) {
    return "000-" + chunks.join("-");
  } else {
    return chunks.join("-");
  }
}

const DownloadPartnerInvoice = () => {
  const { partnerInvoice } = useSelector(({ partner }) => partner);
  console.log('31', partnerInvoice)
  const targetRef = useRef();
  
  // const generatePDF = () => {
  //   const domElement = document.getElementById("report");
  //   html2canvas(domElement, {
  //   }).then(canvas => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPdf({ orientation: 'l', 
  //     unit: 'pt', 
  //     format: [canvas.width, canvas.height]});
  //     pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
  //     pdf.save(`${new Date().toISOString()}.pdf`);
  //   });
  // };

  const generatePDFWithOptions = () => {

    generatePDF(targetRef, { filename: `${new Date().toISOString()}.pdf`})
      .then((blob) => {
        NotificationManager.success("Pdf downloaded success");
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong while generate pdf"));
      });

  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <ul className="nav nav-pills nav-pills-custom mb-3">
          <li className="nav-item mb-3 me-3 me-lg-6">
            <button
              style={{ float: "left", marginLeft: 10, marginTop: "-30px" }}
              type="submit"
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={generatePDFWithOptions}
            >
              {" "}
              Download Pdf
            </button>
          </li>
        </ul>
      </div>
      <div id="report" ref={targetRef}>
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-xxl"
          >
            <div className="card">
              <div className="card-body py-20">
                <div className="mw-lg-950px mx-auto w-100">
                  <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
                    <div className="d-flex gap-1 flex-column">

                      {/* <h5>Services provided to:</h5> */}

                      <div className="w-full d-flex justify-between mt-1">
                        <img src={process.env.REACT_APP_API_BASE_URL_IMAGE+partnerInvoice?.partner?.partner_logo} 
                        className="w-auto h-70px rounded"
                        alt="partner logo"/>
                      </div>
                      <h2 className="fs-1qx" style={{ color: "red" }}>
                        {partnerInvoice?.partner?.name}
                      </h2>
                      <h4 className="fs-1qx -mt-5">
                        {partnerInvoice?.partner?.contractor_abn}
                      </h4>
                      {/* <h4 className="fw-bolder text-gray-800 pe-5 pb-7">
                        Partner Invoice <br></br>#{" "}
                        {formatInvoiceId(partnerInvoice?.id)}
                      </h4> */}
                      <h4
                        className="text-gray-700  w-200px"
                        style={{ fontWeight: "500" }}
                      >
                        {partnerInvoice?.partner?.address}
                      </h4>
                    </div>
                    <div
                      className="d-flex flex-column gap-1 fw-semibold fs-4 text-muted"
                      style={{ width: "350px" }}
                    >
                      <div className="w-full d-flex justify-between">
                        <img src={process.env.REACT_APP_API_BASE_URL_IMAGE+partnerInvoice?.partner?.contractor_logo} className="w-auto h-70px rounded"/>
                      </div>
                      <div className="w-full d-flex justify-between">
                        <h2 className="fs-1qx mt-2" style={{ color: "red" }}>{partnerInvoice?.partner.contractor_name || 'N/A'}</h2>
                      </div>
                      <h4 className="fs-1qx -mt-2">
                        {partnerInvoice?.partner?.contractor_abn}
                      </h4>
                      <div className="w-full d-flex justify-between">
                        <h2 className="fs-2qx">Tax Invoice</h2>
                      </div>
                      <div
                        className="text-gray-700"
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div style={{ width: "50%" }}>Invoice #</div>
                        <div style={{ width: "50%" }}>
                          {formatInvoiceId(partnerInvoice?.id)}
                        </div>
                      </div>
                      <div
                        className="text-gray-700"
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div style={{ width: "50%" }}>Invoice Date</div>
                        <div style={{ width: "50%" }}>
                          {partnerInvoice?.createdAt.substring(0, 10)}
                        </div>
                      </div>
                      <div
                        className="text-gray-700"
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div style={{ width: "50%" }}>Payment Period Unit</div>
                        <div style={{ width: "50%" }}>N/A</div>
                      </div>
                      <div
                        className="text-gray-700"
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div style={{ width: "50%" }}>Total Payment #</div>
                        <div style={{ width: "50%" }}>
                          {(
                            partnerInvoice?.total_amount +
                            +(partnerInvoice?.total_amount * 0.1).toFixed(2)
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <TableIncome2
                  invoices={partnerInvoice?.data}
                  partner={partnerInvoice?.partner}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap:'5px',
                  marginTop: '50px'
                }}
              >
                <div className=" d-flex justify-between" style={{width:'330px'}}>
                  <h2 style={{width:'70%'}}>Total Payments</h2>
                  <h2 style={{width:'30%'}}>{(partnerInvoice?.total_amount).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        })}
                  </h2>
                </div>
               {partnerInvoice?.hide_gst===false?
                <div className=" d-flex justify-between" style={{width:'330px'}}>
                <h2 style={{width:'70%'}}>Total Amount of GST</h2>
                <h2 style={{width:'30%'}}>{(partnerInvoice?.total_amount*0.1).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      })}
                </h2>
              </div>:
              null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPartnerInvoice;
