import axios from "axios";

const API = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

API.interceptors.request.use((req) => {
  try {
    if (localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).accessToken) {
      req.headers.Authorization = `${
        JSON.parse(localStorage.getItem("profile")).accessToken
      }`;
    } else {
      localStorage.clear()
      // localStorage.removeItem('persist:root');
      window.location = `${window.location.protocol}//${window.location.host}/login`
    }
    return req;
  } catch (error) {
    console.error("error",error);
  }
});

API.interceptors.response.use(function (response) {

  return response;
}, function (error) {
  if(error?.response?.data?.statusCode === 401
    || error?.response?.data?.statusCode === 403
    || error?.response?.statusCode === 401
    || (error?.response?.statusCode === 401 && error?.response?.type === "UNAUTHORIZED")
    || (error?.statusCode === 401 && error?.type === "UNAUTHORIZED"
    )
  ){
    localStorage.clear()
    // localStorage.removeItem('persist:root');
    window.location = `${window.location.protocol}//${window.location.host}/login`
  } else {
    console.log('error is',error);
  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});


export const signIn = (data)=>axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/login`,data);

//* ----------------------------------------------------------------Area
export const getAllAreas = (req)=>API.get(`area/getall`,{
  params: req
});
export const fetchAllAreass = ()=>API.get("area/getAll");
export const postArea = (data)=>API.post("area/create",data);
export const fetchArea = (id)=>API.get(`area/get/${id}`);
export const updateArea =(id, data)=> API.put(`area/update/${id}`,data)

//* ----------------------------------------------------------------Driver
export const getAllDrivers = (req) => API.get(`driver/getall`,{
  params: req
});
export const getDriverss = ()=>API.get("/driver/getAll");
export const postDriver = (data)=> API.post("driver/create",data);
export const postDriverDocument = (data)=> API.post("fileUploadInternal",data);//for upload driver documents file
export const correctDriver = (id,data)=>API.put(`driver/update/${id}`,data);
export const getDrivers = (req) => API.get(`driver/getall?pageSize=${req.pageSize}&field=${req.field}&order=${req.order}
&firstName=${req.firstName}&lastName=${req.lastName}&abn=${req.abn}&carPlateRegistrationNumber=${req.carPlateRegistrationNumber}
&mobileNumber=${req.mobileNumber}&employmentType=${req.employmentType}&area_id=${req.areaId}&showHiddenDrivers=${req.showHiddenDrivers}`);

export const deleteDriver = (id)=>API.put(`driver/delete/${id}`);

export const fetchCustomDriverRate = (id)=>API.get(`customDriverRate/perPackage/${id}`)
export const postCustomDriverRate = (data)=>API.post(`customDriverRate/upsert`,data);
export const fetchAllCustomDriverRate = (id)=>API.get(`customDriverRate/getall/${id}`);
export const getsalaryAndEmploymentChangedList = (id)=>API.get(`driver/salaryAndEmploymentChanged/${id}`);

export const postCustomDriverRateForWeekend = (data)=>API.post(`customDriverRateForWeekends/upsert`,{customRatesArray: data});
export const fetchAllCustomDriverRateForWeekend = (id)=>API.get(`customDriverRateForWeekends/getall/${id}`);

//*-----------------------------------------------------------------Sorter Driver

export const getAllSorterDrivers = (req) => API.get(`driverSorter/getall`,{
  params: req
});
export const postSorterDriver = (data)=> API.post("driverSorter/create",data);
export const correctSorterDriver = (id,data)=>API.put(`driverSorter/update/${id}`,data);
export const deleteSorterDriver = (id)=>API.put(`driverSorter/delete/${id}`);
export const getSorterDrivers = (req) => API.get(`driverSorter/getall`,{
  params:req
});


//* ----------------------------------------------------------------Package
export const postPackage = (data)=>API.post("package/create",data);
export const getAllPackages = (req)=>API.get("package/getAll",{
  params:req
});
export const getFilteredPackages = (req)=>API.get("package/getAll",{
  params:req
});

export const editPackage = (id,data)=>API.put(`package/update/${id}`,data);
export const deletePackage = (id) => API.put(`package/delete/${id}`);

//* ----------------------------------------------------------------Expenses
export const postExpenses = (data)=>API.post("expense/create", data)
export const getExpenses =(req)=>API.get("expense/getall", {
  params:req
});
export const getAllExpenses =(req)=>API.get(`expense/getall?page=${req.page}&pageSize=${req.pageSize}&field=${req.field}&order=${req.order}`)
export const getFilteredExpense = (req)=>API.get("expense/getall",{
  params:req
});

export const getExpense =(id)=>API.get(`expense/get/${id}`)
export const updateExpense = (id, data)=>API.put(`expense/update/${id}`, data)
export const deleteExpense =(id)=>API.put(`expense/delete/${id}`)

//---------------------------------------------------------------------Reports
export const fetchReports = (req)=>API.get(`dailyEntry/getall/${req.areaId}/${req.date}`);
export const fetchDailyEntrySummary = (req)=>API.get(`dailyEntry/dailyEntrySummary/${req.area_id}/${req.startDate}/${req.endDate}`);
export const fetchReportComparison = (req)=>API.get(`dailyEntry/reportComparision/${req.startDate}/${req.endDate}/${req.area_id}`);
export const updateReposts = (data)=>API.post("dailyEntry/upsert",data);
export const fetchDriverPackageIncome = (data)=>API.get(`driverPackageIncome/${data.driverId}/${data.areaId}/${data.startDate}/${data.endDate}`);

export const fetchDriverProfit = (data)=> {
  let url = `driverProfit/${data.driver_id}/${data.startDate}/${data.endDate}/${data.showIndividualPackages}/${data.showDailyCount}`
  if(data.area) {
    url += `/${data.area}`;
  }
  return API.get(url)
};
export const fetchProfiteLoss = (data)=>API.get(`profitAndLoss/${data.driver_id}/${data.area_id}/${data.startDate}/${data.endDate}`)

export const fetchCharts = (req)=>API.get(`dailyEntry/incDecChart?duration=${req?.duration}&startDate=${req?.startDate}&endDate=${req?.endDate}`);

//--------------------------------------------------------------------- Users

export const createUser = (data)=>API.post("user/create",data);
// export const fetchArea = (id)=>API.get(`area/get/${id}`);
export const fetchAllUsers= ()=>API.get("user/getAll");
export const updateUser =(id, data)=> API.put(`user/update/${id}`,data)
export const deleteUser =(id)=>API.put(`user/delete/${id}`)
// export const getAllAreas = (req)=>API.get(`area/getall`,{
//   params: req
// });

//--------------------------------------------------------------------- Salary expenses

export const createExpenseSalary = (data)=>API.post("expense_salary/create",data);
export const fetchAllExpenseSalary= (filter)=>{
  return API.get("expense_salary/getAll",{
    params: filter
  })
};
export const updateExpenseSalaryapi =(id, data)=> API.put(`expense_salary/update/${id}`,data)
export const deleteExpenseSalary =(id)=>API.put(`expense_salary/delete/${id}`)


//--------------------------------------------------------------------- Salary expenses

export const createInvoicesApi = (data)=>API.post("invoice/create",data);
export const getAllInvoicesApi= (filter)=>{
  return API.get("invoice/getAll",{
    params: filter
  })
};
export const updateInvoicesApi =(id, data)=> API.put(`invoice/update/${id}`,data)
export const deleteInvoicesApi =(id)=>API.put(`invoice/delete/${id}`)

export const fetchloggedInDrivers = ()=>API.get(`${process.env.REACT_APP_API_BASE_URL_V2}/driver/getAllLoggedInDrivers`);


//* ---------------------------------------------------------------- Category
export const getAllCategories = (req)=>API.get(`expenseCategory/getall`,{
  params: req
});
export const fetchAllCategoriess= ()=>API.get("expenseCategory/getAll");
export const postCategory = (data)=>API.post("expenseCategory/create",data);
export const fetchCategory = (id)=>API.get(`expenseCategory/get/${id}`);
export const updateCategory =(id, data)=> API.put(`expenseCategory/update/${id}`,data)
export const deleteCategory =(id, data)=> API.delete(`expenseCategory/delete/${id}`,data)


//* ---------------------------------------------------------------- Monthly

export const fetchMonthlyData = () => API.get("monthly/getmonthlydata");


//* ----------------------------------------------------------------- Driver in-out pause data
 
export const fetchDriversInOutData = (req)=> API.get(`${process.env.REACT_APP_API_BASE_URL_V2}/driverInOut/getall`,{
  params: req
})

export const correctDriverInOutData = (id,data)=> API.put(`${process.env.REACT_APP_API_BASE_URL_V2}/driverInOut/updatedata/${id}`,data)
export const deleteDriverInOutData = (id)=> API.put(`${process.env.REACT_APP_API_BASE_URL_V2}/driverInOut/deletedata/${id}`)

//------------------------------------------------------------------ Partner
export const fetchPartners = (req)=>API.get(`partner/getAll`,{
  params: req
});
export const postPartner = (data)=>API.post("partner/create",data);
export const fetchPartner = (id)=>API.get(`partner/get/${id}`);
export const updatePartner =(id, data)=> API.put(`partner/update/${id}`,data)
export const deletePartner =(id, data)=> API.put(`partner/delete/${id}`)

export const fetchPartnersInvoices = (req)=>API.get(`partner/invoice_getAll`,{
  params: req
});
export const postPartnerInvoice = (data)=>API.post("partner/invoice_create",data);
export const fetchPartnerInvoice = (id)=>API.get(`partner/invoice_get/${id}`);
export const updatePartnerInvoice =(id, data)=> API.put(`partner/invoice_update/${id}`,data)
export const deletePartnerInvoice =(id, data)=> API.put(`partner/invoice_delete/${id}`)


export const fetchDriverPackageInvoices = (req)=>API.get(`driver_package/invoice_getAll`,{
  params: req
});
export const postDriverPackageInvoice = (data)=>API.post("driver_package/invoice_create",data);
export const fetchDriverPackageInvoice = (id)=>API.get(`driver_package/invoice_get/${id}`);
export const updateDriverPackageInvoice =(id, data)=> API.put(`driver_package/invoice_update/${id}`,data)
export const deleteDriverPackageInvoice =(id, data)=> API.put(`driver_package/invoice_delete/${id}`)