import React, {useEffect, useState} from 'react';
import AccountMenu from './accountMenu'
import { NavLink } from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";

const NewSidebar = () => {
    const dispatch = useDispatch()
    const {monthlyData} = useSelector(({common})=>common)
    const [openAcountMenu,setOpenAcountMenu] = useState(false);
    const [count, setCount] = useState(0)
  const {user, isLoggedIn} = useSelector((state)=>state.user) 

    const MouseOver = ()=>{
        setOpenAcountMenu(true);
      }
    
      const MouseOut = ()=>{
        setOpenAcountMenu(false);
      }
     
  return (
    <div>
       <div
                className="d-flex flex-center px-4 px-lg-6 py-3 py-lg-8"
                id="kt_app_sidebar_logo"
            >
                <NavLink to="/drivers">
                    {/* <h3 className="h-20px h-lg-25px theme-light-show"> */}
                    <img
                        className="d-lg-block w-200px h-150px"
                        style={{
                            background: "none !important",
                            border: "0 !important",
                        }}
                        src="/assets/media/images/img-logo.png"
                        alt=""
                        />
                    {/* </h3> */}
                </NavLink>
                <div className="ms-3" onMouseOver={MouseOver} onMouseOut={MouseOut}>
                    {/*begin::Menu wrapper*/}
                    <div
                        className="cursor-pointer position-relative symbol symbol-circle symbol-40px"
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement="bottom-end"
                    >
                        <img src="/assets/media/avatars/blank.png" alt="user" />
                        <div className="position-absolute rounded-circle bg-success start-100 top-100 h-8px w-8px ms-n3 mt-n3" />
                    </div>
                    {/*begin::User account menu*/}
                    {openAcountMenu?
                    <div style={{
                        position: 'absolute',
                        top:65,
                        right: 0,
                        background: "#fff",
                        zIndex:1202,
                        }}>
                        <AccountMenu />
                    </div>
                    :null}
                </div>
                {/*end::User menu*/}
            </div>

            <div
                className="flex-column-fluid px-4 px-lg-8 py-4"
                id="kt_app_sidebar_nav"
            >
                {/*begin::Nav wrapper*/}
                <div
                    id="kt_app_sidebar_nav_wrapper"
                    className="d-flex flex-column hover-scroll-y pe-4 me-n4"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="true"
                    data-kt-scroll-height="auto"
                    data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                    data-kt-scroll-wrappers="#kt_app_sidebar, #kt_app_sidebar_nav"
                    data-kt-scroll-offset="5px"
                >
                    {/*begin::Progress*/}
                    {/* <div className="d-flex align-items-center flex-column w-100 mb-3 mb-lg-6">
                        <div className="d-flex justify-content-between fw-bolder fs-6 text-gray-800 w-100 mt-auto mb-3">
                            <span>Your Goal</span>
                        </div>
                        <div
                            className="w-100 bg-light-primary rounded mb-2"
                            style={{ height: 24 }}
                        >
                            <div
                                className="bg-primary rounded"
                                role="progressbar"
                                style={{ height: 24, width: "37%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            />
                        </div>
                        <div className="fw-semibold fs-7 text-primary w-100 mt-auto">
                            <span>reached 37% of your target</span>
                        </div>
                    </div> */}
                    {/*end::Progress*/}
                    {/*begin::Stats*/}
                    <div className="d-flex justify-content-between fw-bolder fs-6 text-gray-800 w-100 mt-auto mb-3">
                            <h3>Total Month View</h3>
                        </div>
                        <div className="d-flex mb-3 mb-lg-6" >
                        {/*begin::Stat*/}
                        <div className="border border-white border-dashed rounded min-w-100px w-100 py-2 px-4 me-6" style={{backgroundColor:"#3e97ff",position:"relative"}}>
                            {/*begin::Date*/}
                            <span className="fs-6 text-white fw-bold"> Driver Expenses</span>
                            <span className="mb-2">
                                <i className="fonticon-truck fs-1 p-0" style={{ position:"absolute", color:"#fff",top:10,right:10}}/>
                            </span>
                            {/*end::Date*/}
                            {/*begin::Label*/}
                            <div className="fs-2 fw-bold text-white">{`$${monthlyData?.driver_expenses}`}</div>
                            {/*end::Label*/}
                        </div>
                        <div className="border border-white border-dashed rounded min-w-100px w-100 py-2 px-4" style={{backgroundColor:"#3e97ff",position:'relative'}}>
                            {/*begin::Date*/}
                            <span className="fs-6 text-white fw-bold">Salary Expenses</span>
                            <span className="mb-2">
                                       <i className="fonticon-layers fs-2" style={{position:"absolute", color:"#fff",top:10,right:10}}></i>
                                    </span>
                            {/*end::Date*/}
                            {/*begin::Label*/}
                            <div className="fs-2 fw-bold text-white">{`$${monthlyData?.salary_expenses}`}</div>
                            {/*end::Label*/}
                        </div>
                        {/*end::Stat*/}
                        </div>
                    <div className="d-flex mb-3 mb-lg-6" >
                        {/*begin::Stat*/}
                        <div className="border border-white border-dashed rounded min-w-100px w-100 py-2 px-4 me-6" style={{backgroundColor:"#3e97ff",position:'relative'}}>
                            {/*begin::Date*/}
                            <span className="fs-6 text-white fw-bold">Drivers</span>
                            <span className="mb-2">
                                <i className="fonticon-truck fs-1 p-0" style={{position:"absolute", color:"#fff",top:10,right:10}}/>
                            </span>
                            {/*end::Date*/}
                            {/*begin::Label*/}
                            <div className="fs-2 fw-bold text-white">{monthlyData?.driversCount}</div>
                            {/*end::Label*/}
                        </div>
                        <div className="border border-white border-dashed rounded min-w-100px w-100 py-2 px-4" style={{backgroundColor:"#3e97ff",position:'relative'}}>
                            {/*begin::Date*/}
                            <span className="fs-6 text-white fw-bold">Areas</span>
                            <span className="mb-2">
                                        <i className="fonticon-like-1 fs-2" style={{position:"absolute", color:"#fff",top:10,right:10}}/>
                                    </span>
                            {/*end::Date*/}
                            {/*begin::Label*/}
                            <div className="fs-2 fw-bold text-white">{monthlyData?.areasCount}</div>
                            {/*end::Label*/}
                        </div>
                        {/*end::Stat*/}
                    </div>
                    <div className="d-flex mb-3 mb-lg-6">
                        {/*begin::Stat*/}
                        <div className="border border-white border-dashed rounded min-w-100px w-100 py-2 px-4 me-6" style={{backgroundColor:"#3e97ff",position:'relative'}}>
                            {/*begin::Date*/}
                            <span className="fs-6 text-white fw-bold">Expenses</span>
                            <span className="mb-2">
                                    <i className="fonticon-layers fs-2" style={{marginLeft:15, position:"absolute", color:"#fff",top:10,right:10}}></i>
                                    </span>
                            {/*end::Date*/}
                            {/*begin::Label*/}
                            <div className="fs-2 fw-bold text-white">{monthlyData?.expensesCount}</div>
                            {/*end::Label*/}
                        </div>
                        <div className="border border-white border-dashed rounded min-w-100px w-100 py-2 px-4" style={{backgroundColor:"#3e97ff",position:'relative'}}>                     
                            {/*begin::Date*/}
                            <span className="fs-6 text-white fw-bold" >Packages</span>
                            <span className="mb-2">
                            <i className ="fonticon-insurance fs-2" style={{marginLeft:15, position:"absolute", color:"#fff",top:10,right:10}}></i>
                            </span>
                            {/*end::Date*/}
                            {/*begin::Label*/}
                            <div className="fs-2 fw-bold text-white">{monthlyData?.packagesCount}</div>
                            {/*end::Label*/}
                        </div>
                        {/*end::Stat*/}
                    </div>
                    <div className="mb-6">
                        {/*begin::Title*/}
                        <h3 className="text-gray-800 fw-bold mb-8">Services</h3>
                        {/*end::Title*/}
                        {/*begin::Row*/}
                        <div
                            className="row row-cols-2 row-cols-lg-3"
                            data-kt-buttons="true"
                            data-kt-buttons-target="[data-kt-button]"
                        >
                            {/*begin::Col*/}
                            <div className="col mb-4">
                                {/*begin::Link*/}
                                <NavLink
                                    to="/reports"                                
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                >
                                    {/*begin::Icon*/}
                                    <span className="mb-2">
                                        <i className="fonticon-calendar fs-2" />
                                    </span>
                                    <span className="fs-7 fw-bold">Reports</span>
                                    {/*end::Label*/}
                                </NavLink>
                                {/*end::Link*/}
                            </div>
                            {user.role !== 3 ? 
                                <div className="col mb-4">
                                    {/*begin::Link*/}
                                    <NavLink
                                        to="/expenses"
                                        className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                        data-kt-button="true"
                                        style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                    >
                                        {/*begin::Icon*/}
                                        <span className="mb-2">
                                        <i class="fonticon-layers fs-2"></i>
                                        </span>
                                        <span className="fs-7 fw-bold">Expenses</span>
                                        {/*end::Label*/}
                                    </NavLink>
                                    {/*end::Link*/}
                                </div>
                            :null}
                            <div className="col mb-4">
                                <NavLink
                                    to="/invoices"
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                >
                                    <span className="mb-2">
                                    <i className="bi bi-receipt fs-2"/>
                                    </span>
                                    <span className="fs-7 fw-bold" style={{padding:'0px 5px'}}>Driver And Sorter  Invoice</span>
                                </NavLink>
                            </div>
                            <div className="col mb-4">
                                {/*begin::Link*/}
                                <NavLink
                                    to="/areas"
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                    onClick ={()=>setCount((p)=>p+1)}
                                >
                                    {/*begin::Icon*/}
                                    <span className="mb-2">
                                        <i className="fonticon-like-1 fs-2" />
                                    </span>
                                    <span className="fs-7 fw-bold">Areas</span>
                                </NavLink>
                            </div>
                            <div className="col mb-4">
                                <NavLink
                                    to="/packages"
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                >
                                    <span className="mb-2">
                                        <i class="fonticon-insurance fs-2"></i>
                                    </span>
                                    <span className="fs-7 fw-bold">Package</span>
                                </NavLink>
                                {/*end::Link*/}
                            </div>
                            <div className="col mb-4">
                                <NavLink
                                    to="/driverTracking"
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                >
                                    <span className="mb-2">
                                    <i className="bi bi-geo-alt-fill fs-2"/>
                                    </span>
                                    <span className="fs-7 fw-bold">Driver And Sorter Tracking</span>
                                </NavLink>
                            </div>
                            <div className="col mb-4">
                                <NavLink
                                    to="/users"
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                >
                                    <span className="mb-2">
                                    <i className="bi bi-person fs-2"/>
                                    </span>
                                    <span className="fs-7 fw-bold">Users</span>
                                </NavLink>
                            </div>
                            <div className="col mb-4">
                                <NavLink
                                    to="/drivers"
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                >
                                    <span className="mb-2">
                                        <i className="fonticon-truck fs-1 p-0"/>
                                    </span>
                                    <span className="fs-7 fw-bold">Drivers</span>
                                </NavLink>
                            </div>
                            <div className="col mb-4">
                                <NavLink
                                    to="/partnerInvoices"
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                >
                                    <span className="mb-2">
                                    <i className="bi bi-receipt fs-2"/>
                                    </span>
                                    <span className="fs-7 fw-bold" style={{padding:'0px 5px'}}>Partner Invoices</span>
                                </NavLink>
                            </div>
                            
                            {/* <div className="col mb-4">
                                <NavLink
                                    to="/inOutTimmings"
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                >
                                    <span className="mb-2">
                                        <i className="fonticon-truck fs-1 p-0"/>
                                    </span>
                                    <span className="fs-7 fw-bold">In Out Timmings</span>
                                </NavLink>
                            </div> */}
                            {/* <div className="col mb-4">
                                <NavLink
                                    to="/category"
                                    className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                    data-kt-button="true"
                                    style={({isActive})=>isActive?{backgroundColor: "blue"}:{}}
                                >
                                    <span className="mb-2">
                                    <i className="bi bi-bag fs-2"/>
                                    </span>
                                    <span className="fs-7 fw-bold">Category</span>
                                </NavLink>
                            </div> */}
                        </div>
                    </div>

                  
                    
                </div>
            </div>
    </div>
  )
}

export default NewSidebar