import { ACTION_TYPES } from "../constants";
const INITIAL_STATE = {
  partner: {},
  partners:[],
  allPartners: [],
  partnerInvoice: {},
  partnersInvoices : [],
  loader: false,
  page: 1,
  pageSize: 20,
  field: "createdAt",
  order: "ASC",
};


const partners =  (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case ACTION_TYPES.PARTNER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.PARTNER_SUCCESS:
            return {...state,partner: action.payload,loader: false};
        case ACTION_TYPES.PARTNER_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.GET_PARTNERS_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.GET_PARTNERS_SUCCESS:
            return {...state,partners: action.payload,loader: false};
        case ACTION_TYPES.GET_PARTNERS_FAILED:
            return {...state,loader: false};
        
        case ACTION_TYPES.GET_ALL_PARTNER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.GET_ALL_PARTNER_SUCCESS:
            return {...state,allPartners: action.payload,loader: false};
        case ACTION_TYPES.GET_ALL_PARTNER_FAILED:
            return {...state,loader: false};
        
        case ACTION_TYPES.SET_PARTNER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.SET_PARTNER_SUCCESS:
            return {...state,partner: action.payload,loader: false};
        case ACTION_TYPES.SET_PARTNER_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.EDIT_PARTNER_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.EDIT_PARTNER_SUCCESS:
            return {...state,partner: action.payload,loader: false};
        case ACTION_TYPES.EDIT_PARTNER_FAILED:
            return {...state,loader: false};

        //--------------------------------------------------------partner invoices
        case ACTION_TYPES.PARTNER_INVOICE_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.PARTNER_INVOICE_SUCCESS:
            return {...state,partnerInvoice: action.payload,loader: false};
        case ACTION_TYPES.PARTNER_INVOICE_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.GET_PARTNERS_INVOICES_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.GET_PARTNERS_INVOICES_SUCCESS:
            return {...state,partnersInvoices: action.payload,loader: false};
        case ACTION_TYPES.GET_PARTNERS_INVOICES_FAILED:
            return {...state,loader: false};
        
        // case ACTION_TYPES.GET_ALL_PARTNERS_INVOICES_REQUEST:
        //     return {...state,loader: true};
        // case ACTION_TYPES.GET_ALL_PARTNERS_INVOICES_SUCCESS:
        //     return {...state,allPartners: action.payload,loader: false};
        // case ACTION_TYPES.GET_ALL_PARTNER_FAILED:
        //     return {...state,loader: false};
        
        case ACTION_TYPES.SET_PARTNER_INVOICE_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.SET_PARTNER_INVOICE_SUCCESS:
            return {...state,partnerInvoice: action.payload,loader: false};
        case ACTION_TYPES.SET_PARTNER_INVOICE_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.EDIT_PARTNER_INVOICE_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.EDIT_PARTNER_INVOICE_SUCCESS:
            return {...state,partnerInvoice: action.payload,loader: false};
        case ACTION_TYPES.EDIT_PARTNER_INVOICE_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.SET_PARTNER_INVOICE_PAGE:
            return {...state,page: action.payload};
        case ACTION_TYPES.RESET_PARTNER_INVOICE_PAGE:
            return {...state, page: 1};

        default:
            return state
    }
}

export default partners