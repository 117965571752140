import {ACTION_TYPES} from '../constants';

const initialState ={
    allData:[],
    monthlyData:{}
}

 function common (state= initialState, action){
    switch(action.type){
        case ACTION_TYPES.FETCH_ALL_DATA_REQUEST:
                return {...state}
            case ACTION_TYPES.FETCH_ALL_DATA_SUCCESS:
                return {...state, monthlyData:action.payload}
            case ACTION_TYPES.FETCH_ALL_DATA_FAILED:
                return {...state}
                default:
                    return state
    }

}
export default common