export const tableHeads = [
  "Expense ID",
  "Date",
  "Category",
  "Amount",
  "Status",
  "Driver",
  "Documents",
  "Description",
  "Edit",
  "Delete",
];

export const expensesList = [
  {
    date: "07/07/2022",
    category: "MT PRICHARD PETROL",
    amount: 0,
    status: "paid",
    driver: "name2",
    description: "November",
    edit: "edit",
    delete: "delete",
  },
  {
    date: "09/07/2022",
    category: "MT PRICHARD PETROL",
    amount: 100,
    status: "paid",
    driver: "name1",
    description: "November",
    edit: "edit",
    delete: "delete",
  },
];

export const categoryData = [
  "Petrol",
  "NEW BATTERY",
  "REGISTRATION",
  "BATTRY",
  "TYERS",
  "ALARM",
  "Salary",
  "BATTERY",
  "CAR CHARGER",
  "CAR SEAT",
  "REPAIR",
  "VAN PAYMENT",
  "CHARGE BOOSTER",
  "SERVICE",
  "TYRES",
  "MECHANIC",
  "GREEN SLIP",
  "COOLANT",
  "PUNCTURE REPAIR",
  "CTP GREEN SLIP",
  "MT PRITCHARD PETROL",
];

export const status = ["new", "paid", "unpaid"];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const invoicestableHeads = [
  "Invoice",
  "Invoice Date",
  "Driver/Sorter Name",
  "Invoice Value",
  "Area",
  "Status",
  "Edit",
  "Delete",
  "View",
];


export const tableHeadsInOut = ["ID", "Driver", "Date", "In Time", "Out Time"];
