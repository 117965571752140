import {ACTION_TYPES} from "../constants"
const initialState = {
    show: false,
    message: "",
    type: "",
}

export default (state=initialState, action)=>{
    switch(action.type) {
        case ACTION_TYPES.CREATE_ALERT:
            return {...state,show: true,message: action.payload,type:action.typ,extra: action.extra};
        case ACTION_TYPES.CLOSE_ALERT:
            return {...state,show: false};
        default:
            return state;
    }
}