const format = ["_REQUEST", "_COMPLETE", "_SUCCESS", "_FAILED"];
const types = [
  "AUTH",
  "DRIVER",
  "AREA",
  "EDIT_AREA",
  "EDIT_DRIVER",
  "EXPENSES",
  "EXPENSE",
  "PACKAGES",
  "REPORTS",
  "CUSTOM_RATE",
  "ADD_AREA",
  "UPDATED_AREA",
  "EXPENSE_CATEGORY",
  "GET_EXPENSES",
  "EDIT_EXPENSE",
  "EXPENSE_DELETE",
  "HIDE_DRIVER",
  "SEARCH_EXPENSE",
  "FETCH_ALL_DATA",
  "S_AND_EMP_LIST",
  "ADD_ADMIN",
  "EDIT_ADMIN",
  "FETCH_ADMIN",
  "DELETE_ADMIN",
  "SET_ADMIN",
  "UPDATE_ADMIN",
  "FETCH_ALL_ADMIN",
  "SALARY_EXPENSE",
  "CREATE_SALARY_EXPENSE",
  "SET_SALARY_EXPENSE",
  "DELETE_SALARY_EXPENSE",
  "INVOICES",
  "UPDATE_INVOICES",
  "ADD_INVOICES",
  "SET_INVOICE",
  "DELETE_INVOICE",
  "CUSTOM_RATE_FOR_WEEKEND",
  "CHART",
  "SUMMARY_REPORTS",
  "COMPAIR_REPORT",
  "LOGGED_IN_DRIVER",
  "CATEGORY",
  "ADD_CATEGORY",
  "CATEGORY_DELETE",
  "EDIT_CATEGORY",
  "UPDATE_CATEGORY"
];

const manuals = {
  RESET: "RESET",
  LOG_OUT: "LOG_OUT",
  USER_LOGGEDIN: "USER_LOGGEDIN",
  CLOSE_ALERT: "CLOSE_ALERT",
  CREATE_ALERT: "CREATE_ALERT",
  SIGNUP: "SIGNUP",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILED: "SIGNUP_FAILED",
  LOGIN:"LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  SET_AREA_OPTIONS: "SET_AREA_OPTIONS",
  EDIT_PACKAGE_SUCCESS: "EDIT_PACKAGE_SUCCESS",
  PACKAGE_CATEGORY_SUCCESS: "PACKAGE_CATEGORY_SUCCESS",
  PACKAGE_TYPE_SUCCESS: "PACKAGE_TYPE_SUCCESS",
  TOTAL_PACKAGES_SUCCESS: "TOTAL_PACKAGES_SUCCESS",
  SET_PACKAGE_PAGE: "SET_PACKAGE_PAGE",
  SET_AREA_PAGE: "SET_AREA_PAGE",
  SET_DRIVER_PAGE: "SET_DRIVER_PAGE",
  SET_DRIVER_ID: "SET_DRIVER_ID",
  SET_ALL_CUSTOM_RATE: "SET_ALL_CUSTOM_RATE",
  GET_ALL_DRIVERS: "GET_ALL_DRIVERS",
  SET_EXPENSES_PAGE:"SET_EXPENSES_PAGE",
  DRIVER_PACKAGE_INCOME_SUCCESS: "DRIVER_PACKAGE_INCOME_SUCCESS",
  DRIVER_PROFIT_SUCCESS: "DRIVER_PROFIT_SUCCESS",
  DRIVER_PROFIT_LOSS_SUCCESS: "DRIVER_PROFIT_LOSS_SUCCESS",
  SAVE_STATE: "SAVE_STATE",
  RESET_AREA_PAGE: "RESET_AREA_PAGE",
  RESET_DRIVER_PAGE: "RESET_DRIVER_PAGE",
  RESET_EXPENSES_PAGE: "RESET_EXPENSES_PAGE",
  RESET_PACKAGES_PAGE:"RESET_PACKAGES_PAGE",
  GET_ALL_ADMINS: "GET_ALL_ADMINS",
  SET_OPEN_SALARY_EXPENSE: "SET_OPEN_SALARY_EXPENSE",
  SHOW_ALL_SALARY_EXPENSE: "SHOW_ALL_SALARY_EXPENSE",
  UNSET_OPEN_SALARY_EXPENSE: "UNSET_OPEN_SALARY_EXPENSE",
  SET_CATEGORY_OPTIONS: "SET_CATEGORY_OPTIONS",
  SORTER_DRIVER_SUCCESS:"SORTER_DRIVER_SUCCESS",
  SORTER_DRIVER_FAILED:"SORTER_DRIVER_FAILED",
  SORTER_DRIVER_REQUEST:"SORTER_DRIVER_REQUEST",
  SET_SORTER_DRIVER_REQUEST:"SET_SORTER_DRIVER_REQUEST",
  SET_SORTER_DRIVER_SUCCESS:"SET_SORTER_DRIVER_SUCCESS",
  SET_SORTER_DRIVER_FAILED:"SET_SORTER_DRIVER_FAILED",

  PARTNER_SUCCESS:"PARTNER_SUCCESS",
  PARTNER_FAILED:"PARTNER_FAILED",
  PARTNER_REQUEST:"PARTNER_REQUEST",
  GET_ALL_PARTNER_SUCCESS:"GET_ALL_PARTNER_SUCCESS",
  GET_ALL_PARTNER_FAILED:"GET_ALL_PARTNER_FAILED",
  GET_ALL_PARTNER_REQUEST:"GET_ALL_PARTNER_REQUEST",
  GET_PARTNERS_SUCCESS:"GET_PARTNERS_SUCCESS",
  GET_PARTNERS_FAILED:"GET_PARTNERS_FAILED",
  GET_PARTNERS_REQUEST:"GET_PARTNERS_REQUEST",
  SET_PARTNER_REQUEST:"SET_PARTNER_REQUEST",
  SET_PARTNER_SUCCESS:"SET_PARTNER_SUCCESS",
  SET_PARTNER_FAILED:"SET_PARTNER_FAILED",
  EDIT_PARTNER_REQUEST:"EDIT_PARTNER_REQUEST",
  EDIT_PARTNER_SUCCESS:"EDIT_PARTNER_SUCCESS",
  EDIT_PARTNER_FAILED:"EDIT_PARTNER_FAILED",
  SET_PARTNER_PAGE:"SET_PARTNER_PAGE",
  RESET_PARTNER_PAGE:"RESET_PARTNER_PAGE",

  PARTNER_INVOICE_SUCCESS:"PARTNER_INVOICE_SUCCESS",
  PARTNER_INVOICE_FAILED:"PARTNER_INVOICE_FAILED",
  PARTNER_INVOICE_REQUEST:"PARTNER_INVOICE_REQUEST",
  GET_ALL_PARTNERS_INVOICES_SUCCESS:"GET_ALL_PARTNERS_INVOICES_SUCCESS",
  GET_ALL_PARTNERS_INVOICES_FAILED:"GET_ALL_PARTNERS_INVOICES_FAILED",
  GET_ALL_PARTNERS_INVOICES_REQUEST:"GET_ALL_PARTNERS_INVOICES_REQUEST",
  GET_PARTNERS_INVOICES_SUCCESS:"GET_PARTNERS_INVOICES_SUCCESS",
  GET_PARTNERS_INVOICES_FAILED:"GET_PARTNERS_INVOICES_FAILED",
  GET_PARTNERS_INVOICES_REQUEST:"GET_PARTNERS_INVOICES_REQUEST",
  SET_PARTNER_INVOICE_REQUEST:"SET_PARTNER_INVOICE_REQUEST",
  SET_PARTNER_INVOICE_SUCCESS:"SET_PARTNER_INVOICE_SUCCESS",
  SET_PARTNER_INVOICE_FAILED:"SET_PARTNER_INVOICE_FAILED",
  EDIT_PARTNER_INVOICE_REQUEST:"EDIT_PARTNER_INVOICE_REQUEST",
  EDIT_PARTNER_INVOICE_SUCCESS:"EDIT_PARTNER_INVOICE_SUCCESS",
  EDIT_PARTNER_INVOICE_FAILED:"EDIT_PARTNER_INVOICE_FAILED",
  SET_PARTNER_INVOICE_PAGE:"SET_PARTNER_INVOICE_PAGE",
  RESET_PARTNER_INVOICE_PAGE:"RESET_PARTNER_INVOICE_PAGE",

  DRIVER_PACKAGE_INVOICE_SUCCESS:"DRIVER_PACKAGE_INVOICE_SUCCESS",
  DRIVER_PACKAGE_INVOICE_FAILED:"DRIVER_PACKAGE_INVOICE_FAILED",
  DRIVER_PACKAGE_INVOICE_REQUEST:"DRIVER_PACKAGE_INVOICE_REQUEST",
  GET_ALL_DRIVER_PACKAGE_INVOICES_SUCCESS:"GET_ALL_DRIVER_PACKAGE_INVOICES_SUCCESS",
  GET_ALL_DRIVER_PACKAGE_INVOICES_FAILED:"GET_ALL_DRIVER_PACKAGE_INVOICES_FAILED",
  GET_ALL_DRIVER_PACKAGE_INVOICES_REQUEST:"GET_ALL_DRIVER_PACKAGE_INVOICES_REQUEST",
  GET_DRIVER_PACKAGE_INVOICES_SUCCESS:"GET_DRIVER_PACKAGE_INVOICES_SUCCESS",
  GET_DRIVER_PACKAGE_INVOICES_FAILED:"GET_DRIVER_PACKAGE_INVOICES_FAILED",
  GET_DRIVER_PACKAGE_INVOICES_REQUEST:"GET_DRIVER_PACKAGE_INVOICES_REQUEST",
  SET_DRIVER_PACKAGE_INVOICE_REQUEST:"SET_DRIVER_PACKAGE_INVOICE_REQUEST",
  SET_DRIVER_PACKAGE_INVOICE_SUCCESS:"SET_DRIVER_PACKAGE_INVOICE_SUCCESS",
  SET_DRIVER_PACKAGE_INVOICE_FAILED:"SET_DRIVER_PACKAGE_INVOICE_FAILED",
  EDIT_DRIVER_PACKAGE_INVOICE_REQUEST:"EDIT_DRIVER_PACKAGE_INVOICE_REQUEST",
  EDIT_DRIVER_PACKAGE_INVOICE_SUCCESS:"EDIT_DRIVER_PACKAGE_INVOICE_SUCCESS",
  EDIT_DRIVER_PACKAGE_INVOICE_FAILED:"EDIT_DRIVER_PACKAGE_INVOICE_FAILED",
  SET_DRIVER_PACKAGE_INVOICE_PAGE:"SET_DRIVER_PACKAGE_INVOICE_PAGE",
  RESET_DRIVER_PACKAGE_INVOICE_PAGE:"RESET_DRIVER_PACKAGE_INVOICE_PAGE",

  GET_ALL_INVOICES_REQUEST: "GET_ALL_INVOICES_REQUEST",
  GET_ALL_INVOICES_SUCCESS: "GET_ALL_INVOICES_SUCCESS",
  GET_ALL_INVOICES_FAILED: "GET_ALL_INVOICES_FAILED",

  DRIVER_IN_OUT_DATA_SUCCESS:"DRIVER_IN_OUT_DATA_SUCCESS",
  // SORTER_DRIVER_FAILED:"SORTER_DRIVER_FAILED",
  // SORTER_DRIVER_REQUEST:"SORTER_DRIVER_REQUEST",
  // SET_SORTER_DRIVER_REQUEST:"SET_SORTER_DRIVER_REQUEST",
  SET_DRIVER_IN_OUT_DATA:"SET_DRIVER_IN_OUT_DATA",
  // SET_SORTER_DRIVER_FAILED:"SET_SORTER_DRIVER_FAILED",
};

export default {
  ...types
    .map((t) => format.map((i) => `${t}${i}`))
    .flat(1)
    .reduce((obj, v) => {
      obj[v] = v;
      return obj;
    }, {}),
  ...manuals,
};
