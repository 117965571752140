import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Selector, ViewDatePicker } from "../../components/common";
import { getReportComparison } from "../../actions";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

const durationOptions = [
  "TODAY",
  "YESTERDAY",
  "WEEK",
  "MONTH",
  "THREE_MONTH",
  "YEAR",
];
const compaireOptions = ["NO_COMPARE", "PREVIOUS_PERIOD", "LAST_YEAR"];

function ReportComaparison() {
  const dispatch = useDispatch();
  const { options = [] } = useSelector(({ area }) => area);
  const {compareReport } = useSelector(({ reports }) => reports);
  const [state, setState] = useState({
    area_id: "",
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString()
  });
  const handleSelector = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const onSubmitcompareReport = () => {
    dispatch(getReportComparison(state));
  };

  useEffect(() => {
    dispatch(getReportComparison(state));
  }, []);
  
  const getPercentage = (num1, num2) => {
    let profit = Math.abs(num1 - num2);
    if(num2 === 0){
      num2 = 1;
    }
    return  Math.abs(parseFloat(( profit/num2) * 100).toFixed(2))
  }

  let todayAvgDelPerDriver = (data) => {
    let drivers = data.numOfDrivers;
    if(data?.numOfDrivers === 0){
      drivers = 2;
    }
    if(data?.totalDeliveries && drivers) {
      return Math.abs(parseFloat(data?.totalDeliveries/drivers).toFixed(2))
    }
    return 0;
  }

  let compareAvgDelPerDriver = (data) => {
    if(data?.totalDeliveries && data?.numOfDrivers){
      return Math.abs(parseFloat((data?.totalDeliveries)/(data?.numOfDrivers)).toFixed(2))
    }
    return 0;
  }

  
  return (
    <div className="tab-content">
      <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
        <div className="table-responsive">
          <div className="card-title flex-column">
            <h3 className="fw-bold mb-1">Deliveries Over Time</h3>
          </div>
          <div style={{ display: "flex", margin: "0 10px" }}>
            <div className="row mb-6">
              <label className="col-form-label fw-semibold fs-6">
                Start Date
              </label>
              <div className="col-lg-10 col-md-8 fv-row">
                   <ViewDatePicker
                      name="startDate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        handleSelector("startDate", value.toISOString())
                      }
                      value={state.startDate.substring(0, 10)}
                    />
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-form-label fw-semibold fs-6">
                End Date
              </label>
              <div className="col-lg-10 col-md-8 fv-row">
                   <ViewDatePicker
                      name="endDate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        handleSelector("endDate", value.toISOString())
                      }
                      value={state.startDate.substring(0, 10)}
                    />
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-form-label fw-semibold fs-6">
                Delivery Area
              </label>
              <div className="col-lg-10 col-md-8 fv-row">
                <Selector
                  style={{ width: "100% !important" }}
                  data={options.map((it) => {
                    return {
                      value: it.id,
                      title: it.areaName,
                    };
                  })}
                  onChange={(value) => handleSelector("area_id", value)}
                  value={state.area_id === "" ? "Choose Area" : state.area_id}
                />
              </div>
            </div>
            <div className="row mb-6" style={{ width: "10%" }}>
              <label className="col-form-label fw-semibold fs-6">Search</label>
              <div className="col-lg-4 col-md-6 fv-row">
                <button
                  type="submit"
                  name="description"
                  className="btn btn-primary "
                  id="kt_account_profile_details_submit"
                  onClick={onSubmitcompareReport}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div
          className="rounded bg-light"
          style={{
            padding: "20px",
            width: "30%",
            border: "1px solid rgb(62, 151, 255)",
          }}
        >
          <div className="d-flex justify-content-center align-items-start flex-wrap mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-1">
                <NavLink
                  to="/reports/reportComparison"
                  className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                >
                  Number of Drivers
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            {!compareReport?.duration ? (
              <div className="d-flex justify-content-center align-items-center mb-1 text-gray-800 text-hover-primary fs-2 fw-bold me-3 p-10 pb-15">
                No Data Available
              </div>
            ) : (
              <div>
                <div className="d-flex flex-wrap">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="fs-4 fw-bold">
                        {compareReport?.duration
                          ? new Date(
                              compareReport?.duration?.date
                            ).toLocaleDateString()
                          : "No Data "}
                      </div>
                    </div>
                  </div>
                  <div className="border border-gray-300 border-dashed rounded min-w-40px py-3 px-4 me-6 mb-3">
                    <div className="d-flex  align-items-center">
                      <div className="fs-4 fw-bold">
                        {compareReport?.duration
                          ? compareReport?.duration?.numOfDrivers
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
              
                {/* {noCompare !== compaireOptions[0] && (!compareReport?.duration || !compareReport?.comparedWith ? (
                  null
                ) : compareReport?.duration?.numOfDrivers >=
                  compareReport?.comparedWith?.numOfDrivers ? (
                  <div className="d-flex flex-wrap justify-content-center">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center" style={{'justifyContent':'center'}}>
                      <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                          />
                          <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                        <div
                          className="fs-4 fw-bold"
                        >
                          {`${getPercentage(compareReport?.duration.numOfDrivers,compareReport?.comparedWith?.numOfDrivers )}%`}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-wrap justify-content-center">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center" 
                          style={{'justifyContent':'center'}}>
                        <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                        <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect>
                            <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"></path>
                          </svg>
                        </span>
                        </span>
                        <div
                          className="fs-4 fw-bold"
                        >
                          {`${getPercentage(compareReport?.comparedWith?.numOfDrivers,compareReport?.duration.numOfDrivers )}%`}
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
                
              </div>
            )}
          </div>
        </div>

        <div
          className="rounded bg-light"
          style={{
            padding: "20px",
            width: "30%",
            border: "1px solid rgb(62, 151, 255)",
          }}
        >
          <div className="d-flex justify-content-center align-items-start flex-wrap mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-1">
                <NavLink
                  to="/reports/reportComparison"
                  className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                >
                  Total Deliveries
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            {!compareReport?.duration  ? (
              <div className="d-flex justify-content-center align-items-center mb-1 text-gray-800 text-hover-primary fs-2 fw-bold me-3 p-10 pb-15">
                No Data Available
              </div>
            ) : (
              <div>
                <div className="d-flex  flex-wrap">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="fs-4 fw-bold">
                        {compareReport?.duration
                          ? new Date(
                              compareReport?.duration.date
                            ).toLocaleDateString()
                          : "No Data "}
                      </div>
                    </div>
                  </div>

                  <div className="border border-gray-300 border-dashed rounded min-w-40px py-3 px-4 me-6 mb-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="fs-4 fw-bold">
                        {compareReport?.duration
                          ? compareReport?.duration?.totalDeliveries
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="rounded bg-light"
          style={{
            padding: "20px",
            width: "30%",
            border: "1px solid rgb(62, 151, 255)",
          }}
        >
          <div className="d-flex justify-content-center align-items-start flex-wrap mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-1">
                <NavLink
                  to="/reports/reportComparison"
                  className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                >
                  Avg deliveries/driver
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center ">
            {!compareReport?.duration ? (
              <div className="d-flex justify-content-center align-items-center mb-1 text-gray-800 text-hover-primary fs-2 fw-bold me-3 p-10 pb-15">
                No Data Available
              </div>
            ) : (
              <div>
                <div className="d-flex  flex-wrap">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="fs-4 fw-bold">
                        {compareReport?.duration
                          ? new Date(
                              compareReport?.duration.date
                            ).toLocaleDateString()
                          : "No Data "}
                      </div>
                    </div>
                  </div>

                  <div className="border border-gray-300 border-dashed rounded min-w-40px py-3 px-4 me-6 mb-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="fs-4 fw-bold">
                        {todayAvgDelPerDriver(compareReport?.duration)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportComaparison;
