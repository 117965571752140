import {ACTION_TYPES} from "../constants"
import {signIn} from "../api"
import {  NotificationManager } from 'react-notifications';

export const fetchUsers = () => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.AUTH_REQUEST})
        const {data} = await signIn();
        dispatch({type: ACTION_TYPES.AUTH_SUCCESS,payload: data.data})
    } catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.AUTH_FAILED})
        NotificationManager.error(error.message);
    }
}

export const signUp =(res)=>(dispatch, getState)=>{
    try{
        dispatch({type: ACTION_TYPES.SIGNUP})
        const {users} = getState().user
        dispatch({type : ACTION_TYPES.SIGNUP_SUCCESS, payload:[...users, res]})
    }
    catch(err){
        console.log(err.message);
        dispatch({type : ACTION_TYPES.SIGNUP_FAILED})
    }   
}

export const login = (res,navigate)=>async(dispatch, getState)=>{
    try{ 
        dispatch({type : ACTION_TYPES.AUTH_REQUEST})
        const {data} = await signIn(res);
        navigate("/drivers");
        dispatch({type: ACTION_TYPES.AUTH_COMPLETE,payload: data.data})
        NotificationManager.success("You have successfully logged in!");
    }
    catch(err){
        console.log("error",err?.message);
        dispatch({type : ACTION_TYPES.AUTH_FAILED})
        NotificationManager.error(err?.response?.data?.msg ? err?.response?.data?.msg :"Invalid credentials");
    }
}

export const logout =(res)=>(dispatch, getState)=>{
    try{
        dispatch({type : ACTION_TYPES.LOG_OUT})
    }
    catch(err){
        console.log(err.message);
        NotificationManager.error(err.message);
    }
}
