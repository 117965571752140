import React, { useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Selector, Table, ViewDatePicker } from "../../components/common";
import { fetchAllSorterDriver, fetchDrivers,fetchFilteredSorterDriver,getDriversInOutData,getLoggedInDrivers } from "../../actions";
import { NavLink, Navigate, Outlet, useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { GoogleMap, Marker, LoadScript,useJsApiLoader } from '@react-google-maps/api';
import PaginationControlled from "../../components/common/paginate";
import InputSelect from "../../components/common/InputSelect";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: '-7%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const tableHeads = ["Driver Name", "Location", "Last Location Time"];

const tableHeads1 = ["Driver Name","Login Area","Logout Area","Login Time","Logout Time","Runing Status","Edit","Hide"]

const initialValues = {
  area_name:"",
  driver: "",
  startDate: "",
  endDate: "",
};

function DriverTracking({ width = "100%" }) {
  const navigate = useNavigate()
  const {options} = useSelector(({area})=>area);
  const {loggedInDrivers,driversInOutData,page=1,sorterDrivers } = useSelector(({ driver }) => driver);
  const {rows:data} = sorterDrivers
  const [active, setActive] = useState(0)
  const [state, setState] = useState(initialValues);
  const [error,setError]=useState({startDate: "",endDate: ""})
  const [open,setOpen]=useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(fetchDrivers());
    dispatch(fetchFilteredSorterDriver())
    dispatch(getLoggedInDrivers())
    dispatch(getDriversInOutData())
  }, []);

  const handleSearch = ()=> {
    dispatch(getLoggedInDrivers())
  }

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('yourAppElement');

  const handleSubmit = (e) => {
    e.preventDefault();
    if(state?.startDate>new Date()){
      setError({...error,startDate:"start date should be less than or equal to current date"})
      return
    }
    if(state?.endDate>new Date()){
      setError({...error,endDate:"end date should be less than or equal to current date"})
      return
    }
    if(state?.endDate && !state?.startDate){
      setError({...error,startDate:"please select start date"})
      return
    }
    if(!state?.endDate && state?.startDate){
      setError({...error,endDate:"please select end date"})
      return
    }
    if(state?.startDate && state?.startDate && state?.startDate>=state?.endDate){
      setError({...error,startDate:"start date should be less than end date"})
      return
    }
    const filteredValues = {
      area_id:state.area_name,
      driver_id: state.driver,
      startDate: state.startDate
        ? new Date(new Date(state.startDate ? state.startDate : new Date())).toISOString()
        : "",
      endDate: state.endDate
        ? new Date(new Date(state.endDate ? state.endDate : new Date())).toISOString()
        : "",
    };
    setError({startDate: "",endDate: ""})
    dispatch(getDriversInOutData(filteredValues));
  };

  const onkeyChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return (
    <div
      className="col-xl-8 mb-5 mb-xl-10"
      style={{ width: width ? width : "" }}
    >
      <div className="card card-flush h-md-100">
        <div className="card-body">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ul className="nav nav-pills nav-pills-custom mb-3">
              <li className="nav-item mb-3 me-3 me-lg-6" onClick={()=>{
                setOpen(false)
                setActive(0)
                navigate("/driverTracking/list")
              }}>
              <div
                    className={`nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 ${active===0 && "active"}`}
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i className="fonticon-truck fs-1 p-0" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      Driver Tracking
                    </span>
                    <span
                      className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                    />
                  </div>
              </li>
              <li className="nav-item mb-3 me-3 me-lg-6" onClick={()=>{
                setOpen(false)
                setActive(1)
                navigate("/driverTracking/data/list")
              }}>
              <div
                    className={`nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 ${active===1 && "active"}`}
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i className="fonticon-truck fs-1 p-0" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                    Tracking Data
                    </span>
                    <span
                      className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                    />
                  </div>
              </li>
            </ul>

            <ul className = "nav nav-pills nav-pills-custom mb-3" >
        
            {active===0?<li className ="nav-item mb-3 me-3 me-lg-6">         
               <button
                 style={{float:"right", marginleft:20}}
                 className="btn btn-primary"
                 onClick={handleSearch}
              > <i class="bi bi-arrow-repeat"></i>Reload
              </button>
              </li>:
              <li className ="nav-item mb-3 me-3 me-lg-6">         
              <button
                style={{float:"right", marginleft:20}}
                className="btn btn-primary"
                onClick={()=>setOpen(!open)}
             ><i class="bi bi-search"></i> Search
             </button>
             </li>}
            </ul>


          </div>

          {open && active === 1 && (
            <div style={{ backgroundColor: "#fff", margin: "10px 0" }}>
              <form id="kt_account_profile_details_form" className="form">
                <div className="card-body border-top p-9">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Driver
                    </label>
                    <div className="col-lg-8 fv-row" style={{position:'relative'}}>
                     <InputSelect
                      data={
                        data.length
                          ? data.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it.driverType
                              };
                            })
                          : ["Loadin..."]
                      }
                      onChange={(value)=>onkeyChange("driver",value)}
                      value ={state.driver}
                    />
                  </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Start Date
                    </label>
                    <div className="col-lg-8 fv-row">
                      <ViewDatePicker
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        id="date"
                        name="startDate"
                        onChange={(value) => onkeyChange("startDate", value)}
                        value={state.startDate}
                        style={{ paddingLeft: 10 }}
                      />
                       <div class="fv-plugins-message-container invalid-feedback">
                            <div data-field="fname" data-validator="notEmpty">
                            {error?.startDate && error?.startDate ? <div>{error?.startDate}</div> : null}</div>
                        </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      End Date
                    </label>
                    <div className="col-lg-8 fv-row">
                      <ViewDatePicker
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        id="date"
                        name="endDate"
                        onChange={(value) => onkeyChange("endDate", value)}
                        value={state.endDate}
                        style={{ paddingLeft: 10 }}
                      />
                       <div class="fv-plugins-message-container invalid-feedback">
                            <div data-field="fname" data-validator="notEmpty">
                            {error?.endDate && error?.endDate ? <div>{error?.endDate}</div> : null}</div>
                        </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Area Name
                    </label>
                    <div className="col-lg-8 fv-row">
                    <Selector 
                      data={options.map((it)=>{
                        return {
                          value: it.id,
                          title: it.areaName,
                        }
                      })} 
                      onChange={(value)=>onkeyChange("area_name",value)}
                      value={state.area_name}
                      />
                      
                    </div>
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      {" "}
                    </label>
                  </div>  
                </div>
                <div
                  className="card-footer d-flex "
                  style={{ border: "none", marginLeft: 0, marginTop: -30 }}
                >
                  <button
                    type="submit"
                    name="description"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    onClick={handleSubmit}
                  >
                    Find
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
        <Outlet context={[setActive]}/>
      </div>
      {active===0?
      <div class="d-flex flex-stack flex-wrap pt-10">
					<div className="fs-6 fw-semibold text-gray-700">
          {loggedInDrivers?.count!==0 && `Showing ${1+20*(page-1)} to ${20*page>loggedInDrivers?.count?loggedInDrivers?.count:20*page} of ${loggedInDrivers?.count} entries`}
            </div>
        <PaginationControlled p = {page} lable="driver_in_out"/>
      </div>
      :<div class="d-flex flex-stack flex-wrap pt-10">
					<div className="fs-6 fw-semibold text-gray-700">
          {driversInOutData?.count!==0 && `Showing ${1+20*(page-1)} to ${20*page>driversInOutData?.count?driversInOutData?.count:20*page} of ${driversInOutData?.count} entries`}
            </div>
        <PaginationControlled p = {page} lable="driver_in_out"/>
      </div>}
    </div>
  );
}


export default DriverTracking
