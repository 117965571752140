import React, {useState, useEffect} from 'react'
import ViewDatePicker from "../../components/common/datePicker"
import {useNavigate, Link} from 'react-router-dom';
import {  NotificationManager } from 'react-notifications';
import {editPartnerInvoice, fetchAllAreas, fetchAllSorterDriver, fetchDrivers, updateInvoice}  from '../../actions';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Loader from "../../components/common/loader"
import InputSelect from '../../components/common/InputSelect';



const statusType = ["new", "paid", "unpaid", "partial", "void"];
function EditPartnerInvoice() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { partnerInvoice, loader } =  useSelector(({partner})=>partner);

  const initialValues ={
    "status": partnerInvoice?.status || 'new',
    "hideGst": partnerInvoice?.hide_gst || false
  }
  const validation = yup.object({
    status:yup.string()
    .required("Status Required"),
    hideGst: yup.boolean()   
  })
  const handleSubmit= (values)=>{
    if(values){
      const invoice = {
        "status": values.status,
        "hide_gst": values.hideGst
      }
       dispatch(editPartnerInvoice(invoice,navigate));
      
    }
    else NotificationManager.error('please fill data')
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const errorElement = document.getElementsByName(Object.keys(formik.errors)[0])[0];
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        errorElement.focus({ preventScroll: true });
    }
  }, [formik]);

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        {/*begin::Content container*/}
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
          <div className="card mb-5 mb-xl-10">
            {/* add new card element start */}
          <div className="card card-flush h-md-100">
          <div className="card-body">
            </div>                   
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
              style={{marginTop:"-45px"}}
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Update Partner Invoice</h3>
              </div>
            </div>
            </div>
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              <form id="kt_account_profile_details_form" className="form" onSubmit={formik.handleSubmit}>
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Status
                    </label>
                    <div className="col-lg-8 fv-row">                       
                       <SelectorStatus
                       name="status"
                       multi={false}
                          data={statusType.map((it)=>{
                            return {
                              value: it,
                              title: it
                          }}
                          )}
                          value = {formik.values?.status}
                          // value = {formik.values.area}
                          onChange = {(value)=>formik.setFieldValue("status", value)}
                          onBlur = {formik.handleBlur}
                        />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    {formik.touched.status && formik.errors.status ? <div>{formik.errors.status}</div> : null}</div></div>
                     </div>
                  </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Hide Gst
                  </label>
                  <div className="col-lg-8 fv-row">
                  <label className="form-check form-check-custom form-check-solid me-5">
                        <input className="form-check-input" 
                          type="checkbox" 
                          name="hideGst" 
                          onChange ={(e)=>formik.setFieldValue("hideGst", e.target.checked)}
                          checked={formik.values.hideGst} />
                      </label>                
                  </div>
                  {/*end::Col*/}
                </div>
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2"
                    onClick={()=>navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={loader}
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                  >
                    {loader ? <Loader size={17} />:"Update Invoice"}
                  </button>
                </div>

              </form>
              {/* )}
              </formik> */}
              {/*end::Form*/}
              
            </div>
            {/*end::Content*/}
          </div>
        </div>
        {/*end::Content container*/}
      </div>
     </>

  )
}

export default EditPartnerInvoice

const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px"
}

function Selector2({name="",data =[],style = {},onChange = ()=>{},value=""}) {
  const [type,setType] = useState(value);
  const handleChange = (e)=>{
    const selectedItems = e.target.selectedOptions
    const flavors = [];
    for (let i=0; i<selectedItems?.length; i++) {
        flavors.push(selectedItems[i].value);
    }
    setType(flavors);
    onChange(flavors);
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars"  style={{...styles,...style}} onChange={handleChange} multiple={false}>
      <option hidden selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value===op.value} key={index} value={op.value} >{op.title}</option>
      )}
    </select>
  )
}


function SelectorStatus({name="",data =[],style = {},onChange = ()=>{},value,multi=false}) {
  const [type,setType] = useState("");

  const handleChange = (e)=>{
    if(multi){
      const selectedItems = e.target.selectedOptions
      const flavors = [];
      for (let i=0; i<selectedItems?.length; i++) {
          flavors.push(selectedItems[i].value);
      }
      setType(flavors);
      onChange(flavors);
    }else{
      const {value} = e.target
      setType(value)
      onChange(value);
    }
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars"  style={{...styles,...style}} onChange={handleChange} class="form-select form-select-solid" mutiple={multi}>
      <option selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={multi?value.includes(op.value):value==op.value} key={index} value={op.value} >{op.title}</option>
      )}
    </select>
  )
}