import React,{useEffect, useState} from 'react'
import {NavLink,useLocation} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import {Outlet} from "react-router-dom"
import {fetchDrivers} from "../../actions"

function Report() {
  const [active,setActive] = useState(1);
  const dispatch = useDispatch();
  const {user, isLoggedIn} = useSelector((state)=>state.user) 

  useEffect(()=>{
    dispatch(fetchDrivers())
  },[])
  
  return (
    <div className="col-xl-8 mb-5 mb-xl-10" style={{width:"100%"}}>
    <div className="card card-flush h-md-100">
      <div className="card-body">
        {/*begin::Nav*/}
        <ul className="nav nav-pills nav-pills-custom mb-3">
          <li className="nav-item mb-3 me-3 me-lg-6">
          <NavLink to="dailyEntry" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style={({isActive})=>isActive ? setActive(1) : {}}>
            <div
              className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden h-85px pt-5 pb-2 active"
              data-bs-toggle="pill"
            >
              <div className="nav-icon mb-3">
              <i className="fonticon-calendar fs-2" />
              </div>
                <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Daily Entry</span>
              <span className={ active===1 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
            </div>
            </NavLink>
          </li>
          <li className="nav-item mb-3 me-3 me-lg-6">
          <NavLink to="stats" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style={({isActive})=>isActive ? setActive(5):{}}>
            <div
              className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden h-85px pt-5 pb-2 active"
              data-bs-toggle="pill"
            >
              <div className="nav-icon mb-3">
              <i className="bi bi-graph-up-arrow fs-2"/>
              </div>
                <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Stats</span>
              <span className={ active===5 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
            </div>
            </NavLink>
          </li>
          {user?.role !== 3 ? 
          <li className="nav-item mb-3 me-3 me-lg-6">
          <NavLink to="driverPackageIncome" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style={({isActive})=>isActive ? setActive(2):{}}>
            <div
              className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden h-85px pt-5 pb-2 active"
              data-bs-toggle="pill"
            >
              <div className="nav-icon mb-3">
              <i class="bi bi-piggy-bank fs-2" />
              </div>
                <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Driver/Sorter Package Income</span>
              <span className={ active===2 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
            </div>
            </NavLink>
          </li>
          : null}
          {user?.role !== 3 ? 
          <li className="nav-item mb-3 me-3 me-lg-6">
          <NavLink to="driverPackageInvoice" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style={({isActive})=>isActive ? setActive(7):{}}>
            <div
              className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden h-85px pt-5 pb-2 active"
              data-bs-toggle="pill"
            >
              <div className="nav-icon mb-3">
              <i class="bi bi-piggy-bank fs-2" />
              </div>
                <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Driver/Sorter Package Invoices</span>
              <span className={ active===7 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
            </div>
            </NavLink>
          </li>
          : null}
          {user.role !==3 ?
          <li className="nav-item mb-3 me-3 me-lg-6">
            <NavLink to="profitAndLoss" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style={({isActive})=>isActive ? setActive(3):{}}>
              <div
                className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden h-85px pt-5 pb-2 active"
                data-bs-toggle="pill"
              >
                <div className="nav-icon mb-3">
                <i className="bi bi-graph-up-arrow fs-2"/>
                </div>
                  <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Profit & Loss</span>
                <span className={ active===3 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
              </div>
              </NavLink>
            </li>
          : null}
          {user.role !==3 ?
            <li className="nav-item mb-3 me-3 me-lg-6">
            <NavLink to="DriverProfit" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style= {({isActive})=>isActive ? setActive(4):{}}>
              <div
                className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden h-85px pt-5 pb-2 active"
                data-bs-toggle="pill"
              >
                <div className="nav-icon mb-3">
                <i class="bi bi-wallet fs-2" />
                </div>
                  <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Driver Profit</span>
                <span className={ active===4 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
              </div>
              </NavLink>
            </li>
           : null}
           {user.role !==3 ?
            <li className="nav-item mb-3 me-3 me-lg-6">
            <NavLink to="reportComparison" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style= {({isActive})=>isActive ? setActive(6):{}}>
              <div
                className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden h-85px pt-5 pb-2 active"
                data-bs-toggle="pill"
              >
                <div className="nav-icon mb-3">
                <i class="fonticon-line-chart fs-2" />
                </div>
                  <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Comparison</span>
                <span className={ active===6? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" :""} />
              </div>
              </NavLink>
            </li>
           : null}
        </ul>
        <Outlet />
      </div>
    </div>
  </div>
  )
}

export default Report