import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Aside } from "../common";
// import {login} from '../../services/services'
import { login } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Loader from "../common/loader";

function LoginModal({ setAuth }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const { users, loader } = useSelector((state) => state.user);

  const initialValues = {
    email: "",
    password: "",
  };

  const validation = yup.object({
    email: yup.string().required("Username is required"),
    password: yup.string().required("Please enter a password"),
  });
  const handleSubmit = (values, { resetForm }) => {
    // alert(JSON.stringify(values))
    if (values.email && values.password) {
      //  const userLogin = users.filter((el)=>{
      //    return el.email === values.email && el.password === values.password
      // })
      const user = {
        email: values.email,
        password: values.password,
      };
      try {
        setIsProcessing(true);
        dispatch(login(user, navigate));
        setIsProcessing(false);
      } catch (error) {
        setError("Invalid details");
      }
    }
  };

  const [error, setError] = useState("");

  //* Re-rendered again as 2 state are update at same time
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    redirect: "",
    message: "",
  });

  return (
    <div
      className="d-flex flex-column flex-root"
      id="kt_app_root"
      style={{ height: "100vh" }}
    >
      {/*begin::Authentication - Sign-in */}

      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        {/*begin::Body*/}
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
          {/*begin::Form*/}
          <div>
          <img
                  className="d-lg-block mx-auto w-200px mb-10"
                  style={{
                    background: "none !important",
                    border: "0 !important",
                  }}
                  src="assets/media/images/img-logo.png"
                  alt=""
                />
          </div>
          <div className="d-flex flex-center flex-column flex-lg-row-fluid mb-10">
            <div className="w-lg-500px p-1 mb-1">
              <div className="d-flex flex-column mt-50">
                <h1 className="d-lg-block fs-2qx fw-bolder text-center mb-10">
                  Fast, Efficient and Productive Logistics
                </h1>
                <div className="text-gray-500 d-lg-block fs-base text-center  mb-8">
                  St. Mina Transport Web App is a private logistics management
                  system designed to improve the efficiency and productivity of
                  the company's operations, providing a fast and user-friendly
                  interface for managing transportation and delivery processes,
                  including tracking deliveries, scheduling pickups, and
                  monitoring fleet performance.
                </div>
              </div>
              {/* <br></br>
              <br></br> */}
              <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
              >
                {({ values, handleChange, handleBlur, errors, touched }) => (
                  <Form
                    className="form w-100 mb-20"
                    noValidate="novalidate"
                    id="kt_sign_in_form"
                    data-kt-redirect-url="../../demo23/dist/index.html"
                    action="#"
                  >
                    {/*begin::Heading*/}
                    <div className="text-center mb-11">
                      {/*begin::Title*/}
                      <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
                      {/*end::Title*/}
                      {/*begin::Subtitle*/}
                      {/* <div className="text-gray-500 fw-semibold fs-6">
                    Your Social Campaigns
                  </div> */}
                      {/*end::Subtitle=*/}
                    </div>
                    <div style={{ color: "red" }}>{error}</div>
                    {/*end::Separator*/}
                    {/*begin::Input group=*/}
                    <div className="fv-row mb-8">
                      {/*begin::Email*/}

                      <input
                        // type="text"
                        placeholder="Username"
                        name="email"
                        autoComplete="off"
                        className="form-control bg-transparent"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {/*end::Email*/}
                      {touched.email && errors.email ? (
                        <div style={{ color: "red" }}>{errors.email}</div>
                      ) : null}
                      <br />
                    </div>
                    {/*end::Input group=*/}
                    <div className="fv-row mb-3">
                      {/*begin::Password*/}
                      <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        autoComplete="off"
                        className="form-control bg-transparent"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {/*end::Password*/}
                      {touched.password && errors.password ? (
                        <div style={{ color: "red" }}>{errors.password}</div>
                      ) : null}
                      <br />
                    </div>
                    {/*end::Input group=*/}
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                      <div />
                      {/*begin::Link*/}
                      {/* <a
                    href="../../demo23/dist/authentication/layouts/corporate/reset-password.html"
                    className="link-primary"
                  >
                    Forgot Password ?
                  </a> */}
                      {/*end::Link*/}
                    </div>
                    {/*end::Wrapper*/}
                    {/*begin::Submit button*/}
                    <div className="d-grid mb-10">
                      <button
                        type="submit"
                        id="kt_sign_in_submit"
                        className="btn btn-primary"
                        // onClick={handleSubmit}
                        // onClick={()=>setAuth(true)}
                      >
                        {/*begin::Indicator label*/}
                        <span className="indicator-label">
                          {isProcessing || loader ? (
                            <Loader size={17} />
                          ) : (
                            "Sign In"
                          )}
                        </span>
                        {/*end::Indicator label*/}
                        {/*begin::Indicator progress*/}
                        <span className="indicator-progress">
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                        {/*end::Indicator progress*/}
                      </button>
                    </div>
                    {/*end::Submit button*/}
                    {/*begin::Sign up*/}
                    {/* <div className="text-gray-500 text-center fw-semibold fs-6">
                  Not a Member yet? */}
                    {/* <Link
                  to="/signup"
                    className="link-primary"
                  >
                    Sign up
                  </Link>
                </div> */}
                    {/*end::Sign up*/}
                  </Form>
                )}
              </Formik>
              {/*end::Form*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Form*/}
          {/*begin::Footer*/}
          <div className="d-flex flex-center flex-wrap px-5">
            {/*begin::Links*/}
            <div className="d-flex fw-semibold text-primary fs-base">
              <a
                href="../../demo23/dist/pages/team.html"
                className="px-5"
                target="_blank"
              >
                Terms
              </a>
              <a
                href="../../demo23/dist/pages/pricing/column.html"
                className="px-5"
                target="_blank"
              >
                Plans
              </a>
              <a
                href="../../demo23/dist/pages/contact.html"
                className="px-5"
                target="_blank"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
        {/*begin::Aside*/}
        <Aside />
      </div>
      {/* {openAlert ? <Alert setOpenAlert={setOpenAlert} alertMsg={alertMsg} data={data}/>: null} */}
    </div>
  );
}

export default LoginModal;
