
import React, {useState} from 'react';
import {NavLink, Outlet} from 'react-router-dom';


const Users = () => {
    const [active, setActive] = useState(0)

  return (
    
    <div className="col-xl-8 mb-5 mb-xl-10" style={{width:"100%"}} >
      
    <div className="card card-flush h-md-100">
      <div className="card-body">   
      <div style={{display:"flex", justifyContent:"space-between",  alignItems: 'center'}}>
     
       <ul className="nav nav-pills nav-pills-custom mb-3">
        {/*begin::Item*/}
        <li className="nav-item mb-3 me-3 me-lg-6">
          {/*begin::Link*/}
          {/* <a
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
            href="#kt_stats_widget_6_tab_1"
          > */}
            <NavLink to ="userList" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style ={({isActive})=>isActive ? setActive(1):{}}>
           <div
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
          >
            <div className="nav-icon mb-3" >
            <i className="bi bi-person fs-2"/>
            </div>
            <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Users</span>              
            <span className= {active === 1 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" : ""} />
            </div>
            </NavLink>

          {/* </a> */}
        </li>
        <li className="nav-item mb-3 me-3 me-lg-6">
        <NavLink to="addUser" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style ={({isActive})=> isActive ? setActive(2):{}}>
          <div
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
          >
            <div className="nav-icon mb-3">
            <i className="bi bi-plus-lg" />
            </div>
              <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Add new</span>
            <span className= {active === 2 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" : ""} />
          </div> 
          </NavLink>
        
        </li>
        </ul>        
        </div>
            
      <Outlet />
      </div>
      
      </div>
    </div>
  )
}

export default Users;