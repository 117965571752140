import React, { useEffect } from "react";
import { tableHeadsInOut } from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../components/common";
import { getExpenses } from "../../actions";
import { display } from "../../components/common/round";

function List() {
  const dispatch = useDispatch();
  let { expenses = [] } = useSelector(({ expenses }) => expenses);
  const { allExpenses = [] } = useSelector(({ expenses }) => expenses);

  const amount =
    allExpenses &&
    allExpenses?.rows
      ?.map((val) => {
        return val.amount;
      })
      .reduce((a, b) => a + b, 0);

  useEffect(() => {
    dispatch(getExpenses());
  }, []);

  return (
    <div className="tab-content">
      <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
        <div className="table-responsive">
          <div class="card-title flex-column">
            <h3 class="fw-bold mb-1">Driver In Out Timmings</h3>

          <div className="d-flex flex-wrap justify-content-start">
                 {/*<div class="d-flex flex-wrap">
                <div
                  className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  style={{ marginTop: 5 }}>
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">
                      Total Expense
                    </div>
                  </div>
                  <div
                    className="fs-2 fw-bold text-danger"
                    style={{ textAlign: "center", paddingTop: 5 }}>
                    {amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                  </div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" style={{ marginTop: 5 }}>
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">
                      List of Expenses
                    </div>
                  </div>
                  <div className="fs-2 fw-bold text-success" style={{ textAlign: "center", paddingTop: 5 }}>
                    {allExpenses.count}
                  </div>
                </div>

                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" style={{ marginTop: 5 }}>
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">
                      Total Unpaid
                    </div>
                  </div>
                  <div className="fs-2 fw-bold" style={{ textAlign: "center", paddingTop: 5 ,"color": "#f0416c"}}>
                  {allExpenses?.rows.filter(row => row.status === 'unpaid').length}
                  </div>
                </div>

                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" style={{ marginTop: 5 }}>
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">
                      Total New
                    </div>
                  </div>
                  <div className="fs-2 fw-bold" style={{ textAlign: "center", paddingTop: 5, "color": "#7339ea" }}>
                  {allExpenses?.rows.filter(row => row.status === 'new').length}
                  </div>
                </div>

                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" style={{ marginTop: 5 }}>
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">
                      Total Paid
                    </div>
                  </div>
                  <div className="fs-2 fw-bold" style={{ textAlign: "center", paddingTop: 5 ,"color": "#75cc67" }}>
                  {allExpenses?.rows.filter(row => row.status === 'paid').length}
                  </div>
                </div>

                
              </div>*/}
            </div> 
          </div>

          <hr />
          <Table
            lable="InOutTimmings"
            tableRows={allExpenses?.rows}
            tableHeads={tableHeadsInOut}
          />
        </div>
      </div>
    </div>
  );
}

export default List;
