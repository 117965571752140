import { BrowserRouter } from 'react-router-dom';
import AppRoute from "./Routes/index";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


function App() {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <BrowserRouter>
          <AppRoute />
          <NotificationContainer />
        </BrowserRouter>
      </Provider>
    </PersistGate>
  );
}

export default App;
