import React, { useState, useEffect, useRef } from "react";
import Selector from "../../components/common/Selector";
import ViewDatePicker from "../../components/common/datePicker";
import { useNavigate, Link } from "react-router-dom";
import {
  validator,
  name,
  num,
  email,
  phoneRegExp,
} from "../../helpers/validation";
import { NotificationManager } from "react-notifications";
import { createPartner, uploadDriverDocument } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { categoryData, status } from "../../constants/expenses";
import Loader from "../../components/common/loader";
import InputSelect from "../../components/common/InputSelect";

const statusType = [
  {
    id: "inactive",
    value: "inactive",
    title: "InActive",
  },
  {
    id: "active",
    value: "active",
    title: "Active",
  },
];

function AddPartner({ setDriverDetail }) {
  // const [isProcessing, setIsProcessing] = useState(false)
  const contracterLogo = useRef(null);
  const partnerLogo = useRef(null);
  const [Document, setDocument] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { partner, loader } = useSelector(({ partner }) => partner);

  const initialValues = {
    name: "",
    abn: 0,
    address:"MAIL CONTRACTS-NSW Mail Contract Management  GPO Box 1777MELBOURNE VIC 3001",
    partner_logo:"",
    contractor_name: '',
    contractor_logo: '',
    contractor_no: 0,
    contractor_abn: null,
    contractor_address:
      "St Mina Transport Pty Ltd 4A SALTER ROAD BOSSLEY PARK NSW 2176",
    status: "",
  };
  const validation = yup.object({
    name: yup.string().required("partner name is required"),
    abn: yup
      .string()
      .typeError("must be a number")
      // .required("abn is required")
      .matches(num, "Please provide 11 digit abn number")
      .min(11, "partner abn number must not be less than 11 digits")
      .max(11, "partner abn number must not be greater than 11 digits")
      .required(),
    address: yup.string(),
    partner_logo: yup.string(),
    contractor_name: yup.string(),
    contractor_logo: yup.string(),
    contractor_no: yup.number(),
    contractor_abn: yup
      .string()
      .nullable()
      .typeError("must be a number")
      .matches(num, "Please provide 11 digit abn number")
      .min(11, "contractor abn number must not be less than 11 digits")
      .max(11, "contractor abn number must not be greater than 11 digits"),
    contractor_address: yup.string(),
    status: yup.string().required("status is required"),
  });
  const handleSubmit = (values, { resetForm }) => {
    if (values) {
      dispatch(createPartner(values, navigate));
    } else NotificationManager.error("please fill data");
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const errorElement = document.getElementsByName(
        Object.keys(formik.errors)[0]
      )[0];
      errorElement?.scrollIntoView({ behavior: "smooth", block: "center" });
      errorElement?.focus({ preventScroll: true });
    }
  }, [formik]);


  const handleDocuments = async (setFieldValue, fieldName) => {
    const data = new FormData();
    data.append("file", Document);
    const response = await uploadDriverDocument(data);
    setFieldValue(fieldName, response);
    setDocument("");
  };

  const handleChangeDocument = async (e, setFieldValue, fieldName) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const response = await uploadDriverDocument(data);
    const updateData = response
  
    setFieldValue(fieldName, updateData);
  };

  const handleDeleteDocument = (setFieldValue, fieldName) => {
    setFieldValue(fieldName, "");
  };

  const handleContracterLogoClick = () => {
    contracterLogo.current.click();
  };

  const handlePartnerLogoClick = () => {
    partnerLogo.current.click();
  };

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        {/*begin::Content container*/}
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
          <div className="card mb-5 mb-xl-10">
            {/* add new card element start */}
            <div className="card card-flush h-md-100">
              <div className="card-body"></div>
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
                style={{ marginTop: "-45px" }}
              >
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Add Partner</h3>
                </div>
              </div>
            </div>
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              <form
                id="kt_account_profile_details_form"
                className="form"
                onSubmit={formik.handleSubmit}
              >
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
                  {/* <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Partner Name
                      </label>
                      <div className="col-lg-8 fv-row">
                            <Selector 
                            name="expense_category"
                            style={{height:"50px"}}
                              data={catOptions.map((it)=>{return {it:it.id, title:it.category, value: it.id}})}
                            onChange={(value)=>formik.setFieldValue("expense_category",value)}
                            value={formik.values.expense_category}
                            onBlur={formik.handleBlur}
                          />
                        <div class="fv-plugins-message-container invalid-feedback">
                          <div data-field="fname" data-validator="notEmpty">{formik.touched.expense_category && formik.errors.expense_category? formik.errors.expense_category: ""}
                          </div></div>
                      </div>
                  </div> */}
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Partner Name
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="name"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Partner Name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="name" data-validator="notEmpty">
                          {formik.touched.name && formik.errors.name ? (
                            <div>{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      ABN
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="number"
                        name="abn"
                        onKeyDown={(e) =>
                          ["+", "-"].includes(e.key) && e.preventDefault()
                        }
                        className="form-control form-control-lg form-control-solid"
                        placeholder="abn"
                        onChange={formik.handleChange}
                        value={formik.values.abn}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">
                          {formik.touched.abn && formik.errors.abn ? (
                            <div>{formik.errors.abn}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/*end::Col*/}
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Partner Address
                    </label>
                    <div className="col-lg-8 fv-row">
                      <textarea
                        type="text"
                        name="address"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Partner Address"
                        onChange={formik.handleChange}
                        value={formik.values.address}
                        onBlur={formik.handleBlur}
                        rows={4}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="name" data-validator="notEmpty">
                          {formik.touched.address && formik.errors.address ? (
                            <div>{formik.errors.address}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-semibold fs-6">
                        Partner Logo
                      </label>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-9 fv-row">
                            <input
                              name="partner_logo"
                              type="text"
                              value={
                                Document && Document.name
                                  ? Document?.name
                                  : "Select File"
                              }
                              className="form-control form-control-lg form-control-solid"
                              placeholder="select partner logo"
                              onClick={handlePartnerLogoClick}
                            />

                            <input
                              style={{ display: "none" }}
                              name="partner_logo"
                              type="file"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Scanner User Name"
                              onChange={(e) => setDocument(e.target.files[0])}
                              onBlur={formik.handleBlur}
                              ref={partnerLogo}
                            />
                            <div class="fv-plugins-message-container invalid-feedback">
                              <div data-field="partner_logo" data-validator="notEmpty">
                                {formik.touched.partner_logo &&
                                formik.errors.partner_logo ? (
                                  <div>{formik.errors.partner_logo}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-lg fv-row">
                            <button
                              type="button"
                              onClick={() =>
                                handleDocuments(formik.setFieldValue, "partner_logo")
                              }
                              className="btn btn-primary"
                              style={{ width: "100%" }}
                              disabled={Document !== "" ? false : true}
                            >
                             Upload Logo
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                    {formik.values.partner_logo? (
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                          Selected Files
                        </label>
                        <div className="col-lg-8 d-flex flex-wrap justify-content-start">
                          {formik.values.partner_logo?  
                          <div
                            className="col-lg-3"
                            style={{ margin: "20px 10px" }}
                          >
                            {/*begin::Image input*/}
                            <div className="image-input image-input-outline">
                              {/*begin::Preview existing avatar*/}
                              <div/>
                              <img src={process.env.REACT_APP_API_BASE_URL_IMAGE+formik.values.partner_logo} 
                              className="image-input-wrapper w-125px h-125px"/>
                              {/*end::Preview existing avatar*/}
                              {/*begin::Label*/}
                              <label
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                title="Change avatar"
                              >
                                <i className="bi bi-pencil-fill fs-7" />
                                {/*begin::Inputs*/}
                                <input
                                  type="file"
                                  name="avatar"
                                  accept=".png, .jpg, .jpeg"
                                  onChange={(e) =>
                                    handleChangeDocument(e, formik.setFieldValue, "partner_logo")
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="avatar_remove"
                                />
                                {/*end::Inputs*/}
                              </label>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                title="Remove avatar"
                                onClick={() => handleDeleteDocument(formik.setFieldValue, 'partner_logo')}
                              >
                                <i className="bi bi-x fs-2" />
                              </span>
                              {/*end::Remove*/}
                            </div>
                            {/*end::Image input*/}
                            {/*end::Col*/}
                          </div>
                            : "No Result Found!"}
                        </div>
                      </div>
                    ) : null}

                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Contractor Name.
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="contractor_name"
                        onKeyDown={(e) =>
                          ["+", "-"].includes(e.key) && e.preventDefault()
                        }
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Contractor Name"
                        onChange={formik.handleChange}
                        value={formik.values.contractor_name}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="contractor_name" data-validator="notEmpty">
                          {formik.touched.contractor_name &&
                          formik.errors.contractor_name ? (
                            <div>{formik.errors.contractor_name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/*end::Col*/}
                  </div>

                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Contractor No.
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="number"
                        name="contractor_no"
                        onKeyDown={(e) =>
                          ["+", "-"].includes(e.key) && e.preventDefault()
                        }
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Contractor No"
                        onChange={formik.handleChange}
                        value={formik.values.contractor_no}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">
                          {formik.touched.contractor_no &&
                          formik.errors.contractor_no ? (
                            <div>{formik.errors.contractor_no}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/*end::Col*/}
                  </div>

                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Contractor ABN
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="number"
                        name="contractor_abn"
                        onKeyDown={(e) =>
                          ["+", "-"].includes(e.key) && e.preventDefault()
                        }
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Contractor ABN"
                        onChange={formik.handleChange}
                        value={formik.values.contractor_abn}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">
                          {formik.touched.contractor_abn &&
                          formik.errors.contractor_abn ? (
                            <div>{formik.errors.contractor_abn}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/*end::Col*/}
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Contractor Address
                    </label>
                    <div className="col-lg-8 fv-row">
                      <textarea
                        type="text"
                        name="contractor_address"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Contractor Address"
                        onChange={formik.handleChange}
                        value={formik.values.contractor_address}
                        onBlur={formik.handleBlur}
                        rows={4}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="name" data-validator="notEmpty">
                          {formik.touched.contractor_address &&
                          formik.errors.contractor_address ? (
                            <div>{formik.errors.contractor_address}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Status
                    </label>
                    <div className="col-lg-8 fv-row">
                      <Selector
                        name="status"
                        style={{ height: "50px" }}
                        data={statusType.map((it) => {
                          return { it: it.id, title: it.title, value: it.id };
                        })}
                        onChange={(value) =>
                          formik.setFieldValue("status", value)
                        }
                        value={formik.values.status}
                        onBlur={formik.handleBlur}
                      />
                      <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">
                          {formik.touched.status && formik.errors.status
                            ? formik.errors.status
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-semibold fs-6">
                        Contracter Logo
                      </label>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-9 fv-row">
                            <input
                              name="contractor_logo"
                              type="text"
                              value={
                                Document && Document.name
                                  ? Document?.name
                                  : "Select File"
                              }
                              className="form-control form-control-lg form-control-solid"
                              placeholder="select contracter logo"
                              onClick={handleContracterLogoClick}
                            />

                            <input
                              style={{ display: "none" }}
                              name="contractor_logo"
                              type="file"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Scanner User Name"
                              onChange={(e) => setDocument(e.target.files[0])}
                              onBlur={formik.handleBlur}
                              ref={contracterLogo}
                            />
                            <div class="fv-plugins-message-container invalid-feedback">
                              <div data-field="contractor_logo" data-validator="notEmpty">
                                {formik.touched.contractor_logo &&
                                formik.errors.contractor_logo ? (
                                  <div>{formik.errors.contractor_logo}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-lg fv-row">
                            <button
                              type="button"
                              onClick={() =>
                                handleDocuments(formik.setFieldValue, "contractor_logo")
                              }
                              className="btn btn-primary"
                              style={{ width: "100%" }}
                              disabled={Document !== "" ? false : true}
                            >
                             Upload Logo
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                    {formik.values.contractor_logo? (
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                          Selected Files
                        </label>
                        <div className="col-lg-8 d-flex flex-wrap justify-content-start">
                          {formik.values.contractor_logo?  
                          <div
                            className="col-lg-3"
                            style={{ margin: "20px 10px" }}
                          >
                            {/*begin::Image input*/}
                            <div className="image-input image-input-outline">
                              {/*begin::Preview existing avatar*/}
                              <div/>
                              <img src={process.env.REACT_APP_API_BASE_URL_IMAGE+formik.values.contractor_logo} className="image-input-wrapper w-125px h-125px"/>
                              {/*end::Preview existing avatar*/}
                              {/*begin::Label*/}
                              <label
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                title="Change avatar"
                              >
                                <i className="bi bi-pencil-fill fs-7" />
                                {/*begin::Inputs*/}
                                <input
                                  type="file"
                                  name="avatar"
                                  accept=".png, .jpg, .jpeg"
                                  onChange={(e) =>
                                    handleChangeDocument(e, formik.setFieldValue, "contractor_logo")
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="avatar_remove"
                                />
                                {/*end::Inputs*/}
                              </label>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                title="Remove avatar"
                                onClick={() => handleDeleteDocument(formik.setFieldValue, "partner_logo")}
                              >
                                <i className="bi bi-x fs-2" />
                              </span>
                              {/*end::Remove*/}
                            </div>
                            {/*end::Image input*/}
                            {/*end::Col*/}
                          </div>
                            : "No Result Found!"}
                        </div>
                      </div>
                    ) : null}
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={loader}
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    // onClick={handleSubmit}
                  >
                    {loader ? <Loader size={17} /> : "Add Partner"}
                  </button>
                  <button
                    type="reset"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    style={{ marginLeft: 10 }}
                    onClick={() =>
                      formik.resetForm({
                        values: {
                          name: "",
                          abn: 0,
                          address:"MAIL CONTRACTS-NSW Mail Contract Management  GPO Box 1777MELBOURNE VIC 3001",
                          partner_logo:"",
                          contractor_name: '',
                          contractor_logo: '',
                          contractor_no: 0,
                          contractor_abn: null,
                          contractor_address:
                            "St Mina Transport Pty Ltd 4A SALTER ROAD BOSSLEY PARK NSW 2176",
                          status: "",
                        }
                      })
                    }
                  >
                    <i class="bi bi-arrow-repeat" style={{ fontSize: 25 }}></i>
                  </button>
                </div>
              </form>
              {/* )}
              </formik> */}
              {/*end::Form*/}
            </div>
            {/*end::Content*/}
          </div>
        </div>
        {/*end::Content container*/}
      </div>
    </>
  );
}

export default AddPartner;

const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px",
};

function Selector2({
  name = "",
  data = [],
  style = {},
  onChange = () => {},
  value = "",
}) {
  const [type, setType] = useState(value);
  const handleChange = (e) => {
    const selectedItems = e.target.selectedOptions;
    const flavors = [];
    for (let i = 0; i < selectedItems?.length; i++) {
      flavors.push(selectedItems[i].value);
    }
    setType(flavors);
    onChange(flavors);
  };

  //option attributes --> hidden,disabled
  return (
    <select
      name={name}
      id="cars"
      style={{ ...styles, ...style }}
      onChange={handleChange}
      multiple={false}
    >
      <option hidden selected={value === ""} value="">
        {" "}
        choose one{" "}
      </option>
      {data?.length &&
        data.map((op, index) => (
          <option
            className="col-lg-8 fv-row fw-semibold fs-6"
            selected={value === op.value}
            key={index}
            value={op.value}
          >
            {op.title}
          </option>
        ))}
    </select>
  );
}
