import React from 'react'
import NewSidebar from "./newSidebar";

function SideBar({onLogout}) {
  
  return (
    <div>
        <div
            id="kt_app_sidebar"
            className="app-sidebar flex-column"
            data-kt-drawer="true"
            data-kt-drawer-name="app-sidebar"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="275px"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_app_sidebar_toggle"
        >
            <NewSidebar />
            {/* <div
                className="flex-column-auto d-flex flex-center px-4 px-lg-8 py-3 py-lg-8"
                id="kt_app_sidebar_footer"
            > */}
                {/* <div className="app-footer-item">
                    <NavLink to="/settings" className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px">
                        <i className="fonticon-settings-1 fs-2" />
                    </NavLink>
                </div> */}
            {/* </div> */}
        </div>
    </div>
  )
}

export default SideBar