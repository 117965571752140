import React, {useState, useEffect} from 'react'
import Selector from "../../components/common/Selector"
import ViewDatePicker from "../../components/common/datePicker"
import {useNavigate, Link} from 'react-router-dom';
import {validator, name, num, email, phoneRegExp} from "../../helpers/validation"
import {  NotificationManager } from 'react-notifications';
import {addDriver, addInvoice, fetchAllAreas, fetchAllSorterDriver, fetchDrivers, updateInvoice}  from '../../actions';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {categoryData, status} from "../../constants/expenses";
import Loader from "../../components/common/loader"
import Selector4 from '../../components/common/Selector4';
import InputSelect from '../../components/common/InputSelect';
import { driverAndSorterInvoiceStatus } from '../../constants';

const type=[
"employee",
"contractor"
]

function EditInvoice({setDriverDetail}) {
  // const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {areas,options} = useSelector(({area})=>area);
  const {loader} = useSelector(({driver})=>driver)
  // const {category} = useSelector(({expenses})=>expenses)
  const {selected_invoice} = useSelector(({invoices})=>invoices)
  const {drivers,sorterDrivers} = useSelector(({driver})=>driver)
  const {rows : data} = sorterDrivers
  const {options: catOptions, loader: catLoader} = useSelector(({category})=> category);

  useEffect(() => {
    dispatch(fetchAllAreas());
     // dispatch(fetchDrivers());
     dispatch(fetchAllSorterDriver())//get all drivers 
  }, [])
  

  const initialValues =
  {
    "area": selected_invoice?.area?.id,
    "invoice_date": selected_invoice?.invoice_date,
    "driver": selected_invoice?.driver_id,
    "expense_category": selected_invoice?.expense_category?.id,
    "amount": selected_invoice?.amount,
    "status": selected_invoice.status
  }
    const validation = yup.object({
      area:yup.number().min(1, "Must select atleast one the given options"),
      invoice_date:yup.date()
      .required("Please select date"),
      driver:yup.string()
      .required("Required"),
      expense_category: yup.string()
      .required('Required'),
      amount:yup.number().positive()
      .typeError("must be a number")
      .required("amount is required"),
      status:yup.string()
      .required("status is required")        
  })
  const handleSubmit= (values)=>{
    if(values){
      const invoice = {
        "id": selected_invoice.id,
        "area_id": +values.area,
        "invoice_date": values.invoice_date,
        "driver_id": +values.driver,
        "expense_category": values.expense_category,
        "amount": values.amount,
        "status": values.status
      }
       dispatch(updateInvoice(invoice,navigate));
      
    }
    else NotificationManager.error('please fill data')
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const errorElement = document.getElementsByName(Object.keys(formik.errors)[0])[0];
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        errorElement.focus({ preventScroll: true });
    }
  }, [formik]);

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        {/*begin::Content container*/}
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
          <div className="card mb-5 mb-xl-10">
            {/* add new card element start */}
          <div className="card card-flush h-md-100">
          <div className="card-body">
            </div>                   
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
              style={{marginTop:"-45px"}}
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Update Invoice</h3>
              </div>
            </div>
            </div>
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              <form id="kt_account_profile_details_form" className="form" onSubmit={formik.handleSubmit}>
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
                  <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Category
                      </label>
                      <div className="col-lg-8 fv-row">
                            <Selector4
                            name="expense_category"
                            style={{height:"50px"}}
                            data={catOptions.map((it)=>{return {it:it.id, title:it.category}})}
                            onChange={(value)=>formik.setFieldValue("expense_category",value)}
                            value={formik?.values?.expense_category}
                            // value={formik.values.expense_category}
                            onBlur={formik.handleBlur}
                          />
                        <div class="fv-plugins-message-container invalid-feedback">
                          <div data-field="fname" data-validator="notEmpty">{formik.touched.expense_category && formik.errors.expense_category? formik.errors.expense_category: ""}
                          </div></div>
                      </div>
                  </div>
                  <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Amount
                      </label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="number"
                          name="amount"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Amount"
                          onChange={formik.handleChange}
                          value= { formik.values.amount || selected_invoice.amount}
                          onBlur={formik.handleBlur}
                        />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                      {formik.touched.amount && formik.errors.amount ? <div>{formik.errors.amount}</div> : null}
                        </div></div>
                      </div>
                    </div>
                    <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Driver
                    </label>
                    <div className="col-lg-8 fv-row" style={{position:'relative'}}>
                    {/* <SelectorDriver data={data.map((it)=>{
                      return {value:it.id, title:it.firstName+" "+it.lastName}
                    })} 
                      onChange={(value)=>formik.setFieldValue("driver",value)}
                      value={selected_invoice.driver_id}
                      // value={formik.values.driver}
                      style={{height:"50px"}}
                      name="driver"
                      onBlur={formik.handleBlur}
                    /> */}
                     <InputSelect
                      data={
                        data.length
                          ? data.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it.driverType
                              };
                            })
                          : ["Loadin..."]
                      }
                      onChange={(value) =>
                        formik.setFieldValue("driver", value)
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.driver}
                    />
                    <div class="fv-plugins-message-container invalid-feedback">
                        <div data-field="fname" data-validator="notEmpty">{formik.touched.driver && formik.errors.driver ?formik.errors.driver: ""}</div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    Invoice Date
                    </label>
                    <div className="col-lg-8 fv-row">
                    <ViewDatePicker 
                        className="form-control form-control-lg form-control-solid" 
                        type="date"
                        id="invoice_date" 
                        name="invoice_date"
                        onChange={(value)=>formik.setFieldValue("invoice_date", value.toISOString())}
                        // value={formik.values.invoice_date.substring(0,10)}
                        value={selected_invoice?.invoice_date?.substring(0,10)}
                        onBlur={formik.handleBlur}
                       /> 
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                      {formik.touched.invoice_date && formik.errors.invoice_date ? <div>{formik.errors.invoice_date}</div> : null}
                      </div></div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Area
                    </label>
                    <div className="col-lg-8 fv-row">                       
                       <SelectorArea
                       name="area"
                       multi={false}
                          data={options.map((it)=>{
                            return {
                              value: it.id,
                              title: it.areaName
                          }}
                          )}
                          value = {formik.values?.area}
                          // value = {formik.values.area}
                          onChange = {(value)=>formik.setFieldValue("area", value)}
                          onBlur = {formik.handleBlur}
                        />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    {formik.touched.area && formik.errors.area ? <div>{formik.errors.area}</div> : null}</div></div>
                     </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      Status
                    </label>
                    <div className="col-lg-8 fv-row">                       
                       <Selector
                       name="status"
                       multi={false}
                          data={driverAndSorterInvoiceStatus.map((it)=>{
                            return {
                              value: it,
                              title: it
                          }}
                          )}
                          value = {formik.values.status}
                          onChange = {(value)=>formik.setFieldValue("status", value)}
                          onBlur = {formik.handleBlur}
                        />
                      <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    {formik.touched.status && formik.errors.status ? <div>{formik.errors.status}</div> : null}</div></div>
                     </div>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2"
                    onClick={()=>navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={loader}
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    // onClick={handleSubmit}
                  >
                    {loader ? <Loader size={17} />:"Generate Invoice"}
                  </button>
                  <button
                  type="reset"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  style={{marginLeft:10}}
                  onClick={() => formik.resetForm({
                    values:   {
                      "area": "",
                      "invoice_date": "",
                      "driver_id": 0,
                      "expense_category": "",
                      "amount": 0,
                    }
                    })
                    }>
                  <i class="bi bi-arrow-repeat" style ={{fontSize:25}}></i>              
                 
              </button>
                </div>

              </form>
              {/* )}
              </formik> */}
              {/*end::Form*/}
              
            </div>
            {/*end::Content*/}
          </div>
        </div>
        {/*end::Content container*/}
      </div>
     </>

  )
}

export default EditInvoice

const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px"
}

function Selector2({name="",data =[],style = {},onChange = ()=>{},value=""}) {
  const [type,setType] = useState(value);
  const handleChange = (e)=>{
    const selectedItems = e.target.selectedOptions
    const flavors = [];
    for (let i=0; i<selectedItems?.length; i++) {
        flavors.push(selectedItems[i].value);
    }
    setType(flavors);
    onChange(flavors);
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars"  style={{...styles,...style}} onChange={handleChange} multiple={false}>
      <option hidden selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={value===op.value} key={index} value={op.value} >{op.title}</option>
      )}
    </select>
  )
}


function SelectorArea({name="",data =[],style = {},onChange = ()=>{},value,multi=false}) {
  const [type,setType] = useState("");

  const handleChange = (e)=>{
    if(multi){
      const selectedItems = e.target.selectedOptions
      const flavors = [];
      for (let i=0; i<selectedItems?.length; i++) {
          flavors.push(selectedItems[i].value);
      }
      setType(flavors);
      onChange(flavors);
    }else{
      const {value} = e.target
      setType(value)
      onChange(value);
    }
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars"  style={{...styles,...style}} onChange={handleChange} class="form-select form-select-solid" mutiple={multi}>
      <option selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={multi?value.includes(op.value):value==op.value} key={index} value={op.value} >{op.title}</option>
      )}
    </select>
  )
}



function SelectorDriver({name="",data =[],style = {},onChange = ()=>{},value,multi=false}) {
  const [type,setType] = useState("");
  const handleChange = (e)=>{
    if(multi){
      const selectedItems = e.target.selectedOptions
      const flavors = [];
      for (let i=0; i<selectedItems?.length; i++) {
          flavors.push(selectedItems[i].value);
      }
      setType(flavors);
      onChange(flavors);
    }else{
      const {value} = e.target
      setType(value)
      onChange(value);
    }
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars"  style={{...styles,...style}} onChange={handleChange} class="form-select form-select-solid" mutiple={multi}>
      <option selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={multi?value.includes(op.value):value==op.value} key={index} value={op.value} >{op.title}</option>
      )}
    </select>
  )
}