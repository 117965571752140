import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {Selector} from "../../components/common"
import { validator } from "../../helpers/validation";
import {
  updateExpenses,
  fetchAllDriver,
  fetchAllCategoriesss,
  uploadDriverDocument,
  fetchAllSorterDriver,
} from "../../actions";
import { categoryData, status } from "../../constants/expenses";
import ViewDatePicker from "../../components/common/datePicker";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { fixedValue } from "../../components/common/round";
import NotificationManager from "react-notifications";
import Loader from "../../components/common/loader";
import { FileExtension } from "../../components/common/extenstion";
import Modal from 'react-modal';
import InputSelect from "../../components/common/InputSelect";

function Edit() {
  const receiptDocument = useRef(null);
  const [Document, setDocument] = useState("");
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { drivers } = useSelector(({ driver }) => driver);
  // const { rows: data } = drivers;
  const { sorterDrivers } = useSelector(({ driver }) => driver);
  const { rows: data } = sorterDrivers;
  const { expense = {}, loader } = useSelector(({ expenses }) => expenses);
  const { options } = useSelector(({ category }) => category);
  const [open,setOpen]=useState(false)
  useEffect(() => {
    // dispatch(fetchAllDriver());
    dispatch(fetchAllSorterDriver())
    dispatch(fetchAllCategoriesss());
  }, []);

  const initialValues = {
    date: expense.date,
    category: expense?.expense_category.id,
    amount: expense.amount,
    status: expense.status,
    driver: expense.driver.id,
    description: expense.description,
    receiptDocuments:expense.receiptDocuments
  };
  const FormValidation = Yup.object({
    date: Yup.date().required("Required"),
    description: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    amount: Yup.number().typeError("must be a number").required("Required"),
    status: Yup.string().required("Required"),
    driver: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const expenses = {
      amount: values.amount,
      category: values.category,
      date: values.date,
      description: values.description,
      status: values.status,
      driver_id: values.driver,
      receiptDocuments: values.receiptDocuments
    };
    dispatch(updateExpenses(expenses, navigate));
    NotificationManager.success("Successfullly updated Expenses");
  };

  const handleDocuments = async (setFieldValue, values) => {
    const data = new FormData();
    data.append("file", Document);
    const response = await uploadDriverDocument(data);
    setFieldValue("receiptDocuments", [...values.receiptDocuments, response]);
    setDocument("");
  };

  const handleChangeDocument = async (e, ind, setFieldValue, values) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const response = await uploadDriverDocument(data);
    const updateData = values.receiptDocuments.map((ele, ind1) =>
      ind1 == ind ? response : ele
    );
    setFieldValue("receiptDocuments", updateData);
  };

  const handleDeleteDocument = (ind, setFieldValue, values) => {
    const updateData =
      values.receiptDocuments?.length &&
      values.receiptDocuments.filter((ele, ind1) => ind1 != ind);
    setFieldValue("receiptDocuments", updateData);
  };

  const handleDriverDocumentClick = () => {
    receiptDocument.current.click();
  };


  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ padding: "0px !important" }}
      >
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{ padding: 0 }}
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Edit Expenses</h3>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={FormValidation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                resetForm,
                handleBlur,
                setFieldValue,
              }) => (
                <Form id="kt_account_profile_details_form" className="form">
                  {/* <div className="card-body border-top p-9"> */}
                  <div className="card-body border-top p-9">
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Date
                      </label>
                      <div className="col-lg-8 fv-row">
                        <ViewDatePicker
                          className="form-control form-control-lg form-control-solid"
                          type="text"
                          id="date"
                          name="date"
                          onChange={(value) =>
                            setFieldValue("date", value.toISOString())
                          }
                          value={values.date}
                          onBlur={handleBlur}
                          style={{ paddingLeft: 10 }}
                        />
                        <div class="fv-plugins-message-container invalid-feedback">
                          <div data-field="fname" data-validator="notEmpty">
                            {touched.date && errors.date ? (
                              <div>{errors.date}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Category
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Selector
                          name="category"
                          style={{ height: "50px" }}
                          data={options.map((it) => {
                            return { value: it.id, title: it.category };
                          })}
                          value={values.category}
                          onChange={(value) => setFieldValue("category", value)}
                          onBlur={handleBlur}
                        />
                        <div class="fv-plugins-message-container invalid-feedback">
                          <div data-field="fname" data-validator="notEmpty">
                            {touched.category && errors.category ? (
                              <div>{errors.category}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Amount
                      </label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="number"
                          name="amount"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Enter Amount"
                          onChange={handleChange}
                          value={values.amount}
                          onBlur={handleBlur}
                        />
                        <div class="fv-plugins-message-container invalid-feedback">
                          <div data-field="fname" data-validator="notEmpty">
                            {touched.amount && errors.amount ? (
                              <div>{errors.amount}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Status
                      </label>
                      <div className="col-lg-8 fv-row">
                        <Selector
                          name="status"
                          style={{ height: "50px" }}
                          data={status.map((it) => {
                            return { value: it, title: it };
                          })}
                          value={values.status}
                          onChange={(value) => setFieldValue("status", value)}
                          onBlur={handleBlur}
                        />
                        <div class="fv-plugins-message-container invalid-feedback">
                          <div data-field="fname" data-validator="notEmpty">
                            {touched.status && errors.status ? (
                              <div>{errors.status}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Driver
                      </label>
                      <div className="col-lg-8 fv-row position-relative">
                        {/* <Selector
                          name="driver"
                          style={{ height: "50px" }}
                          data={data.map((it) => {
                            return {
                              value: it.id,
                              title: it.firstName + " " + it.lastName,
                            };
                          })}
                          value={values.driver}
                          onChange={(value) => setFieldValue("driver", value)}
                          onBlur={handleBlur}
                        /> */}
                        <InputSelect
                        name="driver"
                        data={
                        data.length
                          ? data.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it?.driverType
                              };
                            })
                          : ["Loadin..."]
                        }
                        onChange={(value) => setFieldValue("driver", value)}
                        value ={values.driver}
                        />
                        <div class="fv-plugins-message-container invalid-feedback">
                          <div data-field="fname" data-validator="notEmpty">
                            {touched.driver && errors.driver ? (
                              <div>{errors.driver}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Description
                      </label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          name="description"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Enter Description"
                          onChange={handleChange}
                          value={values.description}
                          onBlur={handleBlur}
                        />
                        <div class="fv-plugins-message-container invalid-feedback">
                          <div data-field="fname" data-validator="notEmpty">
                            {touched.description && errors.description ? (
                              <div>{errors.description}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Receipt Documents
                      </label>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-9 fv-row">
                            <input
                              name="driverDocuments"
                              type="text"
                              value={
                                Document && Document.name
                                  ? Document?.name
                                  : "Select File"
                              }
                              className="form-control form-control-lg form-control-solid"
                              placeholder="select driver document"
                              onClick={handleDriverDocumentClick}
                              disabled ={values?.receiptDocuments?.length>0}
                            />

                            <input
                              style={{ display: "none" }}
                              name="driverDocuments"
                              type="file"
                              accept="image/*,.pdf"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Scanner User Name"
                              onChange={(e) => setDocument(e.target.files[0])}
                              onBlur={handleBlur}
                              ref={receiptDocument}
                            />
                            <div class="fv-plugins-message-container invalid-feedback">
                              <div data-field="fname" data-validator="notEmpty">
                                {touched.receiptDocuments &&
                                errors.receiptDocuments ? (
                                  <div>{errors.receiptDocuments}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-lg fv-row">
                            <button
                              type="button"
                              onClick={() =>
                                handleDocuments(setFieldValue, values)
                              }
                              className="btn btn-primary"
                              style={{ width: "100%" }}
                              disabled={Document !== "" ? values?.receiptDocuments?.length>0?true:false : true}
                            >
                              Add Document
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {values?.receiptDocuments?.length ? (
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Selected Files
                        </label>
                        <div className="col-lg-8 d-flex flex-wrap justify-content-start">
                          {values.receiptDocuments
                            ? values.receiptDocuments.map((ele, ind) => {
                              if( FileExtension(ele)!=='.pdf'){
                                return <div
                                className="col-lg-3"
                                style={{ margin: "20px 10px" }}
                              >
                                {/*begin::Image input*/}
                                <div className="image-input image-input-outline">
                                  {/*begin::Preview existing avatar*/}
                                  <div
                                    className="image-input-wrapper w-125px h-125px"
                                    style={{
                                      backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_IMAGE}${ele})`,
                                    }}
                                  />
                                  {/*end::Preview existing avatar*/}
                                  {/*begin::Label*/}
                                  <label
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    title="Change avatar"
                                  >
                                    <i className="bi bi-pencil-fill fs-7" />
                                    {/*begin::Inputs*/}
                                    <input
                                      type="file"
                                      name="avatar"
                                      accept="image/*,.pdf"
                                      onChange={(e) =>
                                        handleChangeDocument(e, ind,setFieldValue,values)
                                      }
                                    />
                                    <input
                                      type="hidden"
                                      name="avatar_remove"
                                    />
                                    {/*end::Inputs*/}
                                  </label>
                                  <span
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="remove"
                                    data-bs-toggle="tooltip"
                                    title="Remove avatar"
                                    onClick={() => handleDeleteDocument(ind,setFieldValue,values)}
                                  >
                                    <i className="bi bi-x fs-2" />
                                  </span>
                                  {/*end::Remove*/}
                                </div>
                                {/*end::Image input*/}
                                {/*end::Col*/}
                                </div>
                              }
                              else{
                                return <div> 
                                  <div className="image-input image-input-outline">
                                  {/*begin::Preview existing avatar*/}
                                  <div className="image-input-wrapper w-100px h-100px p-5" onClick={()=>setOpen(true)}>
                                  <i class="bi bi-file-text" style={{fontSize:'60px'}}></i>
                                  </div>
                                  <label
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    title="Change avatar"
                                  >
                                    <i className="bi bi-pencil-fill fs-7" />
                                    {/*begin::Inputs*/}
                                    <input
                                      type="file"
                                      name="avatar"
                                      accept="image/*,.pdf"
                                      onChange={(e) =>
                                        handleChangeDocument(e, ind,setFieldValue,values)
                                      }
                                    />
                                    <input
                                      type="hidden"
                                      name="avatar_remove"
                                    />
                                    {/*end::Inputs*/}
                                  </label>
                                  <span
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="remove"
                                    data-bs-toggle="tooltip"
                                    title="Remove avatar"
                                    onClick={() => handleDeleteDocument(ind,setFieldValue,values)}
                                  >
                                    <i className="bi bi-x fs-2" />
                                  </span>
                                </div>
                                <Modal
                                 isOpen={open}
                                 onRequestClose={()=>setOpen(false)}
                                 style={{content: {
                                  top: '20%',
                                  left: '50%',
                                  transform: 'translate(-30%, -10%)',
                                }}}
                                >
                                <object
                                  data={`${process.env.REACT_APP_API_BASE_URL_IMAGE}${ele}`} // Replace with the path to your PDF file
                                  type="application/pdf"
                                  width="100%"
                                  height="100%" 
                                >
                                  {/* <p>
                                    It appears you don't have a PDF plugin for this browser.
                                    You can <a href="your-pdf-file.pdf">click here to download the PDF file.</a>
                                  </p> */}
                                </object>
                                </Modal>
                              </div>
                              }
                            })
                            : "No Result Found!"}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* </div> */}
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      name="description"
                      disabled={loader}
                      className="btn btn-primary"
                      id="kt_account_profile_details_submit"
                    >
                      {loader ? <Loader size={17} /> : "Save Changes"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px",
};

function Selector({
  name = "",
  data = [],
  style = {},
  onChange = () => {},
  value = "",
}) {
  const [type, setType] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setType(value);
    onChange(value);
  };

  //option attributes --> hidden,disabled
  return (
    <select
      name={name}
      id="cars"
      data-control="select2"
      className="form-select form-select-solid form-select-lg"
      style={{ ...styles, ...style }}
      onChange={handleChange}
    >
      <option
        className="col-lg-8 fv-row fw-semibold fs-6"
        selected={value === ""}
        value=""
      >
        {" "}
        choose one{" "}
      </option>
      {data?.length &&
        data.map((op, index) => (
          <option
            className="col-lg-8 fv-row fw-semibold fs-6"
            selected={value === op.value}
            key={index}
            value={op.value}
          >
            {op.title}
          </option>
        ))}
    </select>
  );
}
