import React, { useEffect, useState, useRef } from "react";
import {useLocation} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import { ACTION_TYPES } from "../../constants";
import {getAreas,fetchAllPackages, fetchAllDriver,getExpenses, getDriversInOutData, fetchFilteredSorterDriver, getPartnersInvoices} from "../../actions"

const useStyles = makeStyles(()=>({
  pagination:{
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export default function PaginationControlled({
  p = 1,
  type = "",
  numberOfPages=2,
  lable="",
  driverType={}
  }) {
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch();
  const [page, setPage] = useState(p);
  const [pages,setPages] = useState(1);
  const {areas,pageSize: area_size=20,page: area_page=1} = useSelector(({area})=>area)
  const {packages,pageSize: package_size=20,page: package_page=1} = useSelector(({packages})=>packages)
  const {sorterDrivers,driversInOutData,pageSize: driver_size=20,page: driver_page=1} = useSelector(({driver})=>driver)
  const {allExpenses, pageSize:expense_size=20, page:expense_page=1} = useSelector(({expenses})=>expenses)
  const { partnersInvoices } = useSelector(({ partner }) => partner);

  const handleChange = (event, value) => {
    setPage(value);
    switch (lable) {       
        case "packages":
          dispatch({type: ACTION_TYPES.SET_PACKAGE_PAGE,payload: value});
          // setPage(package_page);
          dispatch(fetchAllPackages());
          break;
        case "area":
          dispatch({type: ACTION_TYPES.SET_AREA_PAGE,payload: value});
          // setPage(area_page);
          dispatch(getAreas());
          break;
          case "driver":
            dispatch({type: ACTION_TYPES.SET_DRIVER_PAGE,payload: value});
            // setPage(area_page);
            dispatch(fetchFilteredSorterDriver({...driverType}));
            break;
          case "driver_in_out":
            dispatch({type: ACTION_TYPES.SET_DRIVER_PAGE,payload: value});
            // setPage(area_page);
            dispatch(getDriversInOutData())
            break;
            case "expenses" :
              dispatch({type: ACTION_TYPES.SET_EXPENSES_PAGE, payload: value});
              dispatch(getExpenses());
              break;

            case "partners_invoices":
              dispatch({type: ACTION_TYPES.SET_PARTNER_INVOICE_PAGE,payload: value});
              dispatch(getPartnersInvoices());
              break;
        default:
          return;
    }
  };


  useEffect(() => {
   switch(lable){
    case "packages":
      dispatch({type:ACTION_TYPES.RESET_PACKAGES_PAGE});
      break;
      case "area":
        dispatch({type:ACTION_TYPES.RESET_AREA_PAGE});
        break;
        case "driver":
          dispatch({type:ACTION_TYPES.RESET_DRIVER_PAGE});
          break;
          case "driver_in_out":
            dispatch({type:ACTION_TYPES.RESET_DRIVER_PAGE});
            break;
          case "expenses" :
            dispatch({type:ACTION_TYPES.RESET_EXPENSES_PAGE});
            break;

          case "partners_invoices":
            dispatch({type: ACTION_TYPES.RESET_PARTNER_INVOICE_PAGE});
            dispatch(getPartnersInvoices());
            break;

          default:
            return;           
   }

   return ()=> { 
    dispatch({type: ACTION_TYPES.RESET_PARTNER_INVOICE_PAGE});
  }
  },[lable]);

  useEffect(()=>{
    switch (lable) {       
      case "packages":
        setPages(Math.ceil(packages?.count/20));
        setPage(1)
        break;
        case "driver":
          setPages(Math.ceil(sorterDrivers?.count/20));
          setPage(1);
          break;
        case "driver_in_out":
          setPages(Math.ceil(driversInOutData?.count/20));
          setPage(1);
          break;
        case "area":
          setPages(Math.ceil(areas?.count/20));
          setPage(1);
        break;
        case "expenses":
          setPages(Math.ceil(allExpenses?.count/20))
          setPage(1)
          break;
        case "partners_invoices":
          setPages(Math.ceil(partnersInvoices?.count/20))
          setPage(1)
          break;
      default:
        return;
    }
  },[allExpenses?.count,areas?.count,sorterDrivers?.count,packages?.count,driversInOutData?.count])

  return (
    <div className ={classes.pagination} >
    {pages ?
      <Stack spacing={2}>
      {/* <Typography>Page: {page}</Typography> */}
      <Pagination  count={pages} page={page}  onChange={handleChange} color="primary"/>

    </Stack>
    :null}
    </div>
  );
}
