import {ACTION_TYPES} from "../constants"
const INITIAL_STATE = {
    reports: [],
    loader: false,
    report: {},
    driverPackageIncome:[],
    driverProfit:[],
    profitLoss: {},
    lastState: {},
    charts: [],
    summaryReports:[],
    compareReport:{}
}

const reports =  (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case ACTION_TYPES.REPORTS_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.REPORTS_SUCCESS:
            return {...state,reports: action.payload,loader: false};
        case ACTION_TYPES.SUMMARY_REPORTS_SUCCESS:
            return {...state,summaryReports: action.payload,loader: false};
        case ACTION_TYPES.COMPAIR_REPORT_SUCCESS:
            return {...state,compareReport: action.payload,loader: false};
        case ACTION_TYPES.REPORTS_FAILED:
            return {...state,loader: false};
        case ACTION_TYPES.DRIVER_PACKAGE_INCOME_SUCCESS:
            return {...state,driverPackageIncome: action.payload,loader: false};
        case ACTION_TYPES.DRIVER_PROFIT_SUCCESS:
            return {...state,driverProfit: action.payload,loader: false};
        case ACTION_TYPES.DRIVER_PROFIT_LOSS_SUCCESS:
            return {...state,profitLoss: action.payload,loader: false}
        case ACTION_TYPES.SAVE_STATE:
            return {...state,lastState:action.payload};

        case ACTION_TYPES.CHART_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.CHART_SUCCESS:
            return {...state,charts: action.payload,loader: false};
        case ACTION_TYPES.CHART_FAILED:
            return {...state,loader: false};

        default:
            return state
    }
}

export default reports;