import React, { useMemo, useEffect, useState } from "react";
import { Selector } from "../../components/common";
import TableIncome from "./partnerPackageInvoiceTable";
import TableIncome2 from "./partnerPackageInvoiceTable2";
import { useSelector, useDispatch } from "react-redux";
import {
  createPartnerInvoice,
  fetchAllSorterDriver,
  fetchDrivers,
  getAllInvoices,
  getDriverPackageIncome,
} from "../../actions";
import { NotificationManager } from "react-notifications";
import { useFormik } from "formik";
import * as yup from "yup";
import { ACTION_TYPES } from "../../constants";
import { ViewDatePicker } from "../../components/common";
import { CSVLink } from "react-csv";
import Loader from "../../components/common/loader";

import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import { NavLink, useNavigate } from "react-router-dom";
import InputSelect from "../../components/common/InputSelect";

const statusType = ["new", "paid", "unpaid", "partial", "void"];


function AddPartnerInvoice() {
  
  const { allPartners } = useSelector(({ partner }) => partner);
  const { allInvoices } = useSelector(({ invoices }) => invoices);
  const [selectedPartner, setSelectedPartner] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    start_date: new Date().toISOString(),
    end_date: new Date().toISOString(),
    partnerId: allPartners[0]?.id || 1,
    status: statusType[0],
    hideGst: false
  };

  const validation = yup.object({
    start_date: yup.date().required("Please fill start_date"),
    end_date: yup.date().required("Please fill end_date"),
    hideGst: yup.boolean()
  });

  const handleSubmit = (values) => {
    if (values) {
      const partner = allPartners?.find((item)=> item?.id===values?.partnerId);
      setSelectedPartner(partner);

      const new_values = {
        start_date: values?.start_date,
        end_date: values?.end_date,
      };

      dispatch(getAllInvoices(new_values));
    } else NotificationManager.error("please fill data");
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: handleSubmit,
  });

  const generateInvoice = ()=>{
    if(!formik.values.partnerId){
      NotificationManager.error('Partner required before generating invoice')
      return 
    }
    const data = {
      partner_id: formik.values.partnerId,
      data: allInvoices?.rows,
      total_amount: allInvoices?.rows?.reduce((sum, item)=>sum+item?.amount, 0),
      hide_gst: formik.values.hideGst
    }
    dispatch(createPartnerInvoice(data, navigate))
  }

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ padding: "0px !important" }}
      >
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{ padding: 0 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Generate Partner Invoice</h3>
              </div>
              <div className="d-flex">
               {allInvoices?.rows?.length?
                <div className="d-flex justify-content-start py-7 px-6">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={generateInvoice}
                  >
                    Generate Invoice
                  </button>
               </div>:null}
              </div>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <form
              id="kt_account_profile_details_form"
              className="form"
              onSubmit={formik.handleSubmit}
            >
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Start Date
                  </label>
                  <div className="col-lg-8 fv-row">
                    <ViewDatePicker
                      name="start_date"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        formik.setFieldValue("start_date", value.toISOString())
                      }
                      value={formik.values.start_date.substring(0, 10)}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="start_date" data-validator="notEmpty">
                      {formik.touched.start_date && formik.errors.start_date ? (
                        <div>{formik.errors.start_date}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    End Date
                  </label>
                  <div className="col-lg-8 fv-row">
                    <ViewDatePicker
                      name="end_date"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        formik.setFieldValue("end_date", value.toISOString())
                      }
                      value={formik.values.end_date.substring(0, 10)}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div class="fv-plugins-message-container invalid-feedback">
                    <div data-field="end_date" data-validator="notEmpty">
                      {formik.touched.end_date && formik.errors.end_date ? (
                        <div>{formik.errors.end_date}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Partner
                  </label>
                  <div
                    className="col-lg-8 fv-row"
                    style={{ position: "relative" }}
                  >
                    <InputSelect
                      data={
                        allPartners.length
                          ? allPartners.map((it) => {
                              // console.log("568", it);
                              return {
                                value: it.id,
                                title: `${it.name}`,
                                status: it.status,
                              };
                            })
                          : ["Loadin..."]
                      }
                      onChange={(value) =>
                        formik.setFieldValue("partnerId", value)
                      }
                      value={formik.values.partnerId}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="driverId" data-validator="notEmpty">
                      {formik.touched.driverId && formik.errors.driverId ? (
                        <div>{formik.errors.driverId}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Hide Gst
                  </label>
                  <div className="col-lg-8 fv-row">
                  <label className="form-check form-check-custom form-check-solid me-5">
                        <input className="form-check-input" 
                          type="checkbox" 
                          name="hideGst" 
                          onChange ={(e)=>formik.setFieldValue("hideGst", e.target.checked)}
                          checked={formik.values.hideGst} />
                      </label>                
                  </div>
                  {/*end::Col*/}
                </div>
              </div>
              {/* </div>  */}
              <div className="card-footer d-flex justify-content-start py-6 px-9">
                <button
                  type="submit"
                  name="description"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                >
                  Find
                </button>
              </div>
            </form>
          </div>
          <hr />
          {allInvoices?.rows?.length?
           <TableIncome invoices={allInvoices?.rows} partner={selectedPartner}/>:
           <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
           <h3 className="fw-bold m-0">No Data Found!</h3>
         </div>}
        </div>
      </div>
    </div>
  );
}

export default AddPartnerInvoice;
