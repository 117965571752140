import React from 'react'
import {useSelector,useDispatch} from "react-redux"
import {Table} from "../../components/common"
import { useOutletContext } from 'react-router-dom';

const tableHeads = [
  "Driver Name",
  "Delivery Area",
  "Mobile Number",
  "Employment Type",
  "Salary Rate",
  "Package Rate",
  "Edit",
  "Hide",
  "Home Number",
  "ABN",
  "TFN",
  "Car Plate Number",
  "Registration Expiry Date",
  "Insurance Expiry Date",
  "Insurance Number",
  "Scanner Number",
  "Scanner User Name",
  "Scanner Password",
]

function Driver({width="100%"}) {
  const setOpen = useOutletContext();//function that we pass as a props in Outlet in index.js file
  const dispatch = useDispatch()
  const {sorterDrivers} = useSelector(({driver})=>driver)
  return (
    <div className="tab-content">
      <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
        <div className="table-responsive">
        <div className="d-flex flex-wrap justify-content-start">
				<div class="d-flex flex-wrap">
       <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
				<div className="d-flex align-items-center">
					<div className="fs-6 text-gray-500 fw-bold" >List of Drivers</div>
					</div>
					<div className="fs-2 fw-bold text-success" style={{textAlign:"center", paddingTop:5}}>{sorterDrivers?.count}</div>
						</div>
            </div>
          </div>
          <Table lable="driver" tableHeads={tableHeads} tableRows={sorterDrivers?.rows} setOpen={setOpen?.length?setOpen[0]:""}/>
        </div>
      </div>
    </div>
  )
}

export default Driver