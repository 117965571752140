import React from 'react';
import { NavLink } from 'react-router-dom';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';

const styles={
     padding: "1em",
     backgroundColor: "#fff"
}
function formatInvoiceId(id) {
    if(!id) return 'N/A'
    const idString = id.toString();
    const chunks = [];
    
    for (let i = idString.length - 1; i >= 0; i -= 3) {
      const chunk = idString.slice(Math.max(0, i - 2), i + 1);
      chunks.unshift(chunk.padStart(3, '0'));
    }
    if(chunks.length ===1) {
        return '000-'+chunks.join('-')
    } else {
        return chunks.join('-');
    }
  }

const DownloadInvoice = () => {
    const {selected_invoice} = useSelector(({invoices})=>invoices)
    const generatePDF = () => {
        const domElement = document.getElementById("report");
        html2canvas(domElement, {
        }).then(canvas => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPdf({ orientation: 'l', 
          unit: 'pt', 
          format: [canvas.width, canvas.height]});
          pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
          pdf.save(`${new Date().toISOString()}.pdf`);
        });
      };

  return (
    <div>
    <div style={{display:"flex", justifyContent:"flex-end",  alignItems: 'center'}}>
    <ul className="nav nav-pills nav-pills-custom mb-3">
        <li className="nav-item mb-3 me-3 me-lg-6">
            <button
              style={{float:"left", marginLeft:10, marginTop:"-30px"}}
              type="submit"
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={generatePDF}
              > Download Pdf          
          </button>
          </li></ul></div>
    <div id ="report">
    <div id="kt_app_content" className="app-content flex-column-fluid">								 
	<div id="kt_app_content_container" className="app-container container-xxl">									 
	<div className="card">
   
    <div className="card-body py-20">
    
        <div className="mw-lg-950px mx-auto w-100">
            <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
			<h4 className="fw-bolder text-gray-800 fs-2 pe-5 pb-7">St. Mina Transport Pty Ltd and St. Mina Transport ABN<br></br>#  {formatInvoiceId(selected_invoice?.id)}</h4>
            <div className="text-sm-end">
                <NavLink className="d-block mw-150px ms-sm-auto">
	            <img alt="Logo" src="/assets/media/images/img-logo.png" className="w-100" />
				</NavLink>
                <div className="text-sm-end fw-semibold fs-4 text-muted mt-7">
					<div>Address will be placed here</div>
			        <div>stmina@gmail.com</div>
                    <div>98778465465</div>
                </div>
			</div>
		</div>

		<div className="pb-12">
            <div className="d-flex flex-column gap-7 gap-md-10">
                <div className="fw-bold fs-2">{`${selected_invoice.driver?.firstName} ${selected_invoice.driver?.lastName}`}
			<br />
            {`${selected_invoice.driver?.abn}`}
            <br />
            <span className="text-muted fs-5">Here are your order details. We thank you for your purchase.</span></div>
            <div className="separator"></div>
			    <div className="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
					{/* <div className="flex-root d-flex flex-column">
						<span className="text-muted">Driver Name</span>
						<span className="fs-5">{`${selected_invoice.driver?.firstName} ${selected_invoice.driver?.lastName}`}</span>
					</div> */}
					<div className="flex-root d-flex flex-column">
					    <span className="text-muted">Date created</span>
					    <span className="fs-5">{selected_invoice.invoice_date?.substr(0,10)}</span>
					</div>
					<div className="flex-root d-flex flex-column">
						<span className="text-muted">Area</span>
						<span className="fs-5">{selected_invoice.area?.areaName}</span>
					</div>
					{/* <div className="flex-root d-flex flex-column">
						<span className="text-muted">Shipment ID</span>
					<span className="fs-5">#SHP-0025410</span>
					</div> */}
				</div>
				<div className="d-flex justify-content-between flex-column">
                    <hr/>
                    <div className="table-responsive border-bottom mb-9">
					<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
						<thead>
						<tr className="border-bottom fs-6 fw-bold text-muted">
						<th className="min-w-175px pb-2">Expense Category</th>
						{/* <th colspan="3" className="min-w-100px text-end pb-2">Count</th> */}
						{/* <th colspan="3" className="min-w-100px text-end pb-2">GST (10%)</th> */}
						<th colspan="3" className="min-w-100px text-end pb-2">Amount</th>
						</tr>
						</thead>
						<tbody className="fw-semibold text-gray-600">
                            <tr>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className="ms-5">
                                        <div className="fw-bold">{selected_invoice?.expense_category?.category}</div>
									</div>
								</div>
                            </td>
                            {/* <td colspan="3" className="text-end">2</td> */}
                            {/* <td colspan="3" className="text-end">{((selected_invoice.amount * 0.1)).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }) }</td> */}
                            <td colspan="3" className="text-end">{selected_invoice?.amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}</td>
							</tr>
							{/* <tr>
                                <td>
								<div className="d-flex align-items-center">
                                    <div className="ms-5">
                                        <div className="fw-bold">B</div>
                                    </div>
								</div>
								</td>
                                <td colspan="3" className="text-end">1</td>
                                <td colspan="3" className="text-end">$24.00</td>
							</tr> */}
                    </tbody>
		            </table>
                </div>
				</div></div>
				</div>
            <div style={{display:"flex", justifyContent:"flex-end"}}>
                <div className="table-responsive" >
                <div className="tab" 
                    style={{border:"1px solid",
                    width:"206px",
                    borderRadius:"1em"}}>
                <table
                        style={{borderCollapse: "collapse",
                        width:"100%",
                        borderRadius: "1em",
                        overflow: "hidden"}}>
                <tr>
                <th colspan="2" style={{backgroundColor:"black",padding: "1em", color:"#fff", fontSize:"15px"}}>Invoice Summary</th>
                </tr>
                    {/* <tr>
                        <td style={styles}>SubTotal</td>
                        <td style={styles}>$2</td>
                    </tr>
                    <tr>
                        <td style={styles}>SubTotal A</td>
                        <td style={styles}>$2</td>                   
                    </tr>
                    <tr>
                        <td style={styles}>SubTotal B</td>
                        <td style={styles}>$2</td>                       
                    </tr> */}
                    <tr>
                        <td style={styles}>Total(inc.GST)</td>
                        <td style={styles}>{
                        (selected_invoice.amount + (+(selected_invoice.amount * 0.1).toFixed(2))).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        
                        }</td>                       
                    </tr>
                </table>
                </div>
    </div></div>
								 
    </div>
	</div>				
    </div>
                                            
</div>
</div>	

</div>		
</div>								
								
  )
}

export default DownloadInvoice