import React,{useState} from 'react'
const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px"
}

function Selector({name="",data =[],style = {},onChange = ()=>{},value,multi=false}) {
  const [type,setType] = useState("");
  const handleChange = (e)=>{
    if(multi){
      const selectedItems = e.target.selectedOptions
      const flavors = [];
      for (let i=0; i<selectedItems.length; i++) {
          flavors.push(selectedItems[i].value);
      }
      setType(flavors);
      onChange(flavors);
    }else{
      const {value} = e.target
      setType(value)
      onChange(value);
    }
  }

  //option attributes --> hidden,disabled
  return (
    <select name={name} id="cars"  style={{...styles,...style}} onChange={handleChange} class="form-select form-select-solid" mutiple={multi}>
      <option selected={value === ""} value=""> choose one </option>
      {data?.length && data.map((op,index)=>
        <option className="col-lg-8 fv-row fw-semibold fs-6" selected={multi?value.includes(op.value):value==op.value} key={index} value={op.value} >{op.title}</option>
      )}
    </select>
  )
}

export default Selector