import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../components/common";
import { useFormik } from "formik";
import Selector from "../../components/common/Selector";
import { months, status } from "../../constants/expenses";
import { getSalaryExpenses } from "../../actions";
import InputSelect from "../../components/common/InputSelect";

const tableHeads = [
  "Month",
  "Start Date",
  "Start Time",
  "Finish Date",
  "Finish Time",
  "Area",
  "Driver Name",
  "Amount",
  "Status",
  "Edit",
  "Delete",
];

function Salary() {
  const dispatch = useDispatch();

  // const { drivers } = useSelector(({ driver }) => driver);
  // const { rows: data } = drivers;
  const { sorterDrivers } = useSelector(({ driver }) => driver);
  const { rows: data } = sorterDrivers;
  const { options = [] } = useSelector(({ area }) => area);
  const { salary_expenses, open_salary_expense } = useSelector(
    ({ expenses }) => expenses
  );

  useEffect(() => {
    dispatch(getSalaryExpenses({}));
    dispatch({ type: "SET_OPEN_SALARY_EXPENSE", payload: { open: false } });
    // return () => {
    //   console.log('>>>> return called.');
    // }
  }, []);

  const initialValues = {
    month: "",
    area: "",
    driver_name: "",
    status: "",
  };

  if (!open_salary_expense) {
  }

  const handleSubmit = ({ month, area, driver_name, status }) => {
    let filter = {};
    if (month) {
      filter["month"] = months.indexOf(month) + 1;
    }
    if (area) {
      filter["area_id"] = area;
    }
    if (driver_name) {
      filter["driver_id"] = driver_name;
    }
    if (status) {
      filter["status"] = status;
    }
    dispatch(getSalaryExpenses(filter));
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });
  // console.log('>>>>',open_salary_expense);
  // if(open_salary_expense === false){
  //   formik.resetForm();
  // }
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ padding: "0px !important" }}
      >
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{ padding: 0 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Salary Information</h3>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-wrap justify-content-start">
                  <div class="d-flex flex-wrap"></div>
                </div>
              </div>
            </div>
          </div>
          {open_salary_expense ? (
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              <form
                id="kt_account_profile_details_form"
                className="form"
                onSubmit={formik.handleSubmit}
              >
                <div className="card-body border-top p-9">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Month
                    </label>
                    <div className="col-lg-8 fv-row">
                      <Selector
                        name="month"
                        data={months.map((it) => {
                          return { value: it, title: it };
                        })}
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Month"
                        value={formik.values.month}
                        onChange={(value) =>
                          formik.setFieldValue("month", value)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Area
                    </label>
                    <div className="col-lg-8 fv-row">
                      <Selector
                        name="area"
                        data={options.map((it) => {
                          return { value: it.id, title: it.areaName };
                        })}
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Area"
                        value={formik.values.area}
                        onChange={(value) =>
                          formik.setFieldValue("area", value)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Driver Name
                    </label>
                    <div className="col-lg-8 fv-row" style={{position:'relative'}}>
                    <InputSelect
                      data={
                        data.length
                          ? data.map((it) => {
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status:it?.driverType
                              };
                            })
                          : ["Loadin..."]
                      }
                      onChange={(value)=>formik.setFieldValue("driver_name", value)}
                      value ={formik.values.driver_name}
                    />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      Status
                    </label>
                    <div className="col-lg-8 fv-row">
                      <Selector
                        name="status"
                        data={status.map((it) => {
                          return { value: it, title: it };
                        })}
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Status"
                        value={formik.values.status}
                        onChange={(value) =>
                          formik.setFieldValue("status", value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-start py-6 px-9">
                  <button
                    type="submit"
                    name="description"
                    onSubmit={handleSubmit}
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                  >
                    Find
                  </button>
                </div>
              </form>
            </div>
          ) : null}
          <hr />
          <Table
            lable="salary_expenses"
            tableHeads={tableHeads}
            tableRows={salary_expenses?.rows}
          />
        </div>
      </div>
    </div>
  );
}

export default Salary;
