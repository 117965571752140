
import React, {useEffect, useRef,useState} from 'react';
import {NavLink, Outlet, useLocation} from 'react-router-dom';
import Selector from '../../components/common/Selector';
import {Formik, Form} from "formik"
import * as yup from 'yup';
import {useDispatch, useSelector} from "react-redux"
import {getAreas,getArea} from "../../actions"
import PaginationControlled from '../../components/common/paginate'
import useOutsideClick from '../../components/common/ousideClick';

const initialValues ={
  areaId:"",
  showArchivedArea: false
}

const validation = yup.object({
  areaName:yup.string()
  .required("Please select area name")
})
const handleSubmit =(values)=>{
  // console.log(values)

}
const Area = () => {
    const ref = useRef()
    const location = useLocation()
    const [active, setActive] = useState(0)
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const {areas:area,loader,page} = useSelector(({area})=>area)

    
    const {options} = useSelector(({area})=>area);
    const dispatch = useDispatch();

    const handleSearch =()=>{
       setOpen(!open)
    }
    useOutsideClick(ref, () => {
      if (open) setOpen(false);
    });

    const handleShowAll =()=>{
      dispatch(getAreas())
      setOpen(false)
    }

    useEffect(()=>{
      dispatch(getAreas())
    },[open]);
   
  return (
    
    <div className="col-xl-8 mb-5 mb-xl-10" style={{width:"100%"}} ref={ref}>
      
    <div className="card card-flush h-md-100">
      <div className="card-body">   
      <div style={{display:"flex", justifyContent:"space-between",  alignItems: 'center'}}>
     
       <ul className="nav nav-pills nav-pills-custom mb-3">
        {/*begin::Item*/}
        <li className="nav-item mb-3 me-3 me-lg-6">
          {/*begin::Link*/}
          {/* <a
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
            href="#kt_stats_widget_6_tab_1"
          > */}
            <NavLink to ="areasList" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style ={({isActive})=>isActive ? setActive(1):{}}>
           <div
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
            onClick={()=>setOpen(false)}
          >
            <div className="nav-icon mb-3" >
            <i className="fonticon-like-1 fs-2" />
            </div>
            <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Areas</span>              
            <span className= {active === 1 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" : ""} />
            </div>
            </NavLink>

          {/* </a> */}
        </li>
        <li className="nav-item mb-3 me-3 me-lg-6">
        <NavLink to="addNew" className="nav-text text-gray-800 fw-bold fs-6 lh-1" style ={({isActive})=> isActive ? setActive(2):{}}>
          <div
            className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
            data-bs-toggle="pill"
          >
            <div className="nav-icon mb-3">
            <i class="bi bi-plus-lg" />
            </div>
              <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Add new</span>
            <span className= {active === 2 ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" : ""} />
          </div> 
          </NavLink>
        
        </li>
        </ul>
       
        {
          (active === 1 && location.pathname!=='/areas/edit') && <ul className = "nav nav-pills nav-pills-custom mb-3" >
            <li className ="nav-item mb-3 me-3 me-lg-6">
          <button
              style={{float:"left", marginLeft:10}}
              type="submit"
              name="description"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              onClick={handleShowAll}
              > Show All              
          </button>
          </li>
        
          <li className ="nav-item mb-3 me-3 me-lg-6">         
               <button
                 style={{float:"right", marginleft:20}}
                 className="btn btn-primary"
                 onClick={handleSearch}
              > <i className="bi bi-search"></i> Search                
              </button>
              </li>
            </ul>
            }
         
        </div>
            
        {open && active === 1 && (
            <div style={{backgroundColor: "#fff",margin: "10px 0"}}>
               <Formik
                    initialValues = {initialValues}
                    validationSchema = {validation}
                    onSubmit= {handleSubmit}
                    >
                      {({values, handleBlur, errors, touched, setFieldValue })=>(
                    
                  <Form id="kt_account_profile_details_form" className="form">
                  <div className="card-body border-top p-9">
                    
                  <div className="row mb-6">
                    <label className="col-lg-2 col-form-label fw-semibold fs-6">
                      Area Name
                    </label>
                    <div className="col-lg-6 fv-row">
                    <Selector 
                      data={options.map((it)=>{
                        return {
                          value: it.id,
                          title: it.areaName,
                        }
                      })} 
                      onChange={(value)=>setFieldValue("areaId",value)}
                      value={values.areaId}
                      onBlur ={handleBlur}
                      />
                      
                    </div>
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                      {" "}
                    </label>
                  </div>  
                  <div className="row mb-6">
                  <label className="col-lg-2 col-form-label fw-semibold fs-6">
                    Show Archived
                  </label>
                  <div className="col-lg-6 fv-row">
                  <label className="form-check form-check-custom form-check-solid me-5">
                        <input className="form-check-input" 
                          type="checkbox" 
                          name="hideGst" 
                          onChange ={(e)=>setFieldValue("showArchivedArea",e.target.checked)}
                          checked={values.isArchived} />
                   </label>                
                  </div>
                </div>

                </div>
                <div className="card-footer d-flex" style={{border:"none", marginLeft:0, marginTop:-30}}>
                  <button
                    type="submit"
                    name="description"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    onClick={()=>dispatch(getAreas(values))}
                  >
                    Find
                  </button>
                </div>
              </Form>
                      )}
                      </Formik>
            </div>
         ) }   
      <Outlet context={[setOpen]} />
      </div>
      
      </div>
     {(location.pathname === "/areas/areasList" || location.pathname === "/areas") && <div class="d-flex flex-stack flex-wrap pt-10">
					<div class="fs-6 fw-semibold text-gray-700">
            {area?.count!== 0 && `Showing ${1+20*(page-1)} to ${20*page>area?.count?area?.count:20*page} of ${area?.count} entries`}
          </div>
           <PaginationControlled  lable="area"/>
        </div>}
    </div>
  )
}

export default Area;