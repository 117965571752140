import React, { useState, useEffect, useMemo } from "react";
// import { tableReportHeads } from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import { Table, ViewDatePicker } from "../../components/common";
import { getCharts } from "../../actions";
import { Selector } from "../../components/common";
// import { CSVLink } from "react-csv";
// import { ViewDatePicker } from "../../components/common";


import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';


const options = [
  'TODAY',
  'WEEK',
  'MONTH'
]

function Stats() {
  const dispatch = useDispatch();
  const { charts } = useSelector(
    ({ reports }) => reports
  );
  // const [duration, setDuration] = useState('TODAY');
  const [state1,setState1]=useState({duration:'TODAY', startDate:'' , endDate:''})
 
  // const { options = [] } = useSelector(({ area }) => area);
  // const [reports, setReports] = useState(reportss);

  // const [state, setState] = useState({
  //   date: new Date().toISOString(),
  //   // areaId: options[0].id, //static
  // });

  // useEffect(() => {
  //   setReports(reportss);
  // }, [reportss]);

  // const totalPackages = useMemo(() => {
  //   if (reportss.length) {
  //     let temp = [...reportss[reportss.length - 1]];
  //     return temp;
  //   }
  //   return ["Total/packages", 0, 0, 0, 0, 0, 0, 0, 0];
  // }, [JSON.stringify(reportss)]);

  useEffect(() => {
    dispatch(getCharts({...state1}));
  }, []);

  // const onChange = (k, v) => {
  //   if (k === "date") {
  //     setState({ ...state, [k]: new Date(v).toISOString() });
  //   } else setState({ ...state, [k]: v });
  //   dispatch(
  //     getReports({ date: new Date(v).toISOString(), areaId: state.areaId })
  //   );
  // };

  const resetFilter = () => {
    setState1({duration:'TODAY', startDate:'' , endDate:''})
    window.location.reload();
  }

  const handleFilter = (label, value) =>{
    
    let obj = {...state1};
    switch(label){

      case 'startDate': 
        obj.duration = '';
        obj.startDate= value;
        break;

      case 'endDate':
        obj.duration = '';
        obj.endDate = value;
        break;

      case 'duration':
        obj.duration = value;
        obj.endDate = '';
        obj.startDate = ''
        break;
    }

    dispatch(getCharts({...obj}));
    setState1(obj)
  }

  return (
    <div className="tab-content">
      <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
        <div className="table-responsive">
          <div className="card-title flex-column">
            <h3 className="fw-bold mb-1">Package Delivery Stats</h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap:'20px',
            }}
          >
            <div className="row mb-6">
              <label className="col-form-label fw-semibold fs-6">
                Duration
              </label>
              <div className="col-lg-12 fv-row">
                <Selector
                  style={{ width: "100% !important" }}
                  data={options.map((it) => {
                    return {
                      value: it,
                      title: it,
                    };
                  })}
                  onChange={(value) => handleFilter('duration', value)}
                  value={state1.duration}
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-form-label fw-semibold fs-6">
                From
              </label>
              <div className="col-lg-12 fv-row">
                <ViewDatePicker
                disableFuture={true}
                name="startDate"
                onChange={(value)=>handleFilter('startDate', new Date(value).toISOString())}
                className="form-control form-control-lg form-control-solid"
                placeholder="Date"
                value ={state1.startDate?.substring(0,10) || ""}
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-form-label fw-semibold fs-6">
                {"To"}
              </label>
              <div className="col-lg-12 fv-row">
                <ViewDatePicker
                disableFuture={true}
                name={"to"}
                onChange={(value)=>handleFilter('endDate', new Date(value).toISOString())}
                className="form-control form-control-lg form-control-solid"
                placeholder="Date"
                value ={state1.endDate?.substring(0,10) || ""}
                />
              </div>
            </div>

            <div className="col-lg-2 fv-row d-flex align-items-center">
              <button
                  style={{marginTop:"20px"}}
                  type="submit"
                  name="description"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  onClick={resetFilter}
                  >
                  Reset
              </button>
          </div>
            
          </div>
          <hr />
          <LineChart width={1000} height={300} data={charts}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" padding={{ left: 0, right: 0 }} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Package A" stroke="#007bff" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="Package B" stroke="#00b894" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="Package D" stroke="#e74c3c" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="Package E" stroke="#f39c12" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="Package F" stroke="#8e44ad" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="Package G" stroke="#2ecc71" activeDot={{ r: 8 }} />
              </LineChart>
        </div>
      </div>
    </div>
  );
}

export default Stats;
