import {ACTION_TYPES, areas as areasList} from "../constants"
import {postArea, getAllAreas,fetchArea, updateArea,fetchAllAreass, getDriverss} from '../api';
import {  NotificationManager } from 'react-notifications';

export const addArea = (res, navigate)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.ADD_AREA_REQUEST})
        const {data} = await postArea(res);       
        navigate("/areas")
        NotificationManager.success('Successfully added new area');
        dispatch(getAreas())
        } 
    catch (error) {
        dispatch({type: ACTION_TYPES.ADD_AREA_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const fetchAllAreas = ()=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.AREA_REQUEST});
        const {data} = await fetchAllAreass();
        dispatch({type: ACTION_TYPES.SET_AREA_OPTIONS,payload: data?.data?.rows});
    } catch (error) {
        dispatch({type: ACTION_TYPES.AREA_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getAreas = (payload)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.AREA_REQUEST});
        const {page=1, pageSize=20,order="ASC",field="createdAt"} = getState().area;
        const filter = {
            page,
            pageSize:20,
            field,
            order,
            ...payload
        }
        const {data}  = await getAllAreas(filter);        
        dispatch({type: ACTION_TYPES.AREA_SUCCESS,payload: data.data});
    } catch (error) {
        dispatch({type: ACTION_TYPES.AREA_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}


export const getArea = (id)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.AREA_REQUEST});
        const {areas} = getState().area;
        const {data}  = await fetchArea(id);
        dispatch({type: ACTION_TYPES.AREA_SUCCESS,payload: {...areas,count:1, rows: [data.data]}});
    } catch (error) {
        dispatch({type: ACTION_TYPES.AREA_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}
export const setArea =(res) =>async(dispatch, getState) => {
    try{
        dispatch({type: ACTION_TYPES.EDIT_AREA_REQUEST})
        dispatch({type: ACTION_TYPES.EDIT_AREA_SUCCESS, payload:res})
    }
    catch (error) {
        dispatch({type: ACTION_TYPES.EDIT_AREA_FAILED})
        
    }
}

export const updateAreaDetail =(res,navigate) =>async(dispatch, getState) => {
    try{
        dispatch({type: ACTION_TYPES.UPDATED_AREA_REQUEST})
        const {areas, area} = getState().area;
        const response = await updateArea(area.id,res)
        dispatch({type: ACTION_TYPES.UPDATED_AREA_SUCCESS})
        navigate("/areas")
        NotificationManager.success('Successfully Updated area');
        dispatch(getAreas())
    }
    catch (error) {
        dispatch({type: ACTION_TYPES.UPDATED_AREA_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Area updation failed"));

    }
}


