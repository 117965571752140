import {ACTION_TYPES} from "../constants"
const INITIAL_STATE = {
    areas: {},
    loader: false,
    area:{},
    page: 1,
    pageSize: 20,
    field: "createdAt",
    order: "ASC",
    options: [],
    allData:[]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case ACTION_TYPES.AREA_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.AREA_SUCCESS:
            return {...state,areas: action.payload,loader: false};
        case ACTION_TYPES.AREA_FAILED:
            return {...state,loader: false};
        case ACTION_TYPES.SET_AREA_OPTIONS:
            return {...state,options: action.payload,loader: false};

        case ACTION_TYPES.ADD_AREA_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.ADD_AREA_SUCCESS:
            return {...state,loader: false,area: action.payload};
        case ACTION_TYPES.ADD_AREA_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.EDIT_AREA_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.EDIT_AREA_SUCCESS:
            return {...state,area: action.payload,loader: false};
        case ACTION_TYPES.EDIT_AREA_FAILED:
            return {...state,loader: false};
         

        case ACTION_TYPES.UPDATED_AREA_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.UPDATED_AREA_SUCCESS:
            return {...state, areas:action.payload, loader: false}
        case ACTION_TYPES.UPDATED_AREA_FAILED:
            return {...state, loader:false}

        case ACTION_TYPES.SET_AREA_PAGE:
            return {...state,page: action.payload};
            case ACTION_TYPES.RESET_AREA_PAGE:
            return {...state,page: 1};           
        default:
            return state
    }
}