import React,{useState,useEffect} from 'react'
import {Routes,Route,Navigate,useNavigate,useLocation} from "react-router-dom"
import {useSelector} from 'react-redux'
// import Signup from "../components/login/Signup"
import Login from "../components/login/login"
import DashBoard from '../container/Dashboard'
import Settings from "../container/Setting/settings"
//* ---------------------------------------------------------------- Reports
import Reports from "../container/Reports"
import DailyEntry from "../container/Reports/DailyEntry"
import DriverPackageIncome from "../container/Reports/driverPackageIncome"
import DriverProfit from "../container/Reports/driverProfit"
import ProfiteLoss from '../container/Reports/profiteLoss'

//* ---------------------------------------------------------------- Packages
import Area from "../container/Areas"
import Areas from "../container/Areas/Table"
import EditArea from "../container/Areas/edit"
import AddNew from "../container/Areas/addNew"
import Drivers from "../container/Drivers"
import Driver from  "../container/Drivers/Table"
import Edit from "../container/Drivers/edit"
import AddDriver from "../container/Drivers/addDriver"
import CustomRate from "../container/Drivers/CustomRate"
import Packages from "../container/Packages"
import PackageEdit from '../container/Packages/edit'
import PackageList from "../container/Packages/list"
import AddPackage from '../container/Packages/add'

//* ---------------------------------------------------------------- Expenses
import Expenses from "../container/Expenses"
import ExpenseList from '../container/Expenses/list'
import AddExpenses from '../container/Expenses/add2'
import ExpenseEdit from '../container/Expenses/edit'
import Salary from '../container/Expenses/salary'
import DriverExpense from '../container/Expenses/driverExpense'

//* ---------------------------------------------------------------- Users
import Users from "../container/Users";
import AddUser from "../container/Users/addUser"
import UserList from '../container/Users/list'
import EditUser from "../container/Users/edit"

//* --------------------------------------------------------------Driver Invoices
import Invoices from "../container/Invoices";
import DownloadInvoice from "../container/Invoices/downloadInvoice";
import InvoicesList from "../container/Invoices/list"

//*------------------------------------------------------------------Driver Tracking
// import DriverTracking from "../container/DriverTracking"
// import Map from "../container/DriverTracking/map"

import DashboardLayout from "../container/DashboardLayout"
import AddSalaryExpense from '../container/Expenses/addSalaryExpense'
import EditSalaryExpense from '../container/Expenses/editSalaryExpense'
import AddInvoice from '../container/Invoices/addInvoices'
import EditInvoice from '../container/Invoices/editInvoices'
import CustomRateForWeekend from '../container/Drivers/CustomRateForWeekend'
import Stats from '../container/Reports/Stats'
import ReportComaparison from '../container/Reports/reportComparison'
import Category from '../container/Category'
import CategoryList from '../container/Category/Table'
import AddNewCategory from '../container/Category/addNew'
import EditCategory from "../container/Category/edit"


import InOutTimmings from '../container/InOutTimmings'
import InOutTimmingsList from '../container/InOutTimmings/list'
import EditTrackingData from '../container/DriverTracking/edit'
import DriverTracking from '../container/DriverTracking/driverTracking'
import TrackingList from '../container/DriverTracking/trackingList'
import TrackingDataList from '../container/DriverTracking/trackingDataList'
import PartnerInvoices from '../container/PartnerInvoices'
import PartnerInvoicesList from '../container/PartnerInvoices/list'
import AddPartnerInvoice from '../container/PartnerInvoices/addInvoices'
import EditPartnerInvoice from '../container/PartnerInvoices/editInvoices'
import PartnerList from '../container/PartnerInvoices/partnerList'
import AddPartner from '../container/PartnerInvoices/addPartner'
import EditPartner from '../container/PartnerInvoices/editPartner'
import DownloadPartnerInvoice from '../container/PartnerInvoices/downloadPartnerInvoice'

//* --------------------------------------------------------------Driver Package Invoices
import DriverPackageInvoice from '../container/Reports/driverPackageInvoice/index'
import DriverPackageInvoices from '../container/Reports/driverPackageInvoice/driverPackageInvoices'
import EditDriverPackageInvoice from '../container/Reports/driverPackageInvoice/editDriverPackageInvoice'
import DownloadDriverPackageInvoice from '../container/Reports/driverPackageInvoice/downloadDriverPackageInvoice'


// import AddExpenses from '../container/Expenses/add2'
// import ExpenseEdit from '../container/Expenses/edit'
// import Salary from '../container/Expenses/salary'
// import DriverExpense from '../container/Expenses/driverExpense'

function AppRoute() {
  const {user, isLoggedIn} = useSelector((state)=>state.user) 

  return (
    <Routes>
      { isLoggedIn?
        <>
          <Route element={<DashboardLayout />}>
            {/* <Route index element={<Navigate to="/dashboard"/>} /> */}
            {/* <Route path="/dashboard" element={<DashBoard />} >
              <Route index element={<Driver />} />
              <Route path='*' element={<Navigate to="/drivers"/>} />
            </Route> */}
            <Route index element ={<Navigate to="driversList" />}/>
              <Route path ="driversList" element = {<Driver />} />
            {/* <Route path="/settings" element={<Settings />} /> */}
            <Route path="/drivers" element={<Drivers />} >
              <Route index element ={<Navigate to="driversList" />}/>
              <Route path ="driversList" element = {<Driver />} />
              <Route path = "addDriver" element = {<AddDriver />}/>
              <Route path="edit" element={<Edit />} />
              <Route path="customRate" element={<CustomRate />}/>
              <Route path="customRateForWeekend" element={<CustomRateForWeekend />}/>
            </Route>
            <Route path="/areas" element={<Area />} >
              <Route index element ={<Navigate to="areasList" />}/>
              <Route path="areasList" element ={<Areas />} />
              <Route path ="addNew" element={<AddNew />}/>
              <Route path="edit" element={<EditArea />} />
            </Route >
            <Route path="/packages" element={<Packages />} >
              <Route index element={<Navigate to="list" />}/>
              <Route path="list" element={<PackageList />}/>
              <Route path="edit" element={<PackageEdit />} />
              <Route path="add" element={<AddPackage />} />
            </Route > 
            { user.role !== 3 ?
            <Route path="/expenses" element={<Expenses />}>
                <Route index element={<Navigate to="list" />}/>
                <Route path="list" element={<ExpenseList />}/>
                <Route path="add" element={<AddExpenses />} />
                <Route path="edit" element={<ExpenseEdit />} />
                <Route path="salary" element ={<Salary />}/>
                <Route path ="driverExpense" element ={<DriverExpense />}/>
                <Route path ="addSalaryExpense" element ={<AddSalaryExpense />}/>
                <Route path ="salary_expenses_edit" element ={<EditSalaryExpense />}/>
                <Route path="category" element={<Category />} >
                  <Route index element ={<Navigate to="categoryList" />}/>
                  <Route path="categoryList" element ={<CategoryList />} />
                  <Route path ="addNew" element={<AddNewCategory />}/>
                  <Route path="edit" element={<EditCategory />} />
                </Route >
              </Route >
            : null}
            <Route path="/reports" element={<Reports />}>
              <Route index element={<Navigate to="stats" />}/>
              <Route path="dailyEntry" element={<DailyEntry />} />
              {user.role !== 3 ? <Route path="driverPackageIncome" element={<DriverPackageIncome />} />: null}
              {user.role !== 3 ? 
              <Route path="driverPackageInvoice" element={<DriverPackageInvoice />}>
                <Route index element={<Navigate to="list" />}/>
                <Route path ='list' element={<DriverPackageInvoices />}/>
                <Route path ='create' element={<Navigate to="/reports/driverPackageIncome" />}/>
                <Route path ="edit" element={<EditDriverPackageInvoice/>}/>
                <Route path ="invoice_view" element={<DownloadDriverPackageInvoice/>}/>
              </Route>: 
              null}
              {user.role !== 3 ? <Route path="profitAndLoss" element={<ProfiteLoss />} />: null}
              {user.role !== 3 ? <Route path="driverProfit" element={<DriverProfit />} />: null}
              {user.role !== 3 ? <Route path="reportComparison" element={<ReportComaparison/>} />: null}
              <Route path="stats" element={<Stats />} />
            </Route > 
            <Route path="/users" element={<Users />} >
              <Route index element={<Navigate to="userList" />}/>
              <Route path ="addUser" element ={<AddUser />}/>
              <Route path="userList" element={<UserList />}/>
              <Route path ="edit" element={<EditUser />}/>
              </Route>
            <Route path ="/partnerInvoices" element={<PartnerInvoices />} >
               <Route index element ={<Navigate to="partnerInvoicesList" />}/>
               <Route path="partnerInvoicesList" element={<PartnerInvoicesList/>}/>
              <Route path ="create" element={<AddPartnerInvoice/>}/>
              <Route path ="edit" element={<EditPartnerInvoice/>}/>
              <Route path="partnerList" element={<PartnerList/>}/>
              <Route path ="createPartner" element={<AddPartner/>}/>
              <Route path ="editPartner" element={<EditPartner/>}/>
              <Route path ="invoice_view" element={<DownloadPartnerInvoice/>}/>
            </Route>
            <Route path ="/invoices" element={<Invoices />} >
               <Route index element ={<Navigate to="invoicesList" />}/>
               <Route path="invoicesList" element={<InvoicesList />}/>
              <Route path ="create" element={<AddInvoice/>}/>
              <Route path ="invoice_edit" element={<EditInvoice/>}/>
              <Route path ="invoice_view" element={<DownloadInvoice/>}/>
            </Route>
            <Route path="/inOutTimmings" element={<InOutTimmings />}>
                <Route index element={<Navigate to="list" />}/>
                <Route path="list" element={<InOutTimmingsList />}/>
                {/* <Route path="add" element={<AddExpenses />} />
                <Route path="edit" element={<ExpenseEdit />} />
                <Route path="salary" element ={<Salary />}/>
                <Route path ="driverExpense" element ={<DriverExpense />}/>
                <Route path ="addSalaryExpense" element ={<AddSalaryExpense />}/>
                <Route path ="salary_expenses_edit" element ={<EditSalaryExpense />}/> */}
              </Route >
            {/* <Route path="/driverTracking" element ={<DriverTracking />} > */}
            <Route path="/driverTracking" element ={<DriverTracking/>} >
               <Route index element={<Navigate to="list" />}/>
                <Route path="list" element={<TrackingList/>}/>
                <Route path="data/list" element={<TrackingDataList/>}/>
              {/* <Route index element={<Navigate to ="driverTracking" />}/> */}
              <Route path="edit" element={<EditTrackingData/>}/>
              </Route>
            {/* <Route path="/category" element={<Category />} >
              <Route index element ={<Navigate to="categoryList" />}/>
              <Route path="categoryList" element ={<CategoryList />} />
              <Route path ="addNew" element={<AddNewCategory />}/>
              <Route path="edit" element={<EditCategory />} />
            </Route > */}
            <Route path="*" element={<Navigate to="/drivers"/>}/>
          </Route>
        </>
        :
        <>
        {/* <Route path="/signup" element={<Signup />}/> */}
        <Route path="/login" element={<Login />}/>
        <Route path="*" element={<Navigate to="/login" />}/>
        </>
      }
    </Routes>
  )
}

export default AppRoute