import {ACTION_TYPES} from "../constants"
const INITIAL_STATE = {
    categories: {},
    loader: false,
    category:{},
    page: 1,
    pageSize: 20,
    field: "createdAt",
    order: "ASC",
    options: [],
    allCategoryData:[]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case ACTION_TYPES.CATEGORY_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.CATEGORY_SUCCESS:
            return {...state,categories: action.payload,loader: false};
        case ACTION_TYPES.CATEGORY_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.ADD_CATEGORY_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.ADD_CATEGORY_SUCCESS:
            return {...state,loader: false,category: action.payload};
        case ACTION_TYPES.ADD_CATEGORY_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.EDIT_CATEGORY_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.EDIT_CATEGORY_SUCCESS:
            return {...state,category: action.payload,loader: false};
        case ACTION_TYPES.EDIT_CATEGORY_FAILED:
            return {...state,loader: false};
        
        case ACTION_TYPES.SET_CATEGORY_OPTIONS:
            return {...state,options: action.payload,loader: false};

        case ACTION_TYPES.UPDATED_CATEGORY_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.UPDATED_CATEGORY_SUCCESS:
            return {...state, category:action.payload, loader: false}
        case ACTION_TYPES.UPDATED_CATEGORY_FAILED:
            return {...state, loader:false}

        case ACTION_TYPES.SET_CATEGORY_PAGE:
            return {...state,page: action.payload};
            case ACTION_TYPES.RESET_CATEGORY_PAGE:
            return {...state,page: 1};           
        default:
            return state
    }
}