import {ACTION_TYPES, drivers as driverLists} from "../constants"
import {
    getAllDrivers,
    postDriver,
    correctDriver,
    getDrivers,
    deleteDriver,
    getDriverss,
    getsalaryAndEmploymentChangedList,
    postDriverDocument,
    fetchloggedInDrivers,
    getAllSorterDrivers,
    postSorterDriver,
    correctSorterDriver,
    deleteSorterDriver,
    getSorterDrivers,
    fetchDriversInOutData,
    deleteDriverInOutData,
    correctDriverInOutData
} from "../api"
import {  NotificationManager } from 'react-notifications';

export const fetchAllDriver = () => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.DRIVER_REQUEST})
        const {page=1,pageSize=20,order="ASC",field="createdAt"} = getState().driver;
        const filter = {page,pageSize:20,order,field}
        const {data} = await getAllDrivers(filter);

        dispatch({type: ACTION_TYPES.DRIVER_SUCCESS,payload:data?.data })
    } catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.DRIVER_FAILED})
        NotificationManager.error(error.message ? error.message : "Something went wrong");
    }
}

export const fetchHiddenDriver = () => async(dispatch) => {
    try {
        dispatch({type: ACTION_TYPES.DRIVER_REQUEST})
        const filter = {showHiddenDrivers:true}
        const {data} = await getAllDrivers(filter);
        dispatch({type: "GET_ALL_HIDDEN_DRIVERS",payload:data?.data?.rows })
    } catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.DRIVER_FAILED})
        NotificationManager.error(error.message ? error.message : "Something went wrong");
    }
}


export const fetchFilteredDriver = (res={}) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.DRIVER_REQUEST})
        const {page=1,pageSize=20,order="ASC",field="createdAt"} = getState().driver;
        const filter = {page,pageSize:20,order,field,...res}
        const {data} = await getDrivers(filter);

        dispatch({type: ACTION_TYPES.DRIVER_SUCCESS,payload:data?.data })
        dispatch({type: ACTION_TYPES.GET_ALL_DRIVERS, payload:data?.data?.rows})
    } catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.DRIVER_FAILED})
        NotificationManager.error(error.message);
    }
}

export const fetchDrivers = ()=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.DRIVER_REQUEST});
        const {data} = await getAllDrivers();

        dispatch({type: ACTION_TYPES.GET_ALL_DRIVERS, payload:data?.data?.rows})
    } catch (error) {
        console.error(error);
        dispatch({type: ACTION_TYPES.DRIVER_FAILED})
        NotificationManager.error(error.message);
    }
}

export const addDriver = (res,navigate) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.DRIVER_REQUEST})
        const {drivers} = getState().driver;

        const {data} = await postDriver(res);
        NotificationManager.success("Successfully added new driver");
        dispatch(fetchAllDriver());
        navigate('/drivers');
        
        // dispatch({type: ACTION_TYPES.DRIVER_SUCCESS,payload:data?.data })
    } catch (error) {
        dispatch({type: ACTION_TYPES.DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const updateDriver = (res,navigate) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.DRIVER_REQUEST})
        const {drivers,driver} = getState().driver;
        const {data} = await correctDriver(driver.id,res);
        
        // dispatch({type: ACTION_TYPES.DRIVER_SUCCESS,payload:res })
        dispatch(fetchAllDriver())
        navigate(-1);
        NotificationManager.success("Successfully updated driver");
    } catch (error) {
        console.error(error);
        dispatch({type: ACTION_TYPES.DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}
export const hideDrivers = (res,navigate) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.DRIVER_REQUEST})
        const {hiddenDriver} = getState().driver;
        const {data} = await correctDriver(hiddenDriver.id,res);
        // dispatch({type: ACTION_TYPES.DRIVER_SUCCESS,payload:res })
        dispatch(fetchAllDriver())
        NotificationManager.success("Successfully updated driver");
    } catch (error) {
        console.error(error);
        dispatch({type: ACTION_TYPES.DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const setDriver =(res)=> async(dispatch,getState)=>{
    try{
        dispatch({type: ACTION_TYPES.EDIT_DRIVER_REQUEST})
        dispatch({type: ACTION_TYPES.EDIT_DRIVER_SUCCESS, payload: res})
    }
    catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.EDIT_DRIVER_FAILED})
    }
}

export const HideDriver =(id)=>async(dispatch,getState)=>{
    try{
        dispatch({type: ACTION_TYPES.DRIVER_REQUEST});

        const {data} = await deleteDriver(id);     
        NotificationManager.success("Successfully deleted driver");
        
        // dispatch(fetchFilteredDriver());
        dispatch(fetchAllDriver());
    }
    catch (error) {
        console.log(error);
        dispatch({type: ACTION_TYPES.DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getSandElist =(id)=> async(dispatch,getState)=>{
    try{
        dispatch({type: ACTION_TYPES.S_AND_EMP_LIST_REQUEST})
        // console.log('driver', getState())
        let {data} = await getsalaryAndEmploymentChangedList(id)
        dispatch({type: ACTION_TYPES.S_AND_EMP_LIST_SUCCESS, payload: data.data})
    }
    catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.S_AND_EMP_LIST_FAILED})
    }
}

export const uploadDriverDocument = async(res)=>{
    try{
        // console.log('driver', getState())
        let {data} = await postDriverDocument(res)
        // formik.setFieldValue("driverDocuments", [...formik.values.driverDocuments,data.fileUrl])
        return data.fileUrl;
    }
    catch (error) {
        NotificationManager.error(error.message ? error.message : "Something went wrong");
    }
}

export const getLoggedInDrivers = ()=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.LOGGED_IN_DRIVER_REQUEST});
        const {data} = await fetchloggedInDrivers();
        dispatch({type: ACTION_TYPES.LOGGED_IN_DRIVER_SUCCESS, payload:data?.data})
    } catch (error) {
        console.error(error);
        dispatch({type: ACTION_TYPES.LOGGED_IN_DRIVER_FAILED})
        NotificationManager.error(error.message);
    }
}

export const fetchAllSorterDriver = () => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_REQUEST})
        // const {page=1,pageSize=20,order="ASC",field="createdAt"} = getState().driver;
        // const filter = {page,pageSize:20,order,field}
        // if(driverType) filter["driverType"]=driverType
        const {data} = await getAllSorterDrivers();
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_SUCCESS,payload:data?.data })
    } catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_FAILED})
        NotificationManager.error(error.message ? error.message : "Something went wrong");
    }
}

export const addSorterDriver = (res,navigate) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_REQUEST})

        const {data} = await postSorterDriver(res);
        NotificationManager.success("Successfully added new driver");
        dispatch(fetchFilteredSorterDriver());
        navigate('/drivers');
        
        // dispatch({type: ACTION_TYPES.DRIVER_SUCCESS,payload:data?.data })
    } catch (error) {
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const updateSorterDriver = (res,navigate) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_REQUEST})
        const {sorterDriver} = getState().driver;
        const {data} = await correctSorterDriver(sorterDriver.id,res);
        
        // dispatch({type: ACTION_TYPES.DRIVER_SUCCESS,payload:res })
        dispatch(fetchFilteredSorterDriver())
        navigate(-1);
        NotificationManager.success("Successfully updated driver");
    } catch (error) {
        console.error(error);
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const HideSorterDriver =(id)=>async(dispatch,getState)=>{
    try{
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_REQUEST});

        const {data} = await deleteSorterDriver(id);     
        NotificationManager.success("Successfully deleted driver");
        
        // dispatch(fetchFilteredDriver());
        dispatch(fetchFilteredSorterDriver());
    }
    catch (error) {
        console.log(error);
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const setSorterDriver =(res)=> async(dispatch,getState)=>{
    try{
        dispatch({type: ACTION_TYPES.SET_SORTER_DRIVER_REQUEST})
        dispatch({type: ACTION_TYPES.SET_SORTER_DRIVER_SUCCESS, payload: res})
    }
    catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.SET_SORTER_DRIVER_FAILED})
    }
}

export const hideSorterDrivers = (res,navigate) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_REQUEST})
        const {hiddenDriver} = getState().driver;
        const {data} = await correctSorterDriver(hiddenDriver.id,res);
        // dispatch({type: ACTION_TYPES.DRIVER_SUCCESS,payload:res })
        dispatch(fetchFilteredSorterDriver())
        NotificationManager.success("Successfully updated driver");
    } catch (error) {
        console.error(error);
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const fetchFilteredSorterDriver = (res={}) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_REQUEST})
        const {page=1,pageSize=20,order="ASC",field="createdAt"} = getState().driver;
        const filter = {page,pageSize:20,order,field,...res}
        const {data} = await getSorterDrivers(filter);

        dispatch({type: ACTION_TYPES.SORTER_DRIVER_SUCCESS,payload:data?.data })
    } catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_FAILED})
        NotificationManager.error(error.message);
    }
}


//* ----------------------------------------------------------------- Driver in-out pause data
 
export const getDriversInOutData = (res={})=> async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_REQUEST});
        const {page=1,pageSize=20,order="ASC",field="createdAt"} = getState().driver;
        const filter = {page,pageSize:20,order,field,...res}
        const {data} = await fetchDriversInOutData(filter);
        dispatch({type: ACTION_TYPES.DRIVER_IN_OUT_DATA_SUCCESS,payload:data?.data})
    } catch (error) {
        console.error(error);
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const setTrackingData =(res)=> async(dispatch,getState)=>{
    try{
        dispatch({type: ACTION_TYPES.SET_SORTER_DRIVER_REQUEST})
        dispatch({type: ACTION_TYPES.SET_DRIVER_IN_OUT_DATA, payload: res})
    }
    catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.SET_SORTER_DRIVER_FAILED})
    }
}

export const updateDriverInOutData = (res,navigate) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_REQUEST})
        const {trackingData} = getState().driver;
        const {data} = await correctDriverInOutData(trackingData.id,res);
        navigate("/driverTracking/data/list");
        dispatch( getDriversInOutData())
        NotificationManager.success("Successfully updated driver tracking data");
    } catch (error) {
        console.error(error);
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const HideDriverInOutData =(id)=>async(dispatch,getState)=>{
    try{
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_REQUEST});

        const {data} = await deleteDriverInOutData(id);     
        NotificationManager.success("Successfully deleted driver tracking data");
        dispatch(getDriversInOutData());
    }
    catch (error) {
        console.log(error);
        dispatch({type: ACTION_TYPES.SORTER_DRIVER_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}