import {ACTION_TYPES} from "../constants"
import {fetchReports,updateReposts,fetchDriverPackageIncome,fetchDriverProfit,fetchProfiteLoss, getAllInvoicesApi, deleteInvoicesApi, createInvoicesApi, updateInvoicesApi} from "../api"
import {  NotificationManager } from 'react-notifications';


export const getInvoices = (res)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.INVOICES_REQUEST});
        const {data} = await getAllInvoicesApi(res);
        dispatch({type: ACTION_TYPES.INVOICES_SUCCESS,payload: data?.data})
        // NotificationManager.success("Successfull Operation");
    } catch (error) {
        dispatch({type: ACTION_TYPES.INVOICES_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const getAllInvoices = (res)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.GET_ALL_INVOICES_REQUEST});
        const {data} = await getAllInvoicesApi(res);
        dispatch({type: ACTION_TYPES.GET_ALL_INVOICES_SUCCESS,payload: data?.data})
        // NotificationManager.success("Successfull Operation");
    } catch (error) {
        dispatch({type: ACTION_TYPES.GET_ALL_INVOICES_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}


export const deleteInvoice = (id)=> async(dispatch, getState) =>{
    try{
        dispatch({type:ACTION_TYPES.DELETE_INVOICE_REQUEST});
        await deleteInvoicesApi(id)
        dispatch({type: ACTION_TYPES.DELETE_INVOICE_REQUEST})
        NotificationManager.success("Successfully deleted");     
        dispatch(getInvoices())
    }
    catch(error){
        dispatch({type: ACTION_TYPES.DELETE_INVOICE_REQUEST})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}


export const addInvoice = (res = {},navigate) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.ADD_INVOICES_REQUEST})
        await createInvoicesApi(res);
        NotificationManager.success("Successfullly added Expenses");
        dispatch(getInvoices({}));
        navigate('/invoices');
    } catch (error) {
        dispatch({type: ACTION_TYPES.ADD_INVOICES_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));

    }
}


export const setInvoices =(res)=>async(dispatch,getState)=>{

    try{
        dispatch({type:ACTION_TYPES.SET_INVOICE_REQUEST});
        dispatch({type: ACTION_TYPES.SET_INVOICE_SUCCESS, payload:res })
    }
    catch(err){
        dispatch({type:ACTION_TYPES.SET_INVOICE_FAILED})
        NotificationManager.error(err?.response?.data?.msg ?? (err?.response?.data?.type || "Something went wrong"));

    }
}

export const updateInvoice = (res = {},navigate) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.UPDATE_INVOICES_REQUEST})
        await updateInvoicesApi(res.id,res);
        NotificationManager.success("Successfullly added Expenses");
        dispatch(getInvoices({}));
        navigate('/invoices');
    } catch (error) {
        dispatch({type: ACTION_TYPES.UPDATE_INVOICES_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));

    }
}
