import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import { useNavigate } from 'react-router-dom';
import {createAlert,closeAlert} from "../../actions"
import {  NotificationManager } from 'react-notifications';
import {addCategory} from '../../actions';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {name} from '../../helpers/validation';
import Loader from "../../components/common/loader"
import { fetchAllCategoriess } from '../../api';

function AddNewCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {areas:myArea, loader} = useSelector((state)=>state.area)

  const initialValues ={
    category:"", 
    description:"",
    edit: "edit",
  }
  const [data, setData] = useState([])

  const validation =yup.object({
    category:yup.string()
    .required("area name is required"),
    description:yup.string()
    .required("area description is required")
  })

  const handleSubmit =(values, {resetForm})=>{
  if(values){
    const category = {
      category: values.category,
      description: values.description
    }
    dispatch(addCategory(category, navigate))
    // alert("area details added successfully")
    resetForm({values:""})
    dispatch(createAlert("successfully added","success",4000))
  }    
  }
  return (
    <>
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
      >
        <div className="card mb-5 mb-xl-10">
        <div className="card-body">
          {/*begin::Nav*/}
          </div>
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{marginTop:"-45px"}}
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Add/Edit Category</h3>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <Formik
            initialValues = {initialValues}
              validationSchema = {validation}
              onSubmit= {handleSubmit}
          >
          {({
              values, handleChange, handleBlur, errors,  touched
          })=> (
            <Form id="kt_account_profile_details_form" className="form" >
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    Category Name
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      name="category"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Category Name"
                      value = {values.category}
                      onChange={handleChange}
                      onBlur ={handleBlur}
                    />

                    <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    {touched.category && errors.category ? <div>{errors.category}</div> : null}<br />
                      </div></div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  Description
                  </label>
                  <div className="col-lg-8 fv-row">
                    <textarea 
                      type="text"
                      name="description"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Description"
                      value = {values.description}
                      onChange={handleChange}
                      onBlur ={handleBlur}

                    />
                    <div class="fv-plugins-message-container invalid-feedback"><div data-field="fname" data-validator="notEmpty">
                    { touched.description && errors.description  ? <div >{errors.description}</div> : null}<br />

                      </div></div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="reset"
                  className="btn btn-light btn-active-light-primary me-2"
                  onClick={()=>navigate(-1)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={loader}
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  // onClick={handleSubmit}
                >
                 {loader ? <Loader size={17} />: "Add Category"}
                </button>
                <button
                  type="reset"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  style={{marginLeft:10}}
                  // onClick={handleClear}
                >
                  <i class="bi bi-arrow-repeat" style ={{fontSize:25}}></i>              
                 
              </button>
              </div>
            </Form>
          )}
          </Formik>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}


export default AddNewCategory