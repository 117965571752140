import {ACTION_TYPES} from "../constants"
import {getAllPackages, postPackage,editPackage,deletePackage,getFilteredPackages} from "../api"
import {  NotificationManager } from 'react-notifications';

export const addPackage = (res,navigate)=>async(dispatch,getState)=>{
    try {
        dispatch({type: ACTION_TYPES.PACKAGES_REQUEST});

        const {data} = await postPackage(res);
        NotificationManager.success("Successfully added new package");
        dispatch(fetchAllPackages());
        navigate(-1);
    } catch (error) {
        console.log(error);
        dispatch({type: ACTION_TYPES.PACKAGES_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Invalid entry"));
    }
}

export const fetchAllPackages = (res = {}) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.PACKAGES_REQUEST})
        const {page=1,pageSize=20,order="ASC",field="createdAt"} = getState().packages;
        let filter = {
            page,
            pageSize:20,
            order,
            field
        }
        const resExist = Object.keys(res);
        let temp
        if(resExist?.length) {
            if(res.description) filter.description = res.description;
            if(res.type) filter.type = res.type;
            if(res.area_id) filter.area_id = res.area_id;
            if(res.packageCategory) filter.packageCategory = res.packageCategory;
            if(res.showHidden) filter.showHidden = res.showHidden;
            temp = await getFilteredPackages(filter)
        }
        else {
            temp = await getAllPackages(filter)
        }
        const {data} = temp;
        
        dispatch({type: ACTION_TYPES.PACKAGES_SUCCESS,payload: data?.data})
    } catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.PACKAGES_FAILED,})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const fetchTotalPackages = (res = {}) => async(dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.PACKAGES_REQUEST})
      
        let filter = {
            showHidden: true
        }
        const {data} = await getAllPackages(filter);
        
        const newData = data?.data?.rows
        ?.filter((item, index, array) => array.findIndex(obj => obj.type === item.type) === index)
        .sort((a, b) => a.type.localeCompare(b.type));

        if(data?.data?.rows)   data.data.rows = [...newData]
      
        dispatch({type: ACTION_TYPES.TOTAL_PACKAGES_SUCCESS,payload: data?.data})
    } catch (error) {
        console.log(error.message);
        dispatch({type: ACTION_TYPES.PACKAGES_FAILED,})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const updatePackage = (res,navigate)=>async (dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.PACKAGES_REQUEST})
        const {package: packagee } = getState().packages;
        const response = await editPackage(packagee.id,res);
        NotificationManager.success("Successfully updated package");
        dispatch(fetchAllPackages());
        navigate(-1);

    } catch (error) {
        dispatch({type: ACTION_TYPES.PACKAGES_FAILED,})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Invalid details"));
    }
}


export const hidePackages = (res,navigate)=>async (dispatch,getState) => {
    try {
        dispatch({type: ACTION_TYPES.PACKAGES_REQUEST})
        const {hidePackages } = getState().packages;
        const {data} = await editPackage(hidePackages.id, res)
        NotificationManager.success("Successfully updated package");     
        dispatch(fetchAllPackages());
        // navigate(-1);

    } catch (error) {
        dispatch({type: ACTION_TYPES.PACKAGES_FAILED,})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const hidePackage = (id)=> async (dispatch,getState) =>{
    try {
        dispatch({type: ACTION_TYPES.PACKAGES_REQUEST});
        const {data} = await deletePackage(id);
        NotificationManager.success("Successfully deleted package");
        dispatch(fetchAllPackages());

    } catch (error) {
        dispatch({type: ACTION_TYPES.PACKAGE_FAILED})
        NotificationManager.error(error?.response?.data?.msg ?? (error?.response?.data?.type || "Something went wrong"));
    }
}

export const setPackage = (res)=> (dispatch,getState) =>{
    try {
        dispatch({type: ACTION_TYPES.PACKAGES_REQUEST});
        dispatch({type: ACTION_TYPES.EDIT_PACKAGE_SUCCESS,payload:res})
    } catch (error) {
        dispatch({type: ACTION_TYPES.PACKAGE_FAILED})
    }
}