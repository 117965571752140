import React, { useEffect, useRef, useState } from "react";
import { ACTION_TYPES, userType } from "../../constants";
import { format } from "date-fns";
import {
  setExpense,
  setDriver,
  setArea,
  HideDriver,
  setPackage,
  deleteExpenses,
  hideDrivers,
  hidePackages,
  setAdmin,
  deleteAdmin,
  setExpenseSalaryExpenses,
  deleteSalaryExpenses,
  deleteInvoice,
  setInvoices,
  deleteCategoryFunc,
  setCategory,
  setSorterDriver,
  HideSorterDriver,
  hideSorterDrivers,
  setTrackingData,
  HideDriverInOutData,
  setPartner,
  setPartnerInvoice,
  removePartnerInvoice,
  removePartner,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import swal from "sweetalert";
import { fixedValue } from "../../components/common/round";
import { months } from "../../constants/expenses";
import { NotificationManager } from "react-notifications";
import { removeDriverPackageInvoice, setDriverPackageInvoice } from "../../actions/driverPackageInvoices";

const colors = ["#6996BD", "#336667", "#009BD1", "#42C4DC"];
const date_format = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

const checkForColor = (row) => {
  if (row[1]?.driverStatus === "hidden") return colors[1];
  if (row[1]?.driverStatus === "active") return colors[2];
  if (row[1]?.packageStatus === "hidden") return colors[3];
  return "";
};

function Table({
  lable = "",
  tableHeads = [],
  tableRows = [],
  onUpdate = () => {},
  totalPackages = [],
  setIsOpen = () => {},
  setCenter = { lat: 0, lng: 0 },
  handleModal = "", //for show documents modal in case of receipt expense
  setOpen = "",
}) {
  const [show, setShow] = useState(false);
  const [sortByAlphabetACE, setSortByAlphabetACE] = useState(false);
  const { packages } = useSelector(({ packages }) => packages);
  const Count = useRef(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function format(s) {
    return s?.toString()?.replace(/\d{3}(?=.)/g, "$& ");
  }

  const sortFunction = () => {
    if (!sortByAlphabetACE) {
      tableRows = tableRows?.sort(function (a, b) {
        if (a.driver?.firstName < b.driver?.firstName) {
          return -1;
        }
        if (a.driver?.firstName > b.driver?.firstName) {
          return 1;
        }
        return 0;
      });
    } else {
      tableRows = tableRows?.sort(function (a, b) {
        if (a.driver?.firstName < b.driver?.firstName) {
          return 1;
        }
        if (a.driver?.firstName > b.driver?.firstName) {
          return -1;
        }
        return 0;
      });
    }
  };

  if (tableRows && tableRows.length) {
    switch (lable) {
      case "invoices":
        sortFunction();
        break;

      default:
        if (!sortByAlphabetACE) {
          tableRows = tableRows?.sort(function (a, b) {
            if (a.firstName < b.firstName) {
              return -1;
            }
            if (a.firstName > b.firstName) {
              return 1;
            }
            return 0;
          });
        } else {
          tableRows = tableRows?.sort(function (a, b) {
            if (a.firstName < b.firstName) {
              return 1;
            }
            if (a.firstName > b.firstName) {
              return -1;
            }
            return 0;
          });
        }
    }
  }
  function formatAbn(s) {
    return s?.toString()?.replace(/^(.{2})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
  }
  function sortByAlphabet() {
    setSortByAlphabetACE(!sortByAlphabetACE);
  }
  const toggle = (res) => {
    // dispatch(showHide(res))
    setShow(!show);
  };
  const handleRow = (res) => {
    if (lable === "expenses") {
      if (setOpen) setOpen(false);
      dispatch(setExpense(res));
      navigate("/expenses/edit");
    }

    if (lable === "DriverTrackingData") {
      dispatch(setTrackingData(res));
      navigate("/driverTracking/edit");
    }

    if (lable === "category") {
      if (setOpen) setOpen(false);
      dispatch(setCategory(res));
      navigate("/expenses/category/edit");
    }

    if (lable === "area") {
      if (setOpen) setOpen(false);
      dispatch(setArea(res));
      navigate("/areas/edit");
    }

    if (lable === "driver") {
      if (setOpen) setOpen(false);
      dispatch(setSorterDriver(res));
      navigate("/drivers/edit");
    }

    if (lable === "packages") {
      if (setOpen) setOpen(false);
      dispatch(setPackage(res));
      navigate("/packages/edit");
    }

    if (lable === "users") {
      dispatch(setAdmin(res));
      navigate("/users/edit");
    }

    if (lable === "salary_expenses") {
      dispatch(setExpenseSalaryExpenses(res));
      navigate("/expenses/salary_expenses_edit");
    }

    if (lable === "invoices") {
      if (setOpen) setOpen(false);
      dispatch(setInvoices(res));
      navigate("/invoices/invoice_edit");
    }
    if (lable === "DriverTrackingList") {
      if (res?.geometry && res.geometry?.coordinates) {
        // alert(res.geometry?.coordinates)
        setCenter({
          lat: res.geometry?.coordinates[0] || 0,
          lng: res.geometry?.coordinates[1] || 0,
        });
        setIsOpen(true);
      } else {
        NotificationManager.error("No Coordinates found");
      }
    }
    if (lable === "partners") {
      if (setOpen) setOpen(false);
      dispatch(setPartner(res));
      navigate("/partnerInvoices/editPartner");
    }
    if (lable === "partners_invoices") {
      if (setOpen) setOpen(false);
      dispatch(setPartnerInvoice(res));
      navigate("/partnerInvoices/edit");
    }
    if (lable === "driver_package_invoices") {
      if (setOpen) setOpen(false);
      dispatch(setDriverPackageInvoice(res));
      navigate("/reports/driverPackageInvoice/edit");
    }
  };

  const handleViewDownload = (res) => {
    if (lable === "invoices") {
      dispatch(setInvoices(res));
      navigate("/invoices/invoice_view");
    } else if (lable === "partners_invoices") {
      dispatch(setPartnerInvoice(res));
      navigate("/partnerInvoices/invoice_view");
    } else if(lable === "driver_package_invoices"){
      dispatch(setDriverPackageInvoice(res));
      navigate("/reports/driverPackageInvoice/invoice_view");
    }
  };

  const handleHideData = (row) => {
    if (lable === "packages") {
      swal({
        title: "Are you sure?",
        text: "It will change the package status",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willhide) => {
        if (willhide) {
          dispatch({ type: "SET_HIDE_PACKAGES", payload: row });
          const data = row.status === "active" ? "hidden" : "active";
          dispatch(hidePackages({ status: data })).then((res) => {
            swal({
              title: "Status changed",
              text: "Package's status changed successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
        }
      });
    }
    if (lable === "driver") {
      swal({
        title: "Are you sure?",
        text: "It will change the driver status",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willhide) => {
        if (willhide) {
          dispatch({ type: "SET_HIDE_DRIVER", payload: row });
          const data = row.status === "active" ? "hidden" : "active";
          dispatch(hideSorterDrivers({ status: data })).then((res) => {
            swal({
              title: "Status changed",
              text: "Driver's status changed successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
        }
      });
    }
  };

  const handleHide = async (id) => {
    switch (lable) {
      case "driver":
        swal({
          title: "Are you sure?",
          text: "It will be permanently deleted",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willdelete) => {
          if (willdelete) {
            dispatch(HideSorterDriver(id)).then((res) => {
              swal({
                title: "Delete",
                text: "Driver deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            });
          }
        });
        break;
      case "DriverTrackingData":
        swal({
          title: "Are you sure?",
          text: "It will be permanently deleted",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willdelete) => {
          if (willdelete) {
            dispatch(HideDriverInOutData(id)).then((res) => {
              swal({
                title: "Delete",
                text: "Driver Tracking data deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            });
          }
        });
        break;
      case "expenses":
        try {
          const willDelete = await swal({
            title: "Are you sure?",
            text: "It will be permanently deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
          if (willDelete) {
            const data = dispatch(deleteExpenses(id));
            if (data) {
              await swal({
                title: "Delete",
                text: "Expense deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            }
          }
        } catch (err) {
          swal({
            title: "Error",
            text: err.message,
            icon: "warning",
            timer: 2000,
            button: false,
          });
        }
        break;
      case "InOutTimmings":
        try {
          const willDelete = await swal({
            title: "Are you sure?",
            text: "It will be permanently deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
          if (willDelete) {
            const data = dispatch(deleteExpenses(id));
            if (data) {
              await swal({
                title: "Delete",
                text: "Expense deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            }
          }
        } catch (err) {
          swal({
            title: "Error",
            text: err.message,
            icon: "warning",
            timer: 2000,
            button: false,
          });
        }
        break;
      case "users":
        try {
          const willDelete = await swal({
            title: "Are you sure?",
            text: "It will be permanently deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
          if (willDelete) {
            const data = dispatch(deleteAdmin(id));
            if (data) {
              await swal({
                title: "Delete",
                text: "Admin deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            }
          }
        } catch (err) {
          swal({
            title: "Error",
            text: err.message,
            icon: "warning",
            timer: 2000,
            button: false,
          });
        }
        break;
      case "packages":
        break;
      case "salary_expenses":
        try {
          const willDelete = await swal({
            title: "Are you sure?",
            text: "It will be permanently deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
          if (willDelete) {
            const data = dispatch(deleteSalaryExpenses(id));
            if (data) {
              await swal({
                title: "Delete",
                text: "Expense deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            }
          }
        } catch (err) {
          swal({
            title: "Error",
            text: err.message,
            icon: "warning",
            timer: 2000,
            button: false,
          });
        }
        break;
      case "invoices":
        try {
          const willDelete = await swal({
            title: "Are you sure?",
            text: "It will be permanently deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
          if (willDelete) {
            const data = dispatch(deleteInvoice(id));
            if (data) {
              await swal({
                title: "Delete",
                text: "Expense deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            }
          }
        } catch (err) {
          swal({
            title: "Error",
            text: err.message,
            icon: "warning",
            timer: 2000,
            button: false,
          });
        }
        break;

      case "category":
        try {
          const willDelete = await swal({
            title: "Are you sure?",
            text: "It will be permanently deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
          if (willDelete) {
            const data = dispatch(deleteCategoryFunc(id));
            if (data) {
              await swal({
                title: "Delete",
                text: "Expense deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            }
          }
        } catch (err) {
          swal({
            title: "Error",
            text: err.message,
            icon: "warning",
            timer: 2000,
            button: false,
          });
        }
        break;

      case "partners_invoices":
        try {
          const willDelete = await swal({
            title: "Are you sure?",
            text: "It will be permanently deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
          if (willDelete) {
            const data = dispatch(removePartnerInvoice(id));
            if (data) {
              await swal({
                title: "Delete",
                text: "Partner Invoice deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            }
          }
        } catch (err) {
          swal({
            title: "Error",
            text: err.message,
            icon: "warning",
            timer: 2000,
            button: false,
          });
        }
        break;
      
      case "driver_package_invoices":
        try {
          const willDelete = await swal({
            title: "Are you sure?",
            text: "It will be permanently deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
          if (willDelete) {
            const data = dispatch(removeDriverPackageInvoice(id));
            if (data) {
              await swal({
                title: "Delete",
                text: "Driver Pacakge Invoice deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            }
          }
        } catch (err) {
          swal({
            title: "Error",
            text: err.message,
            icon: "warning",
            timer: 2000,
            button: false,
          });
        }
        break;

      case "partners":
        try {
          const willDelete = await swal({
            title: "Are you sure?",
            text: "It will be permanently deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
          if (willDelete) {
            const data = dispatch(removePartner(id));
            if (data) {
              await swal({
                title: "Delete",
                text: "Partner deleted successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            }
          }
        } catch (err) {
          swal({
            title: "Error",
            text: err.message,
            icon: "warning",
            timer: 2000,
            button: false,
          });
        }
        break;

      default:
        return;
    }
  };

  const handleCustomRate = (res) => {
    dispatch({ type: ACTION_TYPES.SET_DRIVER_ID, payload: res.id });
  };

  const handleChange = (e, row, col) => {
    const { name, value } = e.target;
    if (isNaN(value)) return;
    onUpdate(Number(value), row, col);
  };

  const handleArea = (area) => {
    dispatch(setArea(area));
  };

  const handledReceiptDocument = (row) => {
    let hello = row?.driver?.firstName + " " + row?.driver?.lastName;
    if (row?.receiptDocuments?.length === 0) {
      swal({
        title: "No Result Available.",
        text: hello,
        icon: "error",
        button: "Close",
      });
    } else {
      handleModal(row);
    }
  };

  return (
    <>
      <div className="tab-content">
        <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
          <div className="table-responsive">
            <table
              className={
                "table table-hover table-row-dashed align-middle gs-0 gy-4 my-0"
              }
              style={{ border: "none" }}
            >
              <thead style={{ color: "#ffff" }}>
                <tr
                  className={"fs-7 fw-bold border-bottom-0 bg-light"}
                  style={{
                    fontSize: "15px",
                    color: "#838383",
                    backgroundColor: lable === "reports" && "#DBB768",
                  }}
                >
                  {tableHeads?.length &&
                    tableHeads.map((tble, index) => {
                      return tble === "Edit" ||
                        tble === "Hide" ||
                        tble === "Delete" ? (
                        <th
                          className={
                            index === 0 && lable !== "reports"
                              ? "min-w-200px"
                              : index === tableHeads?.length - 1 &&
                                lable !== "reports"
                              ? "min-w-200px"
                              : lable === "invoices"
                              ? "min-w-50px"
                              : lable ==="partners_invoices"
                              ? "min-w-50px"
                              : lable ==="partners"
                              ? "min-w-50px"
                              :"min-w-90px"
                          }
                        >
                          <span
                            style={
                              lable === "reports"
                                ? {
                                    display: "flex",
                                    padding: index === 0 && "5px",
                                    justifyContent: "center",
                                    color: "#ffff",
                                  }
                                : null
                            }
                          >
                            {tble}
                          </span>
                        </th>
                      ) : tble === "Package Rate" ||
                        tble === "Salary Rate" ||
                        tble === "Employment Type" ? (
                        <th
                          className={
                            index === 0
                              ? "min-w-200px rounded-start"
                              : index === tableHeads?.length - 1
                              ? "min-w-200px rounded-end"
                              : "min-w-150px"
                          }
                        >
                          <span
                            style={
                              lable === "reports"
                                ? {
                                    display: "flex",
                                    padding: index === 0 && "5px",
                                    justifyContent: "center",
                                    color: "#ffff",
                                  }
                                : null
                            }
                          >
                            {tble}
                          </span>
                        </th>
                      ) : lable === "reports" ? (
                        <th
                          className={
                            index === 0
                              ? "min-w-100px rounded-start"
                              : index === tableHeads?.length - 1
                              ? "min-w-100px rounded-end"
                              : "min-w-100px"
                          }
                          style={{ paddingRight: index === 0 && "70px" }}
                        >
                          <span
                            style={
                              lable === "reports"
                                ? {
                                    display: "flex",
                                    padding: index === 0 && "5px",
                                    marginRight:
                                      index === tableHeads?.length - 1 && "5px",
                                    justifyContent: "center",
                                  }
                                : null
                            }
                          >
                            {tble}
                          </span>
                        </th>
                      ) : lable === "individual_report" ? (
                        <th
                          className={
                            index === 0
                              ? "min-w-100px rounded-start"
                              : index === tableHeads?.length - 1
                              ? "min-w-100px rounded-end"
                              : "min-w-100px"
                          }
                          style={{ paddingRight: index === 0 && "70px" }}
                        >
                          <span
                            style={
                              lable === "reports"
                                ? {
                                    display: "flex",
                                    padding: index === 0 && "5px",
                                    marginRight:
                                      index === tableHeads?.length - 1 && "5px",
                                    justifyContent: "center",
                                  }
                                : null
                            }
                          >
                            {tble}
                          </span>
                        </th>
                      ) : lable === "invoices" ? (
                        <th
                          className={
                            index === 0
                              ? "min-w-50px"
                              : index === tableHeads?.length - 1
                              ? "min-w-100px"
                              : "min-w-100px"
                          }
                          style={{ padding: "0 !important" }}
                        >
                          <span style={{ padding: index === 0 && "5px" }}>
                            {tble}
                            {tble === "Driver Name" &&
                              (sortByAlphabetACE ? (
                                <span
                                  class="bi bi-sort-alpha-up"
                                  onClick={sortByAlphabet}
                                  style={{ padding: index === 0 && "5px" }}
                                ></span>
                              ) : (
                                <span
                                  class="bi bi-sort-alpha-down"
                                  onClick={sortByAlphabet}
                                  style={{ padding: index === 0 && "5px" }}
                                ></span>
                              ))}
                          </span>
                        </th>
                      ) : lable === "partners_invoices" ? (
                        <th
                          className={
                            index === 0
                              ? "min-w-50px"
                              : index === tableHeads?.length - 1
                              ? "min-w-100px"
                              : "min-w-100px"
                          }
                          style={{ padding: "0 !important" }}
                        >
                          <span style={{ padding: index === 0 && "5px" }}>
                            {tble}
                          </span>
                        </th>
                      ) : lable === "partners" ? (
                        <th
                          className={
                            index === 0
                              ? "min-w-50px"
                              : index === tableHeads?.length - 1
                              ? "min-w-100px"
                              : "min-w-100px"
                          }
                          style={{ padding: "0 !important" }}
                        >
                          <span style={{ padding: index === 0 && "5px" }}>
                            {tble}
                          </span>
                        </th>
                      ) : lable === "driver_package_invoices" ? (
                        <th
                          className={
                            index === 0
                              ? "min-w-50px"
                              : index === tableHeads?.length - 1
                              ? "min-w-100px"
                              : "min-w-100px"
                          }
                          style={{ padding: "0 !important" }}
                        >
                          <span style={{ padding: index === 0 && "5px" }}>
                            {tble}
                          </span>
                        </th>
                      ) : lable === "DriverTrackingData" ? (
                        <th
                          className={
                            index === 0
                              ? "min-w-100px"
                              : index === tableHeads?.length - 1
                              ? "min-w-100px"
                              : "min-w-100px"
                          }
                          style={{ padding: "0 !important" }}
                        >
                          <span style={{ padding: index === 0 && "5px" }}>
                            {tble}
                          </span>
                        </th>
                      ) : lable === "driver" ? (
                        <th
                          className={
                            index === 0 && lable !== "reports"
                              ? "min-w-200px"
                              : index === tableHeads?.length - 1 &&
                                lable !== "reports"
                              ? "min-w-200px"
                              : "min-w-200px"
                          }
                          style={{ padding: "0 !important" }}
                        >
                          <span style={{ padding: index === 0 && "5px" }}>
                            {tble}
                            {tble === "Driver Name" &&
                              (sortByAlphabetACE ? (
                                <span
                                  class="bi bi-sort-alpha-up"
                                  onClick={sortByAlphabet}
                                  style={{ padding: index === 0 && "5px" }}
                                ></span>
                              ) : (
                                <span
                                  class="bi bi-sort-alpha-down"
                                  onClick={sortByAlphabet}
                                  style={{ padding: index === 0 && "5px" }}
                                ></span>
                              ))}
                          </span>
                        </th>
                      ) : (
                        <th
                          className={
                            index === 0 && lable !== "reports"
                              ? "min-w-200px"
                              : index === tableHeads?.length - 1 &&
                                lable !== "reports"
                              ? "min-w-200px"
                              : "min-w-200px"
                          }
                          style={{ padding: "0 !important" }}
                        >
                          <span style={{ padding: index === 0 && "5px" }}>
                            {tble}
                            {tble === "Driver Name" &&
                              (sortByAlphabetACE ? (
                                <span
                                  class="bi bi-sort-alpha-up"
                                  onClick={sortByAlphabet}
                                  style={{ padding: index === 0 && "5px" }}
                                ></span>
                              ) : (
                                <span
                                  class="bi bi-sort-alpha-down"
                                  onClick={sortByAlphabet}
                                  style={{ padding: index === 0 && "5px" }}
                                ></span>
                              ))}
                          </span>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {lable === "driver"
                  ? tableRows?.length &&
                    tableRows?.map((user, index) => (
                      <tr key={index} className="table-div">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                style={{ padding: "5px" }}
                                // className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {user?.firstName + " " + user?.lastName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user?.areas?.map((area, i) => (
                              <p key={i}>
                                {area.areaName}
                                {user?.areas?.length - 1 === i ? "" : ","}
                              </p>
                            ))}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user?.mobileNumber}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user?.employmentType
                              ? user?.employmentType
                              : "none"}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user?.wages ? fixedValue(user?.wages) : 0}
                          </span>
                        </td>
                        <td>
                          <NavLink
                            to="/drivers/customRate"
                            onClick={() => {
                              handleRow(user);
                              handleCustomRate(user);
                            }}
                          >
                            View
                          </NavLink>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRow(user)}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleHideData(user)}
                        >
                          {user.status === "active" ? (
                            <i class="bi bi-eye fs-2" title="Hide"></i>
                          ) : (
                            <i class="bi bi-eye-slash fs-2" title="Unhide"></i>
                          )}
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user?.homeNumber}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {formatAbn(user?.abn)}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {format(user.tfn)}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user.carPlateRegistrationNumber}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {new Date(
                              user.registrationExpiryDate
                            ).toLocaleDateString()}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {new Date(
                              user.insuranceExpiryDate
                            ).toLocaleDateString()}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user.insurancePolicyNumber}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user.scannerNumber}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user.scannerUserName}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {user.scannerPassword}
                          </span>
                        </td>
                      </tr>
                    ))
                  : null}
                {lable === "area"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {row.areaName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {row.description}
                          </span>
                        </td>
                        <td
                          onClick={() => handleRow(row)}
                          style={{ cursor: "pointer" }}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                          {/* <NavLink
                  to="/areas/edit"
                >
                  {row.edit}
                </NavLink> */}
                        </td>
                      </tr>
                    ))
                  : null}
                {lable === "category"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {row.category}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {row.description}
                          </span>
                        </td>
                        <td
                          onClick={() => handleRow(row)}
                          style={{ cursor: "pointer" }}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                          <NavLink to="/expenses/category/edit">
                            {row.edit}
                          </NavLink>
                        </td>

                        <td style={{ cursor: "pointer", color: "blue" }}>
                          <i
                            class="bi bi-trash fs-2"
                            onClick={() => handleHide(row.id)}
                            title="Delete"
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
                {lable === "expenses"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {row?.id}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {new Date(row.date).toLocaleDateString()}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.expense_category?.category ||
                                  row.category}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {/* {row.amount} */}
                                {row?.amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {/* {row.status} */}
                                {row?.status === "paid" ? (
                                  <span class="badge badge-success">Paid</span>
                                ) : row?.status === "unpaid" ? (
                                  <span class="badge badge-danger">Unpaid</span>
                                ) : (
                                  <span class="badge badge-info">New</span>
                                )}
                                {/* <span class="badge badge-light-danger">Unpaid</span>
                                <span class="badge badge-light-primary fw-bold me-auto px-4 py-3">New</span>
                                <span class="badge badge-light-success fw-bold me-auto px-4 py-3">Paid</span> */}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.driver.firstName +
                                  " " +
                                  row.driver.lastName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handledReceiptDocument(row)}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "40%" }}
                          >
                            <i
                              class="bi bi-file-text fs-2 mx-auto"
                              title="Edit"
                            ></i>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.description}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleRow(row)}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                        </td>
                        <td style={{ cursor: "pointer", color: "blue" }}>
                          <i
                            class="bi bi-trash fs-2"
                            onClick={() => handleHide(row.id)}
                            title="Delete"
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
                {lable === "packages"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {row.id}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.type}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.packageCategory}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.description}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.area?.areaName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.ausPostRate}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.driverRate}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRow(row)}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleHideData(row)}
                        >
                          {row.status === "active" ? (
                            <i class="bi bi-eye fs-2" title="Hide"></i>
                          ) : (
                            <i class="bi bi-eye-slash fs-2" title="Unhide"></i>
                          )}
                        </td>
                      </tr>
                    ))
                  : null}
                {lable === "users"
                  ? tableRows?.length &&
                    tableRows?.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {data.first_name + " " + data.last_name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {data.email}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {data.phone_number}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {data.dob}
                          </span>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleRow(data)}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                        </td>
                        <td style={{ cursor: "pointer", color: "blue" }}>
                          <i
                            class="bi bi-trash fs-2"
                            onClick={() => handleHide(data.id)}
                            title="Delete"
                          ></i>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {
                              userType.filter(
                                (type) => data.role === type.value
                              )[0]?.title
                            }
                          </span>
                        </td>
                      </tr>
                    ))
                  : null}
                {lable === "salary"
                  ? tableRows?.length &&
                    tableRows.map((sal, i) => (
                      <tr key={i}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {sal.month}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
                {lable === "reports"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        {index !== tableRows?.length - 1 && (
                          <>
                            <td>
                              <div className="d-flex align-items-center">
                                <div
                                  className="d-flex justify-content-start"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100px",
                                  }}
                                >
                                  <div
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                                    style={{
                                      flex: "62%",
                                      padding: "2px",
                                      color: "white !important",
                                    }}
                                  >
                                    {row[0]?.driverFullName}
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        backgroundColor: `${checkForColor(
                                          row
                                        )}`,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <input
                                    type="text"
                                    name="a"
                                    className="form-control form-control-lg"
                                    placeholder="Enter a"
                                    onChange={(e) => handleChange(e, index, 1)}
                                    value={row[1]?.count}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <input
                                    type="text"
                                    name="b"
                                    className="form-control form-control-lg"
                                    placeholder="Enter b"
                                    onChange={(e) => handleChange(e, index, 2)}
                                    value={row[2]?.count}
                                  />
                                </div>
                              </div>
                            </td>
                            {/* <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column">
                          <input 
                          type="text"
                          name="c"
                          className="form-control form-control-lg"
                          placeholder="Enter c"
                          onChange = {(e)=>handleChange(e,index,3)}
                          value = {row[3]?.count}
                        />
                          </div>
                        </div>
                      </td> */}
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <input
                                    type="text"
                                    name="d"
                                    className="form-control form-control-lg"
                                    placeholder="Enter d"
                                    onChange={(e) => handleChange(e, index, 3)}
                                    value={row[3]?.count}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <input
                                    type="text"
                                    name="e"
                                    className="form-control form-control-lg"
                                    placeholder="Enter e"
                                    onChange={(e) => handleChange(e, index, 4)}
                                    value={row[4]?.count}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <input
                                    type="text"
                                    name="f"
                                    className="form-control form-control-lg"
                                    placeholder="Enter f"
                                    onChange={(e) => handleChange(e, index, 5)}
                                    value={row[5]?.count}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[5]?.packagesTotal}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <input
                                    type="text"
                                    name="g"
                                    className="form-control form-control-lg"
                                    placeholder="Enter category"
                                    onChange={(e) => handleChange(e, index, 6)}
                                    value={row[6]?.count}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div
                                  className="d-flex justify-content-start flex-column"
                                  style={{ padding: "10px" }}
                                >
                                  <input
                                    type="text"
                                    name="h"
                                    className="form-control form-control-lg"
                                    placeholder="Enter category"
                                    onChange={(e) => handleChange(e, index, 7)}
                                    value={row[7]?.count}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[8]?.hours}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))
                  : null}
                {lable === "reports" && (
                  <tr>
                    {totalPackages?.length &&
                      totalPackages.map((e, i) => (
                        <td key={i}>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-center flex-column w-100">
                              {
                                <p
                                  className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  style={{ textAlign: "center" }}
                                >
                                  {e}
                                </p>
                              }
                            </div>
                          </div>
                        </td>
                      ))}
                  </tr>
                )}
                {lable === "individual_report"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        {
                          <>
                            <td>
                              <div className="d-flex align-items-center">
                                <div
                                  className="d-flex justify-content-start"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100px",
                                  }}
                                >
                                  <div
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                                    style={{
                                      flex: "62%",
                                      padding: "2px",
                                      color: "white !important",
                                    }}
                                  >
                                    {row[0]?.driverFullName}
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        backgroundColor: `${checkForColor(
                                          row
                                        )}`,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[1]?.count}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[2]?.count}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[3]?.count}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[4]?.count}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[5]?.count}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[5]?.packagesTotal}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[6]?.count}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div
                                  className="d-flex justify-content-start flex-column"
                                  style={{ padding: "10px" }}
                                >
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[7]?.count}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <span
                                    href="#"
                                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                  >
                                    {row[8]?.hours}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </>
                        }
                      </tr>
                    ))
                  : null}
                {lable === "salary_expenses"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <tr key={index} onClick={() => handleRow(row)}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {months[row.month - 1]}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {new Date(row.start_date).toDateString()}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {new Date(row.start_date).toLocaleTimeString(
                                  "en-IT",
                                  { hour12: true }
                                )}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {new Date(row.finish_date).toDateString()}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {new Date(row.finish_date).toLocaleTimeString(
                                  "en-IT",
                                  { hour12: true }
                                )}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.area?.areaName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.driver?.firstName + row.driver?.lastName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.amount}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.status}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleRow(row)}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                        </td>
                        <td style={{ cursor: "pointer", color: "blue" }}>
                          <i
                            class="bi bi-trash fs-2"
                            onClick={() => handleHide(row.id)}
                            title="Delete"
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}

                {lable === "invoices"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {row.id}
                              </span>
                            </div>
                          </div>
                        </td>
                        {/* <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column">
                        <span
                          href="#"
                          className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                        >
                          {new Date(row.start_date).toDateString()}
                        </span>
                      </div>
                    </div>
                  </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.invoice_date.substring(0, 10)}
                                {/* {new Date(row.invoice_date).toLocaleTimeString('en-IT', { hour12: true }) } */}
                              </span>
                            </div>
                          </div>
                        </td>
                        {/* <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column">
                        <span
                          href="#"
                          className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                        >
                          {new Date(row.finish_date).toDateString()}
                        </span>
                      </div>
                    </div>
                  </td> */}
                        {/* <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column">
                        <span
                          href="#"
                          className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                        >
                          {new Date(row.finish_date).toLocaleTimeString('en-IT', { hour12: true })}
                        </span>
                      </div>
                    </div>
                  </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start align-items-center gap-2">
                            <span
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  backgroundColor: row?.driver?.driverType==="driver"?"#336667":"#FF5349",
                                  margin: "0 5px 0 10px",
                                }}/>
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.driver?.firstName + row.driver?.firstName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {row.area?.areaName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {/* {row.status} */}
                                {row?.status === "paid" ? (
                                  <span class="badge badge-success">Paid</span>
                                ) : row?.status === "unpaid" ? (
                                  <span class="badge badge-danger">Unpaid</span>
                                ) : (
                                  <span class="badge badge-info">New</span>
                                )}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleRow(row)}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                        </td>
                        <td style={{ cursor: "pointer", color: "blue" }}>
                          <i
                            class="bi bi-trash fs-2"
                            onClick={() => handleHide(row.id)}
                            title="Delete"
                          ></i>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleViewDownload(row)}
                        >
                          <i
                            class="bi bi-cloud-download fs-2"
                            title="View/Download"
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
                {lable === "partners_invoices"
                  ? tableRows?.length ?
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px"}}
                              >
                                {row?.id}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.createdAt.substring(0, 10)}
                                {/* {new Date(row.invoice_date).toLocaleTimeString('en-IT', { hour12: true }) } */}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.partner?.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.total_amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {/* {row.status} */}
                                {row?.status === "paid" ? (
                                  <span class="badge badge-success">Paid</span>
                                ) : row?.status === "unpaid" ? (
                                  <span class="badge badge-danger">Unpaid</span>
                                ) : row?.status === "new" ? (
                                  <span class="badge badge-info">New</span>
                                ) : row?.status === "partial" ? (
                                  <span class="badge badge-warning">Partial</span>
                                ) : <span class="badge badge-dark">Void</span>}
                                {/* <span class="badge badge-light-danger">Unpaid</span>
                                <span class="badge badge-light-primary fw-bold me-auto px-4 py-3">New</span>
                                <span class="badge badge-light-success fw-bold me-auto px-4 py-3">Paid</span> */}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleRow(row)}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                        </td>
                        <td style={{ cursor: "pointer", color: "blue" }}>
                          <i
                            class="bi bi-trash fs-2"
                            onClick={() => handleHide(row?.id)}
                            title="Delete"
                          ></i>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleViewDownload(row)}
                        >
                          <i
                            class="bi bi-cloud-download fs-2"
                            title="View/Download"
                          ></i>
                        </td>
                      </tr>
                    )):
                    (
                      <tr>
                        <td colSpan={6}>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                No data
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  : null}
                {lable === "partners" ? (
                  tableRows?.length ? (
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {row?.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.abn}
                                {/* {new Date(row.invoice_date).toLocaleTimeString('en-IT', { hour12: true }) } */}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.contractor_no}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.contractor_abn}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {row?.status?.charAt(0)?.toUpperCase() +
                                  row?.status?.slice(1)}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleRow(row)}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                        </td>
                        <td style={{ cursor: "pointer", color: "blue" }}>
                          <i
                            class="bi bi-trash fs-2"
                            onClick={() => handleHide(row.id)}
                            title="Delete"
                          ></i>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column">
                            <span
                              href="#"
                              className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              style={{ padding: "5px" }}
                            >
                              No data
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                ) : null}
                {lable === "driver_package_invoices"
                  ? tableRows?.length ?
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px"}}
                              >
                                {row?.id}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.createdAt.substring(0, 10)}
                                {/* {new Date(row.invoice_date).toLocaleTimeString('en-IT', { hour12: true }) } */}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row.driver?.firstName+" "+row.driver?.lastName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.total_amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.hours_worked?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {/* {row.status} */}
                                {row?.status === "paid" ? (
                                  <span class="badge badge-success">Paid</span>
                                ) : row?.status === "unpaid" ? (
                                  <span class="badge badge-danger">Unpaid</span>
                                ) : row?.status === "new" ? (
                                  <span class="badge badge-info">New</span>
                                ) : row?.status === "partial" ? (
                                  <span class="badge badge-warning">Partial</span>
                                ) : <span class="badge badge-dark">Void</span>}
                                {/* <span class="badge badge-light-danger">Unpaid</span>
                                <span class="badge badge-light-primary fw-bold me-auto px-4 py-3">New</span>
                                <span class="badge badge-light-success fw-bold me-auto px-4 py-3">Paid</span> */}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleRow(row)}
                        >
                          <i class="bi bi-pencil-square fs-2" title="Edit"></i>
                        </td>
                        <td style={{ cursor: "pointer", color: "blue" }}>
                          <i
                            class="bi bi-trash fs-2"
                            onClick={() => handleHide(row?.id)}
                            title="Delete"
                          ></i>
                        </td>
                        <td
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleViewDownload(row)}
                        >
                          <i
                            class="bi bi-cloud-download fs-2"
                            title="View/Download"
                          ></i>
                        </td>
                      </tr>
                    )):
                    (
                      <tr>
                        <td colSpan={6}>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                No data
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  : null}
                {lable === "DriverTrackingData"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <React.Fragment key={index}>
                        <tr
                          style={{
                            border: index === tableRows?.length - 1 && "none",
                          }}
                        >
                          <td rowSpan={row.data.length}>{row.driverName}</td>
                          <td>{row?.data[0]?.in_area_pause?.areaName}</td>
                          <td>{row?.data[0]?.out_area_pause?.areaName}</td>
                          <td>
                            {new Date(row?.data[0]?.log_in_time).toLocaleString(
                              undefined,
                              date_format
                            )}
                          </td>
                          <td>
                            {row?.data[0]?.log_out_time
                              ? new Date(
                                  row?.data[0]?.log_out_time
                                ).toLocaleString(undefined, date_format)
                              : "-"}
                          </td>
                          <td>{row?.data[0]?.runing_Status}</td>
                          <td rowSpan={row?.data.length}>{row.hours}</td>
                          <td
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() => handleRow(row?.data[0])}
                          >
                            <i
                              class="bi bi-pencil-square fs-2"
                              title="Edit"
                            ></i>
                          </td>
                          <td style={{ cursor: "pointer", color: "blue" }}>
                            <i
                              class="bi bi-trash fs-2"
                              onClick={() => handleHide(row?.data[0]?.id)}
                              title="Delete"
                            ></i>
                          </td>
                        </tr>
                        {row?.data.slice(1).map((item, subIndex) => (
                          <tr key={subIndex + 1}>
                            <td>
                              {row?.data[subIndex + 1]?.in_area_pause?.areaName}
                            </td>
                            <td>
                              {
                                row?.data[subIndex + 1]?.out_area_pause
                                  ?.areaName
                              }
                            </td>
                            <td>
                              {new Date(
                                row?.data[subIndex + 1]?.log_in_time
                              ).toLocaleString(undefined, date_format)}
                            </td>
                            <td>
                              {row?.data[subIndex + 1]?.log_out_time
                                ? new Date(
                                    row?.data[subIndex + 1]?.log_out_time
                                  ).toLocaleString(undefined, date_format)
                                : "-"}
                            </td>
                            <td>{row?.data[subIndex + 1]?.runing_Status}</td>
                            <td
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={() => handleRow(row?.data[subIndex + 1])}
                            >
                              <i
                                class="bi bi-pencil-square fs-2"
                                title="Edit"
                              ></i>
                            </td>
                            <td style={{ cursor: "pointer", color: "blue" }}>
                              <i
                                class="bi bi-trash fs-2"
                                onClick={() =>
                                  handleHide(row?.data[subIndex + 1]?.id)
                                }
                                title="Delete"
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                  : null}
                {lable === "DriverTrackingList"
                  ? tableRows?.length &&
                    tableRows?.map((user, index) => (
                      <tr key={index} className="table-div">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                style={{ padding: "5px" }}
                                // className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {user?.firstName || user?.lastName
                                  ? user?.firstName + " " + user?.lastName
                                  : user?.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRow(user)}
                        >
                          <i class="bi bi-pin-map fs-2" title="Location"></i>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                style={{ padding: "5px" }}
                                // className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {user?.locationTime
                                  ? new Date(
                                      user?.locationTime
                                    )?.toLocaleString("en-US", { hour12: true })
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </td>
                        {/* <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleHideData(user)}
                        >
                          {user.status === "active" ? (
                            <i class="bi bi-eye fs-2" title="Hide"></i>
                          ) : (
                            <i class="bi bi-eye-slash fs-2" title="Unhide"></i>
                          )}
                        </td> */}
                      </tr>
                    ))
                  : null}
                {lable === "InOutTimmings"
                  ? tableRows?.length &&
                    tableRows?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {row?.id}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                {row?.expense_category?.category ||
                                  row.category}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {new Date(row.date).toLocaleDateString()}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {new Date(row.date).toLocaleDateString()}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                href="#"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                                style={{ padding: "5px" }}
                              >
                                {new Date(row.date).toLocaleDateString()}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
