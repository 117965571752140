import {ACTION_TYPES} from "../constants"
const INITIAL_STATE = {
    invoices: [],
    allInvoices: [],
    loader: false,
    invoice: {},
    selected_invoice: {}
}

const invoices =  (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case ACTION_TYPES.INVOICES_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.INVOICES_SUCCESS:
            return {...state,invoices: action.payload,loader: false};
        case ACTION_TYPES.INVOICES_FAILED:
            return {...state,loader: false};

        case ACTION_TYPES.GET_ALL_INVOICES_REQUEST:
            return {...state,loader: true};
        case ACTION_TYPES.GET_ALL_INVOICES_SUCCESS:
            return {...state,allInvoices: action.payload,loader: false};
        case ACTION_TYPES.GET_ALL_INVOICES_FAILED:
            return {...state,loader: false};
        
        case ACTION_TYPES.SET_INVOICE_SUCCESS:
            return {...state,selected_invoice: action.payload,loader: false};
        default:
            return state
    }
}

export default invoices;