import React, { useState, useEffect, useMemo } from "react";
import { tableReportHeads, tableReportHeadsOverView } from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../components/common";
import { fetchTotalPackages, getDailyEntrySummary, getDailyEntrySummaryByDriver, getReports, updateReport } from "../../actions";
import { Selector, CheckBox } from "../../components/common";
import { CSVLink } from "react-csv";
import { ViewDatePicker } from "../../components/common";
import { createPortal } from "react-dom";
import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';


const checks = [
  {
    id: "color1",
    name: "Area Changed Drivers",
    color: "#6996BD",
  },
  {
    id: "color2",
    name: "Hidden Drivers",
    color: "#336667",
  },
  {
    id: "color3",
    name: "Active Drivers",
    color: "#009BD1",
  },
  {
    id: "color4",
    name: "Hidden Packages",
    color: "#42C4DC",
  },
];

const driverTypeOptions = ["all", "active", "hidden"];

const VirtualizedTable = ({ summaryReports, tableReportHeadsOverView, handleDriverSelect }) => {

  const rowRenderer = ({ key, index, style }) => {
    const ele = summaryReports[index];
    return (
      <div key={key} style={{...style}}>
        <table
          className="table table-hover table-row-dashed align-middle gs-0 gy-4 my-4"
          style={{
            border: "none",
            borderCollapse: "separate",
            borderSpacing: "0 1em",
          }}
        >
          <thead style={{ color: "#ffff" }}>
            <tr
              className="fs-7 fw-bold border-bottom-0 bg-light"
              style={{
                fontSize: "15px",
                color: "#838383",
                backgroundColor: "#DBB768",
              }}
            >
              {tableReportHeadsOverView?.length &&
                tableReportHeadsOverView.map((tble, index) => (
                  <th
                    key={index}
                    className={
                      index === 0
                        ? "min-w-100px rounded-start"
                        : index === tableReportHeadsOverView?.length - 1
                        ? "min-w-100px rounded-end"
                        : "min-w-100px"
                    }
                    style={{
                      paddingRight: index === 0 && "70px",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        padding: index === 0 && "5px",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0
                        ? new Date(ele.date).toLocaleDateString()
                        : tble}
                    </span>
                  </th>
                ))}
            </tr>
            <tr
              className="fs-7 fw-bold border-bottom-0 bg-light"
              style={{
                fontSize: "15px",
                color: "#838383",
                backgroundColor: "#DBB768",
              }}
            >
              <th className="min-w-100px rounded-start" style={{ paddingRight: "70px" }}>
                <span style={{ display: "flex", padding: "5px", justifyContent: "center", color: "rgb(62, 151, 255)" }}>
                  Total/Packages
                </span>
              </th>
              {['A', 'B', 'D', 'E', 'F', 'G', 'H'].map((key, index) => (
                <th key={index} className="min-w-100px">
                  <span style={{ display: "flex", justifyContent: "center" }}>
                    {ele[key]}
                  </span>
                </th>
              ))}
               <th key={index} className="min-w-100px">
                  <span style={{ display: "flex", justifyContent: "center" }}>
                    {['A', 'B', 'D', 'E', 'F', 'G', 'H'].reduce((intitalValue,sum)=>(ele[sum]+intitalValue),0)}
                  </span>
                </th>
            </tr>
          </thead>
        </table>
        <div
          className="rounded fs-7 fw-bold border-bottom-0 bg-light"
          style={{
            display: "flex",
            width: "100%",
            fontSize: "15px",
            color: "rgb(62, 151, 255)",
            backgroundColor: "#DBB768",
            paddingLeft: "40px",
            paddingRight: "20px",
            marginTop: "-12px",
            marginBottom: "20px"
          }}
        >
          <span style={{ 
            display: "flex", 
            justifyContent: "flex-start", 
            padding: "8px 0", 
            minWidth: "22%", // Changed from width to minWidth
            flexShrink: 0 // Prevent this span from shrinking
          }}>
            Drivers
          </span>
          <span style={{ 
            overflowX: "auto",
            display: "flex", 
            justifyContent: "flex-start", 
            padding: "8px 0",
            flexGrow: 1, // Allow this span to grow
            whiteSpace: "nowrap" // Prevent wrapping of content
          }}>
            <style>{`
              .my-div:hover {
                color: #00008B;
                text-decoration: underline;
              }
            `}</style>
            {ele.drivers?.length
              ? ele.drivers.map((item, index) => (
                  <span
                    key={index}
                    onClick={() => handleDriverSelect({...item, date: ele.date})}
                    className="my-div"
                    style={{ marginRight: "5px" }} // Add some space between driver names
                  >
                    {index < ele.drivers.length - 1 ? `${item?.full_name}, ` : item?.full_name}
                  </span>
                ))
              : "No drivers found!"}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div style={{height: '550px' }}> {/* Adjust height as needed */}
      <AutoSizer>
        {({ height, width }) => (
          <List
            width={width}
            height={height}
            rowCount={summaryReports.length}
            rowHeight={200} // Adjust this value based on your actual row height
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </div>
  );
};


function DailyEntry() {
  const dispatch = useDispatch();
  const { totalPackages: uniquePackages } = useSelector(
    ({ packages }) => packages
  );
  const {
    reports: reportss = [],
    loader,
    summaryReports,
  } = useSelector(({ reports }) => reports);
  const { options = [] } = useSelector(({ area }) => area);
  const [reports, setReports] = useState(reportss);
  const [toggle, setToggle] = useState(false);

  const [driverType, setDriverType] = useState(driverTypeOptions[0]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedDriverReports, setSelectedDriverReports] = useState(null);
  const [driverReportLoading, setDriverReportLoading] = useState(false)

  const [state, setState] = useState({
    date: new Date().toISOString(),
    areaId: options[0].id, //static
  });
  const [state1, setState1] = useState({
    area_id: options[0].id,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
  });
  useEffect(() => {
    if (driverType === driverTypeOptions[0]) setReports(reportss);
    else {
      const newReports = reportss?.filter(
        (ele) => ele?.[0]?.driverStatus === driverType
      );
      setReports(newReports);
    }
  }, [reportss, driverType]);

  const totalPackages = useMemo(() => {
    if (reportss?.length) {
      let temp = [...reportss[reportss.length - 1]];
      return temp;
    }
    return ["Total/packages", 0, 0, 0, 0, 0, 0, 0, 0];
  }, [JSON.stringify(reportss)]);

  useEffect(() => {
    dispatch(getReports(state));
    dispatch(getDailyEntrySummary(state1));
    dispatch(fetchTotalPackages())
  }, []);

  const onChange = (k, v) => {
    // console.log(k)
    if (k === "date") {
      setState({ ...state, [k]: new Date(v).toISOString() });
    } else setState({ ...state, [k]: v });
    dispatch(
      getReports({ date: new Date(v).toISOString(), areaId: state.areaId })
    );
  };

  const handleSelector = (key, value) => {
    setState({ ...state, [key]: value });
    dispatch(getReports({ date: state.date, areaId: value }));
  };

  const onUpdate = (val, row, col) => {
    const newReports = reports.map((rows, index) => {
      if (row === index) {
        const temp = rows.map((cols, ind) => {
          if (col === ind) return { ...cols, count: val };
          return cols;
        });
        return temp;
      }
      return rows;
    });
    //it creates a new Row and a new col
    //const newReports = [...reports,[...reports[row],{...report[row][col],count: newVal}]]
    setReports(newReports);
  };
  const data = [
    reports.slice(0, reports.length - 1).map((val) => {
      return {
        Driver: val[0]?.driverFullName,
        A: val[1]?.count,
        B: val[2]?.count,
        D: val[3]?.count,
        E: val[4]?.count,
        F: val[5]?.count,
        "C-1 Total": val[5]?.packagesTotal,
        G: val[6]?.count,
        H: val[7]?.count,
      };
    }),
  ];
  const total = {
    Driver: totalPackages[0],
    A: totalPackages[1],
    B: totalPackages[2],
    D: totalPackages[3],
    E: totalPackages[4],
    F: totalPackages[5],
    "C-1 Total": totalPackages[6],
    G: totalPackages[7],
    H: totalPackages[8],
  };

  data[0].push(total);
  const onSubmitUpdate = () => {
    const createNew = [],
      update = [];
    let copy_reports = reports.slice(0, -1);

    copy_reports.forEach((row, index) => {
      row.forEach((col, ind) => {
        if (ind !== 0) {
          if (col.id === 0) createNew.push(col);
          else if (col.id > 0 && col.count > 0) update.push(col);
        }
      });
    });

    const updateReports = {
      date: state.date,
      createDailyEntry: createNew, //which has zero id
      updateDailyEntry: update, //which has some count
    };
    dispatch(updateReport(updateReports, state));
  };

  const onSubmitDailyEntrySummary = () => {
    dispatch(getDailyEntrySummary(state1));
  };

  const handleDriverSelect = (value) => {
    setSelectedDriver(value);
    getDailyEntrySummaryByDriver(
      {
        date: value?.date, 
        areaId: state1?.area_id
      }, 
      value,setSelectedDriverReports,
      setDriverReportLoading
      )
    setShowModal(true);
  };



  return (
    <div className="tab-content">
      <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
        <div className="table-responsive">
          <div className="card-title flex-column">
            <h3 className="fw-bold mb-1">
              Daily Package Run Information-Add Entries
            </h3>
          </div>
          {/* <hr /> */}
          <div style={{ display: "flex", margin: "0 10px" }}>
            {!toggle && (
              <div className="row mb-6" style={{ width: "21%" }}>
                <label className="col-form-label fw-semibold fs-6">From</label>
                <div className="col-lg-8 col-md-6 fv-row">
                  <ViewDatePicker
                    name="startDate"
                    onChange={(value) =>
                      setState1({
                        ...state1,
                        startDate: new Date(value).toISOString(),
                      })
                    }
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Date"
                    value={state1.startDate.substring(0, 10)}
                  />
                </div>
              </div>
            )}
            <div className="row mb-6" style={{ width: "21%" }}>
              <label className="col-form-label fw-semibold fs-6">
                {toggle ? "Date" : "To"}
              </label>
              <div className="col-lg-8 col-md-6 fv-row">
                <ViewDatePicker
                  name={toggle ? "date" : "to"}
                  onChange={(value) =>
                    toggle
                      ? onChange("date", value)
                      : setState1({
                          ...state1,
                          endDate: new Date(value).toISOString(),
                        })
                  }
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Date"
                  value={
                    toggle
                      ? state.date.substring(0, 10)
                      : state1.endDate.substring(0, 10)
                  }
                />
              </div>
            </div>
           
            <div className="row mb-6" style={{ width: "20%" }}>
              <label className="col-form-label fw-semibold fs-6">
                Delivery Area
              </label>
              <div className="col-lg-8 col-md-6 fv-row">
                <Selector
                  style={{ width: "100% !important" }}
                  data={options.map((it) => {
                    return {
                      value: it.id,
                      title: it.areaName,
                    };
                  })}
                  onChange={(value) =>
                    toggle
                      ? handleSelector("areaId", value)
                      : setState1({ ...state1, area_id: Number(value) })
                  }
                  value={toggle ? state.areaId : state1.area_id}
                />
              </div>
            </div>

            {toggle && (
              <div className="row mb-6" style={{ width: "20%" }}>
                <label className="col-form-label fw-semibold fs-6">
                  Driver Type
                </label>
                <div className="col-lg-8 col-md-6 fv-row">
                  <Selector
                    style={{ width: "100% !important" }}
                    name={"driver_type"}
                    data={driverTypeOptions.map((it) => {
                      return {
                        value: it,
                        title: it,
                      };
                    })}
                    onChange={(value) => setDriverType(value)}
                    value={driverType}
                  />
                  {/* <div class="fv-plugins-message-container invalid-feedback">
                      <div data-field="fname" data-validator="notEmpty">
                      {formik.touched.driver_type && formik.errors.driver_type ? <div>{formik.errors.driver_type}</div> : null}</div>
                    </div> */}
                  {/*end::Row*/}
                </div>
                {/*end::Col*/}
              </div>
            )}
            
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flex: 1,
              }}
            >
              {!toggle && (
                <div style={{ margin: "10px" }}>
                  <button
                    style={{ marginTop: "20px" }}
                    type="submit"
                    name="description"
                    className="btn btn-primary "
                    id="kt_account_profile_details_submit"
                    onClick={onSubmitDailyEntrySummary}
                  >
                    Search
                  </button>
                </div>
              )}
              <div>
                <button
                  style={{ marginTop: "20px" }}
                  type="submit"
                  name="description"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  onClick={() => setToggle(!toggle)}
                >
                  {toggle ? "Daily Reports" : "Driver Runs"}
                </button>
              </div>
              <div style={{ margin: "10px" }}>
                <CSVLink
                  data={data[0]}
                  filename={"DailyReports.csv"}
                  target="_blank"
                >
                  <button
                    style={{ marginTop: "20px" }}
                    type="submit"
                    name="description"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    // onClick={handleSubmit}
                  >
                    Export
                  </button>
                </CSVLink>
              </div>
              <div>
                <button
                  style={{ marginTop: "20px" }}
                  type="submit"
                  name="description"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                  onClick={onSubmitUpdate}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <hr />
          {toggle ? (
            <>
              {loader ? (
                <h3>Loading...</h3>
              ) : (
                <Table
                  lable="reports"
                  tableRows={reports}
                  tableHeads={tableReportHeads}
                  onUpdate={onUpdate}
                  totalPackages={totalPackages}
                />
              )}
              <hr />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {checks.map((checkName, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: `${checkName.color}`,
                          margin: "0 5px 0 10px",
                        }}
                      ></div>
                      <div>{checkName.name}</div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="tab-content">
              <div
                className="tab-pane fade active show"
                id="kt_stats_widget_6_tab_1"
              >
                <div className="table-responsive">
                  {summaryReports.length?
                    <VirtualizedTable 
                    summaryReports={summaryReports}
                    tableReportHeadsOverView={tableReportHeadsOverView}
                    handleDriverSelect={handleDriverSelect}
                    />
                    :
                    <h3>No result found !</h3>}
              
                  <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "8px" }}
                  >
                    {uniquePackages?.rows?.length
                      ? uniquePackages?.rows?.map((item, index) => (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ gap: "8px" }}
                          >
                            <p className="fw-bold text-primary fs-8 mt-4">
                              {`${item?.type}`}
                            </p>
                            <p className="fw-bold text-primary fs-8 mt-4">
                              {`[${item?.description}]`}
                            </p>

                            {index < uniquePackages?.rows?.length - 1 && (
                              <div
                                className="border border-1 border-primary"
                                style={{ height: "14px", marginLeft: "1px" }}
                              />
                            )}
                          </div>
                        ))
                      : 'no data'}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showModal &&
        createPortal(
          <div
            style={{
              position: "fixed",
              left: "",
              top: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(0, 0, 0, 0.2)",
              zIndex: 150,
            }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            <div
              style={{
                width: "60%",
                height: "auto",
                background: "white",
                borderRadius: "20px",
                padding: "30px",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <label
                className="fw-bold fs-6 text-gray-700"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                {` Driver: ${selectedDriver?.full_name}`}
              </label>
              {driverReportLoading? 
              <h3 style={{textAlign: 'center'}}>Loading...</h3>:
              selectedDriverReports?.length?
                <Table
                lable="individual_report"
                tableRows={selectedDriverReports}
                tableHeads={tableReportHeads}
              />:
              <h3 style={{textAlign: 'center'}}>No result found !</h3>
             }
            </div>
          </div>,
          document.body
        )}
    </div>
  );
}

export default DailyEntry;
